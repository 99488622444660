import React, { useMemo } from 'react';
import ASCIIFolder from 'fold-to-ascii';
import { ClassNameMap } from '@mui/styles';
import { Divider, MenuItem } from '@mui/material';

import Select from '../Select/Select';
import useStyles from './CountriesSelect.styles';
import type { CountriesSelectProps, SelectableCountry } from './CountriesSelect.types';

/**
 * All the countries have been exported from the restcountries public API by using the
 * scripts/generateCountries script:
 *
 * Public API: https://restcountries.com/v3.1/all?fields=name,cca3,flags,translations
 */
import countriesSections from '../utils/countries.json';

export const countries: Array<SelectableCountry> = countriesSections.flat();

type CountryProps = {
    locale: string;
    country: SelectableCountry;
    onChange?: CountriesSelectProps['onChange'];
    classes: ClassNameMap<'flag' | 'country'>;
};

const renderCountry = (props: CountryProps) => {
    const { country, locale, classes, onChange } = props;
    return (
        <MenuItem
            onClick={() => onChange && onChange(country.id, country)}
            className={classes.country}
            key={country.id}
            value={country.id}
        >
            <img
                className={classes.flag}
                src={country.flag}
                width={16}
                height={16}
                alt={country.cca3}
            />
            <span>{country.translations[locale]}</span>
        </MenuItem>
    );
};

const CountriesSelect = (props: CountriesSelectProps) => {
    const { onChange, value, locale: localeProp, ...others } = props;
    const shortLocale = localeProp?.substring(0, 2) || 'de';
    const classes = useStyles();

    const sortedCountriesSection = useMemo(() => {
        return [
            countriesSections[0],
            countriesSections[1]?.sort((a, b) => {
                const aBase = (a.translations as Record<string, string>)[shortLocale];
                const bBase = (b.translations as Record<string, string>)[shortLocale];
                const aSort = ASCIIFolder.foldReplacing(aBase);
                const bSort = ASCIIFolder.foldReplacing(bBase);
                return aSort > bSort ? 1 : aSort < bSort ? -1 : 0;
            })
        ];
    }, [shortLocale]);

    const renderCountriesSection = (
        countriesSection: Array<SelectableCountry> | undefined = [],
        index: number
    ) => {
        if (index + 1 < sortedCountriesSection.length) {
            return [
                countriesSection.map(renderOption),
                <Divider className={classes.divider} key={index} />
            ];
        }
        return countriesSection.map(renderOption);
    };

    const renderOption = (country: SelectableCountry) => {
        const handleChange = (countryId: string) => {
            onChange && onChange(countryId, country);
        };
        return renderCountry({ classes, country, locale: shortLocale, onChange: handleChange });
    };

    const renderValue = () => {
        const country = countries.find((country) => country.id === value);
        if (country) {
            return renderCountry({ classes, country, locale: shortLocale });
        }
        return null;
    };

    const handleClear = () => onChange && onChange('', null);

    return (
        <Select value={value} renderValue={renderValue} onClear={handleClear} {...others}>
            {sortedCountriesSection.map(renderCountriesSection)}
        </Select>
    );
};

export default CountriesSelect;
