import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    durationContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    progress: {
        width: 88,
        height: 3,
        borderRadius: 1,
        marginLeft: theme.spacing(1),
        backgroundColor: theme.palette.body[400]
    }
}));

export default useStyles;
