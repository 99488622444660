import React from 'react';
import Script from 'next/script';
import env from '../../env';

const GTM = () => {
    const GTM_ID = env.GTM_ID;
    return (
        <>
            <Script id="google-tag-manager" strategy="afterInteractive">
                {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${GTM_ID}');`}
            </Script>
            <Script>{`window.dataLayer = window.dataLayer || [];`}</Script>
        </>
    );
};

export default GTM;
