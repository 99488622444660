const getKeyForLocalizedLink = (locale: Locale): DomainForLinks => {
    const localeMap = {
        'en-GB': 'uk',
        'de-DE': 'de',
        'de-AT': 'at',
        'de-CH': 'de-CH',
        'fr-CH': 'fr-CH'
    } as const;

    const domainForLink: DomainForLinks = localeMap[locale];

    return domainForLink;
};

const getLocalizedLink = <Value extends string | null>(
    urlByDomain: Record<string, Value>,
    locale: Locale
): Value => {
    const link = urlByDomain[getKeyForLocalizedLink(locale)];

    if (link) {
        return link;
    }

    return '' as Value;
};

export default getLocalizedLink;
