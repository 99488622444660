export const buildURLWithParams = (URL: string, params?: Array<string>): string => {
    let parts = URL.split('/');
    let index = 0;
    parts = parts
        .map((part) => {
            if (part.startsWith('[') && params && params.length > index) {
                const nextPart = params[index++];
                return typeof nextPart !== 'undefined' ? nextPart : undefined;
            }
            return part;
        })
        .filter(
            (item: string | undefined): item is string =>
                typeof item !== 'undefined' && item.length > 0
        );
    // Add a leading '/' if the original URL had it
    if (URL.startsWith('/')) {
        return '/' + parts.join('/');
    } else {
        return parts.join('/');
    }
};
