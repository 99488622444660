import { useCallback, useEffect, useMemo, useState } from 'react';
import _uniqBy from 'lodash/uniqBy';
import { Fragments, objectKeys, Queries, Types, UserMembership, UserOptions } from 'common';
import { Event, EventItemsSelection, EventItemsSelectionItem, ParticipantTypeState } from './types';

export const ENTIRE_EVENT_ID = 'ENTIRE_EVENT';
export const INVITATION_PARTICIPANT_TYPE = 'EIN';

type ParticipantType = Fragments.ParticipantTypeFieldsFragment;
export const getPriceListParticipantTypes = (
    eventData: Fragments.BookingEventFieldsFragment | null | undefined,
    userBooking: Fragments.BookingFieldsFragment | null | undefined
): Array<ParticipantType> => {
    const unorderedPriceListParticipantTypes = _uniqBy(
        (eventData?.items || []).reduce<Array<ParticipantType>>((acc, item) => {
            item.participantTypePrices.forEach((participantTypePrice) => {
                acc.push(participantTypePrice.participantType);
            });
            return acc;
        }, []),
        'id'
    );

    /**
     * Ordering participant types by their occupation type
     */
    const priceListParticipantTypes = UserOptions.occupationType.reduce<Array<ParticipantType>>(
        (acc, occupationType) => {
            const participantTypes = unorderedPriceListParticipantTypes.filter(
                (participantType) => participantType.occupationType === occupationType.id
            );
            acc.push(...participantTypes);
            return acc;
        },
        []
    );

    if (userBooking?.participantType?.code === INVITATION_PARTICIPANT_TYPE) {
        priceListParticipantTypes.unshift(userBooking.participantType);
    }

    return priceListParticipantTypes;
};

/**
 * Handle logic of filter and choosing default participant type
 * @param eventData - response of getEventForBooking query
 * @param user - response of getUserForDashboard query
 * @param watchForUserChange - `true` for admin. Updates default option when choosing a user (@deprecated param)
 * @param previousParticipantType - participant type chosen during initial booking, used during updating booking
 */
export const useParticipantTypeState = ({
    eventData,
    user,
    watchForUserChange,
    previousParticipantType,
    userBooking
}: {
    eventData: Fragments.BookingEventFieldsFragment | null;
    user: Queries.GetUserForDashboardQuery['user'];
    watchForUserChange?: boolean;
    previousParticipantType: Types.ParticipantTypeR | null | undefined;
    userBooking: Fragments.BookingFieldsFragment | null | undefined;
}) => {
    const priceListParticipantTypes = useMemo(
        () => getPriceListParticipantTypes(eventData, userBooking),
        [eventData]
    );
    const userParticipantType =
        previousParticipantType &&
        (previousParticipantType.occupationType === user?.occupationType ||
            userBooking?.participantType?.code === INVITATION_PARTICIPANT_TYPE)
            ? previousParticipantType
            : priceListParticipantTypes.find(
                  (participantType) => participantType.occupationType === user?.occupationType
              );

    const [participantTypeState, setParticipantTypeState] = useState<ParticipantTypeState>({
        old: null,
        current: userParticipantType || null
    });

    /**
     * Update default chosen option when user changes. Used in booking for admin UI
     */
    useEffect(() => {
        if (watchForUserChange) {
            setParticipantTypeState({
                old: null,
                current: userParticipantType || null
            });
        }
    }, [user]);

    const changeParticipantType = useCallback(
        (newParticipantType: Types.ParticipantTypeR) => {
            setParticipantTypeState({
                old: participantTypeState.current,
                current: newParticipantType
            });
        },
        [participantTypeState.current]
    );

    const participantTypeId = participantTypeState.current?.id;
    const currentOccupationType = participantTypeState.current?.occupationType;

    const pendingOccupationType = priceListParticipantTypes.find(
        (item) => item.id === participantTypeId
    )?.occupationType;

    return {
        participantTypeState,
        priceListParticipantTypes,
        changeParticipantType,
        participantTypeId,
        currentOccupationType,
        pendingOccupationType
    };
};

export const transformSelectionsObjectToArray = (
    selectionIds: EventItemsSelection
): Array<EventItemsSelectionItem> =>
    objectKeys(selectionIds).reduce<Array<EventItemsSelectionItem>>((acc, id) => {
        const selection = selectionIds[id];
        if (selection?.selected) {
            acc.push(selection);
        }
        return acc;
    }, []);

/**
 * This function is useful to get the discount given the promo code and the total price
 * @param promoCodeApplied
 * @param price
 * @returns the discount
 */
export const getDiscount = (
    promoCodeApplied: Fragments.PromoCodeForOrderFieldsFragment | null,
    price: number
) => {
    /**
     * We divide percentage value by 100 twice because we store it in a way that
     * `10%` will be `1000` in DB
     */
    return promoCodeApplied
        ? promoCodeApplied.promoCodeType === Types.PromoCodeType.Percentage
            ? price * (promoCodeApplied.promoAmount / 100 / 100)
            : promoCodeApplied.promoAmount
        : 0;
};

export const getIsPromoCodeAllowedForEvent = (
    event: Event,
    bookingRequest: Omit<Types.BookingRequest, 'promoCode'> | undefined,
    userMembership: UserMembership | null | undefined,
    selectedItems: Array<EventItemsSelectionItem>
): bookingRequest is Omit<Types.BookingRequest, 'promoCode'> => {
    // can't use promo code if event doesn't have any
    if (!event.arePromoCodesAvailable) {
        return false;
    }
    // can't use event promo code if not booking an event
    if (!bookingRequest) {
        return false;
    }
    // can't use event promo code if member only pays for printed coursebook
    if (userMembership) {
        const payedItems = selectedItems.filter(
            (item) =>
                getTicketPrice(item) > 0 && item.ticketType !== Types.TicketType.PrintedCourseBook
        );
        return payedItems.length > 0;
    }

    return true;
};

export const getIsPromoCodeAllowedForMembership = (
    membershipToOrderId: Types.UUID | null | undefined
): membershipToOrderId is Types.UUID => {
    return !!membershipToOrderId;
};

/**
 * Info about different targets is located in `booking-logic/types.ts` file in `EventItemTransformed` type
 * @param selectedItems
 * @param target
 */
export const getSelectedItemsAccreditationPoints = (
    selectedItems: Array<EventItemsSelectionItem>,
    target: 'MEMBERSHIP_DEDUCTION' | 'OVERALL_CALCULATION'
) => {
    const accreditationItemKey =
        target === 'MEMBERSHIP_DEDUCTION'
            ? 'accreditationItemForMembershipDeduction'
            : 'accreditationItemForOverallCalculation';
    const result = selectedItems.reduce(
        (acc, item) => acc + (item[accreditationItemKey]?.amount || 0),
        0
    );
    return result;
};

export const getTicketPrice = (ticket: {
    price: number;
    priceWithDiscount: number | null;
}): number => (ticket.priceWithDiscount != null ? ticket.priceWithDiscount : ticket.price);
