import { useMediaQuery, useTheme } from '@mui/material';

/**
 *
 * @property {boolean} [defaultMatches] - Sets the return value to be used on SSR where
 * this data is not available. By default it's set using the user agent of the request.
 */
const useIsDesktop = (config?: { defaultMatches?: boolean }) => {
    const theme = useTheme();
    const { defaultMatches } = config || {};
    const defaultMatchesToUse = defaultMatches ? defaultMatches : theme.isMobile ? false : true;

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: defaultMatchesToUse,
        noSsr: true
    });

    return isDesktop;
};

export default useIsDesktop;
