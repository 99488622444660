import { COUNTRY_CODE_3_TO_COUNTRY_CODE, Fragments, isFeatureEnabled } from '../';

/**
 * EFN (for DE) uses Luhn (or mod 10) algorithm to check validity of number
 * https://www.geeksforgeeks.org/luhn-algorithm/
 * EFN should be 15 numbers long
 */
export const verifyEFN = (efnNumber: string): boolean => {
    if (efnNumber.length !== 15) {
        return false;
    }
    if (!efnNumber.startsWith('80276') && !efnNumber.startsWith('83276')) {
        return false;
    }

    const checkSum = efnNumber
        .split('')
        .map((item) => parseInt(item))
        .reverse()
        .map((item, index) => ((index + 1) % 2 === 0 ? item * 2 : item))
        .map((item, index) =>
            (index + 1) % 2 === 0 && item > 9 ? (item % 10) + Math.floor(item / 10) : item
        )
        .reduce((acc, item) => acc + item, 0);

    const isValid = checkSum % 10 === 0;
    return isValid;
};

/**
 * GLN (for CHE) uses GTIN-13 algorithm to check validity of number
 * https://www.gs1.org/services/how-calculate-check-digit-manually
 * GLN should be 13 numbers long
 */
export const verifyGLN = (glnNumber: string): boolean => {
    if (glnNumber.length !== 13) {
        return false;
    }
    if (!glnNumber.startsWith('7601')) {
        return false;
    }

    const parsedDigits = glnNumber.split('').map((item) => parseInt(item, 10));

    // take last digit as check digit
    const checkDigit = parsedDigits[glnNumber.length - 1] ?? NaN;

    // take all but last digits and accumulate them with multiplier
    const checkSum = parsedDigits
        .slice(0, -1)
        .map((item, index) => item * (index % 2 === 0 ? 1 : 3))
        .reduce((acc, item) => acc + item, 0);

    const diff = (10 - (checkSum % 10)) % 10;

    const isValid = diff === checkDigit;

    return isValid;
};

export const verifyOAK = (oakNumber: string): boolean => {
    const match = oakNumber.match(/(^\d{3,5}-\d{2})$/);
    return !!match;
};

export const bypassMedicalVerificationByEmail = (email: string): boolean => {
    const bypassByEmail = email.toLowerCase().includes('@fomf.');
    return bypassByEmail;
};

export const verifyMedicalNumber = (
    user: Pick<
        Fragments.UserFieldsFragment,
        'medicalIdentificationNumber' | 'healthProfessionalConfirmation' | 'country' | 'email'
    >,
    allowNoMedicalNumberForHealthProfessional: boolean,
    allowInvalidMedicalNumber?: boolean
): boolean => {
    const userCountryDomain =
        user.country && COUNTRY_CODE_3_TO_COUNTRY_CODE[user.country as CountryCode3];

    if (
        !userCountryDomain ||
        (userCountryDomain && !isFeatureEnabled('medicalNumberCheck', userCountryDomain))
    ) {
        return true;
    }

    if (user.healthProfessionalConfirmation && allowNoMedicalNumberForHealthProfessional) {
        return true;
    }

    if (allowInvalidMedicalNumber !== undefined && allowInvalidMedicalNumber) {
        return true;
    }

    if (bypassMedicalVerificationByEmail(user.email)) {
        return true;
    }

    const number = user.medicalIdentificationNumber;

    if (!number) {
        return false;
    }

    switch (user.country) {
        case 'DEU':
            return verifyEFN(number);
        case 'CHE':
            return verifyGLN(number);
        case 'AUT':
            return verifyOAK(number);
        default:
            return false;
    }
};
