import { useState, useEffect, useCallback, useRef } from 'react';
import _debounce from 'lodash/debounce';

type InfiniteScrollArgs<T> = {
    moreItemsAvailable: boolean;
    onFetchMore: (args: T & { isFetching: boolean }) => Promise<void>;
    onFetchMoreArgs: T;
};

type InfiniteScrollResult = {
    isFetching: boolean;
};

type ScrollEventType = React.UIEvent<HTMLUListElement> | Event;

/**
 * This hook is used to implement infinite scroll functionality.
 * @param args Object with arguments
 * @param args.moreItemsAvailable If there are more items available to fetch, then the hook will listen to the
 * scroll event and call the onFetchMore callback when the user scrolls to the bottom of the page.
 * Otherwise, it will remove the scroll event listener.
 * @param args.onFetchMore Callback to fetch more items.
 * @param args.onFetchMoreArgs Array of arguments for onFetchMore function
 * @returns isFetching Boolean to indicate whether the hook is fetching more items.
 */
const useInfiniteScroll = <T>({
    moreItemsAvailable,
    onFetchMore,
    onFetchMoreArgs
}: InfiniteScrollArgs<T>): InfiniteScrollResult => {
    const [isFetching, setIsFetching] = useState(false);
    const isListenerAdded = useRef(false);

    const _handleScroll = async (event: ScrollEventType) => {
        const windowHeight = window.innerHeight;
        const { target } = event;
        if (!target) {
            return;
        }
        const scrollHeight = document.body.scrollHeight;
        const scrollTop =
            // default approach
            window.pageYOffset ||
            // for firefox
            document.documentElement.scrollTop ||
            // for chrome
            document.body.scrollTop ||
            0;
        // Multiply by 2 to ancitipate the user scroll and invoke the offset change callback earlier
        if (moreItemsAvailable && 2 * windowHeight + scrollTop >= scrollHeight && !isFetching) {
            setIsFetching(true);
            await onFetchMore({ isFetching, ...onFetchMoreArgs });
            setIsFetching(false);
        }
    };

    const handleScroll = useCallback(
        _debounce((event) => {
            _handleScroll(event);
        }, 300),
        [moreItemsAvailable, isFetching, ...Object.values(onFetchMoreArgs)]
    );

    useEffect(() => {
        const removeScrollEventListener = () => {
            if (isListenerAdded.current) {
                window?.removeEventListener('scroll', handleScroll);
                isListenerAdded.current = false;
            }
        };

        if (moreItemsAvailable && !isListenerAdded.current) {
            window?.addEventListener('scroll', handleScroll);
            isListenerAdded.current = true;
        } else if (!moreItemsAvailable) {
            removeScrollEventListener();
        }
        return () => {
            removeScrollEventListener();
        };
    }, [moreItemsAvailable, handleScroll]);

    return { isFetching };
};

export default useInfiniteScroll;
