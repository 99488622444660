import { LDContext } from '@launchdarkly/node-server-sdk';
import { getCookie, getRandomUUID } from '../index';

export const setUpLdContexts = async (
    user: { id: string | null | undefined; email: string | null | undefined } | null | undefined,
    anonymousId: string,
    countryCode?: CountryCode
): Promise<LDContext> => {
    if (user && user.id && user.email) {
        return {
            kind: 'user',
            key: user.id,
            email: user.email
        };
    }
    return {
        kind: 'guest',
        key: anonymousId,
        country: countryCode
    };
};

export const getAnonymousId = (cookie: string | null | undefined) => {
    const anonymousId = (cookie && getCookie(cookie, 'ajs_anonymous_id')) || getRandomUUID();
    return anonymousId;
};
