import React, { useContext } from 'react';
import {
    Queries,
    UserMembership,
    getUserPaidNotExpiredMembership,
    Types,
    getCountryCode3,
    booleanFilter
} from '../index';
import { useUserContext } from './UserContext';
import { useIntl } from 'react-intl';

export type MembershipContextType =
    // loading state
    | {
          loading: true;
          isMember: false;
          userMembership: null;
          allMemberships: [];
          allMembershipsLoading: true;
      }
    // error state
    | {
          loading: false;
          isMember: false;
          userMembership: null;
          allMemberships: Array<Types.Membership>;
          allMembershipsLoading: boolean;
      }
    // successful state
    | {
          loading: false;
          isMember: boolean;
          userMembership: UserMembership;
          allMemberships: Array<Types.Membership>;
          allMembershipsLoading: boolean;
      };

const defaultMembershipContext: MembershipContextType = {
    loading: true,
    isMember: false,
    userMembership: null,
    allMemberships: [],
    allMembershipsLoading: true
};

const MembershipContext = React.createContext<MembershipContextType>(defaultMembershipContext);

type MembershipContextProviderProps = {
    children: React.ReactNode;
};

const MembershipContextProvider = (props: MembershipContextProviderProps) => {
    const { locale } = useIntl();
    const { user, isLoading: isUserLoading } = useUserContext();
    const { children } = props;
    const { data, error, loading } = Queries.useGetUserMembershipQuery({
        variables: { userId: user?.id ?? ('' as Types.UUID) },
        skip: !user ?? isUserLoading
    });
    const { data: membershipsData, loading: allMembershipsLoading } =
        Queries.useGetMembershipsQuery({
            variables: { country: getCountryCode3(locale) }
        });
    const allMemberships = (membershipsData?.memberships || []).filter(booleanFilter);

    let membershipContext: MembershipContextType = defaultMembershipContext;
    if (!loading && !isUserLoading) {
        if (error) {
            membershipContext = {
                loading: false,
                isMember: false,
                userMembership: null,
                allMemberships,
                allMembershipsLoading
            };
        }
        const userMembership = getUserPaidNotExpiredMembership(data, undefined, locale);
        if (userMembership) {
            membershipContext = {
                loading: false,
                isMember: true,
                userMembership,
                allMemberships,
                allMembershipsLoading
            };
        } else if (error || !userMembership) {
            membershipContext = {
                loading: false,
                isMember: false,
                userMembership: null,
                allMemberships,
                allMembershipsLoading
            };
        }
    }
    return (
        <MembershipContext.Provider value={membershipContext}>
            {children}
        </MembershipContext.Provider>
    );
};

const useMembershipContext = () => useContext(MembershipContext);

export { MembershipContextProvider, useMembershipContext };
