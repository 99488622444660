import { useIntl } from 'react-intl';
import { getCountryCode, isFeatureEnabled } from '../utils';
import { useTheme } from '@mui/material';
import useIsDesktop from './useIsDesktop';

const useHeaderHeight = (config?: { defaultMatches?: boolean }) => {
    const { defaultMatches = true } = config || {};
    const { locale } = useIntl();
    const theme = useTheme();
    const isDesktop = useIsDesktop({ defaultMatches });
    const countryCode = getCountryCode(locale);
    if (!isDesktop) {
        return theme.headerHeight.sm;
    }
    return isFeatureEnabled('mediathek', countryCode)
        ? theme.headerHeight.lg
        : theme.headerHeight.sm;
};

export default useHeaderHeight;
