import { env, generateRandomString, getCountryCode3, http, LOCALE_TO_DOMAIN } from '../';

type AuthUrlProps = {
    nonce?: string;
    tenantId?: string;
    locale: Locale;
};
const removeDoubleSlashes = (url: string): string => {
    return url.replace(/([^:/]|^)\/\/+/g, '$1/');
};

export const getAuthURL = (authUrlProps: AuthUrlProps, extraParamsStr = '') => {
    const { tenantId, locale, nonce } = authUrlProps;
    const nonceToUse = nonce ? nonce : generateRandomString();
    const audience =
        tenantId && env.SAAS_USER_BASE_URL ? `${env.SAAS_USER_BASE_URL}/${tenantId}` : '';

    const searchParams = new URLSearchParams();
    const countryCode = getCountryCode3(locale);
    const language = LOCALE_TO_DOMAIN[locale] === 'ch' && locale === 'fr-CH' ? 'fr' : undefined;
    const baseUrl = window.location.origin;

    searchParams.set('max_age', '0');
    searchParams.set('response_type', 'code');
    searchParams.set('scope', 'openid offline');
    searchParams.set('state', nonceToUse);
    countryCode && searchParams.set('cr', countryCode);
    language && searchParams.set('lang', language);
    baseUrl && searchParams.set('base_url', baseUrl);

    env.OAUTH_CLIENT_ID && searchParams.set('client_id', env.OAUTH_CLIENT_ID);

    const redirectUrlSearchParams = new URLSearchParams();
    redirectUrlSearchParams.set('client', 'fomf');
    countryCode && redirectUrlSearchParams.set('cr', countryCode);

    if (env.IS_PREVIEW_ENVIRONMENT === 'true') {
        redirectUrlSearchParams.set('base_url', baseUrl);
    }

    const redirectUrl = `${env.USER_SERVICE_BASE_URL()}/oauth/callback?${redirectUrlSearchParams.toString()}`;

    redirectUrl && searchParams.set('redirect_uri', redirectUrl);

    audience && searchParams.set('audience', audience);

    const searchParamsString =
        searchParams.toString() + (extraParamsStr ? `&${extraParamsStr}` : '');
    const authURL = removeDoubleSlashes(
        `${env.OAUTH_SERVER_BASE_URL}/oauth2/auth?${searchParamsString}`
    );

    return authURL;
};

export const refreshCookieRequest = async (): Promise<boolean> => {
    const refreshCookieURL = `${env.USER_SERVICE_BASE_URL()}/oauth/refresh_token?client=fomf`;
    try {
        await http.get(refreshCookieURL);
        return true;
    } catch (e) {
        return false;
    }
};
