import makeStyles from '@mui/styles/makeStyles';

type AnchorHeaderStylesProps = {
    height: number;
    indicatorColor: string | null;
};

const useStyles = makeStyles(
    (theme) => ({
        links: ({ height }: AnchorHeaderStylesProps) => ({
            display: 'flex',
            height: height,
            paddingTop: theme.spacing(1),
            backgroundColor: theme.palette.common.white
        }),
        link: {
            display: 'flex',
            padding: theme.spacing(0, 1.5),
            height: '100%',
            alignItems: 'center',
            '& > p': {
                fontWeight: 400
            }
        },
        tab: ({ height }: AnchorHeaderStylesProps) => ({
            minHeight: height - 8,
            padding: 0,
            marginRight: 0,
            paddingRight: theme.spacing(1),
            ...theme.border({ kind: 'bottom', color: '#00000014', width: 1, radius: 0 })
        }),
        tabsRoot: {
            ...theme.border({ kind: 'bottom', color: '#00000014', width: 1, radius: 0 })
        },
        tabsIndicator: ({ indicatorColor }: AnchorHeaderStylesProps) => ({
            backgroundColor: indicatorColor ?? ''
        })
    }),
    { name: 'AnchorHeader' }
);

export default useStyles;
