import React from 'react';
import { Box, Radio as MUIRadio } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import { RadioProps } from './Radio.types';
import useStyles from './Radio.styles';

const Radio = (props: RadioProps) => {
    const { localeId, localeValues, disabled, ...rest } = props;
    const classes = useStyles(props);
    const baseIconClass = classnames(classes.radio, { [classes.disabled]: disabled });
    const baseRadio = (
        <MUIRadio
            disabled={disabled}
            icon={<Box className={baseIconClass} />}
            checkedIcon={<Box className={classnames(baseIconClass, classes.radioChecked)} />}
            {...rest}
        />
    );

    if (!localeId) {
        return baseRadio;
    }
    return (
        <div>
            {baseRadio}
            <FormattedMessage id={localeId} values={localeValues} />
        </div>
    );
};

export default Radio;
