import { Types } from '../index';

export const contentTypeOptions = [
    { id: Types.ContentType.Article, localeId: 'common.product-type.article' },
    { id: Types.ContentType.LiveCourse, localeId: 'common.product-type.live-course' },
    { id: Types.ContentType.Webinar, localeId: 'common.product-type.webinar' },
    { id: Types.ContentType.Course, localeId: 'common.product-type.course' },
    { id: Types.ContentType.Lecture, localeId: 'common.product-type.lecture' },
    { id: Types.ContentType.AddonCourse, localeId: 'common.product-type.addon-course' },
    { id: Types.ContentType.Pdf, localeId: 'common.product-type.pdf' },
    { id: Types.ContentType.OndemandCourse, localeId: 'common.product-type.on-demand-course' },
    {
        id: Types.ContentType.OnDemandAccredited,
        localeId: 'common.product-type.on-demand-accredited'
    },
    {
        id: Types.ContentType.PartnerEvent,
        localeId: 'common.product-type.partner-event'
    }
];
