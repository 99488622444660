import { formatEnum, getCountryCode3, UserOptions, UserValidation } from '..';

const formatMedicalIdNameByCountry = (
    countryArg: string | null | undefined,
    locale: Locale
): string => {
    const countryHasMedicalNumber = countryArg
        ? UserValidation.countryHasMedicalNumber(countryArg)
        : false;
    const country = countryHasMedicalNumber ? countryArg : getCountryCode3(locale);
    return formatEnum(country, { options: UserOptions.medicalIdNameByCountry });
};

export default formatMedicalIdNameByCountry;
