import { ReactNode, useMemo, FocusEvent } from 'react';
import _get from 'lodash/get';
import {
    FormikError,
    FormikInputProps,
    FormikFormType,
    getErrorsParams,
    isRequiredField,
    FormikErrors
} from '../';
import { useIntl } from 'react-intl';
import getCommonLocaleId from '../utils/getCommonLocaleId';

/**
 * For cases of complex fields like `mobile`
 */
type ErrorForFieldWithValue = {
    value: string | FormikError;
};

type RawError<Values> =
    | string
    | FormikErrors<Values>[keyof FormikErrors<Values>]
    | ErrorForFieldWithValue
    | undefined;

type FormikErrorProps = { id: string; values: Record<string, string | ReactNode> };

interface FormikLabels {
    label: ReactNode;
    error: FormikErrorProps;
    hasError: boolean;
    formikValue: unknown;
    isRequired: boolean;
}

const isErrorForFieldWithValue = <Values>(
    error: RawError<Values>
): error is ErrorForFieldWithValue =>
    error ? (error as ErrorForFieldWithValue).value != null : false;

export const getFieldError = <Values>(
    name: string,
    label: ReactNode,
    formik: FormikFormType<Values>
): FormikErrorProps => {
    const rawError = _get(formik.errors, name) as RawError<Values>;

    const formikExtractedError = isErrorForFieldWithValue(rawError)
        ? (rawError as ErrorForFieldWithValue).value
        : rawError;

    const formikError = ((Array.isArray(formikExtractedError)
        ? formikExtractedError[0]
        : formikExtractedError) || '') as string | FormikError;

    let errorLocaleId: string;
    let errorValues: Record<string, string | ReactNode>;

    if (typeof formikError === 'string') {
        errorLocaleId = formikError;
        errorValues = {
            fieldText: label,
            ...getErrorsParams(formik.validationSchema, name)
        };
    } else {
        errorLocaleId = formikError.localeId;
        errorValues = {
            fieldText: label,
            ...formikError.values
        };
    }

    return { id: errorLocaleId, values: errorValues };
};

/**
 * @deprecated this method is deprecated, use useFormikProps instead
 */
// TODO remove this function
const useFormikLabels = (props: FormikInputProps): FormikLabels => {
    const { name, namePrefix, label, localeId, formik } = props;
    const { messages } = useIntl();

    const formikTouched = _get(formik.touched, name);
    const formikBaseError = _get(formik.errors, name);
    const formikValue = _get(formik.values, name);

    const hasError = formikTouched ? Boolean(formikBaseError) : false;

    const nameWithoutPrefix =
        namePrefix && name.includes(namePrefix)
            ? name.slice(name.indexOf(namePrefix) + namePrefix.length)
            : name;
    const localeIdFromName = getCommonLocaleId(nameWithoutPrefix);

    let fieldText: ReactNode;
    if (label) {
        fieldText = label;
    } else if (localeId) {
        const msg = messages[localeId];
        fieldText = typeof msg === 'string' ? msg : localeId;
    } else {
        const msg = messages[localeIdFromName];
        fieldText = typeof msg === 'string' ? msg : localeIdFromName;
    }

    const isRequired =
        isRequiredField(formik.validationSchema, name) ||
        isRequiredField(formik.validationSchema, `${name}.value`);

    return {
        label: fieldText,
        error: getFieldError(name, fieldText, formik),
        hasError,
        formikValue,
        isRequired
    };
};

type Value = unknown;

export interface UseFormikProps {
    label: ReactNode;
    error: { id: string; values: Record<string, string | ReactNode> };
    hasError: boolean;
    value: unknown;
    required: boolean;
    onChange: (newValue: Value) => void;
    onBlur: (event: FocusEvent<unknown>) => void;
}

type InputProps = Omit<FormikInputProps, 'formik' | 'intl'>;

type UseFormikReturnType = (props: InputProps) => UseFormikProps & InputProps;

export const useFormikProps = (formik: FormikFormType): UseFormikReturnType => {
    const { messages } = useIntl();
    const getFormikProps = ({ name, localeId, label, namePrefix }: InputProps) => {
        const formikTouched = _get(formik.touched, name);
        const formikBaseError = _get(formik.errors, name);
        const value = _get(formik.values, name);

        const hasError = formikTouched ? Boolean(formikBaseError) : false;

        const nameWithoutPrefix =
            namePrefix && name.includes(namePrefix)
                ? name.slice(name.indexOf(namePrefix) + namePrefix.length)
                : name;
        const localeIdFromName = getCommonLocaleId(nameWithoutPrefix);
        const fieldText =
            label ||
            (localeId && (messages[localeId] as string)) ||
            (messages[localeIdFromName] as string);

        if (!fieldText) {
            if (typeof label === 'string') {
                throw new Error(`Empty label was passed for the field "${name}"`);
            } else if (localeId) {
                throw new Error(
                    `Missing translation for the field "${name}" with custom localeId "${localeId}"`
                );
            } else {
                throw new Error(
                    `Missing translation for the field "${name}" with localeIdFromName "${localeIdFromName}"`
                );
            }
        }

        const isRequired =
            isRequiredField(formik.validationSchema, name) ||
            isRequiredField(formik.validationSchema, `${name}.value`);

        const fieldError = getFieldError(name, fieldText, formik);

        // TODO: investigate if `useMemo` is required
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const result = useMemo(
            () => ({
                label: fieldText,
                error: fieldError,
                hasError,
                value,
                required: isRequired,
                name,
                onChange: (newValue: Value) => {
                    formik.setFieldValue(name, newValue);
                    formik.setFieldTouched(name, true);
                },
                onBlur: formik.handleBlur
            }),
            [value, hasError, fieldError.id]
        );

        return result;
    };
    return getFormikProps;
};

export default useFormikLabels;
