import classnames from 'classnames';
import React from 'react';
import useStyles from './RadioGroupItem.styles';
import { RadioGroupItemProps } from './RadioGroupItem.types';
import { FormattedMessage } from 'react-intl';
import { FormControlLabel } from '@mui/material';

import { Radio } from '../../';

const RadioGroupItem = (props: RadioGroupItemProps) => {
    const { id, localeId, label, disabled, className } = props;
    const classes = useStyles();

    return (
        <FormControlLabel
            value={id}
            label={label ? label : <FormattedMessage id={localeId} />}
            key={typeof id !== 'boolean' ? id : localeId || (label as string)}
            control={<Radio disabled={disabled} className={classes.radio} />}
            classes={{ root: classnames(classes.root, className) }}
        />
    );
};

export default RadioGroupItem;
