import { MessageFormatElement } from '@formatjs/icu-messageformat-parser';
import * as Types from './types';
import { I18n } from './types';

type MakeOptional<T, K extends keyof T = keyof T> = Types.MakeOptional<T, K>;
type MakeRequired<T, K extends keyof T = keyof T> = Omit<T, K> & {
    [SubKey in K]-?: NonNullable<T[SubKey]>;
};

type Messages = Record<string, string> | Record<string, MessageFormatElement[]>;

type DescriptionEntity = {
    code: string;
    description: I18n;
    id: string;
};

type AddressFormik = {
    id: Types.UUID | undefined;
    isPersonal: boolean;
    street: string | null;
    apartmentNumber: string | null;
    postalCode: string | null;
    city: string | null;
    country: string | null;
    fullName: string | null;
    company: string | null;
    additionalInformation: string | null;
    toDelete: boolean | undefined;
};

export * from './types';
export type { MakeOptional, MakeRequired, Messages, DescriptionEntity, AddressFormik };
