import { IconName } from 'components';
import { EventFormat } from '../graphql/generated';

export type EventFormatInfo = { label: string; icon: IconName; localeId: string };

const eventFormatOptions: Record<EventFormat, EventFormatInfo> = {
    [EventFormat.UpdateRefresher]: {
        label: 'Update Refresher',
        icon: 'refresher' as const,
        localeId: 'common.event-format.update-refresher'
    },
    [EventFormat.Refresher]: {
        label: 'Refresher',
        icon: 'refresher' as const,
        localeId: 'common.event-format.refresher'
    },
    [EventFormat.StudyConferenceUpdate]: {
        label: 'Studien- und Kongressupdate',
        icon: 'refresher' as const,
        localeId: 'common.event-format.study-conference-update'
    },
    [EventFormat.RefresherAktuell]: {
        label: 'Refresher Aktuell',
        icon: 'refresher' as const,
        localeId: 'common.event-format.refresher-actuell'
    },
    [EventFormat.ExpertenForum]: {
        label: 'Experten-Forum',
        icon: 'refresher' as const,
        localeId: 'common.event-format.experten-forum'
    },
    [EventFormat.Fortbildungstage]: {
        label: 'Fortbildungstage',
        icon: 'refresher' as const,
        localeId: 'common.event-format.fortbildungstage'
    },
    [EventFormat.Webup]: {
        label: 'WebUp',
        icon: 'webUp' as const,
        localeId: 'common.event-format.webup'
    },
    [EventFormat.PartnerEvent]: {
        label: 'Partner Event',
        icon: 'refresher' as const,
        localeId: 'common.event-format.partner-event'
    }
};

export const getEventFormatInfo = (eventFormat: EventFormat): EventFormatInfo => {
    return eventFormatOptions[eventFormat];
};

export default getEventFormatInfo;
