/**
 * This function is useful to build an URL given the page name and parameters.
 * @param page to navigate to
 * @param params key-value object containing the new query parameters to add
 * @returns a string with the shape "[page]?oldParam=1&newParam=2"
 */
import { QueryParamsType } from '..';

const getUrlWithParams = (page: string, params: QueryParamsType = {}, keepOld = true): string => {
    const search = typeof window !== 'undefined' && keepOld ? window.location.search : '';
    const paramsStr = Object.keys(params)
        .filter((key) => params[key])
        .map((key) => {
            const paramRawValue = params[key];
            const paramValue = paramRawValue
                ? Array.isArray(paramRawValue)
                    ? JSON.stringify(paramRawValue)
                    : paramRawValue
                : '';
            return key + '=' + paramValue;
        })
        .join('&');

    let url = `/${page}${search}`;
    if (paramsStr.length) {
        url += `${search.length === 0 ? '?' : paramsStr.length > 0 ? '&' : ''}${paramsStr}`;
    }

    return url;
};

export default getUrlWithParams;
