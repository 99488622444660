import React from 'react';
import {
    useMediaQuery,
    useTheme,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box
} from '@mui/material';

import Dialog from '../Dialog';
import Typography from '../../Typography/Typography';
import Button from '../../Button/Button';
import useStyles from './FullScreenDialog.styles';
import { FullScreenDialogProps, DialogHeightEnum } from './FullScreenDialog.types';
import { Icon } from '../../Icon';
import classnames from 'classnames';

const FullScreenDialog = (props: FullScreenDialogProps) => {
    const {
        open,
        onClose,
        actionButton: actionButtonProp,
        children,
        title,
        titleLocaleId,
        maxWidth,
        size = DialogHeightEnum.Normal,
        actions,
        classes: classesProp,
        ...others
    } = props;
    const theme = useTheme();
    const fullscreen = useMediaQuery(theme.breakpoints.down('md'));
    const classes = useStyles(props);
    const actionButton = actionButtonProp && (
        <Button
            onClick={actionButtonProp.onClick}
            className={classnames(classes.actionButton, { [classes.fullscreen]: fullscreen })}
            minimal
            variant="text"
            startIcon={actionButtonProp.icon}
            iconStrokeWidth={actionButtonProp.iconStrokeWidth}
            onlyIcon={fullscreen}
        >
            <Typography
                title={actionButtonProp.title}
                localeId={actionButtonProp.localeId}
                variant="linkSmall"
            />
        </Button>
    );

    return (
        <Dialog
            fullScreen={fullscreen}
            open={open}
            maxWidth={maxWidth ?? 'xl'}
            fullWidth
            hideCloseButton
            onClose={onClose}
            scroll="paper"
            classes={{
                paper: classnames(
                    classes.dialogPaper,
                    size === DialogHeightEnum.FullHeight && classes.dialogPaperFullHeight
                ),
                scrollPaper: classnames(
                    classes.dialogScrollPaper,
                    size === DialogHeightEnum.FullHeight && classes.dialogScrollPaperFullHeight,
                    size === DialogHeightEnum.AutoHeight && classes.dialogScrollPaperAutoHeight
                ),
                root: classes.dialogRoot
            }}
            {...others}
        >
            <DialogTitle className={classes.dialogTitle}>
                <div className={classes.headerContainer}>
                    <Box className={classes.titleContainer}>
                        <Icon icon="close" onClick={onClose} className={classes.closeIcon} />
                        <Typography
                            color="primary"
                            fontFamily="Poppins"
                            className={classes.title}
                            title={title}
                            localeId={titleLocaleId}
                        />
                    </Box>

                    {actionButton}
                </div>
            </DialogTitle>

            {
                /* This is workaround to disable MUI styles selector when DialogContent is located right after the DialogTitle */
                <Box />
            }

            <DialogContent className={classnames(classes.dialogContent)}>{children}</DialogContent>
            {actions && <DialogActions className={classes.dialogActions}>{actions}</DialogActions>}
        </Dialog>
    );
};

export default FullScreenDialog;
