import makeStyles from '@mui/styles/makeStyles';
import { ThemeTypes } from 'components';

const useStyles = makeStyles<ThemeTypes.Theme>(
    (theme) => ({
        membershipTitle: {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap'
        },
        title: {
            ...theme.typography.linkLarge,
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            fontFamily: 'Poppins'
        },
        membershipType: {
            ...theme.typography.body1,
            fontSize: '20px',
            marginLeft: theme.spacing(1),
            color: '#00A1C4',
            fontWeight: 700,
            fontFamily: 'Poppins'
        }
    }),
    { name: 'MembershipTitle' }
);

export default useStyles;
