import formatCurrency from './formatCurrency';

const prefixSymbols = (['GBP'] as const).map(formatCurrency);
const symbolsWithSpace = (['EUR', 'CHF'] as const).map(formatCurrency);

const formatPrice = (
    price: number,
    locale: Locale,
    currencySymbol?: string | null | undefined
): string => {
    currencySymbol = currencySymbol || '€';
    const isNegative = price < 0;
    const absolutePrice = Math.abs(price) / 100;

    const numberFormatter = new Intl.NumberFormat(locale, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        useGrouping: true
    });

    const formattedNumber = numberFormatter.format(absolutePrice);
    const sign = isNegative ? '-' : '';
    const isPrefix = prefixSymbols.includes(currencySymbol);
    const isSpaced = symbolsWithSpace.includes(currencySymbol);

    const currencyString = isSpaced
        ? isPrefix
            ? `${currencySymbol} `
            : ` ${currencySymbol}`
        : currencySymbol;

    const prefix = isPrefix ? currencyString : '';
    const suffix = isPrefix ? '' : currencyString;
    return `${sign}${prefix}${formattedNumber}${suffix}`;
};

export default formatPrice;
