import React from 'react';
import { Box, Container } from '@mui/material';
import classNames from 'classnames';
import Dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';

import LocaleSwitch from '../Header/LocaleSwitch';
import { Typography } from 'components';
import { getCountryCode } from '../../utils';

const useStyles = makeStyles((theme) => ({
    copyrightContainer: {
        backgroundColor: theme.palette.grey[300],
        minHeight: 56,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: theme.spacing(3),
        padding: theme.spacing(1.5, 0, 1, 0)
    },
    chCopyrightContainer: {
        backgroundColor: 'unset',
        ...theme.border({ kind: 'top', radius: 0, color: '#0000000f' })
    },
    copyrightInnerContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    localeSwitchContainer: {
        position: 'absolute',
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block'
        }
    },
    lineBreak: {
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    copyright: {
        color: '#59748D',
        margin: 'auto',
        textAlign: 'center'
    },
    chCopyright: {
        color: theme.palette.label.main,
        ...theme.typography.textXXSmall
    }
}));

const SimpleFooter = () => {
    const { locale } = useIntl();
    const countryCode = getCountryCode(locale);
    const classes = useStyles();
    return (
        <Box
            className={classNames(classes.copyrightContainer, {
                [classes.chCopyrightContainer]: countryCode === 'ch'
            })}
        >
            <Container className={classes.copyrightInnerContainer}>
                <Box className={classes.localeSwitchContainer}>
                    <LocaleSwitch />
                </Box>
                <Typography
                    localeId="components.footer.copyright"
                    localeValues={{
                        year: Dayjs().year(),
                        lineBreak: <br className={classes.lineBreak} />
                    }}
                    className={classNames(classes.copyright, {
                        [classes.chCopyright]: countryCode === 'ch'
                    })}
                    variant="textLarge"
                />
            </Container>
        </Box>
    );
};

export default SimpleFooter;
