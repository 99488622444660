import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import CountriesSelect from '../CountriesSelect/CountriesSelect';
import { FormikInputProps, FormikInputWrapper, isRequiredField, useFormikLabels } from '..';
import type { CountriesSelectProps } from '../CountriesSelect/CountriesSelect.types';

type Props = FormikInputProps<CountriesSelectProps>;

/**
 * Use this component for CountriesSelect under a formik form.
 */
const FormikCountriesSelect = (props: Props) => {
    const { formik, gridProps, gridClassName, name, intl, ...others } = props;

    const { label, error, hasError, formikValue } = useFormikLabels(props);

    const isRequired = isRequiredField(formik.validationSchema, name);

    const handleChange = (value: string) => {
        formik.setFieldTouched(name, true);
        formik.setFieldValue(name, value);
    };

    if (
        typeof formikValue !== 'undefined' &&
        typeof formikValue !== 'string' &&
        formikValue !== null
    ) {
        console.error(`Field ${name} is not a string`);
    }

    return (
        <FormikInputWrapper gridProps={gridProps} className={gridClassName}>
            <CountriesSelect
                name={name}
                label={label}
                fullWidth
                defaultValue=""
                value={typeof formikValue === 'string' ? formikValue : ''}
                onChange={handleChange}
                error={hasError}
                helperText={hasError && <FormattedMessage {...error} />}
                onBlur={formik.handleBlur}
                required={isRequired}
                {...others}
            />
        </FormikInputWrapper>
    );
};

const EnhancedFormikCountriesSelect = injectIntl(FormikCountriesSelect);

export { FormikCountriesSelect };
export default EnhancedFormikCountriesSelect;
