import camelCaseToKebabCase from './camelCaseToKebabCase';

const FORMIK_LOCALE_STRING_PREFIX = 'common';

const getCommonLocaleId = (name: string, prefixName?: string) => {
    const prefix = prefixName ? `${camelCaseToKebabCase(prefixName)}.` : '';
    return `${FORMIK_LOCALE_STRING_PREFIX}.${prefix}${camelCaseToKebabCase(name)}`;
};

export default getCommonLocaleId;
