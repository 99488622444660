import makeStyles from '@mui/styles/makeStyles';
import { ThemeTypes } from 'components';

const sectionBorder = (theme: ThemeTypes.Theme) =>
    theme.border({ color: theme.palette.body.light, radius: 0, kind: 'bottom' });

const useStyles = makeStyles(
    (theme) => ({
        container: {
            position: 'relative',
            height: theme.headerHeight.sm,
            paddingLeft: 0,
            paddingRight: 0,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%'
        },
        // will be applied to drawer root
        root: {
            height: 0,
            [theme.breakpoints.up('md')]: {
                display: 'none'
            }
        },
        withTenantContainer: {
            height: 120,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        registrationLoginContainer: {
            display: 'flex',
            paddingLeft: theme.spacing(2.5),
            flexDirection: 'column',
            marginTop: theme.spacing(2),
            marginRight: 'auto',
            ...theme.border({ color: theme.palette.body.light, radius: 0, kind: 'top' })
        },
        primaryButton: {
            display: 'flex',
            justifyContent: 'center',
            margin: theme.spacing(3, 0, 2, 0)
        },
        headerLink: {
            display: 'flex',
            width: '100%',
            height: '100%',
            alignItems: 'center'
        },
        logo: {
            width: 93,
            height: 35,
            display: 'flex',
            alignItems: 'center',
            marginRight: theme.spacing(1),
            transition: theme.transitions.create('opacity'),

            '& img': {
                maxWidth: '100%'
            },

            '& svg': {
                width: '100%!important'
            }
        },
        toolbar: {
            color: theme.palette.body['700'],
            height: 'unset',
            padding: theme.spacing(3, 2, 3, 2)
        },
        sectionsMenuContainer: {
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            padding: theme.spacing(0, 2, 2, 2),
            flexDirection: 'column',
            alignItems: 'center',
            ...sectionBorder(theme)
        },
        list: {
            marginBottom: 0,
            padding: 0,
            ...sectionBorder(theme),

            '&:last-child': {
                borderBottom: 'none'
            }
        },
        listItem: {
            marginBottom: 0
        },
        iconsContainer: {
            display: 'flex',
            alignItems: 'center',
            transition: theme.transitions.create('opacity')
        },
        backIcon: {
            width: 24,
            height: 24,
            marginRight: theme.spacing(1),
            '& svg': {
                color: theme.palette.secondary.main
            }
        },
        avatar: {
            border: 0,
            width: 40,
            height: 40,
            background: theme.palette.primary.main,
            borderRadius: '50%',
            display: 'flex',
            color: theme.palette.common.white,
            margin: theme.spacing(4, 'auto', 1.5),
            alignItems: 'center',
            ...theme.typography.textMedium,
            '& > svg': {
                width: 'unset',
                margin: 'auto',
                fill: 'currentColor'
            }
        },
        // sub menu styles
        subMenuHeaderContainer: {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer'
        },
        userDetails: { display: 'flex', flexDirection: 'column' },
        userFullName: {
            fontSize: 16,
            fontWeight: 700,
            lineHeight: '24px',
            color: '#1B3F62',
            margin: theme.spacing(0, 4, 4, 4),
            textAlign: 'center'
        },
        userDivider: { width: 'unset', margin: theme.spacing(0, 2) },
        companyLogo: {
            width: 24,
            height: 24,
            marginRight: theme.spacing(1.5),
            flexShrink: 0
        },
        addonIcon: {
            border: 0,
            marginRight: theme.spacing(5)
        },
        hidden: {
            opacity: 0
        },
        searchBox: {
            width: '100%',
            maxWidth: 400,
            position: 'absolute',
            marginRight: 0,
            right: theme.spacing(5),
            transition: theme.transitions.create('transform'),
            transform: 'translateX(100%)',

            '&$isActive': {
                transform: `translateX(${theme.spacing(5)}px)`,
                pointerEvents: 'initial',

                '& $searchBoxInnerElement': {
                    opacity: 1
                }
            }
        },
        searchBoxInnerElement: {
            transition: theme.transitions.create('opacity'),
            opacity: 0
        },
        itemsContainer: {
            padding: theme.spacing(0, 2)
        },
        isActive: {},
        secondLevelMenuBackIcon: {
            '& $listItemIcon': {
                width: 10,
                height: 15
            }
        },
        footerSection: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            flexGrow: 1,
            padding: theme.spacing(2)
        },
        footerIconsContainer: {
            display: 'flex',
            gap: theme.spacing(2)
        },
        footerIconButton: {
            width: 32,
            height: 32,
            padding: 0
        },
        footerIcon: {
            width: '100%',
            height: '100%',
            '& svg': {
                color: theme.palette.primary['500']
            }
        },
        listItemIcon: {},
        companyLogoContainer: {
            display: 'flex',
            alignItems: 'center',
            marginBlock: theme.spacing(2),
            paddingInline: theme.spacing(3)
        }
    }),
    // to have more priority over DrawerMenu and SearchBox
    { name: 'Header.mobile', index: 20 }
);

export default useStyles;

export const useToolbarHeaderStyles = makeStyles(
    (theme) => ({
        toolbarContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
        },
        avatar: {
            border: 0,
            width: 40,
            height: 40,
            background: theme.palette.primary.main,
            borderRadius: '50%',
            display: 'flex',
            color: theme.palette.common.white,
            marginRight: theme.spacing(1),
            justifyContent: 'center',
            alignItems: 'center',
            ...theme.typography.textMedium,
            '& > svg': {
                width: 'unset',
                margin: 'auto',
                fill: 'currentColor'
            }
        },
        loginIcon: {
            width: 24,
            height: 24
        },
        userMenuContainer: {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer'
        },
        rightContainer: {
            display: 'flex'
        },
        subtitle: { lineHeight: '24px', color: theme.palette.body['500'] },
        title: {
            fontWeight: 700,
            color: theme.palette.secondary.main
        },
        signIn: {
            display: 'flex',
            alignItems: 'center'
        },
        signInIcon: {
            width: 10,
            height: 14,
            marginLeft: theme.spacing(1),
            color: theme.palette.secondary.main,

            '& svg': {
                color: 'inherit'
            }
        },
        caretRight: {
            marginLeft: theme.spacing(1),
            marginTop: 'auto',
            marginBottom: 'auto',
            color: theme.palette.secondary.main
        },
        topicsLink: {
            height: theme.spacing(5),
            marginTop: theme.spacing(2),
            display: 'flex',
            alignItems: 'center',
            fontFamily: 'Roboto',
            letterSpacing: 0.25,
            fontWeight: 700,
            fontSize: 14,
            transition: theme.transitions.create('color'),

            '&:hover': {
                color: theme.palette.secondary.main
            }
        },
        topicsIcon: {
            width: 20,
            height: 20,
            marginRight: theme.spacing(1),
            color: 'inherit'
        },
        primary: {
            color: theme.palette.primary.main
        }
    }),
    { name: 'ToolbarHeader' }
);
