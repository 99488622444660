import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Breadcrumbs } from '@mui/material';
import { Link, Typography } from 'components';
import classNames from 'classnames';

import { CATALOG_URLS } from '../../../urls';
import { buildURLWithParams } from '../../../utils';
import { useTenantContext } from '../../../contexts/TenantContext';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
    navLinks: {
        display: 'flex',
        color: theme.palette.grey[500],
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    navItem: {
        [theme.breakpoints.down('sm')]: {
            marginTop: 2
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(2)
        },
        marginRight: theme.spacing(2),
        flexWrap: 'nowrap',
        fontWeight: 400,
        textTransform: 'uppercase',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '350px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        },
        [theme.breakpoints.down(350)]: {
            maxWidth: '150px'
        }
    },
    fomfNavItem: {
        fontWeight: 400,
        color: theme.palette.primary.main
    },
    logoContainer: {
        display: 'flex',
        marginRight: theme.spacing(2),
        color: theme.palette.primary.main
    },
    logo: {
        width: '24px',
        height: '24px',
        marginRight: '8px',
        color: theme.palette.primary.main
    },
    partnerforum: {
        color: '#00000061'
    },
    currentNavItem: {
        color: theme.palette.grey[500]
    },
    link: {
        '&:hover': {
            textDecoration: 'underline'
        }
    }
}));

const Breadcrumb = ({
    href,
    text,
    isClickable
}: {
    href?: string;
    text: string;
    isClickable: boolean;
}) => {
    const classes = useStyles();
    const typographyComponent = (
        <Typography variant="body1" className={classes.navItem}>
            {text}
        </Typography>
    );

    if (isClickable && href) {
        return (
            <Link href={href} className={classes.link}>
                {typographyComponent}
            </Link>
        );
    }
    return typographyComponent;
};

const TenantBreadcrumbs = () => {
    const router = useRouter();
    const { formatMessage } = useIntl();
    const { tenantConfig } = useTenantContext();
    const classes = useStyles();
    const slug = tenantConfig?.companyPage?.slug || '';
    const companyName = slug.toUpperCase() || '';
    const contentBreadcrumb = tenantConfig?.contentTitle || '';
    const isBookedEventsPage = router.pathname.includes('booked-events');
    const isInBookingDetailsPage = router.pathname.includes('booking-details');

    return (
        <Breadcrumbs className={classes.navLinks}>
            <Link href={CATALOG_URLS.base} className={classes.link}>
                <Box className={classes.logoContainer}>
                    <img
                        className={classes.logo}
                        src="https://bk-public-prod.storage.googleapis.com/public/static/icon-fomf-logo-dark.svg"
                        alt="FOMF"
                    />
                    <Typography
                        variant="body1"
                        className={classNames(classes.fomfNavItem, classes.navItem)}
                        localeId="common.components.header.tenantHeader.fomf"
                    />
                </Box>
            </Link>
            <Breadcrumb
                href={buildURLWithParams(CATALOG_URLS.partner, [slug || '', 'all'])}
                text={formatMessage(
                    {
                        id: 'common.components.header.tenantHeader.tenant'
                    },
                    { companyName }
                )}
                isClickable={Boolean(
                    contentBreadcrumb || isBookedEventsPage || isInBookingDetailsPage
                )}
            />
            {contentBreadcrumb && <Breadcrumb text={contentBreadcrumb} isClickable={false} />}
            {isBookedEventsPage && (
                <Breadcrumb
                    text={formatMessage({
                        id: 'common.components.header.tenantHeader.booked-events'
                    })}
                    isClickable={false}
                />
            )}
        </Breadcrumbs>
    );
};

export default TenantBreadcrumbs;
