/**
 * This function transform a string in camelCase notation to a kebab-case notation.
 * For example, "helloWorld" will be converted to "hello-world".
 */
const camelCaseToKebabCase = (text: string) =>
    text
        .split('')
        .map((letter, i) => {
            const previousLetter = i > 0 ? text[i - 1] : '';
            return letter.toUpperCase() === letter
                ? `${
                      i > 0 && previousLetter !== '-' && letter !== '-' ? '-' : ''
                  }${letter.toLowerCase()}`
                : letter;
        })
        .join('');

export default camelCaseToKebabCase;
