import makeStyles from '@mui/styles/makeStyles';
import type { ThemeTypes } from '../';
import { SnackbarProps, SnackbarClasses } from './Snackbar.types';

const useStyles = makeStyles<ThemeTypes.Theme, SnackbarProps, SnackbarClasses>(
    (theme) => {
        const primaryColor = theme.palette.primary.main;
        const successColor = theme.palette.success.main;
        const errorColor = theme.palette.error.main;

        return {
            root: {
                position: 'relative',

                width: 320,
                minHeight: 80,
                padding: theme.spacing(2, 3, 2, 2),

                display: 'flex',
                alignItems: 'center',

                borderLeft: '3px solid',
                borderRadius: '3px 0px 0px 3px',

                background: theme.palette.common.white,
                boxShadow: '0px 5px 15px 4px rgba(0, 0, 0, 0.05)',

                [theme.breakpoints.up('sm')]: {
                    width: 430
                }
            },
            info: {
                borderColor: primaryColor,
                '& $icon': {
                    color: primaryColor
                }
            },
            error: {
                borderColor: errorColor,
                '& $icon': {
                    color: errorColor
                }
            },
            success: {
                borderColor: successColor,
                '& $icon': {
                    color: successColor
                }
            },
            icon: {
                width: theme.spacing(3),
                height: theme.spacing(3),
                marginRight: theme.spacing(2)
            },
            close: {
                width: 12,
                height: 12,
                position: 'absolute',
                top: theme.spacing(1),
                right: theme.spacing(1),
                color: theme.palette.grey[200],
                cursor: 'pointer',
                '&:hover': {
                    color: '#000000'
                }
            }
        };
    },
    { name: 'Snackbar' }
);

export default useStyles;
