import React from 'react';
import type { UserPopoverProps } from './UserPopover.types';
import { CATALOG_URLS, Types, UserCompanyLogo, buildURLWithParams, createURL } from '../../..';
import usePopoverStyles from './UserPopover.styles';
import { Link } from 'components';
import { useIntl } from 'react-intl';

type UserCompanyLinkProps = Pick<UserPopoverProps, 'user' | 'hasGroupMembership' | 'links'>;

export const UserCompanyLink = ({ user, hasGroupMembership, links }: UserCompanyLinkProps) => {
    const classes = usePopoverStyles();
    const { formatMessage } = useIntl();

    const { company } = user || {};
    const { name: companyName, logoUrl: companyLogo, id: companyId, companyPage } = company || {};

    const isPhaAccountWithPartnerForum =
        user.accountType === Types.AccountType.Pha && companyPage && companyId;

    const partnerForumLink = isPhaAccountWithPartnerForum
        ? buildURLWithParams(CATALOG_URLS.partner, [companyPage?.slug || companyId, 'all'])
        : '';

    const partnerForumAbsoluteLink = partnerForumLink
        ? createURL(partnerForumLink, 'catalog', true).url
        : '';

    if (hasGroupMembership || isPhaAccountWithPartnerForum) {
        return (
            <>
                <Link
                    className={classes.regularLink}
                    href={hasGroupMembership ? links.homePage : partnerForumAbsoluteLink}
                >
                    <UserCompanyLogo companyLogo={companyLogo} className={classes.companyLogo} />
                    {hasGroupMembership
                        ? companyName
                        : formatMessage({
                              id: 'common.header.user-navbar.popover.my-partnerforum'
                          })}
                </Link>
                <div className={classes.divider} />
            </>
        );
    }

    return null;
};
