export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type UUID = `${string}-${string}-${string}-${string}-${string}`;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: UUID;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    Date: string;
    DateTime: string;
    /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar.This scalar is serialized to a string in ISO 8601 format and parsed from a string in ISO 8601 format. */
    DateTimeISO: unknown;
    JSON: unknown;
    Locale: 'de-DE' | 'de-AT' | 'de-CH' | 'fr-CH' | 'en-GB';
    Upload: unknown;
};

export type AcademicDegreeR = {
    __typename?: 'AcademicDegreeR';
    code: Scalars['String'];
    description: Array<AcademicTranslationR>;
    gender?: Maybe<Gender>;
    id: Scalars['String'];
};

export type AcademicTranslationR = {
    __typename?: 'AcademicTranslationR';
    isSuffix: Scalars['Boolean'];
    locale: Scalars['String'];
    prefix?: Maybe<Scalars['String']>;
    suffix?: Maybe<Scalars['String']>;
    value: Scalars['String'];
};

export type AccessSetting = {
    __typename?: 'AccessSetting';
    accessibleByAnonymous?: Maybe<Scalars['Boolean']>;
    occupationTypes: Array<Maybe<OccupationType>>;
};

export const AccessSettingType = {
    DoctorAndAssistant: 'DOCTOR_AND_ASSISTANT',
    LoggedIn: 'LOGGED_IN'
} as const;

export type AccessSettingType = (typeof AccessSettingType)[keyof typeof AccessSettingType];
export const AccessType = {
    Edit: 'EDIT',
    Owner: 'OWNER',
    Read: 'READ'
} as const;

export type AccessType = (typeof AccessType)[keyof typeof AccessType];
export const AccountType = {
    Dl: 'DL',
    Int: 'INT',
    Jou: 'JOU',
    Koo: 'KOO',
    Pha: 'PHA',
    Ref: 'REF',
    Ska: 'SKA',
    Sku: 'SKU',
    Tf: 'TF',
    Tn: 'TN',
    Vah: 'VAH'
} as const;

export type AccountType = (typeof AccountType)[keyof typeof AccountType];
export type AccreditationSettings = {
    __typename?: 'AccreditationSettings';
    description?: Maybe<Scalars['JSON']>;
    showScheme?: Maybe<Scalars['Boolean']>;
    showSection?: Maybe<Scalars['Boolean']>;
};

export type AccreditationSettingsInput = {
    description?: InputMaybe<Scalars['JSON']>;
    showScheme?: InputMaybe<Scalars['Boolean']>;
    showSection?: InputMaybe<Scalars['Boolean']>;
};

export type AccreditationSlot = {
    __typename?: 'AccreditationSlot';
    accreditationId?: Maybe<Scalars['String']>;
    accreditationPassword?: Maybe<Scalars['String']>;
    accreditationPoints: Array<Maybe<SlotCredit>>;
    accreditationSource?: Maybe<Scalars['String']>;
    country: CourseCountry;
    courseId: Scalars['ID'];
    endDateTime: Scalars['DateTime'];
    id: Scalars['ID'];
    parentSlotId?: Maybe<Scalars['String']>;
    participationType?: Maybe<ParticipationType>;
    slotId: Scalars['ID'];
    slotType?: Maybe<AccreditationType>;
    startDateTime: Scalars['DateTime'];
    title: Scalars['String'];
};

export type AccreditationSlotContext = {
    __typename?: 'AccreditationSlotContext';
    endDateTime?: Maybe<Scalars['DateTime']>;
    slotId?: Maybe<Scalars['String']>;
    startDateTime?: Maybe<Scalars['DateTime']>;
    title?: Maybe<Scalars['String']>;
};

export type AccreditationSlotInput = {
    accreditationId?: InputMaybe<Scalars['String']>;
    accreditationPassword?: InputMaybe<Scalars['String']>;
    accreditationPoints: Array<InputMaybe<SlotCreditInput>>;
    accreditationSource?: InputMaybe<Scalars['String']>;
    country: CourseCountry;
    courseId: Scalars['ID'];
    endDateTime: Scalars['DateTime'];
    parentSlotId?: InputMaybe<Scalars['String']>;
    participationType?: InputMaybe<ParticipationType>;
    slotId: Scalars['ID'];
    slotType?: InputMaybe<AccreditationType>;
    startDateTime: Scalars['DateTime'];
    title: Scalars['String'];
};

export const AccreditationSlotType = {
    Block: 'BLOCK',
    Day: 'DAY',
    Stream: 'STREAM',
    Topic: 'TOPIC'
} as const;

export type AccreditationSlotType =
    (typeof AccreditationSlotType)[keyof typeof AccreditationSlotType];
export const AccreditationType = {
    Block: 'BLOCK',
    Day: 'DAY',
    Module: 'MODULE',
    Stream: 'STREAM',
    Topic: 'TOPIC'
} as const;

export type AccreditationType = (typeof AccreditationType)[keyof typeof AccreditationType];
export type ActivateUserResult = {
    __typename?: 'ActivateUserResult';
    activated: Scalars['Boolean'];
    resetPasswordToken?: Maybe<Scalars['String']>;
};

export type AddToMailchimpAudienceResult = {
    __typename?: 'AddToMailchimpAudienceResult';
    added: Scalars['Boolean'];
};

export type AdditionalContentInput = {
    endDateTime?: InputMaybe<Scalars['DateTime']>;
    fileId?: InputMaybe<Scalars['ID']>;
    isAvailableOnMediathek?: InputMaybe<Scalars['Boolean']>;
    isCommercial?: InputMaybe<Scalars['Boolean']>;
    isDownloadable?: InputMaybe<Scalars['Boolean']>;
    keywords?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    mediaType?: InputMaybe<MediaType>;
    providerId?: InputMaybe<Scalars['ID']>;
    publishedCountries?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    releaseDate?: InputMaybe<Scalars['Date']>;
    sourceUrl?: InputMaybe<Scalars['String']>;
    sponsorId?: InputMaybe<Scalars['ID']>;
    startDateTime?: InputMaybe<Scalars['DateTime']>;
    title?: InputMaybe<Scalars['String']>;
    validDate?: InputMaybe<Scalars['Date']>;
};

export type AddressR = {
    __typename?: 'AddressR';
    additionalInformation?: Maybe<Scalars['String']>;
    apartmentNumber?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    company?: Maybe<Scalars['String']>;
    country?: Maybe<Scalars['String']>;
    fullName?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    isPersonal?: Maybe<Scalars['Boolean']>;
    postalCode?: Maybe<Scalars['String']>;
    street?: Maybe<Scalars['String']>;
};

export type AddressRequest = {
    additionalInformation?: InputMaybe<Scalars['String']>;
    apartmentNumber?: InputMaybe<Scalars['String']>;
    city?: InputMaybe<Scalars['String']>;
    company?: InputMaybe<Scalars['String']>;
    country?: InputMaybe<Scalars['String']>;
    fullName?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    isPersonal?: InputMaybe<Scalars['Boolean']>;
    postalCode?: InputMaybe<Scalars['String']>;
    street?: InputMaybe<Scalars['String']>;
};

export type AdminBookingItemRequest = {
    eventBookableItemFunctionId: Scalars['String'];
    price?: InputMaybe<Scalars['Int']>;
};

export type AdminBookingRequest = {
    billingAddressId?: InputMaybe<Scalars['ID']>;
    bookedFromCountry?: InputMaybe<Scalars['String']>;
    bookedFromLocale?: InputMaybe<Scalars['Locale']>;
    bookingItems: Array<AdminBookingItemRequest>;
    deliveryAddressId?: InputMaybe<Scalars['ID']>;
    discountAmount?: InputMaybe<Scalars['Int']>;
    eventId?: InputMaybe<Scalars['ID']>;
    eventMarketingSourceOptionCodes: Array<Scalars['String']>;
    participantTypeId: Scalars['ID'];
    paymentMethod?: InputMaybe<PaymentMethod>;
    promoCode?: InputMaybe<Scalars['String']>;
    ubEventId?: InputMaybe<Scalars['String']>;
};

export type AgendaSettings = {
    __typename?: 'AgendaSettings';
    defaultDescription?: Maybe<Scalars['JSON']>;
    showAgenda?: Maybe<Scalars['Boolean']>;
    showProgramOverview?: Maybe<Scalars['Boolean']>;
    showSpeakers?: Maybe<Scalars['Boolean']>;
};

export type AgendaSettingsInput = {
    defaultDescription?: InputMaybe<Scalars['JSON']>;
    showAgenda?: InputMaybe<Scalars['Boolean']>;
    showProgramOverview?: InputMaybe<Scalars['Boolean']>;
    showSpeakers?: InputMaybe<Scalars['Boolean']>;
};

export const AggregationType = {
    Max: 'MAX',
    Min: 'MIN',
    Terms: 'TERMS'
} as const;

export type AggregationType = (typeof AggregationType)[keyof typeof AggregationType];
export const AmountUnit = {
    Course: 'COURSE',
    Credit: 'CREDIT',
    Day: 'DAY',
    Hour: 'HOUR',
    Video: 'VIDEO'
} as const;

export type AmountUnit = (typeof AmountUnit)[keyof typeof AmountUnit];
export type AnalyticsEventRequest = {
    data: Scalars['String'];
    eventType: AnalyticsEventType;
};

export type AnalyticsEventResult = {
    __typename?: 'AnalyticsEventResult';
    created: Scalars['Boolean'];
};

export const AnalyticsEventType = {
    Booking: 'BOOKING',
    Login: 'LOGIN',
    Registration: 'REGISTRATION'
} as const;

export type AnalyticsEventType = (typeof AnalyticsEventType)[keyof typeof AnalyticsEventType];
export type Answer = {
    __typename?: 'Answer';
    description: Scalars['String'];
    id: Scalars['ID'];
};

export type AttachPaymentMethodToUserResult = {
    __typename?: 'AttachPaymentMethodToUserResult';
    paymentMethodAttached: Scalars['Boolean'];
};

export type Attachment = {
    __typename?: 'Attachment';
    attachmentType?: Maybe<AttachmentType>;
    contentId: Scalars['ID'];
    file?: Maybe<File>;
    fileId?: Maybe<Scalars['ID']>;
    groupId?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
};

export type AttachmentInput = {
    attachmentType?: InputMaybe<AttachmentType>;
    contentId?: InputMaybe<Scalars['ID']>;
    fileId?: InputMaybe<Scalars['ID']>;
    groupId?: InputMaybe<Scalars['String']>;
};

export type AttachmentSearch = {
    __typename?: 'AttachmentSearch';
    data: Array<Attachment>;
    pageNumber?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    totalCount?: Maybe<Scalars['Int']>;
};

export const AttachmentType = {
    Authenticated: 'AUTHENTICATED',
    DigitalCoursebook: 'DIGITAL_COURSEBOOK',
    ParticipationInformation: 'PARTICIPATION_INFORMATION',
    Public: 'PUBLIC',
    SlideLecture: 'SLIDE_LECTURE'
} as const;

export type AttachmentType = (typeof AttachmentType)[keyof typeof AttachmentType];
export type AttendanceOverviewR = {
    __typename?: 'AttendanceOverviewR';
    title: Scalars['String'];
};

export type AttendanceRecordR = {
    __typename?: 'AttendanceRecordR';
    attendanceSlotId: Scalars['String'];
    attendanceSlotTitle: Scalars['String'];
    attendanceSlotType?: Maybe<AccreditationSlotType>;
    eventId: Scalars['ID'];
    id: Scalars['ID'];
    participantId: Scalars['ID'];
    recordDate: Scalars['DateTime'];
    recordType: RecordType;
};

export type BasicFile = {
    __typename?: 'BasicFile';
    downloadUrl?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    mimetype?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    shortUrl?: Maybe<Scalars['String']>;
    size?: Maybe<Scalars['Int']>;
    thumbnailUrl?: Maybe<Scalars['String']>;
    url?: Maybe<Scalars['String']>;
};

export type Benefit = {
    __typename?: 'Benefit';
    footNote?: Maybe<Scalars['JSON']>;
    iconUrl?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    isFootNoteEnabled?: Maybe<Scalars['Boolean']>;
    title?: Maybe<Scalars['JSON']>;
    usage?: Maybe<BenefitUsage>;
};

export type BenefitInput = {
    footNote?: InputMaybe<Scalars['JSON']>;
    iconUrl?: InputMaybe<Scalars['String']>;
    isFootNoteEnabled?: InputMaybe<Scalars['Boolean']>;
    title?: InputMaybe<Scalars['JSON']>;
    usage?: InputMaybe<BenefitUsage>;
};

export type BenefitSearch = {
    __typename?: 'BenefitSearch';
    data: Array<Benefit>;
    pageNumber?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    totalCount?: Maybe<Scalars['Int']>;
};

export const BenefitUsage = {
    Both: 'BOTH',
    Content: 'CONTENT',
    Product: 'PRODUCT'
} as const;

export type BenefitUsage = (typeof BenefitUsage)[keyof typeof BenefitUsage];
export type BookingContext = {
    __typename?: 'BookingContext';
    academicDegreeCode?: Maybe<Scalars['String']>;
    academicDegreePrefix?: Maybe<Scalars['String']>;
    academicDegreeSuffix?: Maybe<Scalars['String']>;
    amountDue?: Maybe<Scalars['Int']>;
    city?: Maybe<Scalars['String']>;
    efn?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    firstname?: Maybe<Scalars['String']>;
    hasCourseBook?: Maybe<Scalars['Boolean']>;
    isPaid?: Maybe<Scalars['Boolean']>;
    items?: Maybe<Array<Maybe<BookingSlotContext>>>;
    lastname?: Maybe<Scalars['String']>;
    location?: Maybe<Scalars['String']>;
    occupationType?: Maybe<Scalars['String']>;
    participantTypeCode?: Maybe<Scalars['String']>;
    participationType?: Maybe<ParticipationType>;
    speciality?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
};

export type BookingEventR = {
    __typename?: 'BookingEventR';
    allowedParticipantTypes: Array<ParticipantTypeR>;
    arePromoCodesAvailable: Scalars['Boolean'];
    availableForMembers: Scalars['Boolean'];
    category: Category;
    city?: Maybe<LabelValueR>;
    connectedAccountId?: Maybe<Scalars['String']>;
    content?: Maybe<ContentByUbEvent>;
    contentV2?: Maybe<Content>;
    country: LabelValueR;
    currency: Currency;
    endDateTime: Scalars['DateTime'];
    eventFormat: EventFormat;
    id: Scalars['ID'];
    items: Array<EventBookableItemR>;
    locale?: Maybe<Scalars['Locale']>;
    name: Scalars['String'];
    printedCourseBookFunctionId?: Maybe<Scalars['String']>;
    printedCourseBookPrice?: Maybe<Scalars['Int']>;
    productCategory: ProductCategoryR;
    publishedDomains: Array<Scalars['String']>;
    startDateTime: Scalars['DateTime'];
    taxPercentage?: Maybe<Scalars['Int']>;
    taxType?: Maybe<TaxType>;
    validMarketingOptions: Array<LabelValueR>;
};

export type BookingInvoiceFile = {
    __typename?: 'BookingInvoiceFile';
    id?: Maybe<Scalars['String']>;
};

export type BookingItemR = {
    __typename?: 'BookingItemR';
    bookingItemStatus?: Maybe<BookingItemStatus>;
    bookingItemType?: Maybe<BookingItemType>;
    eventBookableItemFunctionId?: Maybe<Scalars['String']>;
    price?: Maybe<Scalars['Int']>;
};

export type BookingItemRequest = {
    eventBookableItemFunctionId: Scalars['String'];
};

export const BookingItemStatus = {
    Active: 'ACTIVE',
    Canceled: 'CANCELED',
    Draft: 'DRAFT'
} as const;

export type BookingItemStatus = (typeof BookingItemStatus)[keyof typeof BookingItemStatus];
export const BookingItemType = {
    DigitalCourseBook: 'DIGITAL_COURSE_BOOK',
    Discount: 'DISCOUNT',
    PrintedCourseBook: 'PRINTED_COURSE_BOOK',
    PromoCode: 'PROMO_CODE',
    Tax: 'TAX',
    Ticket: 'TICKET'
} as const;

export type BookingItemType = (typeof BookingItemType)[keyof typeof BookingItemType];
export const BookingOperation = {
    CancelFromV2: 'CANCEL_FROM_V2',
    CreateFromUb: 'CREATE_FROM_UB',
    CreateFromV2: 'CREATE_FROM_V2',
    UpdateFromUb: 'UPDATE_FROM_UB',
    UpdateFromV2: 'UPDATE_FROM_V2'
} as const;

export type BookingOperation = (typeof BookingOperation)[keyof typeof BookingOperation];
export type BookingR = {
    __typename?: 'BookingR';
    bookingDate?: Maybe<Scalars['DateTime']>;
    bookingItems: Array<BookingItemR>;
    bookingStatus?: Maybe<BookingStatus>;
    bookingUpdatedDate?: Maybe<Scalars['DateTime']>;
    certificates?: Maybe<Array<EventParticipantCertificate>>;
    currency?: Maybe<Currency>;
    event?: Maybe<UserBookingEventR>;
    eventParticipantsSummaries?: Maybe<Array<EventParticipantsSummaryR>>;
    id: Scalars['ID'];
    invoiceFile?: Maybe<File>;
    invoiceLastUpdated?: Maybe<Scalars['DateTime']>;
    membershipCreditEffective?: Maybe<Scalars['Boolean']>;
    participantType?: Maybe<ParticipantTypeR>;
    totalPrice?: Maybe<Scalars['Int']>;
    ubOrderId?: Maybe<Scalars['String']>;
    userId: Scalars['ID'];
};

export type BookingReceiptFile = {
    __typename?: 'BookingReceiptFile';
    id?: Maybe<Scalars['String']>;
};

export type BookingRequest = {
    billingAddressId?: InputMaybe<Scalars['ID']>;
    bookedFromCountry?: InputMaybe<Scalars['String']>;
    bookedFromLocale?: InputMaybe<Scalars['Locale']>;
    bookingItems: Array<BookingItemRequest>;
    deliveryAddressId?: InputMaybe<Scalars['ID']>;
    eventId?: InputMaybe<Scalars['ID']>;
    eventMarketingSourceOptionCodes: Array<Scalars['String']>;
    participantTypeId: Scalars['ID'];
    paymentMethod?: InputMaybe<PaymentMethod>;
    promoCode?: InputMaybe<Scalars['String']>;
    requestedBy?: InputMaybe<Scalars['String']>;
    stripePaymentMethodReference?: InputMaybe<Scalars['String']>;
    ubEventId?: InputMaybe<Scalars['String']>;
};

export type BookingSearchQuery = {
    aggregations?: InputMaybe<Array<InputMaybe<SearchAggregation>>>;
    eventTimeConstraints?: InputMaybe<Array<EventTimeConstraint>>;
    filters?: InputMaybe<Array<SearchFilter>>;
    orders?: InputMaybe<Array<Scalars['String']>>;
    pageNumber?: InputMaybe<Scalars['Int']>;
    pageSize?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
};

export type BookingSearchResult = {
    __typename?: 'BookingSearchResult';
    data?: Maybe<Array<BookingR>>;
    pageNumber?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    totalCount?: Maybe<Scalars['Int']>;
};

export type BookingSlotContext = {
    __typename?: 'BookingSlotContext';
    participationType?: Maybe<Scalars['String']>;
    slotId?: Maybe<Scalars['String']>;
};

export const BookingStatus = {
    Canceled: 'CANCELED',
    Draft: 'DRAFT',
    Paid: 'PAID',
    PartiallyPaid: 'PARTIALLY_PAID',
    WaitingForPayment: 'WAITING_FOR_PAYMENT'
} as const;

export type BookingStatus = (typeof BookingStatus)[keyof typeof BookingStatus];
export type BookingValidationResult = {
    __typename?: 'BookingValidationResult';
    isValid: Scalars['Boolean'];
};

export const CacheControlScope = {
    Private: 'PRIVATE',
    Public: 'PUBLIC'
} as const;

export type CacheControlScope = (typeof CacheControlScope)[keyof typeof CacheControlScope];
export type CancelBookingResult = {
    __typename?: 'CancelBookingResult';
    canceled: Scalars['Boolean'];
};

export type CapturePayPalPaymentResult = {
    __typename?: 'CapturePayPalPaymentResult';
    paymentCaptured: Scalars['Boolean'];
};

export const Category = {
    Event: 'EVENT',
    FaceToFaceTraining: 'FACE_TO_FACE_TRAINING',
    Flm: 'FLM',
    FomfDigital: 'FOMF_DIGITAL',
    Service: 'SERVICE'
} as const;

export type Category = (typeof Category)[keyof typeof Category];
export const CertificateStatus = {
    NotSent: 'NOT_SENT',
    Sent: 'SENT',
    WithError: 'WITH_ERROR'
} as const;

export type CertificateStatus = (typeof CertificateStatus)[keyof typeof CertificateStatus];
export type ChangePasswordRequest = {
    currentPassword?: InputMaybe<Scalars['String']>;
    newPassword: Scalars['String'];
};

export type ChangePasswordResult = {
    __typename?: 'ChangePasswordResult';
    passwordUpdated?: Maybe<Scalars['Boolean']>;
};

export type City = {
    __typename?: 'City';
    id: Scalars['ID'];
    title?: Maybe<Scalars['JSON']>;
};

export type ColorSettings = {
    __typename?: 'ColorSettings';
    primaryDark?: Maybe<Scalars['String']>;
    primaryLight?: Maybe<Scalars['String']>;
    primaryTextDark?: Maybe<Scalars['String']>;
    primaryTextLight?: Maybe<Scalars['String']>;
};

export type Company = {
    __typename?: 'Company';
    address?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    colorSettings?: Maybe<ColorSettings>;
    /** @deprecated Field no longer supported */
    companyContacts: Array<CompanyContact>;
    companyPage?: Maybe<CompanyPage>;
    companyType?: Maybe<CompanyType>;
    country?: Maybe<Scalars['String']>;
    departments: Array<Department>;
    description?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    fax?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    links: Array<CompanyLink>;
    logoUrl?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    postalCode?: Maybe<Scalars['String']>;
    publishedDomains?: Maybe<Array<Maybe<Scalars['String']>>>;
    ubAccountCode?: Maybe<Scalars['String']>;
    websites?: Maybe<Scalars['JSON']>;
};

export type CompanyContact = {
    __typename?: 'CompanyContact';
    avatarSrcUrl?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    locales: Array<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    translatedDescription?: Maybe<Scalars['JSON']>;
};

export type CompanyDocument = {
    __typename?: 'CompanyDocument';
    address?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    colorSettings?: Maybe<ColorSettings>;
    companyType?: Maybe<Scalars['String']>;
    country?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    fax?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    logoUrl?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    postalCode?: Maybe<Scalars['String']>;
    publishStatus?: Maybe<PublishStatusType>;
    slug?: Maybe<Scalars['String']>;
    ubAccountCode?: Maybe<Scalars['String']>;
    websites?: Maybe<Scalars['JSON']>;
};

export type CompanyInput = {
    address?: InputMaybe<Scalars['String']>;
    city?: InputMaybe<Scalars['String']>;
    companyType?: InputMaybe<CompanyType>;
    country?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    email?: InputMaybe<Scalars['String']>;
    fax?: InputMaybe<Scalars['String']>;
    links?: InputMaybe<Array<CompanyLinkInput>>;
    logoUrl?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    phone?: InputMaybe<Scalars['String']>;
    postalCode?: InputMaybe<Scalars['String']>;
    publishedDomains?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    ubAccountCode?: InputMaybe<Scalars['String']>;
};

export type CompanyLink = {
    __typename?: 'CompanyLink';
    locales: Array<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    type?: Maybe<CompanyLinkType>;
    url?: Maybe<Scalars['String']>;
};

export type CompanyLinkInput = {
    locales?: InputMaybe<Array<Scalars['String']>>;
    name?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<CompanyLinkType>;
    url?: InputMaybe<Scalars['String']>;
};

export const CompanyLinkType = {
    Facebook: 'FACEBOOK',
    Linkedin: 'LINKEDIN',
    Other: 'OTHER',
    Twitter: 'TWITTER',
    Website: 'WEBSITE',
    Youtube: 'YOUTUBE'
} as const;

export type CompanyLinkType = (typeof CompanyLinkType)[keyof typeof CompanyLinkType];
export type CompanyPage = {
    __typename?: 'CompanyPage';
    accessLink?: Maybe<Scalars['String']>;
    accessSetting?: Maybe<AccessSettingType>;
    customTitle?: Maybe<Scalars['JSON']>;
    descriptionBody?: Maybe<Scalars['JSON']>;
    descriptionHeader?: Maybe<Scalars['JSON']>;
    directLink?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    isContactSectionHidden?: Maybe<Scalars['Boolean']>;
    publishStatus?: Maybe<PublishStatusType>;
    /** @deprecated Field no longer supported */
    shortLink?: Maybe<Scalars['String']>;
    slug?: Maybe<Scalars['String']>;
};

export type CompanyPageInput = {
    accessSetting?: InputMaybe<AccessSettingType>;
    companyId?: InputMaybe<Scalars['ID']>;
    customTitle?: InputMaybe<Scalars['JSON']>;
    departmentId?: InputMaybe<Scalars['ID']>;
    descriptionBody?: InputMaybe<Scalars['JSON']>;
    descriptionHeader?: InputMaybe<Scalars['JSON']>;
    directLink?: InputMaybe<Scalars['String']>;
    isContactSectionHidden?: InputMaybe<Scalars['Boolean']>;
    publishStatus?: InputMaybe<PublishStatusType>;
    slug?: InputMaybe<Scalars['String']>;
};

export type CompanySearch = {
    __typename?: 'CompanySearch';
    data: Array<Company>;
    pageNumber?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    totalCount?: Maybe<Scalars['Int']>;
};

export const CompanyType = {
    Clinic: 'CLINIC',
    Cooperation: 'COOPERATION',
    Hospital: 'HOSPITAL',
    Society: 'SOCIETY',
    Sponsor: 'SPONSOR',
    Unknown: 'UNKNOWN'
} as const;

export type CompanyType = (typeof CompanyType)[keyof typeof CompanyType];
export type Content = {
    __typename?: 'Content';
    accreditationSettings?: Maybe<AccreditationSettings>;
    agendaSettings?: Maybe<AgendaSettings>;
    attachmentIds: Array<Scalars['ID']>;
    attachments: Array<Attachment>;
    benefitIds: Array<Scalars['ID']>;
    benefits: Array<Benefit>;
    bookmarked?: Maybe<Scalars['Boolean']>;
    brand?: Maybe<ProductBrand>;
    capacityStatus?: Maybe<ContentCapacityStatus>;
    certificate?: Maybe<Report>;
    city?: Maybe<Scalars['JSON']>;
    cityId?: Maybe<Scalars['String']>;
    companiesAccess: Array<ContentCompanyAccess>;
    companiesAccessIds: Array<Scalars['ID']>;
    contentAdmin?: Maybe<UserR>;
    contentId: Scalars['String'];
    contentProgress?: Maybe<ContentProgress>;
    /** @deprecated Field no longer supported */
    contentType?: Maybe<ContentType>;
    contentTypeV2?: Maybe<ContentType>;
    contentUnit?: Maybe<ContentUnit>;
    contentUnitIds: Array<Scalars['ID']>;
    contentUnits: Array<ContentUnit>;
    credits: Array<ContentCredit>;
    /** @deprecated Field no longer supported */
    department?: Maybe<Department>;
    description?: Maybe<Scalars['JSON']>;
    descriptionTitle?: Maybe<Scalars['JSON']>;
    detailsPageSettings?: Maybe<DetailsPageSettings>;
    dominantColor?: Maybe<Scalars['String']>;
    endDateTime?: Maybe<Scalars['DateTime']>;
    eventCost?: Maybe<Scalars['Int']>;
    externalId?: Maybe<Scalars['String']>;
    featuredInfoUpdatedAt?: Maybe<Scalars['DateTime']>;
    headerUrl?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    imageUrl?: Maybe<Scalars['String']>;
    isAvailableOnHomepage?: Maybe<Scalars['Boolean']>;
    isAvailableOnMediathek?: Maybe<Scalars['Boolean']>;
    isAvailableWithoutEfn?: Maybe<Scalars['Boolean']>;
    isCommercial?: Maybe<Scalars['Boolean']>;
    isCompanyPublished?: Maybe<Scalars['Boolean']>;
    isDeleted: Scalars['Boolean'];
    isDownloadable?: Maybe<Scalars['Boolean']>;
    isFeatured: Scalars['Boolean'];
    isFree?: Maybe<Scalars['Boolean']>;
    isOnDemand?: Maybe<Scalars['Boolean']>;
    isOnSite?: Maybe<Scalars['Boolean']>;
    isScientificLeaderEnabled?: Maybe<Scalars['Boolean']>;
    isSponsored?: Maybe<Scalars['Boolean']>;
    keywords: Array<Scalars['String']>;
    locale?: Maybe<Scalars['Locale']>;
    location?: Maybe<Scalars['String']>;
    mediaSourceId?: Maybe<Scalars['String']>;
    membershipSettings: MembershipSettings;
    order?: Maybe<Scalars['Int']>;
    packageOwner?: Maybe<Company>;
    parent?: Maybe<Content>;
    participationOption?: Maybe<ContentParticipationOption>;
    participationType?: Maybe<ParticipationType>;
    partnerEventAccessType?: Maybe<PartnerEventAccessType>;
    partnershipIds: Array<Scalars['ID']>;
    partnerships: Array<Partnership>;
    product?: Maybe<Product>;
    productCodes: Array<Scalars['String']>;
    programSchedules: Array<ProgramSchedule>;
    /** @deprecated Field no longer supported */
    provider?: Maybe<Company>;
    providers?: Maybe<Company>;
    publishedCountries: Array<Scalars['String']>;
    questions?: Maybe<Array<Maybe<Question>>>;
    rating?: Maybe<Scalars['Float']>;
    releaseDate?: Maybe<Scalars['Date']>;
    replacementDate?: Maybe<Scalars['Date']>;
    replacementTitle?: Maybe<Scalars['String']>;
    required?: Maybe<Scalars['Boolean']>;
    schedule: Array<Content>;
    scientificCoLeaders: Array<Speaker>;
    scientificLeaders: Array<Speaker>;
    seoSettings?: Maybe<SeoSettings>;
    slug?: Maybe<Scalars['String']>;
    source?: Maybe<Source>;
    speaker?: Maybe<Speaker>;
    speakers: Array<Speaker>;
    specialities: Array<Scalars['String']>;
    sponsorshipIds: Array<Scalars['ID']>;
    sponsorships: Array<Sponsorship>;
    startDateTime?: Maybe<Scalars['DateTime']>;
    submodule?: Maybe<Submodule>;
    submodules?: Maybe<Array<Submodule>>;
    subtitle?: Maybe<Scalars['String']>;
    targetGroupIds: Array<Scalars['ID']>;
    targetGroups: Array<TargetGroup>;
    title?: Maybe<Scalars['String']>;
    topicIds: Array<Scalars['ID']>;
    topics: Array<Topic>;
    validDate?: Maybe<Scalars['Date']>;
    willBeBroadcasted?: Maybe<Scalars['Boolean']>;
    willBeOnDemand?: Maybe<Scalars['Boolean']>;
};

export type ContentByUbEvent = {
    __typename?: 'ContentByUbEvent';
    content?: Maybe<Content>;
    contentId?: Maybe<Scalars['String']>;
    externalId: Scalars['String'];
};

export const ContentCapacityStatus = {
    OnsiteNearlySoldOut: 'ONSITE_NEARLY_SOLD_OUT',
    OnsiteSoldOut: 'ONSITE_SOLD_OUT',
    OnsiteSoldOutLivestreamAvailable: 'ONSITE_SOLD_OUT_LIVESTREAM_AVAILABLE'
} as const;

export type ContentCapacityStatus =
    (typeof ContentCapacityStatus)[keyof typeof ContentCapacityStatus];
export type ContentCompanyAccess = {
    __typename?: 'ContentCompanyAccess';
    company?: Maybe<Company>;
    companyId?: Maybe<Scalars['ID']>;
    contentId: Scalars['ID'];
    id: Scalars['ID'];
    ubAccountId?: Maybe<Scalars['String']>;
};

export type ContentCredit = {
    __typename?: 'ContentCredit';
    accreditationInstitute?: Maybe<Scalars['String']>;
    amount: Scalars['Int'];
    isApproved?: Maybe<Scalars['Boolean']>;
    participationType?: Maybe<ParticipationType>;
    unit: Scalars['String'];
};

export type ContentCreditInput = {
    amount: Scalars['Int'];
    unit: Scalars['String'];
};

export type ContentDocument = {
    __typename?: 'ContentDocument';
    bookmarked?: Maybe<Scalars['Boolean']>;
    brand?: Maybe<ProductBrand>;
    capacityStatus?: Maybe<ContentCapacityStatus>;
    city?: Maybe<Scalars['JSON']>;
    code?: Maybe<Scalars['String']>;
    contentId: Scalars['String'];
    contentProgress?: Maybe<ContentProgress>;
    contentType: ContentType;
    credits: Array<ContentCredit>;
    /** @deprecated Field no longer supported */
    department?: Maybe<DepartmentDocument>;
    description?: Maybe<Scalars['JSON']>;
    dominantColor?: Maybe<Scalars['String']>;
    endDateTime?: Maybe<Scalars['DateTime']>;
    externalId?: Maybe<Scalars['String']>;
    featuredInfoUpdatedAt?: Maybe<Scalars['DateTime']>;
    headerUrl?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    isAvailableOnHomepage?: Maybe<Scalars['Boolean']>;
    isAvailableOnMediathek?: Maybe<Scalars['Boolean']>;
    isCompanyPublished?: Maybe<Scalars['Boolean']>;
    isFeatured: Scalars['Boolean'];
    isFree?: Maybe<Scalars['Boolean']>;
    isOnDemand?: Maybe<Scalars['Boolean']>;
    isOnSite?: Maybe<Scalars['Boolean']>;
    isSponsored?: Maybe<Scalars['Boolean']>;
    locale?: Maybe<Scalars['Locale']>;
    parent?: Maybe<ContentDocument>;
    participationOption?: Maybe<ContentParticipationOption>;
    partnerEventAccessType?: Maybe<PartnerEventAccessType>;
    product?: Maybe<ProductDocument>;
    productCodes: Array<Scalars['String']>;
    /** @deprecated Field no longer supported */
    provider?: Maybe<CompanyDocument>;
    providers: Array<Company>;
    rating?: Maybe<Scalars['Float']>;
    releaseDate?: Maybe<Scalars['Date']>;
    schedule: Array<ContentDocument>;
    scheduleIds: Array<Scalars['Int']>;
    scheduleItemsCount?: Maybe<Scalars['Int']>;
    scientificCoLeaders: Array<Speaker>;
    scientificLeaders: Array<Speaker>;
    slug?: Maybe<Scalars['String']>;
    source?: Maybe<Source>;
    speaker?: Maybe<Speaker>;
    speakers: Array<Speaker>;
    specialities: Array<Scalars['String']>;
    sponsorships: Array<SponsorshipDocument>;
    startDateTime?: Maybe<Scalars['DateTime']>;
    subtitle?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    topics: Array<Topic>;
    validDate?: Maybe<Scalars['Date']>;
    willBeBroadcasted?: Maybe<Scalars['Boolean']>;
    willBeOnDemand?: Maybe<Scalars['Boolean']>;
};

export type ContentDocumentConnection = {
    __typename?: 'ContentDocumentConnection';
    aggregations?: Maybe<Array<Maybe<SearchResultAggregation>>>;
    data: Array<ContentDocument>;
    pageNumber?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    totalCount?: Maybe<Scalars['Int']>;
};

export type ContentInput = {
    accreditationSettings?: InputMaybe<AccreditationSettingsInput>;
    agendaSettings?: InputMaybe<AgendaSettingsInput>;
    benefitIds?: InputMaybe<Array<Scalars['ID']>>;
    brand?: InputMaybe<ProductBrand>;
    brandTitle?: InputMaybe<Scalars['JSON']>;
    cityId?: InputMaybe<Scalars['ID']>;
    contentType?: InputMaybe<ContentType>;
    departmentId?: InputMaybe<Scalars['ID']>;
    description?: InputMaybe<Scalars['JSON']>;
    descriptionTitle?: InputMaybe<Scalars['JSON']>;
    detailsPageSettings?: InputMaybe<DetailsPageSettingsInput>;
    dominantColor?: InputMaybe<Scalars['String']>;
    endDateTime?: InputMaybe<Scalars['DateTime']>;
    eventCost?: InputMaybe<Scalars['Int']>;
    fileId?: InputMaybe<Scalars['ID']>;
    headerUrl?: InputMaybe<Scalars['String']>;
    imageUrl?: InputMaybe<Scalars['String']>;
    isAvailableOnHomepage?: InputMaybe<Scalars['Boolean']>;
    isAvailableOnMediathek?: InputMaybe<Scalars['Boolean']>;
    isAvailableWithoutEfn?: InputMaybe<Scalars['Boolean']>;
    isCommercial?: InputMaybe<Scalars['Boolean']>;
    isCompanyPublished?: InputMaybe<Scalars['Boolean']>;
    isDownloadable?: InputMaybe<Scalars['Boolean']>;
    isFeatured?: InputMaybe<Scalars['Boolean']>;
    isFree?: InputMaybe<Scalars['Boolean']>;
    isOnSite?: InputMaybe<Scalars['Boolean']>;
    isScientificLeaderEnabled?: InputMaybe<Scalars['Boolean']>;
    keywords?: InputMaybe<Array<Scalars['String']>>;
    locale?: InputMaybe<Scalars['Locale']>;
    location?: InputMaybe<Scalars['String']>;
    parentId?: InputMaybe<Scalars['ID']>;
    participationOption?: InputMaybe<ContentParticipationOption>;
    productId?: InputMaybe<Scalars['ID']>;
    providerId?: InputMaybe<Scalars['ID']>;
    publishedCountries?: InputMaybe<Array<Scalars['String']>>;
    releaseDate?: InputMaybe<Scalars['Date']>;
    scientificCoLeaderIds?: InputMaybe<Array<Scalars['ID']>>;
    scientificLeaderIds?: InputMaybe<Array<Scalars['ID']>>;
    seoSettings?: InputMaybe<SeoSettingsInput>;
    slug?: InputMaybe<Scalars['String']>;
    sourceUrl?: InputMaybe<Scalars['String']>;
    speakerIds?: InputMaybe<Array<Scalars['ID']>>;
    startDateTime?: InputMaybe<Scalars['DateTime']>;
    subtitle?: InputMaybe<Scalars['String']>;
    targetGroupIds?: InputMaybe<Array<Scalars['ID']>>;
    title?: InputMaybe<Scalars['String']>;
    topicIds?: InputMaybe<Array<Scalars['ID']>>;
    unit?: InputMaybe<Scalars['String']>;
    validDate?: InputMaybe<Scalars['Date']>;
    willBeBroadcasted?: InputMaybe<Scalars['Boolean']>;
    willBeOnDemand?: InputMaybe<Scalars['Boolean']>;
};

export const ContentParticipationOption = {
    Hybrid: 'HYBRID',
    LiveStream: 'LIVE_STREAM',
    OnDemand: 'ON_DEMAND',
    OnSite: 'ON_SITE'
} as const;

export type ContentParticipationOption =
    (typeof ContentParticipationOption)[keyof typeof ContentParticipationOption];
export type ContentProgress = {
    __typename?: 'ContentProgress';
    completed?: Maybe<Scalars['Boolean']>;
    content?: Maybe<ContentDocument>;
    contentId: Scalars['ID'];
    time?: Maybe<Scalars['Float']>;
    trackedDateTime?: Maybe<Scalars['DateTime']>;
    userId: Scalars['ID'];
    video?: Maybe<Video>;
};

export type ContentProgressConnection = {
    __typename?: 'ContentProgressConnection';
    edges?: Maybe<Array<Maybe<ContentProgressEdge>>>;
    pageInfo?: Maybe<PageInfo>;
};

export type ContentProgressEdge = {
    __typename?: 'ContentProgressEdge';
    cursor?: Maybe<Scalars['String']>;
    node?: Maybe<ContentProgress>;
};

export type ContentSearch = {
    __typename?: 'ContentSearch';
    data: Array<Content>;
    pageNumber?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    totalCount?: Maybe<Scalars['Int']>;
};

export type ContentSearchInput = {
    aggregations?: InputMaybe<Array<InputMaybe<SearchAggregation>>>;
    filters?: InputMaybe<Array<SearchFilter>>;
    orders?: InputMaybe<Array<Scalars['String']>>;
    pageNumber?: InputMaybe<Scalars['Int']>;
    pageSize?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
    topicsOrder?: InputMaybe<Array<Scalars['String']>>;
};

export type ContentSourceItem = {
    __typename?: 'ContentSourceItem';
    key?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['String']>;
};

export type ContentSponsorship = {
    __typename?: 'ContentSponsorship';
    contentId: Scalars['ID'];
    id: Scalars['ID'];
    scheduleId?: Maybe<Scalars['ID']>;
    sponsorshipId: Scalars['ID'];
};

export type ContentSponsorshipInput = {
    contentId: Scalars['ID'];
    scheduleId: Scalars['ID'];
    sponsorshipId: Scalars['ID'];
};

export const ContentType = {
    AddonCourse: 'ADDON_COURSE',
    Article: 'ARTICLE',
    Course: 'COURSE',
    Lecture: 'LECTURE',
    LiveCourse: 'LIVE_COURSE',
    OndemandCourse: 'ONDEMAND_COURSE',
    OnDemandAccredited: 'ON_DEMAND_ACCREDITED',
    PartnerEvent: 'PARTNER_EVENT',
    Pdf: 'PDF',
    Webinar: 'WEBINAR'
} as const;

export type ContentType = (typeof ContentType)[keyof typeof ContentType];
export type ContentUnit = {
    __typename?: 'ContentUnit';
    functionId: Scalars['String'];
    id: Scalars['ID'];
    order?: Maybe<Scalars['Int']>;
    submodules?: Maybe<Array<Submodule>>;
    title: Scalars['String'];
};

export type Course = {
    __typename?: 'Course';
    accreditationSettings?: Maybe<AccreditationSettings>;
    agendaSettings?: Maybe<AgendaSettings>;
    bookmarked?: Maybe<Scalars['Boolean']>;
    brand?: Maybe<ProductBrand>;
    capacityStatus?: Maybe<ContentCapacityStatus>;
    certificate?: Maybe<Report>;
    city?: Maybe<Scalars['JSON']>;
    code?: Maybe<Scalars['String']>;
    contentId: Scalars['String'];
    contentType: ContentType;
    courseProgress?: Maybe<CourseProgress>;
    courseRatingUrl?: Maybe<Scalars['String']>;
    credits: Array<ContentCredit>;
    /** @deprecated Field no longer supported */
    department?: Maybe<DepartmentDocument>;
    description?: Maybe<Scalars['JSON']>;
    descriptionTitle?: Maybe<Scalars['JSON']>;
    detailsPageSettings?: Maybe<DetailsPageSettings>;
    dominantColor?: Maybe<Scalars['String']>;
    endDateTime?: Maybe<Scalars['DateTime']>;
    externalId?: Maybe<Scalars['String']>;
    featuredInfoUpdatedAt?: Maybe<Scalars['DateTime']>;
    headerUrl?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    isAvailableOnHomepage?: Maybe<Scalars['Boolean']>;
    isAvailableOnMediathek?: Maybe<Scalars['Boolean']>;
    isCompanyPublished?: Maybe<Scalars['Boolean']>;
    isFeatured: Scalars['Boolean'];
    isFree?: Maybe<Scalars['Boolean']>;
    isOnDemand?: Maybe<Scalars['Boolean']>;
    isOnSite?: Maybe<Scalars['Boolean']>;
    isSponsored?: Maybe<Scalars['Boolean']>;
    locale?: Maybe<Scalars['Locale']>;
    modules: Array<Module>;
    parent?: Maybe<ContentDocument>;
    participationOption?: Maybe<ContentParticipationOption>;
    product?: Maybe<ProductDocument>;
    productCodes: Array<Scalars['String']>;
    programSchedules: Array<ProgramSchedule>;
    /** @deprecated Field no longer supported */
    provider?: Maybe<CompanyDocument>;
    providers: Array<Company>;
    questions: Array<Question>;
    releaseDate?: Maybe<Scalars['Date']>;
    /** @deprecated Field no longer supported */
    schedule: Array<ContentDocument>;
    /** @deprecated Field no longer supported */
    scheduleIds: Array<Scalars['Int']>;
    scheduleItemsCount?: Maybe<Scalars['Int']>;
    scientificCoLeaders: Array<Speaker>;
    scientificLeaders: Array<Speaker>;
    seoSettings?: Maybe<SeoSettings>;
    slug?: Maybe<Scalars['String']>;
    source?: Maybe<Source>;
    /** @deprecated Field no longer supported */
    speaker?: Maybe<Speaker>;
    speakers: Array<Speaker>;
    specialities: Array<Scalars['String']>;
    sponsorships: Array<SponsorshipDocument>;
    startDateTime?: Maybe<Scalars['DateTime']>;
    submodules?: Maybe<Array<Submodule>>;
    subtitle?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    /** @deprecated Field no longer supported */
    topics: Array<Topic>;
    validDate?: Maybe<Scalars['Date']>;
    videoRatingUrl?: Maybe<Scalars['String']>;
    videos: Array<Video>;
    willBeBroadcasted?: Maybe<Scalars['Boolean']>;
    willBeOnDemand?: Maybe<Scalars['Boolean']>;
};

export type CourseConnection = {
    __typename?: 'CourseConnection';
    aggregations?: Maybe<Array<Maybe<SearchResultAggregation>>>;
    data: Array<Course>;
    pageNumber?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    totalCount?: Maybe<Scalars['Int']>;
};

export const CourseCountry = {
    Aus: 'AUS',
    Aut: 'AUT',
    Che: 'CHE',
    Deu: 'DEU',
    Gbr: 'GBR'
} as const;

export type CourseCountry = (typeof CourseCountry)[keyof typeof CourseCountry];
export type CourseProgress = {
    __typename?: 'CourseProgress';
    completedPercent: Scalars['Int'];
    course?: Maybe<Course>;
    courseId: Scalars['ID'];
    lastWatchedVideo?: Maybe<Video>;
    startedDateTime: Scalars['DateTime'];
    userId: Scalars['ID'];
};

export type CreateNewOnboardingToken = {
    __typename?: 'CreateNewOnboardingToken';
    created?: Maybe<Scalars['Boolean']>;
};

export type CreateReminderConfigInput = {
    eventUbId: Scalars['String'];
    locale: Scalars['Locale'];
    reminderChannel: ReminderChannel;
    reminderDateTime: Scalars['DateTimeISO'];
    reminderTemplate: ReminderTemplate;
};

export type CreateReminderTransactionInput = {
    externalProvider: Scalars['String'];
    externalProviderSendResult?: InputMaybe<Scalars['String']>;
    externalTransactionId?: InputMaybe<Scalars['String']>;
    reminderConfigId: Scalars['ID'];
    sendDateTime: Scalars['DateTimeISO'];
    status: ReminderTransactionStatus;
};

export type CreateSetupIntentResult = {
    __typename?: 'CreateSetupIntentResult';
    clientSecret: Scalars['String'];
};

export type CreatedResult = {
    __typename?: 'CreatedResult';
    id: Scalars['ID'];
};

export type CreditPointR = {
    __typename?: 'CreditPointR';
    amount?: Maybe<Scalars['Int']>;
    mainAccreditation?: Maybe<Scalars['Boolean']>;
    unit: Scalars['String'];
};

export const CreditTransferStatus = {
    NotTransferred: 'NOT_TRANSFERRED',
    Transferred: 'TRANSFERRED'
} as const;

export type CreditTransferStatus = (typeof CreditTransferStatus)[keyof typeof CreditTransferStatus];
export const Currency = {
    Chf: 'CHF',
    Eur: 'EUR',
    Gbp: 'GBP'
} as const;

export type Currency = (typeof Currency)[keyof typeof Currency];
export type CustomerEventRequest = {
    category?: InputMaybe<Category>;
    description: Scalars['String'];
    endDateTime: Scalars['DateTime'];
    eventId: Scalars['ID'];
    livestream: Scalars['Boolean'];
    location?: InputMaybe<Scalars['String']>;
    name: Scalars['String'];
    onSite: Scalars['Boolean'];
    startDateTime: Scalars['DateTime'];
};

export type DeductedCreditsRequest = {
    deductedAmount: Scalars['Int'];
    reason?: InputMaybe<MembershipTransactionReason>;
    reference: Scalars['String'];
};

export type DeductedCreditsResult = {
    __typename?: 'DeductedCreditsResult';
    deducted?: Maybe<Scalars['Boolean']>;
};

export type DefaultUserPaymentMethodResult = {
    __typename?: 'DefaultUserPaymentMethodResult';
    default: Scalars['Boolean'];
    reference: Scalars['String'];
};

export type DeleteEventReminderResponse = {
    __typename?: 'DeleteEventReminderResponse';
    deleted: Scalars['Boolean'];
};

export type DeleteParticipantTypeResult = {
    __typename?: 'DeleteParticipantTypeResult';
    deleted: Scalars['Boolean'];
    participantTypeId: Scalars['ID'];
};

export type DeletePriceListResult = {
    __typename?: 'DeletePriceListResult';
    deleted: Scalars['Boolean'];
    priceListId: Scalars['ID'];
};

export type DeletePromoCodeResult = {
    __typename?: 'DeletePromoCodeResult';
    deleted: Scalars['Boolean'];
    promoCodeId: Scalars['ID'];
};

export type DeleteUserPaymentMethodResult = {
    __typename?: 'DeleteUserPaymentMethodResult';
    deleted: Scalars['Boolean'];
    reference: Scalars['String'];
};

export type DeleteUserResult = {
    __typename?: 'DeleteUserResult';
    deleted: Scalars['Boolean'];
};

export type DeletedResult = {
    __typename?: 'DeletedResult';
    deleted: Scalars['Boolean'];
    id: Scalars['ID'];
};

export type Department = {
    __typename?: 'Department';
    company?: Maybe<Company>;
    companyContacts: Array<CompanyContact>;
    companyPage?: Maybe<CompanyPage>;
    id: Scalars['ID'];
    links: Array<CompanyLink>;
    logoUrl?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    speciality?: Maybe<Scalars['String']>;
    translatedName?: Maybe<Scalars['JSON']>;
};

export type DepartmentDocument = {
    __typename?: 'DepartmentDocument';
    id: Scalars['ID'];
    logoUrl?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    publishStatus?: Maybe<PublishStatusType>;
    slug?: Maybe<Scalars['String']>;
    speciality?: Maybe<Scalars['String']>;
};

export type DepartmentInput = {
    companyId?: InputMaybe<Scalars['ID']>;
    links?: InputMaybe<Array<CompanyLinkInput>>;
    logoUrl?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    speciality?: InputMaybe<Scalars['String']>;
    translatedName?: InputMaybe<Scalars['JSON']>;
};

export type DetailsPageSettings = {
    __typename?: 'DetailsPageSettings';
    eventBookable?: Maybe<Scalars['Boolean']>;
    redirectThroughExhibition?: Maybe<Scalars['Boolean']>;
    showLocation?: Maybe<Scalars['Boolean']>;
    showScientificLeaders?: Maybe<Scalars['Boolean']>;
    sponsorDescription?: Maybe<Scalars['JSON']>;
};

export type DetailsPageSettingsInput = {
    eventBookable?: InputMaybe<Scalars['Boolean']>;
    redirectThroughExhibition?: InputMaybe<Scalars['Boolean']>;
    showLocation?: InputMaybe<Scalars['Boolean']>;
    showScientificLeaders?: InputMaybe<Scalars['Boolean']>;
};

export const DiscountType = {
    NoDiscount: 'NO_DISCOUNT',
    RwgimMember: 'RWGIM_MEMBER'
} as const;

export type DiscountType = (typeof DiscountType)[keyof typeof DiscountType];
export type EarlyRenewGroupMembershipResult = {
    __typename?: 'EarlyRenewGroupMembershipResult';
    failedUserRenewals?: Maybe<Array<Maybe<FailedUserRenewals>>>;
    groupMembershipEarlySuccessfullyRenewed: Scalars['Boolean'];
};

export type EarlyRenewMembershipResult = {
    __typename?: 'EarlyRenewMembershipResult';
    earlyRenewed: Scalars['Boolean'];
};

export type EventBookableItemR = {
    __typename?: 'EventBookableItemR';
    bookedCount?: Maybe<Scalars['Int']>;
    capacity?: Maybe<Scalars['Int']>;
    creditPoints?: Maybe<Array<Maybe<CreditPointR>>>;
    endDateTime: Scalars['DateTime'];
    eventBookableItemId: Scalars['String'];
    name: Scalars['String'];
    participantTypePrices: Array<ParticipantTypePriceR>;
    participationType: ParticipationType;
    startDateTime: Scalars['DateTime'];
    ticketType: TicketType;
};

export type EventBookableItemUpdateRequest = {
    capacity?: InputMaybe<Scalars['Int']>;
    eventBookableItemId: Scalars['String'];
};

export type EventBookingContext = {
    __typename?: 'EventBookingContext';
    allBookings: Array<BookingContext>;
    eventSlots: Array<AccreditationSlotContext>;
};

export const EventFormat = {
    ExpertenForum: 'EXPERTEN_FORUM',
    Fortbildungstage: 'FORTBILDUNGSTAGE',
    PartnerEvent: 'PARTNER_EVENT',
    Refresher: 'REFRESHER',
    RefresherAktuell: 'REFRESHER_AKTUELL',
    StudyConferenceUpdate: 'STUDY_CONFERENCE_UPDATE',
    UpdateRefresher: 'UPDATE_REFRESHER',
    Webup: 'WEBUP'
} as const;

export type EventFormat = (typeof EventFormat)[keyof typeof EventFormat];
export type EventParticipantCertificate = {
    __typename?: 'EventParticipantCertificate';
    certificateFile?: Maybe<File>;
    participationType?: Maybe<ParticipationType>;
    status?: Maybe<CertificateStatus>;
};

export type EventParticipantTypeLimitR = {
    __typename?: 'EventParticipantTypeLimitR';
    id: Scalars['ID'];
    limit?: Maybe<Scalars['Int']>;
    participantType: ParticipantTypeR;
};

export type EventParticipantTypeLimitRequest = {
    limit?: InputMaybe<Scalars['Int']>;
    participantTypeId: Scalars['ID'];
};

export type EventParticipantsSummaryR = {
    __typename?: 'EventParticipantsSummaryR';
    attendanceOverviews: Array<AttendanceOverviewR>;
    attendanceRecords: Array<AttendanceRecordR>;
    certificateSendingError?: Maybe<Scalars['String']>;
    certificateSentAt?: Maybe<Scalars['DateTime']>;
    certificateSentByUser?: Maybe<UserR>;
    certificateStatus: CertificateStatus;
    certificateUrl?: Maybe<Scalars['String']>;
    certificates: Array<EventParticipantCertificate>;
    creditTransferStatus: CreditTransferStatus;
    eventId: Scalars['ID'];
    grantedPoints: Array<SlotCredit>;
    id: Scalars['ID'];
    isBookingPaid: Scalars['Boolean'];
    maxPoints: Array<SlotCredit>;
    participantEfnNumber?: Maybe<Scalars['String']>;
    participantFullName: Scalars['String'];
    participantId: Scalars['ID'];
};

export type EventParticipantsSummarySearchResult = {
    __typename?: 'EventParticipantsSummarySearchResult';
    data: Array<EventParticipantsSummaryR>;
    pageNumber?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    totalCount?: Maybe<Scalars['Int']>;
};

export type EventR = {
    __typename?: 'EventR';
    allowedParticipantTypes?: Maybe<Array<ParticipantTypeR>>;
    availableForMembers: Scalars['Boolean'];
    capacityOnSite?: Maybe<Scalars['Int']>;
    category: Category;
    city?: Maybe<LabelValueR>;
    country?: Maybe<LabelValueR>;
    customerEventId?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    endDateTime: Scalars['DateTime'];
    eventFormat: EventFormat;
    eventParticipantTypeLimits: Array<EventParticipantTypeLimitR>;
    id: Scalars['ID'];
    items: Array<EventBookableItemR>;
    livestream: Scalars['Boolean'];
    locale?: Maybe<Scalars['Locale']>;
    location?: Maybe<Scalars['String']>;
    medicalChamberReportDownloadedAt?: Maybe<Scalars['DateTime']>;
    medicalChamberReportDownloadedByUser?: Maybe<UserR>;
    name: Scalars['String'];
    onSite: Scalars['Boolean'];
    priceList?: Maybe<PriceListR>;
    printedCourseBookFunctionId?: Maybe<Scalars['String']>;
    printedCourseBookPrice?: Maybe<Scalars['Int']>;
    productCategory?: Maybe<ProductCategoryR>;
    promoCodes: Array<PromoCodeR>;
    publishedDomains: Array<Scalars['String']>;
    specialities: Array<LabelValueR>;
    startDateTime: Scalars['DateTime'];
    ubEventId?: Maybe<Scalars['String']>;
    validMarketingOptions: Array<LabelValueR>;
};

export type EventRequest = {
    eventBookableItems?: InputMaybe<Array<EventBookableItemUpdateRequest>>;
    eventParticipantTypeLimits?: InputMaybe<Array<EventParticipantTypeLimitRequest>>;
    priceListId?: InputMaybe<Scalars['ID']>;
    promoCodeIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EventSearchResult = {
    __typename?: 'EventSearchResult';
    data: Array<EventR>;
    pageNumber?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    totalCount?: Maybe<Scalars['Int']>;
};

export const EventTimeConstraint = {
    InProgress: 'IN_PROGRESS',
    Past: 'PAST',
    Upcoming: 'UPCOMING'
} as const;

export type EventTimeConstraint = (typeof EventTimeConstraint)[keyof typeof EventTimeConstraint];
export type ExportAllMembersResult = {
    __typename?: 'ExportAllMembersResult';
    base64EncodedData: Scalars['String'];
};

export type FailedUserRenewals = {
    __typename?: 'FailedUserRenewals';
    reason?: Maybe<Scalars['String']>;
    userId: Scalars['ID'];
};

export const FieldOfActivity = {
    ClinicDoctor: 'CLINIC_DOCTOR',
    Other: 'OTHER',
    SettledDown: 'SETTLED_DOWN'
} as const;

export type FieldOfActivity = (typeof FieldOfActivity)[keyof typeof FieldOfActivity];
export type File = {
    __typename?: 'File';
    downloadUrl?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    mimetype?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    permissions: Array<Maybe<Permission>>;
    publicThumbnail?: Maybe<Scalars['Boolean']>;
    shortUrl?: Maybe<Scalars['String']>;
    size?: Maybe<Scalars['Int']>;
    thumbnailUrl?: Maybe<Scalars['String']>;
    uploadUrl?: Maybe<Scalars['String']>;
    url?: Maybe<Scalars['String']>;
};

export type FinalizeParticipationResult = {
    __typename?: 'FinalizeParticipationResult';
    failureReason?: Maybe<Scalars['String']>;
    participantId?: Maybe<Scalars['ID']>;
    succeeded: Scalars['Boolean'];
};

export type FixRefundableCreditsRequest = {
    alreadyRefundedPoints: Scalars['Int'];
    bookingId: Scalars['ID'];
    currentRefundingPoints: Scalars['Int'];
    userId: Scalars['ID'];
};

export type FixRefundableCreditsResult = {
    __typename?: 'FixRefundableCreditsResult';
    fixed?: Maybe<Scalars['Boolean']>;
};

export const Gender = {
    Female: 'FEMALE',
    Male: 'MALE'
} as const;

export type Gender = (typeof Gender)[keyof typeof Gender];
export type GenerateResetPasswordLinkResult = {
    __typename?: 'GenerateResetPasswordLinkResult';
    link?: Maybe<Scalars['String']>;
};

export type GenericRegistrationRequest = {
    academicDegreeCode?: InputMaybe<Scalars['String']>;
    email: Scalars['String'];
    firstName: Scalars['String'];
    gender?: InputMaybe<Gender>;
    lastName: Scalars['String'];
    mobile?: InputMaybe<Scalars['String']>;
    password: Scalars['String'];
    privacyPolicyConfirmation?: InputMaybe<Scalars['Boolean']>;
};

export type GroupMemberRegistrationRequest = {
    dataSharingConsent: Scalars['Boolean'];
    onboardingToken: Scalars['ID'];
    password: Scalars['String'];
    privacyPolicyConfirmation: Scalars['Boolean'];
    userId: Scalars['ID'];
};

export type GroupMembership = {
    __typename?: 'GroupMembership';
    companyId: Scalars['ID'];
    endDateTime: Scalars['DateTime'];
    fullCredit?: Maybe<Scalars['Boolean']>;
    id: Scalars['ID'];
    startDateTime: Scalars['DateTime'];
    userMemberships: Array<UserMembership>;
};

export type GroupMembershipInfo = {
    __typename?: 'GroupMembershipInfo';
    companyId: Scalars['ID'];
    endDateTime: Scalars['DateTime'];
    id: Scalars['ID'];
    startDateTime: Scalars['DateTime'];
};

export type I18n = {
    __typename?: 'I18n';
    translations?: Maybe<Array<Translation>>;
};

export type ImportRegistrationRequest = {
    companyName: Scalars['String'];
    country?: InputMaybe<Scalars['String']>;
    departmentId?: InputMaybe<Scalars['String']>;
    email: Scalars['String'];
    firstName?: InputMaybe<Scalars['String']>;
    gender?: InputMaybe<Gender>;
    lastName?: InputMaybe<Scalars['String']>;
    occupationType?: InputMaybe<OccupationType>;
    onboardingToken?: InputMaybe<Scalars['ID']>;
    registrationLocale?: InputMaybe<Scalars['Locale']>;
};

export type KeyValue = {
    key: Scalars['String'];
    value?: InputMaybe<Scalars['String']>;
};

export type LabelValueR = {
    __typename?: 'LabelValueR';
    label: I18n;
    value: Scalars['String'];
};

export type LegalPaymentReminder = {
    __typename?: 'LegalPaymentReminder';
    createdAt?: Maybe<Scalars['DateTime']>;
    id: Scalars['ID'];
    shortUrl: Scalars['String'];
};

export type ManualPaymentRequest = {
    amount: Scalars['Int'];
    bookingId: Scalars['ID'];
    paymentDate: Scalars['DateTime'];
    paymentMethod: PaymentMethod;
    reference?: InputMaybe<Scalars['String']>;
};

export type ManualPaymentResult = {
    __typename?: 'ManualPaymentResult';
    amount: Scalars['Int'];
    id: Scalars['ID'];
    paymentDate?: Maybe<Scalars['DateTime']>;
    paymentMethod?: Maybe<PaymentMethod>;
    paymentReason?: Maybe<PaymentReason>;
    paymentStatus?: Maybe<PaymentStatus>;
    reference?: Maybe<Scalars['String']>;
    userId: Scalars['ID'];
};

export type ManualTransitionResult = {
    __typename?: 'ManualTransitionResult';
    transitioned: Scalars['Boolean'];
};

export const MediaType = {
    Link: 'LINK',
    Pdf: 'PDF',
    Video: 'VIDEO'
} as const;

export type MediaType = (typeof MediaType)[keyof typeof MediaType];
export type Membership = {
    __typename?: 'Membership';
    country: Scalars['String'];
    currency: Currency;
    cycle: MembershipCycle;
    feeItem?: Maybe<MembershipItem>;
    id: Scalars['ID'];
    membershipItems: Array<MembershipItem>;
    membershipType: MembershipType;
    name?: Maybe<Array<TranslatedText>>;
    occupationType?: Maybe<OccupationType>;
    orderPriority: Scalars['Int'];
    price: Scalars['Int'];
    recommended: Scalars['Boolean'];
    subtitle?: Maybe<Array<TranslatedText>>;
    validMarketingOptions: Array<LabelValueR>;
};

export const MembershipCycle = {
    OneMonth: 'ONE_MONTH',
    OneYear: 'ONE_YEAR',
    SixMonths: 'SIX_MONTHS',
    ThreeMonths: 'THREE_MONTHS',
    ThreeYears: 'THREE_YEARS',
    TwoMonths: 'TWO_MONTHS'
} as const;

export type MembershipCycle = (typeof MembershipCycle)[keyof typeof MembershipCycle];
export type MembershipItem = {
    __typename?: 'MembershipItem';
    amount?: Maybe<Scalars['Int']>;
    amountUnit?: Maybe<AmountUnit>;
    country: Scalars['String'];
    featureType?: Maybe<MembershipItemFeatureType>;
    id: Scalars['ID'];
    membershipItemType: MembershipItemType;
    name: Array<TranslatedText>;
    price?: Maybe<Scalars['Int']>;
};

export const MembershipItemFeatureType = {
    Accreditation: 'ACCREDITATION',
    Cme: 'CME',
    CourseParticipation: 'COURSE_PARTICIPATION',
    LivestreamCourseParticipation: 'LIVESTREAM_COURSE_PARTICIPATION',
    MediaLibrary: 'MEDIA_LIBRARY',
    Webinars: 'WEBINARS'
} as const;

export type MembershipItemFeatureType =
    (typeof MembershipItemFeatureType)[keyof typeof MembershipItemFeatureType];
export const MembershipItemType = {
    LegalPaymentReminderFee: 'LEGAL_PAYMENT_REMINDER_FEE',
    Main: 'MAIN',
    TopUp: 'TOP_UP'
} as const;

export type MembershipItemType = (typeof MembershipItemType)[keyof typeof MembershipItemType];
export type MembershipOption = {
    membershipId?: InputMaybe<Scalars['ID']>;
    userId?: InputMaybe<Scalars['ID']>;
};

export type MembershipOrderRequest = {
    billingAddressId: Scalars['ID'];
    membershipId: Scalars['ID'];
    membershipMarketingSourceOptionCodes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    orderLocale?: InputMaybe<Scalars['String']>;
    paymentMethod?: InputMaybe<PaymentMethod>;
    promoCode?: InputMaybe<Scalars['String']>;
    topUpIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type MembershipOrderResult = {
    __typename?: 'MembershipOrderResult';
    clientReference?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
};

export type MembershipPromoCode = {
    __typename?: 'MembershipPromoCode';
    code: Scalars['String'];
    id: Scalars['ID'];
    name: Scalars['String'];
    promoAmount: Scalars['Int'];
    promoCodeType: PromoCodeType;
    validFrom: Scalars['DateTime'];
    validUntil: Scalars['DateTime'];
};

export type MembershipPromoCodeRequest = {
    code: Scalars['String'];
    membershipIds: Array<Scalars['ID']>;
    name: Scalars['String'];
    promoAmount: Scalars['Int'];
    promoCodeId?: InputMaybe<Scalars['ID']>;
    promoCodeType: PromoCodeType;
    validFrom: Scalars['DateTime'];
    validUntil: Scalars['DateTime'];
};

export type MembershipPromoCodeResult = {
    __typename?: 'MembershipPromoCodeResult';
    success: Scalars['Boolean'];
};

export type MembershipSettings = {
    __typename?: 'MembershipSettings';
    availableForMembers: Scalars['Boolean'];
};

export const MembershipTransactionReason = {
    AddedToGroupMembership: 'ADDED_TO_GROUP_MEMBERSHIP',
    AddedToGroupMembershipAndTransferredCredits:
        'ADDED_TO_GROUP_MEMBERSHIP_AND_TRANSFERRED_CREDITS',
    BookingCanceled: 'BOOKING_CANCELED',
    BookingCreated: 'BOOKING_CREATED',
    BookingItemCanceled: 'BOOKING_ITEM_CANCELED',
    BookingUpdated: 'BOOKING_UPDATED',
    CanceledTopUp: 'CANCELED_TOP_UP',
    Discrepancy: 'DISCREPANCY',
    FixRefundableCreditsFromPreviousMembership: 'FIX_REFUNDABLE_CREDITS_FROM_PREVIOUS_MEMBERSHIP',
    OnDemandModuleCompleted: 'ON_DEMAND_MODULE_COMPLETED',
    PurchasedMembership: 'PURCHASED_MEMBERSHIP',
    PurchasedTopUp: 'PURCHASED_TOP_UP',
    RefundCredits: 'REFUND_CREDITS',
    RenewedMembership: 'RENEWED_MEMBERSHIP'
} as const;

export type MembershipTransactionReason =
    (typeof MembershipTransactionReason)[keyof typeof MembershipTransactionReason];
export const MembershipType = {
    Hybrid: 'HYBRID',
    Online: 'ONLINE'
} as const;

export type MembershipType = (typeof MembershipType)[keyof typeof MembershipType];
export type Module = {
    __typename?: 'Module';
    certificate?: Maybe<ModuleReport>;
    credits: Array<ContentCredit>;
    functionId?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    order?: Maybe<Scalars['Int']>;
    submodules?: Maybe<Array<Submodule>>;
    title?: Maybe<Scalars['String']>;
};

export type ModuleCompletedInput = {
    contentCountry: Scalars['String'];
    contentId: Scalars['ID'];
    contentTitle: Scalars['String'];
    moduleId: Scalars['String'];
    moduleTitle: Scalars['String'];
    userFirstName: Scalars['String'];
    userId: Scalars['ID'];
    userLastName: Scalars['String'];
    userMedicalIdentificationNumber?: InputMaybe<Scalars['String']>;
};

export type ModuleCompletedResult = {
    __typename?: 'ModuleCompletedResult';
    deductedPoints: Array<SlotCredit>;
};

export type ModuleReport = {
    __typename?: 'ModuleReport';
    file?: Maybe<File>;
    moduleId: Scalars['ID'];
    reportDate?: Maybe<Scalars['DateTime']>;
    reportStatus: ReportStatus;
    requiredCreditAmount?: Maybe<Scalars['Int']>;
    userId: Scalars['ID'];
};

export type Mutation = {
    __typename?: 'Mutation';
    activateUser?: Maybe<ActivateUserResult>;
    activateUserByAdmin?: Maybe<ActivateUserResult>;
    /** @deprecated Field no longer supported */
    addItemToBookingForAdmin: UpdateBookingResult;
    addToMailchimpAudience?: Maybe<AddToMailchimpAudienceResult>;
    addUserToGroupMembership: MembershipOrderResult;
    answerQuestion: Scalars['Boolean'];
    answerQuestions: Scalars['Boolean'];
    /** @deprecated Field no longer supported */
    attachStripePaymentMethodToUser?: Maybe<AttachPaymentMethodToUserResult>;
    bookmarkContent: Scalars['Boolean'];
    cancelBooking: CancelBookingResult;
    cancelBookingItem: CancelBookingResult;
    cancelPayment?: Maybe<ManualPaymentResult>;
    capturePayPalPayment?: Maybe<CapturePayPalPaymentResult>;
    changePassword: ChangePasswordResult;
    chargeByStripePaymentMethod?: Maybe<StripeChargePaymentMethodResult>;
    completeGroupMemberRegistration: RegistrationResponse;
    completeUserGroupMembershipOnboarding?: Maybe<MembershipOrderResult>;
    computeAccreditation: ModuleCompletedResult;
    createAccreditationSlot: AccreditationSlot;
    createAdditionalContent: CreatedResult;
    createAnalyticsEvent: AnalyticsEventResult;
    createAttachment: Attachment;
    createBenefit: Benefit;
    createBookingForAdmin: OrderEventResult;
    createCompanyPage: CompanyPage;
    createContent: Content;
    createDepartment: Department;
    createFile?: Maybe<File>;
    createGroupMembership: GroupMembership;
    createInvoiceByAdmin?: Maybe<BookingInvoiceFile>;
    createNewOnboardingToken?: Maybe<CreateNewOnboardingToken>;
    createOrUpdateMembershipPromoCode: MembershipPromoCodeResult;
    createParticipantType: ParticipantTypeR;
    createPartnership: Partnership;
    createPayment?: Maybe<ManualPaymentResult>;
    createPriceList?: Maybe<PriceListR>;
    createProduct: Product;
    createPromoCode?: Maybe<PromoCodeR>;
    createReceiptByAdmin?: Maybe<BookingReceiptFile>;
    createReminderConfig: ReminderConfig;
    createReminderTransaction: ReminderTransaction;
    createSetupIntent?: Maybe<CreateSetupIntentResult>;
    createSponsorship: Sponsorship;
    createSponsorshipUserConsent: Scalars['Boolean'];
    createSponsorshipV2: Sponsorship;
    createSymposium: ContentSponsorship;
    createTargetGroup: TargetGroup;
    createUserPaymentMethod: UserPaymentMethodR;
    deductCreditPoints?: Maybe<DeductedCreditsResult>;
    deleteAttachment: DeletedResult;
    deleteBenefit: DeletedResult;
    deleteCompanyPage: DeletedResult;
    deleteContent: DeletedResult;
    deleteContentV2: DeletedResult;
    deleteFile: DeletedResult;
    deleteModule: DeletedResult;
    deleteParticipantType: DeleteParticipantTypeResult;
    deletePartnership: DeletedResult;
    deletePriceList: DeletePriceListResult;
    deleteProduct: DeletedResult;
    deletePromoCode: DeletePromoCodeResult;
    deleteReminderConfig: DeleteEventReminderResponse;
    deleteSponsorship: DeletedResult;
    deleteSponsorshipUserConsent: DeletedResult;
    deleteSponsorshipV2: DeletedResult;
    deleteSymposium: DeletedResult;
    deleteTargetGroup: DeletedResult;
    deleteUser: DeleteUserResult;
    deleteUserPaymentMethod: DeleteUserPaymentMethodResult;
    earlyRenewGroupMembership: EarlyRenewGroupMembershipResult;
    earlyRenewMembership: EarlyRenewMembershipResult;
    finalizeParticipation: Array<FinalizeParticipationResult>;
    fixRefundableCredits?: Maybe<FixRefundableCreditsResult>;
    generateCourseSlug?: Maybe<Scalars['String']>;
    generateResetPasswordLink?: Maybe<GenerateResetPasswordLinkResult>;
    importCustomerEvent: EventR;
    mergeUsers: Scalars['Boolean'];
    mergeUsersBookings: Scalars['Boolean'];
    orderEvent?: Maybe<OrderEventResult>;
    orderFreeMembership: MembershipOrderResult;
    orderMembership: MembershipOrderResult;
    payNotPaidMembershipOrder: MembershipOrderResult;
    reCalculateUsersCreditPoints?: Maybe<RecalculateUserCreditPointsResult>;
    recalculateGrantedCMEPoints: Array<EventParticipantsSummaryR>;
    recalculateGrantedPoints: Array<EventParticipantsSummaryR>;
    register: RegistrationResponse;
    registerByAdmin: RegistrationResponse;
    registerGenericUser: RegistrationResponse;
    registerUser: UserR;
    resendActivationLink?: Maybe<ResendActivationLinkResult>;
    resetPassword?: Maybe<ResetPasswordResult>;
    retrievePayment?: Maybe<RetrievePaymentResult>;
    revokeMembership: RevokeMembershipResult;
    revokeTopUp: RevokeTopUpResult;
    sendCertificateEmail?: Maybe<Scalars['Boolean']>;
    sendConfirmationEmailByAdmin?: Maybe<Scalars['Boolean']>;
    sendNotPaidLegalPaymentReminder: UserMembership;
    sendPaymentReceiptEmailByAdmin?: Maybe<Scalars['Boolean']>;
    sendResetPasswordLink?: Maybe<ResetPasswordLinkResult>;
    sendUbCreatedOrUpdatedInvoiceEmailByAdmin?: Maybe<BookingInvoiceFile>;
    setDefaultUserPaymentMethod: DefaultUserPaymentMethodResult;
    setWatchedVideoStatus: Scalars['Boolean'];
    startPayment: StartPaymentResult;
    storeUserForFutureOnboarding: StoreUserForFutureOnboardingResult;
    syncBookingsForEventSummary: SyncBookingsForEventSummaryResult;
    syncContent: SyncResult;
    syncContentUserBooking: Scalars['Boolean'];
    syncStripePaymentStatus?: Maybe<Scalars['Boolean']>;
    trackContentProgress: Scalars['Boolean'];
    transitionMemberManually: ManualTransitionResult;
    transitionTrialMembershipManually: ManualTransitionResult;
    updateAccreditationSlot: AccreditationSlot;
    updateAdditionalContent: UpdatedResult;
    updateAttachment: Attachment;
    updateBenefit: Benefit;
    updateBookedFromLocale?: Maybe<Scalars['Boolean']>;
    updateBooking: UpdateBookingResult;
    updateCompany: Company;
    updateCompanyPage: CompanyPage;
    updateContent: Content;
    updateDepartment: Department;
    updateEvent: EventR;
    updateFilePermissions?: Maybe<File>;
    updateFileThumbnail?: Maybe<File>;
    updateGroupMembershipAutomaticRenewal: GroupMembership;
    updateParticipantType: ParticipantTypeR;
    updatePartnership: Partnership;
    updatePaymentStatus: UpdatePaymentResult;
    updatePriceList?: Maybe<PriceListR>;
    updateProduct: Product;
    updatePromoCode?: Maybe<PromoCodeR>;
    updateReminderConfig: ReminderConfig;
    updateReminderTransaction: ReminderTransaction;
    updateSponsorship: Sponsorship;
    updateSponsorshipV2: Sponsorship;
    updateTargetGroup: TargetGroup;
    /** @deprecated Field no longer supported */
    updateUserDetailsForAdmin: UserR;
    updateUserDetailsForUser: UserR;
    updateUserMembershipRenewalStatus: UserMembership;
    updateUserMembershipRenewalType: UserMembership;
    upgradeToHybrid: MembershipOrderResult;
    uploadFile?: Maybe<File>;
};

export type MutationActivateUserArgs = {
    activationToken: Scalars['ID'];
};

export type MutationActivateUserByAdminArgs = {
    tenantId: Scalars['ID'];
    userId: Scalars['ID'];
};

export type MutationAddItemToBookingForAdminArgs = {
    bookingId: Scalars['ID'];
    bookingRequest: AdminBookingRequest;
};

export type MutationAddToMailchimpAudienceArgs = {
    email: Scalars['String'];
    locale: Scalars['Locale'];
    mobile?: InputMaybe<Scalars['String']>;
    tags: Array<Scalars['String']>;
};

export type MutationAddUserToGroupMembershipArgs = {
    companyId: Scalars['ID'];
    country: Scalars['String'];
    earlyRenewUserGroupMembership?: InputMaybe<Scalars['Boolean']>;
    membershipRenewalType?: InputMaybe<MembershipType>;
    membershipType: MembershipType;
    occupationType: OccupationType;
    orderLocale: Scalars['String'];
    renewUserGroupMembership?: InputMaybe<Scalars['Boolean']>;
    tenantId: Scalars['ID'];
    userId: Scalars['ID'];
};

export type MutationAnswerQuestionArgs = {
    userAnswer: UserAnswer;
};

export type MutationAnswerQuestionsArgs = {
    userAnswers: Array<UserAnswer>;
};

export type MutationAttachStripePaymentMethodToUserArgs = {
    country: Scalars['String'];
    paymentMethodId?: InputMaybe<Scalars['String']>;
    tenantId: Scalars['ID'];
    userId: Scalars['ID'];
};

export type MutationBookmarkContentArgs = {
    bookmarked: Scalars['Boolean'];
    contentId: Scalars['String'];
};

export type MutationCancelBookingArgs = {
    bookingId: Scalars['ID'];
    ignoreCancelDate?: InputMaybe<Scalars['Boolean']>;
    tenantId: Scalars['ID'];
};

export type MutationCancelBookingItemArgs = {
    bookingId: Scalars['ID'];
    bookingItemId: Scalars['ID'];
};

export type MutationCancelPaymentArgs = {
    paymentId: Scalars['ID'];
};

export type MutationCapturePayPalPaymentArgs = {
    clientReference: Scalars['String'];
    tenantId: Scalars['ID'];
};

export type MutationChangePasswordArgs = {
    changePasswordRequest: ChangePasswordRequest;
    tenantId: Scalars['ID'];
    userId: Scalars['ID'];
};

export type MutationChargeByStripePaymentMethodArgs = {
    chargePaymentMethodRequest?: InputMaybe<StripeChargePaymentMethodRequest>;
    tenantId: Scalars['ID'];
    userId: Scalars['ID'];
};

export type MutationCompleteGroupMemberRegistrationArgs = {
    groupMemberRegistrationRequest: GroupMemberRegistrationRequest;
};

export type MutationCompleteUserGroupMembershipOnboardingArgs = {
    companyId: Scalars['ID'];
    userId: Scalars['ID'];
};

export type MutationComputeAccreditationArgs = {
    data: ModuleCompletedInput;
};

export type MutationCreateAccreditationSlotArgs = {
    data: AccreditationSlotInput;
};

export type MutationCreateAdditionalContentArgs = {
    data: AdditionalContentInput;
};

export type MutationCreateAnalyticsEventArgs = {
    analyticsEventRequest: AnalyticsEventRequest;
    tenantId: Scalars['String'];
    userId: Scalars['String'];
};

export type MutationCreateAttachmentArgs = {
    data: AttachmentInput;
};

export type MutationCreateBenefitArgs = {
    data: BenefitInput;
};

export type MutationCreateBookingForAdminArgs = {
    bookingRequest: AdminBookingRequest;
    userId: Scalars['ID'];
};

export type MutationCreateCompanyPageArgs = {
    data: CompanyPageInput;
};

export type MutationCreateContentArgs = {
    data: ContentInput;
};

export type MutationCreateDepartmentArgs = {
    data: DepartmentInput;
};

export type MutationCreateFileArgs = {
    directories?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fileName?: InputMaybe<Scalars['String']>;
    permissions: Array<InputMaybe<PermissionInput>>;
    publicThumbnail?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCreateGroupMembershipArgs = {
    companyId: Scalars['ID'];
    fullCredit?: InputMaybe<Scalars['Boolean']>;
    shouldAutomaticallyRenew?: InputMaybe<Scalars['Boolean']>;
    startDateTime: Scalars['DateTime'];
};

export type MutationCreateInvoiceByAdminArgs = {
    bookingId: Scalars['ID'];
    bookingOperation: BookingOperation;
    referenceInvoiceNumber?: InputMaybe<Scalars['Int']>;
};

export type MutationCreateNewOnboardingTokenArgs = {
    onboardingToken: Scalars['String'];
    userId: Scalars['ID'];
};

export type MutationCreateOrUpdateMembershipPromoCodeArgs = {
    membershipPromoCodeRequest: MembershipPromoCodeRequest;
    tenantId: Scalars['ID'];
};

export type MutationCreateParticipantTypeArgs = {
    participantTypeRequest: ParticipantTypeRequest;
    tenantId: Scalars['ID'];
};

export type MutationCreatePartnershipArgs = {
    data: PartnershipInput;
};

export type MutationCreatePaymentArgs = {
    paymentRequest?: InputMaybe<ManualPaymentRequest>;
    sendReceipt?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCreatePriceListArgs = {
    priceListRequest: PriceListRequest;
    tenantId: Scalars['ID'];
};

export type MutationCreateProductArgs = {
    data: ProductInput;
};

export type MutationCreatePromoCodeArgs = {
    promoCodeRequest: PromoCodeRequest;
    tenantId: Scalars['ID'];
};

export type MutationCreateReceiptByAdminArgs = {
    paymentId: Scalars['ID'];
};

export type MutationCreateReminderConfigArgs = {
    createReminderConfigInput: CreateReminderConfigInput;
    userId: Scalars['ID'];
};

export type MutationCreateReminderTransactionArgs = {
    createReminderTransactionInput: CreateReminderTransactionInput;
};

export type MutationCreateSetupIntentArgs = {
    country?: InputMaybe<Scalars['String']>;
    metadata?: InputMaybe<Array<InputMaybe<KeyValue>>>;
    tenantId?: InputMaybe<Scalars['ID']>;
    userId?: InputMaybe<Scalars['ID']>;
};

export type MutationCreateSponsorshipArgs = {
    data: SponsorshipInput;
};

export type MutationCreateSponsorshipUserConsentArgs = {
    sponsorshipId: Scalars['ID'];
};

export type MutationCreateSponsorshipV2Args = {
    data: SponsorshipInput;
};

export type MutationCreateSymposiumArgs = {
    data: ContentSponsorshipInput;
};

export type MutationCreateTargetGroupArgs = {
    data: TargetGroupInput;
};

export type MutationCreateUserPaymentMethodArgs = {
    tenantId: Scalars['ID'];
    userId: Scalars['ID'];
    userPaymentMethodRequest: UserPaymentMethodRequest;
};

export type MutationDeductCreditPointsArgs = {
    deductedCreditsRequest: DeductedCreditsRequest;
    userId: Scalars['ID'];
};

export type MutationDeleteAttachmentArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteBenefitArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteCompanyPageArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteContentArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteContentV2Args = {
    id: Scalars['ID'];
    includeMedia?: InputMaybe<Scalars['Boolean']>;
};

export type MutationDeleteFileArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteModuleArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteParticipantTypeArgs = {
    participantTypeId: Scalars['ID'];
    tenantId: Scalars['ID'];
};

export type MutationDeletePartnershipArgs = {
    id: Scalars['ID'];
};

export type MutationDeletePriceListArgs = {
    priceListId: Scalars['ID'];
    tenantId: Scalars['ID'];
};

export type MutationDeleteProductArgs = {
    id: Scalars['ID'];
};

export type MutationDeletePromoCodeArgs = {
    promoCodeId: Scalars['ID'];
    tenantId: Scalars['ID'];
};

export type MutationDeleteReminderConfigArgs = {
    reminderConfigId: Scalars['ID'];
    userId: Scalars['ID'];
};

export type MutationDeleteSponsorshipArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteSponsorshipUserConsentArgs = {
    sponsorshipId: Scalars['ID'];
};

export type MutationDeleteSponsorshipV2Args = {
    id: Scalars['ID'];
};

export type MutationDeleteSymposiumArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteTargetGroupArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteUserArgs = {
    tenantId: Scalars['ID'];
    userId: Scalars['ID'];
};

export type MutationDeleteUserPaymentMethodArgs = {
    paymentMethodReference: Scalars['String'];
    tenantId: Scalars['ID'];
    userId: Scalars['ID'];
};

export type MutationEarlyRenewGroupMembershipArgs = {
    companyId: Scalars['ID'];
};

export type MutationEarlyRenewMembershipArgs = {
    userId: Scalars['ID'];
};

export type MutationFinalizeParticipationArgs = {
    eventId: Scalars['ID'];
    participantIds: Array<Scalars['ID']>;
    price: Scalars['Int'];
};

export type MutationFixRefundableCreditsArgs = {
    fixRefundableCreditsRequest: FixRefundableCreditsRequest;
};

export type MutationGenerateCourseSlugArgs = {
    id: Scalars['ID'];
};

export type MutationGenerateResetPasswordLinkArgs = {
    country?: InputMaybe<Scalars['String']>;
    loginChallenge?: InputMaybe<Scalars['String']>;
    tenantId: Scalars['ID'];
    userEmail: Scalars['String'];
};

export type MutationImportCustomerEventArgs = {
    customerEventRequest: CustomerEventRequest;
    tenantId: Scalars['ID'];
};

export type MutationMergeUsersArgs = {
    primaryUserId: Scalars['ID'];
    secondaryUserId: Scalars['ID'];
};

export type MutationMergeUsersBookingsArgs = {
    primaryUserId: Scalars['ID'];
    secondaryUserId: Scalars['ID'];
};

export type MutationOrderEventArgs = {
    bookingRequest?: InputMaybe<BookingRequest>;
    tenantId: Scalars['ID'];
    userId: Scalars['ID'];
};

export type MutationOrderFreeMembershipArgs = {
    membershipId: Scalars['ID'];
    orderLocale: Scalars['String'];
    tenantId: Scalars['ID'];
    userId: Scalars['ID'];
};

export type MutationOrderMembershipArgs = {
    checkoutSessionId?: InputMaybe<Scalars['String']>;
    membershipOrderRequest: MembershipOrderRequest;
    tenantId: Scalars['ID'];
    userId: Scalars['ID'];
};

export type MutationPayNotPaidMembershipOrderArgs = {
    paymentMethod: PaymentMethod;
    tenantId: Scalars['ID'];
    userMembershipId: Scalars['ID'];
};

export type MutationReCalculateUsersCreditPointsArgs = {
    recalculateUserCreditPointsRequest?: InputMaybe<RecalculateUserCreditPointsRequest>;
};

export type MutationRecalculateGrantedCmePointsArgs = {
    eventId: Scalars['ID'];
    tenantId: Scalars['ID'];
};

export type MutationRecalculateGrantedPointsArgs = {
    eventId: Scalars['ID'];
    tenantId: Scalars['ID'];
};

export type MutationRegisterArgs = {
    registrationRequest?: InputMaybe<RegistrationRequest>;
    tenantId?: InputMaybe<Scalars['ID']>;
};

export type MutationRegisterByAdminArgs = {
    registrationRequestByAdmin?: InputMaybe<RegistrationRequestByAdmin>;
};

export type MutationRegisterGenericUserArgs = {
    genericRegistrationRequest?: InputMaybe<GenericRegistrationRequest>;
    tenantId?: InputMaybe<Scalars['ID']>;
};

export type MutationRegisterUserArgs = {
    importRegistrationRequest: ImportRegistrationRequest;
    tenantId: Scalars['ID'];
};

export type MutationResendActivationLinkArgs = {
    emailLocale?: InputMaybe<Scalars['String']>;
    tenantId: Scalars['ID'];
    userId: Scalars['ID'];
};

export type MutationResetPasswordArgs = {
    password: Scalars['String'];
    resetPasswordAttemptId: Scalars['String'];
};

export type MutationRetrievePaymentArgs = {
    stripePaymentIntentId: Scalars['String'];
    tenantId: Scalars['ID'];
    userId: Scalars['ID'];
};

export type MutationRevokeMembershipArgs = {
    userId: Scalars['ID'];
};

export type MutationRevokeTopUpArgs = {
    topUpOrderItemId: Scalars['ID'];
    userId: Scalars['ID'];
    userMembershipItemId: Scalars['ID'];
};

export type MutationSendCertificateEmailArgs = {
    eventParticipantsSummaryId: Scalars['ID'];
};

export type MutationSendConfirmationEmailByAdminArgs = {
    bookingId: Scalars['ID'];
    bookingOperation: BookingOperation;
    documentId?: InputMaybe<Scalars['ID']>;
};

export type MutationSendNotPaidLegalPaymentReminderArgs = {
    userMembershipId: Scalars['ID'];
};

export type MutationSendPaymentReceiptEmailByAdminArgs = {
    documentId: Scalars['ID'];
};

export type MutationSendResetPasswordLinkArgs = {
    country?: InputMaybe<Scalars['String']>;
    loginChallenge?: InputMaybe<Scalars['String']>;
    tenantId: Scalars['ID'];
    userEmail: Scalars['String'];
};

export type MutationSendUbCreatedOrUpdatedInvoiceEmailByAdminArgs = {
    bookingId: Scalars['ID'];
    bookingOperation: BookingOperation;
};

export type MutationSetDefaultUserPaymentMethodArgs = {
    country: Scalars['String'];
    paymentMethodReference: Scalars['String'];
    tenantId: Scalars['ID'];
    userId: Scalars['ID'];
};

export type MutationSetWatchedVideoStatusArgs = {
    contentMediaId: Scalars['String'];
    deleted: Scalars['Boolean'];
};

export type MutationStartPaymentArgs = {
    paymentRequest?: InputMaybe<PaymentRequest>;
    tenantId: Scalars['ID'];
    userId: Scalars['ID'];
};

export type MutationStoreUserForFutureOnboardingArgs = {
    storeUserForFutureOnboardingRequest: StoreUserForFutureOnboardingRequest;
};

export type MutationSyncBookingsForEventSummaryArgs = {
    eventId: Scalars['ID'];
};

export type MutationSyncContentArgs = {
    contentId?: InputMaybe<Scalars['String']>;
    externalId?: InputMaybe<Scalars['String']>;
};

export type MutationSyncContentUserBookingArgs = {
    ubEventIds: Array<Scalars['String']>;
};

export type MutationSyncStripePaymentStatusArgs = {
    paymentIds: Array<InputMaybe<Scalars['ID']>>;
};

export type MutationTrackContentProgressArgs = {
    completed?: InputMaybe<Scalars['Boolean']>;
    contentId: Scalars['String'];
    time?: InputMaybe<Scalars['Float']>;
};

export type MutationTransitionMemberManuallyArgs = {
    paymentReference?: InputMaybe<Scalars['String']>;
    userId: Scalars['ID'];
    userMembershipId: Scalars['ID'];
};

export type MutationTransitionTrialMembershipManuallyArgs = {
    tenantId: Scalars['ID'];
    userId: Scalars['ID'];
};

export type MutationUpdateAccreditationSlotArgs = {
    data: AccreditationSlotInput;
    id: Scalars['ID'];
};

export type MutationUpdateAdditionalContentArgs = {
    data: AdditionalContentInput;
    id: Scalars['ID'];
};

export type MutationUpdateAttachmentArgs = {
    data: AttachmentInput;
    id: Scalars['ID'];
};

export type MutationUpdateBenefitArgs = {
    data: BenefitInput;
    id: Scalars['ID'];
};

export type MutationUpdateBookedFromLocaleArgs = {
    bookedFromLocale: Scalars['Locale'];
    bookingId: Scalars['ID'];
};

export type MutationUpdateBookingArgs = {
    bookingId: Scalars['ID'];
    bookingRequest: BookingRequest;
    tenantId: Scalars['ID'];
};

export type MutationUpdateCompanyArgs = {
    data: CompanyInput;
    id: Scalars['ID'];
};

export type MutationUpdateCompanyPageArgs = {
    data: CompanyPageInput;
    id: Scalars['ID'];
};

export type MutationUpdateContentArgs = {
    data: ContentInput;
    id: Scalars['ID'];
};

export type MutationUpdateDepartmentArgs = {
    data: DepartmentInput;
    id: Scalars['ID'];
};

export type MutationUpdateEventArgs = {
    eventId: Scalars['ID'];
    eventRequest: EventRequest;
    tenantId: Scalars['ID'];
};

export type MutationUpdateFilePermissionsArgs = {
    id: Scalars['ID'];
    permissions: Array<InputMaybe<PermissionInput>>;
};

export type MutationUpdateFileThumbnailArgs = {
    id: Scalars['ID'];
    publicThumbnail: Scalars['Boolean'];
};

export type MutationUpdateGroupMembershipAutomaticRenewalArgs = {
    groupMembershipId: Scalars['ID'];
    shouldAutomaticallyRenew: Scalars['Boolean'];
};

export type MutationUpdateParticipantTypeArgs = {
    participantTypeId: Scalars['ID'];
    participantTypeRequest: ParticipantTypeRequest;
    tenantId: Scalars['ID'];
};

export type MutationUpdatePartnershipArgs = {
    data: PartnershipInput;
    id: Scalars['ID'];
};

export type MutationUpdatePaymentStatusArgs = {
    paymentId: Scalars['ID'];
    paymentStatus?: InputMaybe<PaymentStatus>;
};

export type MutationUpdatePriceListArgs = {
    priceListId: Scalars['ID'];
    priceListRequest: PriceListRequest;
    tenantId: Scalars['ID'];
};

export type MutationUpdateProductArgs = {
    data: ProductInput;
    id: Scalars['ID'];
};

export type MutationUpdatePromoCodeArgs = {
    promoCodeId: Scalars['ID'];
    promoCodeRequest: PromoCodeRequest;
    tenantId: Scalars['ID'];
};

export type MutationUpdateReminderConfigArgs = {
    reminderConfigId: Scalars['ID'];
    updateReminderConfigInput: UpdateReminderConfigInput;
};

export type MutationUpdateReminderTransactionArgs = {
    updateReminderTransactionInput: UpdateReminderTransactionInput;
};

export type MutationUpdateSponsorshipArgs = {
    data: SponsorshipInput;
    id: Scalars['ID'];
};

export type MutationUpdateSponsorshipV2Args = {
    data: SponsorshipInput;
    id: Scalars['ID'];
};

export type MutationUpdateTargetGroupArgs = {
    data: TargetGroupInput;
    id: Scalars['ID'];
};

export type MutationUpdateUserDetailsForAdminArgs = {
    tenantId: Scalars['ID'];
    updateUserRequest: UpdateUserRequest;
    userId: Scalars['ID'];
};

export type MutationUpdateUserDetailsForUserArgs = {
    tenantId: Scalars['ID'];
    updateUserRequest: UpdateUserRequest;
    userId: Scalars['ID'];
};

export type MutationUpdateUserMembershipRenewalStatusArgs = {
    renewalStatus: UserMembershipRenewalStatus;
    userMembershipId: Scalars['ID'];
};

export type MutationUpdateUserMembershipRenewalTypeArgs = {
    membershipRenewalType: MembershipType;
    userMembershipId: Scalars['ID'];
};

export type MutationUpgradeToHybridArgs = {
    tenantId: Scalars['ID'];
    userId: Scalars['ID'];
};

export type MutationUploadFileArgs = {
    directories?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    file: Scalars['Upload'];
    permissions: Array<InputMaybe<PermissionInput>>;
};

export const OccupationType = {
    AssistantDoctor: 'ASSISTANT_DOCTOR',
    Doctor: 'DOCTOR',
    HealthcareProfessional: 'HEALTHCARE_PROFESSIONAL',
    Other: 'OTHER',
    Student: 'STUDENT'
} as const;

export type OccupationType = (typeof OccupationType)[keyof typeof OccupationType];
export type OrderEventResult = {
    __typename?: 'OrderEventResult';
    automaticallyCharged?: Maybe<Scalars['Boolean']>;
    bookingId?: Maybe<Scalars['ID']>;
    clientReference?: Maybe<Scalars['String']>;
    totalPrice?: Maybe<Scalars['Int']>;
};

export type PageInfo = {
    __typename?: 'PageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    startCursor?: Maybe<Scalars['String']>;
};

export type ParticipantTypePriceR = {
    __typename?: 'ParticipantTypePriceR';
    currentPrice?: Maybe<Scalars['Int']>;
    digitalCoursebookPrice?: Maybe<Scalars['Int']>;
    earlyBirdPrice?: Maybe<Scalars['Int']>;
    latePrice?: Maybe<Scalars['Int']>;
    memberPrice?: Maybe<Scalars['Int']>;
    normalPrice?: Maybe<Scalars['Int']>;
    participantType: ParticipantTypeR;
    printedCoursebookPrice?: Maybe<Scalars['Int']>;
};

export type ParticipantTypeR = {
    __typename?: 'ParticipantTypeR';
    code: Scalars['String'];
    country: LabelValueR;
    id: Scalars['ID'];
    memberIdRequired: Scalars['Boolean'];
    name: Array<TranslatedText>;
    occupationType: OccupationType;
};

export type ParticipantTypeRequest = {
    code: Scalars['String'];
    countryCode: Scalars['String'];
    memberIdRequired: Scalars['Boolean'];
    name: Array<TranslatedInputText>;
    occupationType: OccupationType;
};

export const ParticipationType = {
    Hybrid: 'HYBRID',
    Livestream: 'LIVESTREAM',
    OnDemand: 'ON_DEMAND',
    OnSite: 'ON_SITE'
} as const;

export type ParticipationType = (typeof ParticipationType)[keyof typeof ParticipationType];
export type PartnerCategory = {
    __typename?: 'PartnerCategory';
    id: Scalars['ID'];
    name?: Maybe<Scalars['JSON']>;
    order?: Maybe<Scalars['Int']>;
};

export type PartnerCompany = {
    __typename?: 'PartnerCompany';
    country?: Maybe<Scalars['String']>;
    departments: Array<PartnerDepartment>;
    description?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    logoUrl?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    partnerCategories: Array<PartnerCategory>;
    publishedDomains?: Maybe<Array<Maybe<Scalars['String']>>>;
    slug?: Maybe<Scalars['String']>;
};

export type PartnerCompanySearch = {
    __typename?: 'PartnerCompanySearch';
    data: Array<PartnerCompany>;
    pageNumber?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    totalCount?: Maybe<Scalars['Int']>;
};

export type PartnerDepartment = {
    __typename?: 'PartnerDepartment';
    description?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    logoUrl?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    slug?: Maybe<Scalars['String']>;
    translatedName?: Maybe<Scalars['JSON']>;
};

export const PartnerEventAccessType = {
    LearnPortal: 'LEARN_PORTAL',
    PartnerForum: 'PARTNER_FORUM',
    PartnerForumSearch: 'PARTNER_FORUM_SEARCH'
} as const;

export type PartnerEventAccessType =
    (typeof PartnerEventAccessType)[keyof typeof PartnerEventAccessType];
export type Partnership = {
    __typename?: 'Partnership';
    contentId: Scalars['ID'];
    id: Scalars['ID'];
    partnerCompany?: Maybe<Company>;
    partnerCompanyId?: Maybe<Scalars['ID']>;
    partnershipType?: Maybe<PartnershipType>;
};

export type PartnershipInput = {
    contentId?: InputMaybe<Scalars['ID']>;
    partnerCompanyId?: InputMaybe<Scalars['ID']>;
    partnershipType?: InputMaybe<PartnershipType>;
};

export type PartnershipSearch = {
    __typename?: 'PartnershipSearch';
    data: Array<Partnership>;
    pageNumber?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    totalCount?: Maybe<Scalars['Int']>;
};

export const PartnershipType = {
    CooperationPartner: 'COOPERATION_PARTNER',
    MediaPartner: 'MEDIA_PARTNER',
    Sponsorship: 'SPONSORSHIP'
} as const;

export type PartnershipType = (typeof PartnershipType)[keyof typeof PartnershipType];
export type PassedQuestion = {
    __typename?: 'PassedQuestion';
    passedAt?: Maybe<Scalars['DateTime']>;
    questionId: Scalars['ID'];
    userId: Scalars['ID'];
};

export type Payment = {
    __typename?: 'Payment';
    currency?: Maybe<Currency>;
    id: Scalars['ID'];
    paymentReason?: Maybe<PaymentReason>;
    paymentReference?: Maybe<Scalars['String']>;
    paymentStatus?: Maybe<PaymentStatus>;
    price?: Maybe<Scalars['Int']>;
    tenantId?: Maybe<Scalars['ID']>;
    userId?: Maybe<Scalars['ID']>;
};

export const PaymentMethod = {
    AdminOffer: 'ADMIN_OFFER',
    CreditCard: 'CREDIT_CARD',
    GroupMembership: 'GROUP_MEMBERSHIP',
    PayPal: 'PAY_PAL',
    Sepa: 'SEPA',
    StripePaypal: 'STRIPE_PAYPAL',
    StripeTwint: 'STRIPE_TWINT',
    WireTransfer: 'WIRE_TRANSFER'
} as const;

export type PaymentMethod = (typeof PaymentMethod)[keyof typeof PaymentMethod];
export const PaymentReason = {
    AddedToGroupMembership: 'ADDED_TO_GROUP_MEMBERSHIP',
    Booking: 'BOOKING',
    BookingAndMembership: 'BOOKING_AND_MEMBERSHIP',
    ManualMembershipRenewal: 'MANUAL_MEMBERSHIP_RENEWAL',
    Membership: 'MEMBERSHIP',
    PayNotPaidMembership: 'PAY_NOT_PAID_MEMBERSHIP',
    Refund: 'REFUND',
    RenewedGroupMembership: 'RENEWED_GROUP_MEMBERSHIP',
    RenewedMembership: 'RENEWED_MEMBERSHIP',
    TopUp: 'TOP_UP'
} as const;

export type PaymentReason = (typeof PaymentReason)[keyof typeof PaymentReason];
export type PaymentRequest = {
    country: Scalars['String'];
    currency: Currency;
    orderId?: InputMaybe<Scalars['String']>;
    paymentMethod: PaymentMethod;
    paymentReason: PaymentReason;
    price: Scalars['Int'];
    stripeConnectedAccountId?: InputMaybe<Scalars['String']>;
};

export type PaymentSettings = {
    __typename?: 'PaymentSettings';
    invoiceTemplate?: Maybe<Scalars['String']>;
    stripeConnectedAccountId?: Maybe<Scalars['String']>;
};

export const PaymentStatus = {
    Canceled: 'CANCELED',
    Failed: 'FAILED',
    Incomplete: 'INCOMPLETE',
    Paid: 'PAID'
} as const;

export type PaymentStatus = (typeof PaymentStatus)[keyof typeof PaymentStatus];
export type Permission = {
    __typename?: 'Permission';
    id?: Maybe<Scalars['ID']>;
    permissionType?: Maybe<PermissionType>;
    scope?: Maybe<Scalars['JSON']>;
};

export type PermissionInput = {
    permissionType: PermissionType;
    scope?: InputMaybe<Scalars['JSON']>;
};

export const PermissionType = {
    Authenticated: 'AUTHENTICATED',
    CompanyMember: 'COMPANY_MEMBER',
    Custom: 'CUSTOM',
    Public: 'PUBLIC'
} as const;

export type PermissionType = (typeof PermissionType)[keyof typeof PermissionType];
export type PriceListItemR = {
    __typename?: 'PriceListItemR';
    discountType: DiscountType;
    earlyBirdPrice?: Maybe<Scalars['Int']>;
    id?: Maybe<Scalars['ID']>;
    isPrivate?: Maybe<Scalars['Boolean']>;
    latePrice?: Maybe<Scalars['Int']>;
    memberPrice?: Maybe<Scalars['Int']>;
    normalPrice: Scalars['Int'];
    participantType: ParticipantTypeR;
    participationType: ParticipationType;
    ticketType: TicketType;
    workshopIncluded?: Maybe<Scalars['Boolean']>;
};

export type PriceListItemRequest = {
    discountType: DiscountType;
    earlyBirdPrice?: InputMaybe<Scalars['Int']>;
    latePrice?: InputMaybe<Scalars['Int']>;
    memberPrice?: InputMaybe<Scalars['Int']>;
    normalPrice: Scalars['Int'];
    participantTypeId: Scalars['ID'];
    participationType: ParticipationType;
    ticketType: TicketType;
    workshopIncluded?: InputMaybe<Scalars['Boolean']>;
};

export type PriceListR = {
    __typename?: 'PriceListR';
    country: LabelValueR;
    currency: Currency;
    earlyBirdExpirationWeeks: Scalars['Int'];
    id: Scalars['ID'];
    isDefault?: Maybe<Scalars['Boolean']>;
    items: Array<PriceListItemR>;
    name: Scalars['String'];
    normalPriceExpirationDays: Scalars['Int'];
    printedCourseBookPrice?: Maybe<Scalars['Int']>;
    productCategories?: Maybe<Array<Maybe<ProductCategoryR>>>;
    /** @deprecated Field no longer supported */
    productCategory?: Maybe<ProductCategoryR>;
    taxPercentage?: Maybe<Scalars['Int']>;
    taxType?: Maybe<TaxType>;
    validFrom: Scalars['DateTime'];
    validUntil: Scalars['DateTime'];
};

export type PriceListRequest = {
    countryCode: Scalars['String'];
    currency: Currency;
    earlyBirdExpirationWeeks: Scalars['Int'];
    isDefault: Scalars['Boolean'];
    items: Array<PriceListItemRequest>;
    name: Scalars['String'];
    normalPriceExpirationDays: Scalars['Int'];
    printedCourseBookPrice?: InputMaybe<Scalars['Int']>;
    privateParticipantTypes?: InputMaybe<Array<Scalars['ID']>>;
    productCategoriesCodes: Array<Scalars['String']>;
    productCategoryCode?: InputMaybe<Scalars['String']>;
    taxPercentage?: InputMaybe<Scalars['Int']>;
    taxType?: InputMaybe<TaxType>;
    validFrom: Scalars['DateTime'];
    validUntil: Scalars['DateTime'];
};

export type PriceListSearchResult = {
    __typename?: 'PriceListSearchResult';
    data: Array<PriceListR>;
    pageNumber?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    totalCount?: Maybe<Scalars['Int']>;
};

export type Product = {
    __typename?: 'Product';
    accreditation?: Maybe<Scalars['JSON']>;
    benefitIds: Array<Scalars['ID']>;
    benefits: Array<Benefit>;
    brand?: Maybe<ProductBrand>;
    code: Scalars['String'];
    contentType?: Maybe<ContentType>;
    contents: Array<Content>;
    country?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['JSON']>;
    descriptionTitle?: Maybe<Scalars['JSON']>;
    iconPrimaryColor: Scalars['String'];
    id: Scalars['ID'];
    isEnabled?: Maybe<Scalars['Boolean']>;
    isExternal?: Maybe<Scalars['Boolean']>;
    isScientificLeaderEnabled?: Maybe<Scalars['Boolean']>;
    liveStreamInfo?: Maybe<Scalars['JSON']>;
    name?: Maybe<Scalars['JSON']>;
    order?: Maybe<Scalars['Int']>;
    seoSettings?: Maybe<SeoSettings>;
    specialityIconUrl?: Maybe<Scalars['String']>;
    targetGroupIds: Array<Scalars['ID']>;
    targetGroups: Array<TargetGroup>;
};

export const ProductBrand = {
    Fortbildungstage: 'FORTBILDUNGSTAGE',
    Guideline: 'GUIDELINE',
    KfsSymposion: 'KFS_SYMPOSION',
    Ondemand: 'ONDEMAND',
    OndemandAccredited: 'ONDEMAND_ACCREDITED',
    Refresher: 'REFRESHER',
    RefresherActuell: 'REFRESHER_ACTUELL',
    SgmoCurriculum: 'SGMO_CURRICULUM',
    Skill: 'SKILL',
    SpecialistCertificate: 'SPECIALIST_CERTIFICATE',
    StudyConferenceUpdate: 'STUDY_CONFERENCE_UPDATE',
    Summedup: 'SUMMEDUP',
    Syfat: 'SYFAT',
    Syrot: 'SYROT',
    UpdateRefresher: 'UPDATE_REFRESHER',
    Wu660: 'WU660',
    Wuex: 'WUEX',
    Wuif: 'WUIF'
} as const;

export type ProductBrand = (typeof ProductBrand)[keyof typeof ProductBrand];
export type ProductCategoryR = {
    __typename?: 'ProductCategoryR';
    code: Scalars['String'];
    description: Array<TranslatedText>;
    id: Scalars['ID'];
};

export type ProductConnection = {
    __typename?: 'ProductConnection';
    data: Array<Product>;
    pageNumber?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    totalCount?: Maybe<Scalars['Int']>;
};

export type ProductDocument = {
    __typename?: 'ProductDocument';
    brand?: Maybe<ProductBrand>;
    code: Scalars['String'];
    country?: Maybe<Scalars['String']>;
    iconPrimaryColor: Scalars['String'];
    id: Scalars['ID'];
    isEnabled?: Maybe<Scalars['Boolean']>;
    name?: Maybe<Scalars['JSON']>;
    order?: Maybe<Scalars['Int']>;
    specialityIconUrl?: Maybe<Scalars['String']>;
    topics: Array<Topic>;
};

export type ProductInput = {
    accreditation?: InputMaybe<Scalars['JSON']>;
    benefitIds?: InputMaybe<Array<Scalars['ID']>>;
    brand?: InputMaybe<ProductBrand>;
    code?: InputMaybe<Scalars['String']>;
    contentType?: InputMaybe<ContentType>;
    country?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['JSON']>;
    descriptionTitle?: InputMaybe<Scalars['JSON']>;
    iconPrimaryColor?: InputMaybe<Scalars['String']>;
    isEnabled?: InputMaybe<Scalars['Boolean']>;
    isExternal?: InputMaybe<Scalars['Boolean']>;
    isScientificLeaderEnabled?: InputMaybe<Scalars['Boolean']>;
    liveStreamInfo?: InputMaybe<Scalars['JSON']>;
    name?: InputMaybe<Scalars['JSON']>;
    order?: InputMaybe<Scalars['Int']>;
    seoSettings?: InputMaybe<SeoSettingsInput>;
    specialityIconUrl?: InputMaybe<Scalars['String']>;
    targetGroupIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type ProductSearch = {
    __typename?: 'ProductSearch';
    data: Array<Product>;
    pageNumber?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    totalCount?: Maybe<Scalars['Int']>;
};

export type ProgramSchedule = {
    __typename?: 'ProgramSchedule';
    content?: Maybe<Content>;
    endDateTime?: Maybe<Scalars['DateTime']>;
    externalId?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    level?: Maybe<Scalars['Int']>;
    /** @deprecated Field no longer supported */
    provider?: Maybe<Company>;
    /** @deprecated Field no longer supported */
    providerDepartment?: Maybe<Department>;
    scheduleType?: Maybe<ProgramScheduleType>;
    speakerIds: Array<Scalars['String']>;
    speakers: Array<Speaker>;
    sponsorships: Array<Sponsorship>;
    startDateTime?: Maybe<Scalars['DateTime']>;
    title?: Maybe<Scalars['String']>;
};

export type ProgramScheduleSearch = {
    __typename?: 'ProgramScheduleSearch';
    data: Array<ProgramSchedule>;
    pageNumber?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    totalCount?: Maybe<Scalars['Int']>;
};

export const ProgramScheduleType = {
    BeginEnd: 'BEGIN_END',
    Block: 'BLOCK',
    Day: 'DAY',
    Lecture: 'LECTURE',
    Organizational: 'ORGANIZATIONAL',
    Registration: 'REGISTRATION',
    SponsoredLecture: 'SPONSORED_LECTURE',
    TopicBlock: 'TOPIC_BLOCK',
    Workshop: 'WORKSHOP'
} as const;

export type ProgramScheduleType = (typeof ProgramScheduleType)[keyof typeof ProgramScheduleType];
export type PromoCodeForMembershipOrder = {
    __typename?: 'PromoCodeForMembershipOrder';
    code: Scalars['String'];
    name: Scalars['String'];
    promoAmount: Scalars['Int'];
    promoCodeType: PromoCodeType;
};

export type PromoCodeForOrderR = {
    __typename?: 'PromoCodeForOrderR';
    code: Scalars['String'];
    name: Scalars['String'];
    promoAmount: Scalars['Int'];
    promoCodeType: PromoCodeType;
};

export type PromoCodeR = {
    __typename?: 'PromoCodeR';
    code: Scalars['String'];
    countries: Array<LabelValueR>;
    id: Scalars['ID'];
    name: Scalars['String'];
    participationTypes: Array<ParticipationType>;
    promoAmount: Scalars['Int'];
    promoCodeType: PromoCodeType;
    ticketTypes: Array<TicketType>;
    usageTypes?: Maybe<Array<Maybe<PromoCodeUsageType>>>;
    validFrom: Scalars['DateTime'];
    validUntil: Scalars['DateTime'];
};

export type PromoCodeRequest = {
    code: Scalars['String'];
    countryCodes: Array<Scalars['String']>;
    name: Scalars['String'];
    participationTypes: Array<ParticipationType>;
    promoAmount: Scalars['Int'];
    promoCodeType: PromoCodeType;
    ticketTypes: Array<TicketType>;
    usageTypes?: InputMaybe<Array<InputMaybe<PromoCodeUsageType>>>;
    validFrom: Scalars['DateTime'];
    validUntil: Scalars['DateTime'];
};

export type PromoCodeSearchResult = {
    __typename?: 'PromoCodeSearchResult';
    data: Array<PromoCodeR>;
    pageNumber?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    totalCount?: Maybe<Scalars['Int']>;
};

export const PromoCodeType = {
    Fixed: 'FIXED',
    Percentage: 'PERCENTAGE'
} as const;

export type PromoCodeType = (typeof PromoCodeType)[keyof typeof PromoCodeType];
export const PromoCodeUsageType = {
    Event: 'EVENT',
    Membership: 'MEMBERSHIP'
} as const;

export type PromoCodeUsageType = (typeof PromoCodeUsageType)[keyof typeof PromoCodeUsageType];
export const PublishStatusType = {
    Private: 'PRIVATE',
    Published: 'PUBLISHED',
    Unpublished: 'UNPUBLISHED'
} as const;

export type PublishStatusType = (typeof PublishStatusType)[keyof typeof PublishStatusType];
export type Query = {
    __typename?: 'Query';
    addOnContents: ContentSearch;
    attachment: Attachment;
    attachments: AttachmentSearch;
    attendanceRecords: Array<AttendanceRecordR>;
    authenticatedUser?: Maybe<UserR>;
    basicFile?: Maybe<BasicFile>;
    benefit: Benefit;
    benefits: BenefitSearch;
    bookings: BookingSearchResult;
    bookmarkedContents: ContentDocumentConnection;
    companies: CompanySearch;
    company: Company;
    companyPaymentSettings?: Maybe<PaymentSettings>;
    content: Content;
    contentDocuments: ContentDocumentConnection;
    contentProgress?: Maybe<ContentProgress>;
    contents: ContentSearch;
    contentsV2: ContentSearch;
    countries?: Maybe<Array<Maybe<LabelValueR>>>;
    course: Course;
    courses: CourseConnection;
    deductedCreditsAmount: Scalars['Int'];
    department?: Maybe<Department>;
    event: EventR;
    eventCities?: Maybe<Array<LabelValueR>>;
    eventCountries?: Maybe<Array<LabelValueR>>;
    eventForBooking?: Maybe<BookingEventR>;
    eventParticipantsSummaries: Array<EventParticipantsSummaryR>;
    eventPriceList?: Maybe<PriceListR>;
    eventPriceLists?: Maybe<Array<PriceListR>>;
    eventProductCategories?: Maybe<Array<ProductCategoryR>>;
    eventPromoCodes?: Maybe<Array<PromoCodeR>>;
    eventSpecialities?: Maybe<Array<LabelValueR>>;
    exportAllMembers: ExportAllMembersResult;
    exportBookingReport: EventBookingContext;
    file?: Maybe<File>;
    fileById?: Maybe<File>;
    findSpecialities: Array<Maybe<SpecialityR>>;
    generateHtmlFromTemplate?: Maybe<Scalars['String']>;
    generateOnsiteNameTagReport?: Maybe<Scalars['String']>;
    generateParticipantReport?: Maybe<Scalars['String']>;
    getActiveOrFutureGroupMembership: GroupMembershipInfo;
    getDueReminders: ReminderConfigPaginatedResponse;
    getUserByEmail?: Maybe<UserR>;
    getUserRemindersForEvent: ReminderConfigPaginatedResponse;
    membership?: Maybe<Membership>;
    memberships?: Maybe<Array<Maybe<Membership>>>;
    participantType: ParticipantTypeR;
    participantTypes: Array<ParticipantTypeR>;
    participantsCourseProgresses?: Maybe<Array<Maybe<CourseProgress>>>;
    partnerCompanies: PartnerCompanySearch;
    partnership: Partnership;
    partnerships: PartnershipSearch;
    payment?: Maybe<Payment>;
    priceList: PriceListR;
    product: Product;
    products: ProductSearch;
    productsByContent: ProductConnection;
    programSchedule: ProgramSchedule;
    programSchedules: ProgramScheduleSearch;
    promoCode: PromoCodeR;
    promoCodeForBooking?: Maybe<PromoCodeForOrderR>;
    promoCodeForMembership?: Maybe<PromoCodeForMembershipOrder>;
    recentContentProgresses?: Maybe<ContentProgressConnection>;
    recentlyStartedCourses?: Maybe<Array<Maybe<CourseProgress>>>;
    recentlyWatchedContents?: Maybe<Array<Maybe<ContentProgress>>>;
    reminders: ReminderConfigPaginatedResponse;
    retrieveSetupIntent: SetupIntentR;
    retrieveStripePaymentMethod: RetrievePaymentMethodResult;
    searchEventParticipantsSummaries: EventParticipantsSummarySearchResult;
    searchEvents: EventSearchResult;
    searchParticipantsSummaries: EventParticipantsSummarySearchResult;
    searchPriceLists: PriceListSearchResult;
    searchPromoCodes: PromoCodeSearchResult;
    searchReminderTransactions: ReminderTransactionPaginatedResponse;
    searchUserMemberships: UserMembershipSearch;
    searchUsers: UserSearchResult;
    speaker: Speaker;
    specialities: Array<SpecialityR>;
    speciality?: Maybe<SpecialityR>;
    sponsorship: Sponsorship;
    sponsorships: SponsorshipSearch;
    sponsorshipsByContent: SponsorshipConnection;
    targetGroup: TargetGroup;
    targetGroups: TargetGroupSearch;
    topicDocuments: TopicDocumentConnection;
    topics: TopicSearch;
    user?: Maybe<UserR>;
    userAcademicDegrees: Array<AcademicDegreeR>;
    userBookingCreditPoints?: Maybe<UserBookingCreditPoints>;
    userGroupMembership?: Maybe<GroupMembership>;
    userMembership?: Maybe<UserMembership>;
    userMemberships?: Maybe<Array<UserMembership>>;
    userPaymentMethods: Array<UserPaymentMethodR>;
    userPaymentStatus: UserPaymentStatusR;
    validateTicketSelection: BookingValidationResult;
    video: Video;
    videos: VideoConnection;
    watchedVideos: VideoConnection;
};

export type QueryAddOnContentsArgs = {
    searchQuery?: InputMaybe<SearchQuery>;
};

export type QueryAttachmentArgs = {
    id: Scalars['ID'];
};

export type QueryAttachmentsArgs = {
    searchQuery?: InputMaybe<SearchQuery>;
};

export type QueryAttendanceRecordsArgs = {
    eventId?: InputMaybe<Scalars['ID']>;
    userId?: InputMaybe<Scalars['ID']>;
};

export type QueryAuthenticatedUserArgs = {
    tenantId: Scalars['ID'];
};

export type QueryBasicFileArgs = {
    shortUrl: Scalars['String'];
};

export type QueryBenefitArgs = {
    id: Scalars['ID'];
};

export type QueryBenefitsArgs = {
    searchQuery?: InputMaybe<SearchQuery>;
};

export type QueryBookingsArgs = {
    searchQuery: BookingSearchQuery;
    tenantId: Scalars['ID'];
};

export type QueryBookmarkedContentsArgs = {
    searchQuery?: InputMaybe<SearchQuery>;
};

export type QueryCompaniesArgs = {
    searchQuery?: InputMaybe<SearchQuery>;
};

export type QueryCompanyArgs = {
    country?: InputMaybe<Scalars['String']>;
    domain?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    reImport?: InputMaybe<Scalars['Boolean']>;
    slug?: InputMaybe<Scalars['String']>;
    ubAccountCode?: InputMaybe<Scalars['String']>;
};

export type QueryCompanyPaymentSettingsArgs = {
    ubAccountCode?: InputMaybe<Scalars['String']>;
};

export type QueryContentArgs = {
    contentId: Scalars['String'];
};

export type QueryContentDocumentsArgs = {
    searchQuery?: InputMaybe<ContentSearchInput>;
};

export type QueryContentProgressArgs = {
    contentId: Scalars['String'];
};

export type QueryContentsArgs = {
    searchQuery?: InputMaybe<SearchQuery>;
};

export type QueryContentsV2Args = {
    searchQuery?: InputMaybe<SearchQuery>;
};

export type QueryCourseArgs = {
    identifier: Scalars['String'];
};

export type QueryCoursesArgs = {
    searchQuery?: InputMaybe<SearchQuery>;
};

export type QueryDeductedCreditsAmountArgs = {
    userId: Scalars['ID'];
};

export type QueryDepartmentArgs = {
    id: Scalars['ID'];
};

export type QueryEventArgs = {
    bookingCountry?: InputMaybe<Scalars['String']>;
    eventId?: InputMaybe<Scalars['ID']>;
    tenantId: Scalars['ID'];
    ubEventId?: InputMaybe<Scalars['String']>;
    userId?: InputMaybe<Scalars['ID']>;
};

export type QueryEventForBookingArgs = {
    bookingCountry?: InputMaybe<Scalars['String']>;
    contentId?: InputMaybe<Scalars['ID']>;
    eventId?: InputMaybe<Scalars['ID']>;
    tenantId: Scalars['ID'];
    ubEventId?: InputMaybe<Scalars['String']>;
    userId?: InputMaybe<Scalars['ID']>;
};

export type QueryEventParticipantsSummariesArgs = {
    eventId?: InputMaybe<Scalars['ID']>;
    userId?: InputMaybe<Scalars['ID']>;
};

export type QueryEventPriceListArgs = {
    tenantId: Scalars['ID'];
    ubEventId: Scalars['String'];
};

export type QueryExportBookingReportArgs = {
    eventId: Scalars['ID'];
    participationTypes: Array<ParticipationType>;
};

export type QueryFileArgs = {
    shortUrl: Scalars['String'];
};

export type QueryFileByIdArgs = {
    id: Scalars['ID'];
};

export type QueryFindSpecialitiesArgs = {
    codes: Array<Scalars['String']>;
};

export type QueryGenerateHtmlFromTemplateArgs = {
    context: Scalars['JSON'];
    locale?: InputMaybe<Scalars['Locale']>;
    templateName: Scalars['String'];
};

export type QueryGenerateOnsiteNameTagReportArgs = {
    eventId: Scalars['ID'];
    interval?: InputMaybe<Scalars['String']>;
    slotId?: InputMaybe<Scalars['String']>;
};

export type QueryGenerateParticipantReportArgs = {
    eventId: Scalars['ID'];
    interval?: InputMaybe<Scalars['String']>;
    slotId?: InputMaybe<Scalars['String']>;
};

export type QueryGetActiveOrFutureGroupMembershipArgs = {
    companyId: Scalars['ID'];
};

export type QueryGetDueRemindersArgs = {
    isUrgent?: InputMaybe<Scalars['Boolean']>;
    searchQuery: ReminderConfigPaginatedQueryInput;
};

export type QueryGetUserByEmailArgs = {
    email: Scalars['String'];
    tenantId: Scalars['ID'];
};

export type QueryGetUserRemindersForEventArgs = {
    eventUbId: Scalars['String'];
    userId: Scalars['ID'];
};

export type QueryMembershipArgs = {
    membershipId: Scalars['ID'];
    tenantId: Scalars['ID'];
};

export type QueryMembershipsArgs = {
    country: Scalars['String'];
    tenantId: Scalars['ID'];
};

export type QueryParticipantTypeArgs = {
    participantTypeId: Scalars['ID'];
    tenantId: Scalars['ID'];
};

export type QueryParticipantTypesArgs = {
    tenantId: Scalars['ID'];
};

export type QueryParticipantsCourseProgressesArgs = {
    courseId: Scalars['String'];
};

export type QueryPartnerCompaniesArgs = {
    searchQuery?: InputMaybe<SearchQuery>;
};

export type QueryPartnershipArgs = {
    id: Scalars['ID'];
};

export type QueryPartnershipsArgs = {
    searchQuery?: InputMaybe<SearchQuery>;
};

export type QueryPaymentArgs = {
    paymentId: Scalars['ID'];
};

export type QueryPriceListArgs = {
    priceListId: Scalars['ID'];
    tenantId: Scalars['ID'];
};

export type QueryProductArgs = {
    id: Scalars['ID'];
};

export type QueryProductsArgs = {
    searchQuery?: InputMaybe<SearchQuery>;
};

export type QueryProductsByContentArgs = {
    searchQuery?: InputMaybe<SearchQuery>;
};

export type QueryProgramScheduleArgs = {
    id: Scalars['ID'];
};

export type QueryProgramSchedulesArgs = {
    searchQuery?: InputMaybe<SearchQuery>;
};

export type QueryPromoCodeArgs = {
    promoCodeId: Scalars['ID'];
    tenantId: Scalars['ID'];
};

export type QueryPromoCodeForBookingArgs = {
    bookingRequest: BookingRequest;
    tenantId: Scalars['ID'];
    userId: Scalars['ID'];
};

export type QueryPromoCodeForMembershipArgs = {
    membershipId: Scalars['ID'];
    promoCode?: InputMaybe<Scalars['String']>;
    tenantId: Scalars['ID'];
};

export type QueryRecentContentProgressesArgs = {
    after?: InputMaybe<Scalars['String']>;
    before?: InputMaybe<Scalars['String']>;
    first?: InputMaybe<Scalars['Int']>;
    last?: InputMaybe<Scalars['Int']>;
};

export type QueryRecentlyStartedCoursesArgs = {
    brandType?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    completed?: InputMaybe<Scalars['Boolean']>;
    contentType?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    country?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryRemindersArgs = {
    inProgress?: InputMaybe<Scalars['Boolean']>;
    isSent?: InputMaybe<Scalars['Boolean']>;
    searchQuery: ReminderConfigPaginatedQueryInput;
};

export type QueryRetrieveSetupIntentArgs = {
    checkoutSessionId: Scalars['String'];
    country: Scalars['String'];
};

export type QueryRetrieveStripePaymentMethodArgs = {
    paymentId: Scalars['ID'];
    tenantId: Scalars['ID'];
};

export type QuerySearchEventParticipantsSummariesArgs = {
    eventId: Scalars['ID'];
    searchQuery: SearchQuery;
    tenantId: Scalars['ID'];
};

export type QuerySearchEventsArgs = {
    searchQuery: SearchQuery;
    tenantId: Scalars['ID'];
};

export type QuerySearchParticipantsSummariesArgs = {
    searchQuery: SearchQuery;
    tenantId: Scalars['ID'];
};

export type QuerySearchPriceListsArgs = {
    searchQuery: SearchQuery;
    tenantId: Scalars['ID'];
};

export type QuerySearchPromoCodesArgs = {
    searchQuery: SearchQuery;
    tenantId: Scalars['ID'];
};

export type QuerySearchReminderTransactionsArgs = {
    searchQuery: ReminderTransactionPaginatedQueryInput;
};

export type QuerySearchUserMembershipsArgs = {
    searchQuery: SearchQuery;
};

export type QuerySearchUsersArgs = {
    searchQuery: SearchQuery;
    tenantId: Scalars['ID'];
};

export type QuerySpeakerArgs = {
    ubAccountCode: Scalars['String'];
};

export type QuerySpecialityArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type QuerySponsorshipArgs = {
    id: Scalars['ID'];
};

export type QuerySponsorshipsArgs = {
    searchQuery?: InputMaybe<SearchQuery>;
};

export type QuerySponsorshipsByContentArgs = {
    searchQuery?: InputMaybe<SearchQuery>;
};

export type QueryTargetGroupArgs = {
    id: Scalars['ID'];
};

export type QueryTargetGroupsArgs = {
    searchQuery?: InputMaybe<SearchQuery>;
};

export type QueryTopicDocumentsArgs = {
    searchQuery: SearchQuery;
};

export type QueryTopicsArgs = {
    searchQuery?: InputMaybe<SearchQuery>;
};

export type QueryUserArgs = {
    tenantId: Scalars['ID'];
    userId: Scalars['ID'];
};

export type QueryUserAcademicDegreesArgs = {
    locale: Scalars['String'];
};

export type QueryUserBookingCreditPointsArgs = {
    membershipEndDate?: InputMaybe<Scalars['DateTime']>;
    membershipStartDate?: InputMaybe<Scalars['DateTime']>;
    userId: Scalars['ID'];
};

export type QueryUserGroupMembershipArgs = {
    userUuid: Scalars['ID'];
};

export type QueryUserMembershipArgs = {
    tenantId?: InputMaybe<Scalars['ID']>;
    userId: Scalars['ID'];
};

export type QueryUserMembershipsArgs = {
    userId: Scalars['ID'];
};

export type QueryUserPaymentMethodsArgs = {
    requestFromCountry?: InputMaybe<Scalars['String']>;
    tenantId: Scalars['ID'];
    userId: Scalars['ID'];
};

export type QueryUserPaymentStatusArgs = {
    paymentReference: Scalars['String'];
    tenantId: Scalars['ID'];
    userId: Scalars['ID'];
};

export type QueryValidateTicketSelectionArgs = {
    bookingRequest?: InputMaybe<BookingRequest>;
    tenantId: Scalars['ID'];
};

export type QueryVideoArgs = {
    identifier: Scalars['String'];
};

export type QueryVideosArgs = {
    searchQuery?: InputMaybe<SearchQuery>;
};

export type QueryWatchedVideosArgs = {
    searchQuery?: InputMaybe<SearchQuery>;
};

export type Question = {
    __typename?: 'Question';
    answers?: Maybe<Array<Answer>>;
    description: Scalars['String'];
    id: Scalars['ID'];
    module?: Maybe<Module>;
    order: Scalars['Int'];
    passed?: Maybe<PassedQuestion>;
    type: QuestionType;
};

export const QuestionType = {
    FreeResponse: 'FREE_RESPONSE',
    MultipleChoice: 'MULTIPLE_CHOICE',
    SingleChoice: 'SINGLE_CHOICE'
} as const;

export type QuestionType = (typeof QuestionType)[keyof typeof QuestionType];
export type RecalculateUserCreditPointsRequest = {
    userIds: Array<Scalars['ID']>;
};

export type RecalculateUserCreditPointsResult = {
    __typename?: 'RecalculateUserCreditPointsResult';
    recalculationStarted: Scalars['Boolean'];
};

export const RecordType = {
    Click: 'CLICK',
    LearnControl: 'LEARN_CONTROL',
    Signature: 'SIGNATURE'
} as const;

export type RecordType = (typeof RecordType)[keyof typeof RecordType];
export type RegistrationRequest = {
    country?: InputMaybe<Scalars['String']>;
    dataSharingConsent: Scalars['Boolean'];
    email: Scalars['String'];
    locale?: InputMaybe<Scalars['Locale']>;
    mediaLibraryTrial?: InputMaybe<Scalars['Boolean']>;
    password: Scalars['String'];
    privacyPolicyConfirmation: Scalars['Boolean'];
};

export type RegistrationRequestByAdmin = {
    accountType?: InputMaybe<AccountType>;
    dataSharingConsent: Scalars['Boolean'];
    email: Scalars['String'];
    locale: Scalars['Locale'];
    password?: InputMaybe<Scalars['String']>;
    privacyPolicyConfirmation: Scalars['Boolean'];
};

export type RegistrationResponse = {
    __typename?: 'RegistrationResponse';
    email: Scalars['String'];
    id: Scalars['ID'];
    loginToken?: Maybe<Scalars['String']>;
};

export const ReminderChannel = {
    Email: 'EMAIL',
    Sms: 'SMS'
} as const;

export type ReminderChannel = (typeof ReminderChannel)[keyof typeof ReminderChannel];
export type ReminderConfig = {
    __typename?: 'ReminderConfig';
    contentId?: Maybe<Scalars['String']>;
    createdAt: Scalars['String'];
    eventUbId?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    inProgress: Scalars['Boolean'];
    isSent: Scalars['Boolean'];
    locale: Scalars['Locale'];
    payload?: Maybe<Scalars['JSON']>;
    reminderChannel: ReminderChannel;
    reminderDateTime: Scalars['DateTimeISO'];
    reminderTemplate: ReminderTemplate;
    transactions: Array<ReminderTransaction>;
    updatedAt: Scalars['String'];
    userId: Scalars['ID'];
};

export type ReminderConfigPaginatedQueryInput = {
    orders?: InputMaybe<Array<Scalars['String']>>;
    pageNumber?: InputMaybe<Scalars['Int']>;
    pageSize?: InputMaybe<Scalars['Int']>;
    where: Array<ReminderConfigSearchFilter>;
};

export type ReminderConfigPaginatedResponse = {
    __typename?: 'ReminderConfigPaginatedResponse';
    hasMore: Scalars['Boolean'];
    items: Array<ReminderConfig>;
    totalCount: Scalars['Int'];
};

export type ReminderConfigSearchFilter = {
    field: Scalars['String'];
    operation?: InputMaybe<SearchFilterOperation>;
    value: Array<InputMaybe<Scalars['String']>>;
};

export const ReminderTemplate = {
    EventReminder: 'EVENT_REMINDER'
} as const;

export type ReminderTemplate = (typeof ReminderTemplate)[keyof typeof ReminderTemplate];
export type ReminderTransaction = {
    __typename?: 'ReminderTransaction';
    createdAt: Scalars['String'];
    externalProvider: Scalars['String'];
    externalProviderSendResult?: Maybe<Scalars['String']>;
    externalTransactionId?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    reminderConfig: ReminderConfig;
    sendDateTime: Scalars['DateTimeISO'];
    status: ReminderTransactionStatus;
    updatedAt: Scalars['String'];
};

export type ReminderTransactionPaginatedQueryInput = {
    orders?: InputMaybe<Array<Scalars['String']>>;
    pageNumber?: InputMaybe<Scalars['Int']>;
    pageSize?: InputMaybe<Scalars['Int']>;
    where: Array<ReminderTransactionSearchFilter>;
};

export type ReminderTransactionPaginatedResponse = {
    __typename?: 'ReminderTransactionPaginatedResponse';
    hasMore: Scalars['Boolean'];
    items: Array<ReminderTransaction>;
    totalCount: Scalars['Int'];
};

export type ReminderTransactionSearchFilter = {
    field: Scalars['String'];
    operation?: InputMaybe<SearchFilterOperation>;
    value: Array<InputMaybe<Scalars['String']>>;
};

export const ReminderTransactionStatus = {
    Failed: 'FAILED',
    InProgress: 'IN_PROGRESS',
    Scheduled: 'SCHEDULED',
    Succeeded: 'SUCCEEDED'
} as const;

export type ReminderTransactionStatus =
    (typeof ReminderTransactionStatus)[keyof typeof ReminderTransactionStatus];
export type Report = {
    __typename?: 'Report';
    contentId: Scalars['ID'];
    file?: Maybe<File>;
    reportDate?: Maybe<Scalars['DateTime']>;
    reportStatus: ReportStatus;
    userId: Scalars['ID'];
};

export const ReportStatus = {
    Generated: 'GENERATED',
    Generating: 'GENERATING',
    HasError: 'HAS_ERROR',
    NotGenerated: 'NOT_GENERATED',
    RequiresTopup: 'REQUIRES_TOPUP'
} as const;

export type ReportStatus = (typeof ReportStatus)[keyof typeof ReportStatus];
export type ResendActivationLinkResult = {
    __typename?: 'ResendActivationLinkResult';
    emailSent?: Maybe<Scalars['Boolean']>;
};

export type ResetPasswordLinkResult = {
    __typename?: 'ResetPasswordLinkResult';
    emailSent?: Maybe<Scalars['Boolean']>;
};

export type ResetPasswordResult = {
    __typename?: 'ResetPasswordResult';
    passwordUpdated?: Maybe<Scalars['Boolean']>;
};

export type RetrievePaymentMethodResult = {
    __typename?: 'RetrievePaymentMethodResult';
    stripePaymentMethod?: Maybe<Scalars['String']>;
};

export type RetrievePaymentResult = {
    __typename?: 'RetrievePaymentResult';
    clientReference?: Maybe<Scalars['String']>;
    country: Scalars['String'];
    currency: Currency;
    paymentId: Scalars['ID'];
    paymentReason: PaymentReason;
    paymentReference: Scalars['String'];
    paymentStatus: PaymentStatus;
    price: Scalars['Int'];
    userId: Scalars['ID'];
};

export type RevokeMembershipResult = {
    __typename?: 'RevokeMembershipResult';
    revoked: Scalars['Boolean'];
};

export type RevokeTopUpResult = {
    __typename?: 'RevokeTopUpResult';
    revoked: Scalars['Boolean'];
};

export const Role = {
    Admin: 'ADMIN',
    Direction: 'DIRECTION',
    EventManager: 'EVENT_MANAGER',
    MediathekTester: 'MEDIATHEK_TESTER',
    Sales: 'SALES',
    User: 'USER'
} as const;

export type Role = (typeof Role)[keyof typeof Role];
export type SearchAggregation = {
    data?: InputMaybe<Array<InputMaybe<SearchAggregationData>>>;
    field: Scalars['String'];
    name: Scalars['String'];
    order?: InputMaybe<Scalars['String']>;
    size?: InputMaybe<Scalars['Int']>;
    subAggregation?: InputMaybe<SearchSubAggregation>;
    type: AggregationType;
};

export type SearchAggregationData = {
    key: Scalars['String'];
    value: Scalars['String'];
};

export type SearchFilter = {
    dataType?: InputMaybe<SearchFilterDataType>;
    field?: InputMaybe<Scalars['String']>;
    operation?: InputMaybe<SearchFilterOperation>;
    subFilterCondition?: InputMaybe<SearchFilterCondition>;
    subFilters?: InputMaybe<Array<SearchFilter>>;
    value?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    valueWithNull?: InputMaybe<Scalars['Boolean']>;
};

export const SearchFilterCondition = {
    And: 'AND',
    Or: 'OR'
} as const;

export type SearchFilterCondition =
    (typeof SearchFilterCondition)[keyof typeof SearchFilterCondition];
export const SearchFilterDataType = {
    Boolean: 'BOOLEAN',
    CertificateStatus: 'CERTIFICATE_STATUS',
    CreditTransferStatus: 'CREDIT_TRANSFER_STATUS',
    Enum: 'ENUM',
    Uuid: 'UUID'
} as const;

export type SearchFilterDataType = (typeof SearchFilterDataType)[keyof typeof SearchFilterDataType];
export const SearchFilterOperation = {
    Contain: 'CONTAIN',
    Equal: 'EQUAL',
    GreaterThan: 'GREATER_THAN',
    GreaterThanEqual: 'GREATER_THAN_EQUAL',
    IsNull: 'IS_NULL',
    LessThan: 'LESS_THAN',
    LessThanEqual: 'LESS_THAN_EQUAL',
    MatchPhrase: 'MATCH_PHRASE',
    NotEqual: 'NOT_EQUAL',
    Regex: 'REGEX'
} as const;

export type SearchFilterOperation =
    (typeof SearchFilterOperation)[keyof typeof SearchFilterOperation];
export type SearchQuery = {
    aggregations?: InputMaybe<Array<InputMaybe<SearchAggregation>>>;
    filterCondition?: InputMaybe<SearchFilterCondition>;
    filters?: InputMaybe<Array<SearchFilter>>;
    orders?: InputMaybe<Array<Scalars['String']>>;
    pageNumber?: InputMaybe<Scalars['Int']>;
    pageSize?: InputMaybe<Scalars['Int']>;
    query?: InputMaybe<Scalars['String']>;
};

export type SearchResultAggregation = {
    __typename?: 'SearchResultAggregation';
    buckets?: Maybe<Array<Maybe<SearchResultAggregationData>>>;
    name: Scalars['String'];
};

export type SearchResultAggregationData = {
    __typename?: 'SearchResultAggregationData';
    key?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['String']>;
};

export type SearchSubAggregation = {
    field: Scalars['String'];
    name: Scalars['String'];
    type: AggregationType;
};

export const SeoIndexSetting = {
    Indexed: 'INDEXED',
    IndexedFollow: 'INDEXED_FOLLOW',
    IndexedNoFollow: 'INDEXED_NO_FOLLOW',
    NotIndexed: 'NOT_INDEXED'
} as const;

export type SeoIndexSetting = (typeof SeoIndexSetting)[keyof typeof SeoIndexSetting];
export type SeoSettings = {
    __typename?: 'SeoSettings';
    description?: Maybe<Scalars['JSON']>;
    image?: Maybe<Scalars['String']>;
    indexSetting?: Maybe<SeoIndexSetting>;
    title?: Maybe<Scalars['JSON']>;
};

export type SeoSettingsInput = {
    description?: InputMaybe<Scalars['JSON']>;
    image?: InputMaybe<Scalars['String']>;
    indexSetting?: InputMaybe<SeoIndexSetting>;
    title?: InputMaybe<Scalars['JSON']>;
};

export type SetupIntentR = {
    __typename?: 'SetupIntentR';
    id: Scalars['String'];
    mandate?: Maybe<Scalars['String']>;
    paymentMethod: Scalars['String'];
};

export type SlotCredit = {
    __typename?: 'SlotCredit';
    amount: Scalars['Int'];
    certified: Scalars['Boolean'];
    mainAccreditation: Scalars['Boolean'];
    unit: Scalars['String'];
};

export type SlotCreditInput = {
    amount: Scalars['Int'];
    certified: Scalars['Boolean'];
    mainAccreditation: Scalars['Boolean'];
    unit: Scalars['String'];
};

export type Source = {
    __typename?: 'Source';
    duration?: Maybe<Scalars['Float']>;
    file?: Maybe<File>;
    thumbnail?: Maybe<Scalars['String']>;
    url?: Maybe<Scalars['String']>;
};

export type Speaker = {
    __typename?: 'Speaker';
    avatarSrcUrl?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    gender?: Maybe<Gender>;
    id: Scalars['ID'];
    name: Scalars['String'];
    title?: Maybe<Scalars['String']>;
};

export type SpeakerInput = {
    id: Scalars['ID'];
};

export type SpecialityR = {
    __typename?: 'SpecialityR';
    code: Scalars['String'];
    description: I18n;
    id: Scalars['String'];
};

export type Sponsorship = {
    __typename?: 'Sponsorship';
    content?: Maybe<Content>;
    contentId: Scalars['ID'];
    /** @deprecated Field no longer supported */
    departmentSymposiumIds: Array<Maybe<Scalars['ID']>>;
    /** @deprecated Field no longer supported */
    departmentSymposiums: Array<Maybe<ProgramSchedule>>;
    description?: Maybe<Scalars['JSON']>;
    entireEventParticipation?: Maybe<Scalars['Boolean']>;
    entireEventSponsor?: Maybe<Scalars['Boolean']>;
    id: Scalars['ID'];
    participantRestriction: Scalars['Boolean'];
    /** @deprecated Field no longer supported */
    provider?: Maybe<Company>;
    /** @deprecated Field no longer supported */
    providerId?: Maybe<Scalars['ID']>;
    specialConsent?: Maybe<Scalars['Boolean']>;
    specialConsentAccepted?: Maybe<Scalars['Boolean']>;
    specialConsentDescription?: Maybe<Scalars['String']>;
    specialConsentTitle?: Maybe<Scalars['String']>;
    sponsorCompany?: Maybe<Company>;
    sponsorCompanyId?: Maybe<Scalars['ID']>;
    /** @deprecated Field no longer supported */
    sponsorDepartment?: Maybe<Department>;
    /** @deprecated Field no longer supported */
    sponsorDepartmentId?: Maybe<Scalars['ID']>;
    sponsorDepartmentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
    sponsorDepartments?: Maybe<Array<Maybe<Department>>>;
    sponsorshipAmount?: Maybe<Scalars['Int']>;
    sponsorshipType?: Maybe<SponsorshipType>;
    symposiumIds: Array<Maybe<Scalars['ID']>>;
    symposiums: Array<Maybe<ProgramSchedule>>;
    virtualExhibition?: Maybe<Scalars['Boolean']>;
};

export type SponsorshipConnection = {
    __typename?: 'SponsorshipConnection';
    data: Array<Sponsorship>;
    pageNumber?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    totalCount?: Maybe<Scalars['Int']>;
};

export type SponsorshipDocument = {
    __typename?: 'SponsorshipDocument';
    amount?: Maybe<Scalars['Int']>;
    description?: Maybe<Scalars['JSON']>;
    entireEventParticipation?: Maybe<Scalars['Boolean']>;
    entireEventSponsor?: Maybe<Scalars['Boolean']>;
    id: Scalars['ID'];
    participantRestriction?: Maybe<Scalars['Boolean']>;
    specialConsent?: Maybe<Scalars['Boolean']>;
    specialConsentDescription?: Maybe<Scalars['String']>;
    specialConsentTitle?: Maybe<Scalars['String']>;
    sponsorCompany?: Maybe<CompanyDocument>;
    /** @deprecated Field no longer supported */
    sponsorDepartment?: Maybe<DepartmentDocument>;
    sponsorDepartments?: Maybe<Array<Maybe<DepartmentDocument>>>;
    type?: Maybe<Scalars['String']>;
    virtualExhibition?: Maybe<Scalars['Boolean']>;
};

export type SponsorshipInput = {
    contentId?: InputMaybe<Scalars['ID']>;
    contentScheduleIds?: InputMaybe<Array<Scalars['ID']>>;
    description?: InputMaybe<Scalars['JSON']>;
    entireEventParticipation?: InputMaybe<Scalars['Boolean']>;
    entireEventSponsor?: InputMaybe<Scalars['Boolean']>;
    participantRestriction?: InputMaybe<Scalars['Boolean']>;
    specialConsent?: InputMaybe<Scalars['Boolean']>;
    specialConsentDescription?: InputMaybe<Scalars['String']>;
    specialConsentTitle?: InputMaybe<Scalars['String']>;
    sponsorCompanyId?: InputMaybe<Scalars['ID']>;
    sponsorDepartmentId?: InputMaybe<Scalars['ID']>;
    sponsorDepartmentIds?: InputMaybe<Array<Scalars['ID']>>;
    sponsorshipAmount?: InputMaybe<Scalars['Int']>;
    sponsorshipType?: InputMaybe<SponsorshipType>;
    virtualExhibition?: InputMaybe<Scalars['Boolean']>;
};

export type SponsorshipSearch = {
    __typename?: 'SponsorshipSearch';
    data: Array<Sponsorship>;
    pageNumber?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    totalCount?: Maybe<Scalars['Int']>;
};

export const SponsorshipType = {
    Gold: 'GOLD',
    Head: 'HEAD',
    Platin: 'PLATIN',
    Silver: 'SILVER'
} as const;

export type SponsorshipType = (typeof SponsorshipType)[keyof typeof SponsorshipType];
export type StartPaymentResult = {
    __typename?: 'StartPaymentResult';
    clientReference?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    paymentMethod: PaymentMethod;
    paymentStatus?: Maybe<PaymentStatus>;
};

export type StoreUserForFutureOnboardingRequest = {
    companyId: Scalars['ID'];
    membershipType: MembershipType;
    onboardingToken: Scalars['ID'];
    orderLocale: Scalars['String'];
    userId: Scalars['ID'];
};

export type StoreUserForFutureOnboardingResult = {
    __typename?: 'StoreUserForFutureOnboardingResult';
    stored?: Maybe<Scalars['Boolean']>;
};

export type StripeChargePaymentMethodRequest = {
    country: Scalars['String'];
    currency: Currency;
    paymentMethodReference: Scalars['String'];
    paymentReason: PaymentReason;
    price: Scalars['Int'];
    stripeConnectedAccountId?: InputMaybe<Scalars['String']>;
};

export type StripeChargePaymentMethodResult = {
    __typename?: 'StripeChargePaymentMethodResult';
    clientReference?: Maybe<Scalars['String']>;
    paymentId: Scalars['ID'];
    paymentMethod?: Maybe<PaymentMethod>;
    paymentStatus?: Maybe<PaymentStatus>;
};

export type Submodule = {
    __typename?: 'Submodule';
    iconUrl?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    module?: Maybe<Module>;
    order?: Maybe<Scalars['Int']>;
    title?: Maybe<Scalars['JSON']>;
};

export type SyncBookingsForEventSummaryResult = {
    __typename?: 'SyncBookingsForEventSummaryResult';
    bookingIds: Array<Scalars['ID']>;
    succeeded: Scalars['Boolean'];
};

export type SyncResult = {
    __typename?: 'SyncResult';
    syncing: Scalars['Boolean'];
};

export type TargetGroup = {
    __typename?: 'TargetGroup';
    id: Scalars['ID'];
    title?: Maybe<Scalars['JSON']>;
};

export type TargetGroupInput = {
    title?: InputMaybe<Scalars['JSON']>;
};

export type TargetGroupSearch = {
    __typename?: 'TargetGroupSearch';
    data: Array<TargetGroup>;
    pageNumber?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    totalCount?: Maybe<Scalars['Int']>;
};

export const TaxType = {
    Excluded: 'EXCLUDED',
    Included: 'INCLUDED'
} as const;

export type TaxType = (typeof TaxType)[keyof typeof TaxType];
export const TicketType = {
    DayTicket: 'DAY_TICKET',
    EntireEvent: 'ENTIRE_EVENT',
    HalfDayTicket: 'HALF_DAY_TICKET',
    KombiTicket: 'KOMBI_TICKET',
    OnDemand: 'ON_DEMAND',
    PrintedCourseBook: 'PRINTED_COURSE_BOOK',
    Workshop: 'WORKSHOP'
} as const;

export type TicketType = (typeof TicketType)[keyof typeof TicketType];
export type TopUpOrder = {
    __typename?: 'TopUpOrder';
    amount: Scalars['Int'];
    createdAt?: Maybe<Scalars['DateTime']>;
    remainingAmount: Scalars['Int'];
    revoked?: Maybe<Scalars['Boolean']>;
    revokedAt?: Maybe<Scalars['DateTime']>;
    topUpOrderItemId: Scalars['ID'];
    userMembershipItemId: Scalars['ID'];
};

export type Topic = {
    __typename?: 'Topic';
    abbreviation?: Maybe<Scalars['JSON']>;
    activated?: Maybe<Scalars['Boolean']>;
    backgroundUrl?: Maybe<Scalars['String']>;
    code: Scalars['String'];
    description?: Maybe<Scalars['JSON']>;
    iconPrimaryColor: Scalars['String'];
    id: Scalars['ID'];
    level?: Maybe<Scalars['Int']>;
    parents: Array<Topic>;
    specialityIconUrl?: Maybe<Scalars['String']>;
    subtopicPriority?: Maybe<Scalars['Int']>;
};

export type TopicDocumentConnection = {
    __typename?: 'TopicDocumentConnection';
    aggregations?: Maybe<Array<Maybe<SearchResultAggregation>>>;
    data: Array<Topic>;
    pageNumber?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    totalCount?: Maybe<Scalars['Int']>;
};

export type TopicSearch = {
    __typename?: 'TopicSearch';
    data: Array<Topic>;
    pageNumber?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    totalCount?: Maybe<Scalars['Int']>;
};

export type TranslatedInputText = {
    locale: Scalars['String'];
    value: Scalars['String'];
};

export type TranslatedText = {
    __typename?: 'TranslatedText';
    locale: Scalars['String'];
    value: Scalars['String'];
};

export type TranslatedTextInput = {
    locale: Scalars['String'];
    value: Scalars['String'];
};

export type Translation = {
    __typename?: 'Translation';
    locale: Scalars['String'];
    value: Scalars['String'];
};

export type UpdateBookingResult = {
    __typename?: 'UpdateBookingResult';
    bookingId: Scalars['ID'];
    clientReference?: Maybe<Scalars['String']>;
    totalPrice?: Maybe<Scalars['Int']>;
};

export type UpdatePaymentResult = {
    __typename?: 'UpdatePaymentResult';
    paymentStatusUpdated: Scalars['Boolean'];
};

export type UpdateReminderConfigInput = {
    eventUbId?: InputMaybe<Scalars['String']>;
    locale?: InputMaybe<Scalars['Locale']>;
    reminderChannel?: InputMaybe<ReminderChannel>;
    reminderDateTime?: InputMaybe<Scalars['DateTimeISO']>;
    reminderTemplate?: InputMaybe<ReminderTemplate>;
    userId?: InputMaybe<Scalars['ID']>;
};

export type UpdateReminderTransactionInput = {
    externalProvider?: InputMaybe<Scalars['String']>;
    externalProviderSendResult?: InputMaybe<Scalars['String']>;
    externalTransactionId?: InputMaybe<Scalars['String']>;
    id: Scalars['ID'];
    sendDateTime?: InputMaybe<Scalars['DateTimeISO']>;
    status?: InputMaybe<ReminderTransactionStatus>;
};

export type UpdateUserRequest = {
    academicDegreeCode?: InputMaybe<Scalars['String']>;
    addresses?: InputMaybe<Array<InputMaybe<AddressRequest>>>;
    companyId?: InputMaybe<Scalars['String']>;
    country?: InputMaybe<Scalars['String']>;
    dataSharingConsent?: InputMaybe<Scalars['Boolean']>;
    departmentId?: InputMaybe<Scalars['String']>;
    email?: InputMaybe<Scalars['String']>;
    fieldOfActivity?: InputMaybe<FieldOfActivity>;
    firstName?: InputMaybe<Scalars['String']>;
    gender?: InputMaybe<Gender>;
    healthProfessionalConfirmation?: InputMaybe<Scalars['Boolean']>;
    isOnHold?: InputMaybe<Scalars['Boolean']>;
    lastName?: InputMaybe<Scalars['String']>;
    locale?: InputMaybe<Scalars['Locale']>;
    medicalIdentificationNumber?: InputMaybe<Scalars['String']>;
    mobile?: InputMaybe<Scalars['String']>;
    newsletterSubscription?: InputMaybe<Scalars['Boolean']>;
    occupationType?: InputMaybe<OccupationType>;
    onHoldReason?: InputMaybe<Scalars['String']>;
    privacyPolicyConfirmation?: InputMaybe<Scalars['Boolean']>;
    specialityCodes?: InputMaybe<Array<Scalars['String']>>;
    ubAccountCode?: InputMaybe<Scalars['String']>;
};

export type UpdatedResult = {
    __typename?: 'UpdatedResult';
    id: Scalars['ID'];
};

export type UserAnswer = {
    answers: Array<Scalars['String']>;
    questionId: Scalars['ID'];
};

export type UserBookingCreditPoints = {
    __typename?: 'UserBookingCreditPoints';
    totalBookingPointsCertified: Array<CreditPointR>;
    totalMembershipBookingPointsEffectedCertified: Array<CreditPointR>;
};

export type UserBookingEventItem = {
    __typename?: 'UserBookingEventItem';
    endDateTime: Scalars['DateTime'];
    functionId: Scalars['String'];
    name: Scalars['String'];
    participationType: ParticipationType;
    startDateTime: Scalars['DateTime'];
    ticketType: TicketType;
};

export type UserBookingEventR = {
    __typename?: 'UserBookingEventR';
    availableForMembers: Scalars['Boolean'];
    category: Category;
    city?: Maybe<LabelValueR>;
    contentByUbEvent?: Maybe<ContentByUbEvent>;
    contentV2?: Maybe<Content>;
    country?: Maybe<LabelValueR>;
    description?: Maybe<Scalars['String']>;
    endDateTime: Scalars['DateTime'];
    eventFormat?: Maybe<EventFormat>;
    id: Scalars['ID'];
    items?: Maybe<Array<Maybe<UserBookingEventItem>>>;
    linkedOnDemandCourseId?: Maybe<Scalars['ID']>;
    livestream: Scalars['Boolean'];
    locale?: Maybe<Scalars['Locale']>;
    name: Scalars['String'];
    onSite: Scalars['Boolean'];
    productCategory?: Maybe<ProductCategoryR>;
    publishedDomains: Array<Scalars['String']>;
    specialities: Array<LabelValueR>;
    startDateTime: Scalars['DateTime'];
    ubEventId?: Maybe<Scalars['String']>;
};

export type UserMembership = {
    __typename?: 'UserMembership';
    addedToGroupMembershipDateTime?: Maybe<Scalars['DateTime']>;
    cycle: MembershipCycle;
    expiresAt?: Maybe<Scalars['DateTime']>;
    id: Scalars['ID'];
    initialStartedAt?: Maybe<Scalars['DateTime']>;
    items: Array<UserMembershipItem>;
    legalPaymentReminders?: Maybe<Array<Maybe<LegalPaymentReminder>>>;
    membership: Membership;
    membershipRenewalType?: Maybe<MembershipType>;
    paymentMethodReference?: Maybe<Scalars['String']>;
    renewalStatus?: Maybe<UserMembershipRenewalStatus>;
    startedAt?: Maybe<Scalars['DateTime']>;
    state?: Maybe<UserMembershipState>;
    topUpOrders?: Maybe<Array<Maybe<TopUpOrder>>>;
    userId?: Maybe<Scalars['ID']>;
};

export type UserMembershipItem = {
    __typename?: 'UserMembershipItem';
    amountUnit: AmountUnit;
    initialAmount: Scalars['Int'];
    membershipItem: MembershipItem;
    remainingAmount: Scalars['Int'];
    usedAmount: Scalars['Int'];
};

export const UserMembershipRenewalStatus = {
    Active: 'ACTIVE',
    Canceled: 'CANCELED'
} as const;

export type UserMembershipRenewalStatus =
    (typeof UserMembershipRenewalStatus)[keyof typeof UserMembershipRenewalStatus];
export type UserMembershipSearch = {
    __typename?: 'UserMembershipSearch';
    data: Array<UserMembership>;
    pageNumber?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    totalCount?: Maybe<Scalars['Int']>;
};

export const UserMembershipState = {
    AddedToGroupMembership: 'ADDED_TO_GROUP_MEMBERSHIP',
    AutomaticallyRenewed: 'AUTOMATICALLY_RENEWED',
    CanceledAndExpired: 'CANCELED_AND_EXPIRED',
    EarlyRenewed: 'EARLY_RENEWED',
    Expired: 'EXPIRED',
    GroupMembershipEarlyRenewed: 'GROUP_MEMBERSHIP_EARLY_RENEWED',
    GroupMembershipRenewed: 'GROUP_MEMBERSHIP_RENEWED',
    ManuallyRenewed: 'MANUALLY_RENEWED',
    NewlyCreated: 'NEWLY_CREATED',
    NotPaid: 'NOT_PAID',
    OfferedByAdmin: 'OFFERED_BY_ADMIN',
    PaidManuallyAutomaticallyRenewed: 'PAID_MANUALLY_AUTOMATICALLY_RENEWED',
    PaymentRetriedAutomaticallyRenewed: 'PAYMENT_RETRIED_AUTOMATICALLY_RENEWED',
    Revoked: 'REVOKED',
    TrialOffered: 'TRIAL_OFFERED',
    UpgradeToHybrid: 'UPGRADE_TO_HYBRID'
} as const;

export type UserMembershipState = (typeof UserMembershipState)[keyof typeof UserMembershipState];
export type UserPaymentMethodR = {
    __typename?: 'UserPaymentMethodR';
    brand?: Maybe<Scalars['String']>;
    country?: Maybe<Scalars['String']>;
    default?: Maybe<Scalars['Boolean']>;
    expMonth?: Maybe<Scalars['Int']>;
    expYear?: Maybe<Scalars['Int']>;
    last4?: Maybe<Scalars['String']>;
    reference?: Maybe<Scalars['String']>;
    type?: Maybe<UserPaymentMethodType>;
};

export type UserPaymentMethodRequest = {
    cardCvc: Scalars['String'];
    cardExpMonth: Scalars['Int'];
    cardExpYear: Scalars['Int'];
    cardNumber: Scalars['String'];
};

export const UserPaymentMethodType = {
    CreditCard: 'CREDIT_CARD',
    SepaDebit: 'SEPA_DEBIT',
    StripePayPal: 'STRIPE_PAY_PAL'
} as const;

export type UserPaymentMethodType =
    (typeof UserPaymentMethodType)[keyof typeof UserPaymentMethodType];
export type UserPaymentStatusR = {
    __typename?: 'UserPaymentStatusR';
    paymentStatus: PaymentStatus;
};

export type UserR = {
    __typename?: 'UserR';
    academicDegree?: Maybe<AcademicDegreeR>;
    accountType?: Maybe<AccountType>;
    addresses: Array<AddressR>;
    company?: Maybe<Company>;
    country?: Maybe<Scalars['String']>;
    createdAt?: Maybe<Scalars['DateTime']>;
    dataSharingConsent: Scalars['Boolean'];
    email: Scalars['String'];
    fieldOfActivity?: Maybe<FieldOfActivity>;
    firstName?: Maybe<Scalars['String']>;
    gender?: Maybe<Gender>;
    hasValidActivationToken: Scalars['Boolean'];
    healthProfessionalConfirmation?: Maybe<Scalars['Boolean']>;
    id: Scalars['ID'];
    isActivated: Scalars['Boolean'];
    isCompleteOnboardingRequired?: Maybe<Scalars['Boolean']>;
    isOnHold?: Maybe<Scalars['Boolean']>;
    lastLogin?: Maybe<Scalars['DateTime']>;
    lastName?: Maybe<Scalars['String']>;
    locale?: Maybe<Scalars['Locale']>;
    medicalIdentificationNumber?: Maybe<Scalars['String']>;
    membership?: Maybe<UserMembership>;
    mobile?: Maybe<Scalars['String']>;
    newsletterSubscription?: Maybe<Scalars['Boolean']>;
    occupationType?: Maybe<OccupationType>;
    onHoldReason?: Maybe<Scalars['String']>;
    privacyPolicyConfirmation: Scalars['Boolean'];
    registrationCountry?: Maybe<Scalars['String']>;
    registrationLocale?: Maybe<Scalars['Locale']>;
    roles: Array<Role>;
    settings: Array<UserSettingR>;
    specialities: Array<SpecialityR>;
    totalMembershipPointsEffectedCertified: Array<CreditPointR>;
    totalPointCertified: Array<CreditPointR>;
    ubAccountCode?: Maybe<Scalars['String']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserSearchResult = {
    __typename?: 'UserSearchResult';
    data: Array<UserR>;
    pageNumber?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    totalCount?: Maybe<Scalars['Int']>;
};

export type UserSettingR = {
    __typename?: 'UserSettingR';
    data?: Maybe<Scalars['String']>;
    field?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
};

export type Video = {
    __typename?: 'Video';
    /** @deprecated Field no longer supported */
    accreditationSettings?: Maybe<AccreditationSettings>;
    /** @deprecated Field no longer supported */
    agendaSettings?: Maybe<AgendaSettings>;
    attachmentIds: Array<Scalars['ID']>;
    attachments: Array<Attachment>;
    benefitIds: Array<Scalars['ID']>;
    benefits: Array<Benefit>;
    bookmarked?: Maybe<Scalars['Boolean']>;
    brand?: Maybe<ProductBrand>;
    capacityStatus?: Maybe<ContentCapacityStatus>;
    city?: Maybe<Scalars['JSON']>;
    cityId?: Maybe<Scalars['String']>;
    companiesAccess: Array<ContentCompanyAccess>;
    companiesAccessIds: Array<Scalars['ID']>;
    contentAdmin?: Maybe<UserR>;
    contentId: Scalars['String'];
    contentProgress?: Maybe<ContentProgress>;
    contentType: ContentType;
    contentUnit?: Maybe<ContentUnit>;
    contentUnitIds: Array<Scalars['ID']>;
    contentUnits: Array<ContentUnit>;
    credits: Array<ContentCredit>;
    /** @deprecated Field no longer supported */
    department?: Maybe<Department>;
    description?: Maybe<Scalars['JSON']>;
    descriptionTitle?: Maybe<Scalars['JSON']>;
    /** @deprecated Field no longer supported */
    detailsPageSettings?: Maybe<DetailsPageSettings>;
    dominantColor?: Maybe<Scalars['String']>;
    endDateTime?: Maybe<Scalars['DateTime']>;
    eventCost?: Maybe<Scalars['Int']>;
    externalId?: Maybe<Scalars['String']>;
    featuredInfoUpdatedAt?: Maybe<Scalars['DateTime']>;
    headerUrl?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    imageUrl?: Maybe<Scalars['String']>;
    isAvailableOnHomepage?: Maybe<Scalars['Boolean']>;
    isAvailableOnMediathek?: Maybe<Scalars['Boolean']>;
    isAvailableWithoutEfn?: Maybe<Scalars['Boolean']>;
    isCommercial?: Maybe<Scalars['Boolean']>;
    isCompanyPublished?: Maybe<Scalars['Boolean']>;
    isDeleted: Scalars['Boolean'];
    isDownloadable?: Maybe<Scalars['Boolean']>;
    isFeatured: Scalars['Boolean'];
    isFree?: Maybe<Scalars['Boolean']>;
    isOnDemand?: Maybe<Scalars['Boolean']>;
    isOnSite?: Maybe<Scalars['Boolean']>;
    isScientificLeaderEnabled?: Maybe<Scalars['Boolean']>;
    isSponsored?: Maybe<Scalars['Boolean']>;
    keywords: Array<Scalars['String']>;
    locale?: Maybe<Scalars['Locale']>;
    location?: Maybe<Scalars['String']>;
    membershipSettings?: Maybe<MembershipSettings>;
    module?: Maybe<Module>;
    order?: Maybe<Scalars['Int']>;
    packageOwner?: Maybe<Company>;
    parent?: Maybe<Course>;
    participationOption?: Maybe<ContentParticipationOption>;
    partnershipIds: Array<Scalars['ID']>;
    partnerships: Array<Partnership>;
    product?: Maybe<Product>;
    productCodes: Array<Scalars['String']>;
    programSchedules: Array<ProgramSchedule>;
    /** @deprecated Field no longer supported */
    provider?: Maybe<Company>;
    providers: Array<Company>;
    publishedCountries: Array<Scalars['String']>;
    questions: Array<Question>;
    rating?: Maybe<Scalars['Float']>;
    releaseDate?: Maybe<Scalars['Date']>;
    replacementDate?: Maybe<Scalars['Date']>;
    replacementTitle?: Maybe<Scalars['String']>;
    required?: Maybe<Scalars['Boolean']>;
    /** @deprecated Field no longer supported */
    seoSettings?: Maybe<SeoSettings>;
    slug?: Maybe<Scalars['String']>;
    source?: Maybe<Source>;
    speaker?: Maybe<Speaker>;
    speakers: Array<Speaker>;
    specialities: Array<Scalars['String']>;
    sponsorshipIds: Array<Scalars['ID']>;
    sponsorships: Array<Sponsorship>;
    startDateTime?: Maybe<Scalars['DateTime']>;
    submodule?: Maybe<Submodule>;
    subtitle?: Maybe<Scalars['String']>;
    targetGroupIds: Array<Scalars['ID']>;
    targetGroups: Array<TargetGroup>;
    title?: Maybe<Scalars['String']>;
    topicIds: Array<Scalars['ID']>;
    topics: Array<Topic>;
    validDate?: Maybe<Scalars['Date']>;
    willBeBroadcasted?: Maybe<Scalars['Boolean']>;
    willBeOnDemand?: Maybe<Scalars['Boolean']>;
};

export type VideoConnection = {
    __typename?: 'VideoConnection';
    aggregations?: Maybe<Array<Maybe<SearchResultAggregation>>>;
    data: Array<Video>;
    pageNumber?: Maybe<Scalars['Int']>;
    pageSize?: Maybe<Scalars['Int']>;
    totalCount?: Maybe<Scalars['Int']>;
};
