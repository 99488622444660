import { getAnalytics } from './loadAnalyticsWithConsent';
import { CommonTrackedProperties } from './common-tracked-properties/CommonTrackedProperties';
import { CategoryResult } from './getCategory';
import { PageViewed } from './web/segment';

const trackPageView = async (
    categoryResult?: CategoryResult,
    commonProperties?: CommonTrackedProperties
) => {
    const analytics = await getAnalytics();

    const properties: PageViewed = {
        ...commonProperties,
        ...categoryResult
    };
    analytics?.web.pageViewed(properties);
};

export default trackPageView;
