import React, { useEffect, useState, createContext, PropsWithChildren } from 'react';

// { 'de-DE': '/ueber-uns', 'en-GB': '/about-us' }
export type PageLocalesPaths = Record<string, string>;

type PageLocalesPathsContext = {
    pageLocalesPaths: PageLocalesPaths | null;
};

const defaultPagesLocalesPaths: PageLocalesPathsContext = { pageLocalesPaths: {} };

const PageLocalesPathsContext = createContext<PageLocalesPathsContext>(defaultPagesLocalesPaths);

type PageLocalesPathsContextProviderProps = PropsWithChildren & {
    pageLocalesPaths: PageLocalesPaths | null | undefined;
};

export const PageLocalesPathsContextProvider = (props: PageLocalesPathsContextProviderProps) => {
    const { pageLocalesPaths = null, children } = props;
    const [state, setState] = useState(pageLocalesPaths);
    useEffect(() => {
        setState(pageLocalesPaths);
    }, [pageLocalesPaths]);

    return (
        <PageLocalesPathsContext.Provider value={{ pageLocalesPaths: state }}>
            {children}
        </PageLocalesPathsContext.Provider>
    );
};

export const usePageLocalesPaths = () => React.useContext(PageLocalesPathsContext);
