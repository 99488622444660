import React from 'react';
import { NoColorLogo } from './index';

import logoFomf from './logo-fomf.svg';
import logoFomfText from './logo-fomf-text.svg';

type Attributes = {
    fill?: string;
    className?: string;
};

type Props = {
    locale: Locale;
    type?: 'large' | 'compact';
    variant?: 'text' | 'no_text';
} & Attributes;

const CMEDLogo = (props: Attributes) => (
    <img
        src="https://bk-public-prod.storage.googleapis.com/public/static/logo/logo-cmed.svg"
        {...props}
    />
);

const Logo = (props: Props) => {
    const { locale, type = 'large', variant = 'no_text', ...rest } = props;

    let LogoImage;

    if (type === 'compact' && locale !== 'en-GB') {
        return <NoColorLogo {...rest} />;
    }

    switch (locale) {
        case 'en-GB':
            LogoImage = CMEDLogo;
            break;
        case 'fr-CH':
        case 'de-AT':
        case 'de-DE':
        case 'de-CH':
        default:
            LogoImage = variant === 'text' ? logoFomfText : logoFomf;
            break;
    }

    return <LogoImage {...rest} />;
};

export default Logo;
