import { useUserContext } from '../contexts/UserContext';
import { Types, Queries } from '../';

const useIsUserBookedEvent = (ubId?: string | null) => {
    const { user, isLoading: userLoading } = useUserContext();

    const { data: bookingData, loading: bookingLoading } = Queries.useBookingsCountQuery({
        variables: {
            searchQuery: {
                filters: [
                    {
                        field: 'userId',
                        operation: Types.SearchFilterOperation.MatchPhrase,
                        value: [user?.id ?? '']
                    },
                    {
                        field: 'eventUbEventId',
                        operation: Types.SearchFilterOperation.MatchPhrase,
                        value: [ubId ?? '']
                    }
                ]
            }
        },
        skip: !user?.id || !ubId
    });

    const isEventBooked = Boolean(bookingData?.bookings?.totalCount);

    return {
        loading: userLoading || bookingLoading,
        isEventBooked
    };
};

export default useIsUserBookedEvent;
