import createApolloClient from '../../apollo/createApolloClient';
import { getCountryCode3 } from '../../utils';
import { GetMemberships, GetMembershipsQuery } from '../queries';

export const getMemberships = async (locale: Locale) => {
    const country = getCountryCode3(locale);
    const client = createApolloClient();
    const { data, error } = await client.query<GetMembershipsQuery | null>({
        query: GetMemberships,
        variables: {
            country
        }
    });
    const memberships = data?.memberships || [];
    return { memberships, error };
};
