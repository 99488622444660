import React, { useMemo } from 'react';
import { Dropdown, Icon } from 'components';
import useStyles from './LocaleSwitch.styles';
import { getLanguageFromLocale } from '../../../utils/locale';
import { useRouter } from 'next/router';
import type { SelectableOption } from 'components/src/utils/componentsTypes';
import { usePageLocalesPaths } from '../../../contexts';
import { useIntl } from 'react-intl';

type LocaleSwitchProps = {
    className?: string;
};

const removeLeadingSlash = (path: string | undefined): string | undefined =>
    path?.replace(/^\/+/, '');

const LocaleSwitch = (props: LocaleSwitchProps) => {
    const { className } = props;
    const classes = useStyles();
    const { locale: currentLocale } = useIntl();
    const { locales, pathname, query, push, asPath } = useRouter();
    const { pageLocalesPaths } = usePageLocalesPaths();

    const changeLocale = (newLocale: string | null) => {
        if (newLocale === null) {
            return;
        }

        const { resultPathname, resultAsPath } = (() => {
            // If page doesn't have localized routing - use nextjs logic.
            if (!pageLocalesPaths) {
                return { resultPathname: pathname, resultAsPath: asPath };
            }

            // Remove leading slash from both asPath and pageLocalesPaths
            // as sometimes its present and sometimes not.
            const normalizedAsPath = removeLeadingSlash(asPath);
            const normalizedPageLocalePath = removeLeadingSlash(pageLocalesPaths[currentLocale]);

            const localePath =
                normalizedPageLocalePath === normalizedAsPath
                    ? pageLocalesPaths[newLocale]
                    : undefined;

            // If page has localized routing - use path defined for locale
            // If page doesn't have localized routing for this locale - open home page.
            const resultPathname = localePath || '/';
            const resultAsPath = localePath || '/';
            return { resultPathname, resultAsPath };
        })();

        push({ pathname: resultPathname, query }, resultAsPath, { locale: newLocale });
    };

    const isPreviewEnvironment = process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production';
    const options: Array<SelectableOption> = useMemo(() => {
        return (
            locales?.map((localeCode) => {
                if (isPreviewEnvironment) {
                    return {
                        label: localeCode.toUpperCase().replace('-', ' (') + ')', // don't rely on translations file in preview environment
                        id: localeCode
                    };
                } else {
                    return {
                        localeId: localeCode,
                        id: localeCode
                    };
                }
            }) ?? []
        );
    }, [locales]);

    const textProps = isPreviewEnvironment
        ? { title: currentLocale ? getLanguageFromLocale(currentLocale) : '' }
        : { titleLocaleId: currentLocale ? getLanguageFromLocale(currentLocale) : '' };

    if ((locales?.length ?? 0) < 2) {
        return null;
    }

    return (
        <Dropdown
            data-testid="locale-switch"
            value={currentLocale}
            startIcon={<Icon className={classes.dropdownIcon} icon="language" />}
            options={options}
            minimal
            {...textProps}
            onChange={changeLocale}
            classes={{
                minimalText: classes.dropdownText,
                minimalTextField: classes.dropdown,
                inputBaseRoot: classes.dropdownInputBaseRoot,
                select: classes.dropdownSelect,
                input: classes.dropdownInput
            }}
            className={className}
            MenuProps={{
                classes: {
                    paper: classes.menuPaper
                }
            }}
        />
    );
};

export default LocaleSwitch;
