import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
    Checkbox,
    FormikInputProps,
    FormikInputWrapper,
    CheckboxProps,
    useFormikLabels
} from '../';

type Props = Omit<FormikInputProps<CheckboxProps>, 'intl' | 'checked'>;

/**
 * Use this component for TextFields under a formik form.
 */
const FormikCheckbox = (props: Props) => {
    const { formik, gridProps, gridClassName, name, ...others } = props;
    const intl = useIntl();
    const { label, error, hasError, formikValue } = useFormikLabels({ ...props, intl });

    const value = typeof formikValue === 'undefined' || formikValue === null ? '' : formikValue;

    return (
        <FormikInputWrapper gridProps={gridProps} className={gridClassName}>
            <Checkbox
                checked={!!value}
                onChange={() => {
                    formik.setFieldTouched(name, true);
                    formik.setFieldValue(name, !value, true);
                }}
                error={hasError}
                helperText={hasError ? <FormattedMessage {...error} /> : null}
                label={label}
                {...others}
            />
        </FormikInputWrapper>
    );
};

export default FormikCheckbox;
