import React from 'react';
import setupDayjs from '../utils/setupDayjs';
import { useIntl } from 'react-intl';

const DayJsHOC = (props: { children: React.ReactNode }) => {
    const { children } = props;
    const { locale } = useIntl();
    setupDayjs(locale);
    return <>{children}</>;
};

export default DayJsHOC;
