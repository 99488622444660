import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { FormikInputProps, FormikInputWrapper, PhoneInput, useFormikLabels } from '..';
import { PhoneInputProps } from '../PhoneInput/PhoneInput.types';

type Props = FormikInputProps<PhoneInputProps>;

/**
 * Use this component for PhoneInputs under a formik form.
 */
const FormikPhoneInput = (props: Props) => {
    const { formik, gridProps, gridClassName, name, intl, ...others } = props;
    const { label, error, hasError, formikValue, isRequired } = useFormikLabels(props);

    if (typeof formikValue !== 'undefined' && typeof formikValue !== 'object') {
        throw new Error(`Field ${name} is not an object`);
    }

    const value = formikValue as { value: string };

    return (
        <FormikInputWrapper gridProps={gridProps} className={gridClassName}>
            <PhoneInput
                name={name}
                label={label}
                value={value.value}
                onChange={(event) => {
                    formik.setFieldValue(name, event, true);
                    formik.setFieldTouched(name, true);
                }}
                error={hasError}
                helperText={hasError && <FormattedMessage {...error} />}
                fullWidth
                required={isRequired}
                {...others}
            />
        </FormikInputWrapper>
    );
};

const EnhancedFormikTextField = injectIntl(FormikPhoneInput);

export { FormikPhoneInput };

export default EnhancedFormikTextField;
