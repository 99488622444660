import { countries } from 'components';
import { Types } from '../';

export const fieldOfActivity = [
    {
        id: Types.FieldOfActivity.SettledDown,
        localeId: 'common.field-of-activity.settled-down'
    },
    {
        id: Types.FieldOfActivity.ClinicDoctor,
        localeId: 'common.field-of-activity.clinic-doctor'
    },
    {
        id: Types.FieldOfActivity.Other,
        localeId: 'common.field-of-activity.others'
    }
];

export const occupationType = [
    {
        id: Types.OccupationType.Doctor,
        localeId: 'common.occupation-type.doctor'
    },
    {
        id: Types.OccupationType.AssistantDoctor,
        localeId: 'common.occupation-type.assistant-doctor'
    },
    {
        id: Types.OccupationType.HealthcareProfessional,
        localeId: 'common.occupation-type.healthcare-professional'
    },
    {
        id: Types.OccupationType.Student,
        localeId: 'common.occupation-type.student'
    },
    {
        id: Types.OccupationType.Other,
        localeId: 'common.occupation-type.other'
    }
];

export const gender = [
    {
        id: Types.Gender.Female,
        localeId: 'common.gender.female'
    },
    {
        id: Types.Gender.Male,
        localeId: 'common.gender.male'
    }
];

export const country = countries;

export const medicalIdNameByCountry = [
    { id: 'DEU', label: 'EFN' },
    { id: 'GBR', label: 'GMC' },
    { id: 'AUT', label: 'ÖÄK' },
    { id: 'CHE', label: 'GLN' }
];

export const medicalIdTitleByCountry = [
    { id: 'DEU', label: 'EFN-Nummer' },
    { id: 'GBR', label: 'GMC Number' },
    { id: 'AUT', label: 'ÖÄK-Nummer' },
    { id: 'CHE', label: 'GLN-Nummer' }
];
