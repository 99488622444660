import { formatters, Types, UserOptions } from '../';
import { useIntl, IntlShape } from 'react-intl';

type User = Pick<
    Types.UserR,
    'id' | 'email' | 'gender' | 'firstName' | 'lastName' | 'academicDegree'
>;

export const createUserFullName = (user: User, intl: IntlShape) => {
    const { email, gender, firstName, lastName, academicDegree } = user;
    const { messages, locale } = intl;

    const genderLabel =
        gender && formatters.formatEnum(gender, { options: UserOptions.gender, messages });

    const academicDegreeLabel = formatters.formatEntityWithDescription(academicDegree, { locale });
    const academicDegreeArray = (academicDegreeLabel || '').split(',');
    const academicDegreePrefix = academicDegreeArray[0];
    const academicDegreeSuffix =
        academicDegreeArray.length > 1
            ? ', ' +
              academicDegreeArray
                  .slice(1)
                  .map((title) => title.trim())
                  .join(', ')
            : '';

    const fullNameItems = [genderLabel, academicDegreePrefix, firstName, lastName].filter(Boolean);

    const fullName = fullNameItems.join(' ') + academicDegreeSuffix;

    return fullName || email.charAt(0);
};

const useUserFullName = (user: User | null | undefined, intl?: IntlShape): string => {
    const intlHook = useIntl();
    const intlShape = intl || intlHook;

    if (!user) {
        return '';
    }

    return createUserFullName(user, intlShape);
};

export default useUserFullName;
