import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        width: '100%',
        height: theme.headerHeight.lg,
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        alignItems: 'center',
        padding: 0
    },
    firstRow: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    },
    localesContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(1)
    }
}));
