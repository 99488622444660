import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import { ThemeTypes } from 'components';
import React from 'react';

type UseStylesProps = Required<NonNullable<Pick<Props, 'url' | 'width' | 'height' | 'color'>>>;

const useStyles = makeStyles<ThemeTypes.Theme, UseStylesProps>(() => ({
    icon: {
        width: (props) => props.width,
        height: (props) => props.height,
        backgroundColor: (props) => props.color ?? '#019ABC',
        maskImage: (props) => `url(${props.url})`,
        WebkitMaskImage: (props) => `url(${props.url})`,
        maskSize: 'contain',
        WebkitMaskSize: 'contain',
        maskRepeat: 'no-repeat',
        WebkitMaskRepeat: 'no-repeat',
        flexShrink: 0
    }
}));

type Props = {
    className?: string;
    url: string;
    width?: number | string;
    height?: number | string;
    color?: string | null;
};

export const ColoredIcon = (props: Props) => {
    const { url, color = '#019ABC', width = 36, height = 36, className } = props;
    const classes = useStyles({ url, color, width, height });

    return <div className={classnames(classes.icon, className)} data-testid="colored-icon" />;
};
