import makeStyles from '@mui/styles/makeStyles';

const ColumnWidth = {
    max: 273,
    min: 200
};

const useStyles = makeStyles(
    (theme) => ({
        footer: {
            marginTop: theme.spacing(5),
            position: 'relative',
            ...theme.border({ radius: 0, width: 1, color: `${theme.palette.common.black}1E` }),
            ...theme.border({ radius: 0, width: 0, kind: 'bottom' }),
            borderTopLeftRadius: 28,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            [theme.breakpoints.up('sm')]: {
                paddingLeft: theme.spacing(0),
                paddingRight: theme.spacing(0),
                borderTopLeftRadius: 100,
                marginTop: theme.spacing(12)
            }
        },
        columnsContainer: {
            display: 'flex',
            marginTop: theme.spacing(4),
            flexDirection: 'column',
            [theme.breakpoints.up('md')]: {
                marginTop: theme.spacing(6),
                flexDirection: 'row'
            },
            [theme.breakpoints.up('lg')]: {
                marginTop: theme.spacing(8)
            }
        },
        logoContainer: {
            position: 'absolute',
            right: theme.spacing(4),
            top: theme.spacing(4),
            // for very small screens to avoid text going under the logo
            zIndex: -1,
            [theme.breakpoints.up('md')]: {
                position: 'static',
                padding: theme.spacing(0, 0, 0, 4)
            },
            [theme.breakpoints.up('lg')]: {
                padding: theme.spacing(0, 0, 0, 10)
            },
            '& > div': {
                width: 94
            }
        },
        logo: {
            color: theme.palette.primary[300],
            maxWidth: '100%'
        },
        columnItem: {
            display: 'flex',
            marginBottom: theme.spacing(2),
            color: theme.palette.body.main,
            width: '100%'
        },
        icon: {
            marginRight: theme.spacing(2),
            width: 24,
            height: 24
        },
        firstColumn: {
            minWidth: '20vw',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(2)
            },
            [theme.breakpoints.up('md')]: {
                marginLeft: theme.spacing(5),
                minWidth: ColumnWidth.min
            },
            [theme.breakpoints.up('xl')]: {
                marginLeft: theme.spacing(5),
                minWidth: ColumnWidth.max
            }
        },
        secondColumn: {
            minWidth: '20vw',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(2)
            },
            [theme.breakpoints.up('md')]: {
                minWidth: ColumnWidth.min,
                marginLeft: theme.spacing(6)
            },
            [theme.breakpoints.up('lg')]: {
                minWidth: ColumnWidth.max,
                marginLeft: theme.spacing(10)
            }
        },
        socialIcons: {
            display: 'flex',
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1)
            }
        },
        socialIconContainer: {
            marginRight: theme.spacing(1),
            height: '40px',
            width: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 6,

            '&:hover': {
                background: theme.palette.body['200']
            }
        },
        socialIcon: {
            height: '24px',
            width: '24px'
        },
        form: {
            display: 'flex',
            marginTop: theme.spacing(3)
        },
        lineBreak: {
            [theme.breakpoints.up('sm')]: {
                display: 'none'
            }
        },
        submitButton: {
            padding: theme.spacing(1.5, 2, 1.5, 2),
            marginLeft: theme.spacing(1),
            height: '56px',
            minWidth: '124px',
            background: theme.palette.grey['300']
        },
        contactLinks: {
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, 1fr)',
            gridAutoFlow: 'column',
            gridTemplateRows: ({ isCMED }: { isCMED: boolean }) =>
                `repeat(${isCMED ? '3' : '4'}, auto)`,
            marginTop: theme.spacing(1)
        },
        columnText: {
            ...theme.typography.textXSmall,
            color: theme.palette.body.main,

            '& > a': {
                color: theme.palette.body.main,
                textDecoration: 'none',
                ...theme.typography.textXSmall
            }
        },
        link: {
            textDecoration: 'none',
            marginTop: theme.spacing(1)
        },
        linkText: {
            padding: theme.spacing(1),
            paddingLeft: 0
        },
        verticalDivider: {
            margin: theme.spacing(1, 0, 2, 0),
            [theme.breakpoints.up('sm')]: {
                margin: theme.spacing(0, 2, 0, 2)
            },
            [theme.breakpoints.up('md')]: {
                margin: theme.spacing(0, 5, 0, 5)
            },
            [theme.breakpoints.up('lg')]: {
                margin: theme.spacing(0, 5, 0, 5)
            }
        },
        newsletterTitle: {
            margin: theme.spacing(1, 0, 1, 0),
            fontWeight: 600,
            color: theme.palette.primary.main
        },
        newsletterDescription: {
            marginTop: theme.spacing(1.5)
        },
        newsletterButton: {
            marginTop: theme.spacing(3),
            textTransform: 'none'
        }
    }),
    { index: 10, name: 'Footer' }
);

export default useStyles;
