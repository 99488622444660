import { BOOKING_URLS, SEODict } from 'common';
import { IntlShape } from 'react-intl';

const PathnameSEO = {
    home: '/',
    livestream: '/livestream',
    mediathekSumedUp: '/mediathek/summedup',
    mediathekWebup: '/mediathek/webup',
    guidelines: '/mediathek/guidelines',
    memberships: BOOKING_URLS.memberships
};

type PathnameSEO = (typeof PathnameSEO)[keyof typeof PathnameSEO];

const getSeoDictionary = (intl: IntlShape): Record<PathnameSEO, SEODict> => {
    const { formatMessage } = intl;
    const homeTitle = formatMessage({ id: 'catalog.seo-og.home.title' });
    const homeDescription = formatMessage({ id: 'catalog.seo-og.home.description' });

    const livestreamTitle = formatMessage({ id: 'catalog.seo-og.livestream.title' });
    const livestreamDescription = formatMessage({ id: 'catalog.seo-og.livestream.description' });

    const summedUpTitle = formatMessage({ id: 'catalog.seo-og.summedup.title' });
    const summedUpDescription = formatMessage({ id: 'catalog.seo-og.summedup.description' });

    const webupTitle = formatMessage({ id: 'catalog.seo-og.webup.title' });
    const webupDescription = formatMessage({ id: 'catalog.seo-og.webup.description' });

    const guidelinesTitle = formatMessage({ id: 'common.product-brand.guideline' });
    const guidelintesDescription = formatMessage({ id: 'catalog.skills.guideline-in-view' });

    const membershipsTitle = formatMessage({ id: 'booking.seo-og.booking-memberships.title' });
    const membershipsDescription = formatMessage({
        id: 'booking.seo-og.booking-memberships.description'
    });

    if (
        !homeTitle ||
        !homeDescription ||
        !livestreamTitle ||
        !livestreamDescription ||
        !summedUpTitle ||
        !summedUpDescription ||
        !webupTitle ||
        !webupDescription ||
        !guidelinesTitle ||
        !guidelintesDescription ||
        !membershipsTitle ||
        !membershipsDescription
    ) {
        throw new Error('SEO information is missing');
    }

    return {
        [PathnameSEO.home]: {
            title: homeTitle,
            description: homeDescription
        },
        [PathnameSEO.livestream]: {
            title: livestreamTitle,
            description: livestreamDescription
        },
        [PathnameSEO.mediathekSumedUp]: {
            title: summedUpTitle,
            description: summedUpDescription
        },
        [PathnameSEO.mediathekWebup]: {
            title: webupTitle,
            description: webupDescription
        },
        [PathnameSEO.guidelines]: {
            title: guidelinesTitle,
            description: guidelintesDescription
        },
        [PathnameSEO.memberships]: {
            title: membershipsTitle,
            description: membershipsDescription
        }
    };
};

export default getSeoDictionary;
