export { countries } from './CountriesSelect/CountriesSelect';
import Alert from './Alert/Alert';
import AnchorHeader from './AnchorHeader/AnchorHeader';
import Avatar from './Avatar/Avatar';
import CountriesSelect from './CountriesSelect/CountriesSelect';
import DateTimePicker from './DateTimePicker/DateTimePicker';
import DrawerMenu from './DrawerMenu/DrawerMenu';
import Dropdown from './Dropdown/Dropdown';
import FormikCountriesSelect from './FormikCountriesSelect/FormikCountriesSelect';
import FormikInputWrapper from './FormikInputWrapper/FormikInputWrapper';
import FormikSelect from './FormikSelect/FormikSelect';
import FormikSwitch from './FormikSwitch/FormikSwitch';
import HorizontalList from './HorizontalList/HorizontalList';
import Loader from './Loader/Loader';
import PhoneInput, { createYupPhoneValidationSchema } from './PhoneInput/PhoneInput';
import Select from './Select/Select';
import SearchBox from './SearchBox/SearchBox';
import Skeleton from './Skeleton/Skeleton';
import Snackbar from './Snackbar/Snackbar';
import Stepper from './Stepper/Stepper';
import ThemeProvider from './ThemeProvider/ThemeProvider';
import UncontrolledMenu from './UncontrolledMenu/UncontrolledMenu';
import UncontrolledPopover from './UncontrolledPopover/UncontrolledPopover';
import UploadButton from './UploadButton/UploadButton';
import VerticalList from './VerticalList/VerticalList';
import ButtonsSwitch from './ButtonsSwitch/ButtonsSwitch';

export * as ThemeTypes from './theme/theme.types';
export * from './images/index';

export * as ComponentsTypes from './utils/componentsTypes';
export * from './formik';
export * from './hooks';

export * from './Accordion';
export * from './Accordions';
export * from './Autocomplete';
export * from './Badge';
export * from './Button';
export * from './Checkbox';
export * from './Icon';
export * from './Link';
export * from './MultiCheckbox';
export * from './FormikAutocomplete';
export * from './FormikCheckbox';
export * from './FormikMultiCheckbox';
export * from './FormikPhoneInput';
export * from './FormikRadioGroup';
export * from './FormikTextField';
export * from './RadioGroup';
export * from './Radio';
export * from './RadioGroup';
export * from './Tab';
export * from './Tabs';
export * from './TextField';
export * from './SearchBox';
export * from './TitleTooltip';
export * from './Switch';
export * from './DateRangePicker';
export * from './Dialog';
export * from './SectionsMenu';
export * from './Whitespace';
export * from './Typography';

export type { AlertProps } from './Alert/Alert.types';
export type { DrawerMenuProps, MenuItem } from './DrawerMenu/DrawerMenu.types';
export {
    Alert,
    AnchorHeader,
    Avatar,
    CountriesSelect,
    createYupPhoneValidationSchema,
    DateTimePicker,
    DrawerMenu,
    FormikCountriesSelect,
    FormikInputWrapper,
    FormikSelect,
    FormikSwitch,
    HorizontalList,
    Loader,
    PhoneInput,
    Select,
    SearchBox,
    Skeleton,
    Snackbar,
    Stepper,
    ThemeProvider,
    UncontrolledMenu,
    UncontrolledPopover,
    UploadButton,
    VerticalList,
    Dropdown,
    ButtonsSwitch
};

export { default as mergeClasses } from './utils/mergeClasses';
export { default as noop } from './utils/noop';
export { default as getBasePath } from './utils/getBasePath';
export { default as alpha3CountryCodeToAlpha2CountryCode } from './utils/alpha3CountryCodeToAlpha2CountryCode';
export * from './utils/dates';

// source: https://stackoverflow.com/a/49725198/2443849
export type RequireOnlyOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
    {
        [K in Keys]-?: Required<Pick<T, K>> & Partial<Record<Exclude<Keys, K>, undefined>>;
    }[Keys];
