import React, { useRef } from 'react';
import { Box } from '@mui/material';
import classNames from 'classnames';
import { Icon, Typography } from 'components';

import {
    BrandIcon,
    Types,
    formatters,
    AspectRatio,
    FreeBadge,
    showFreeBadge,
    useMembershipContext,
    useIntersectionObserver
} from '../../';
import { ThumbnailProps } from './Thumbnail.types';
import useStyles from './Thumbnail.styles';
import { ColoredIcon } from '../ColoredIcon/ColoredIcon';
import { buildImageURL } from '../../../../catalog/src/utils/buildImageURL';
import {
    VIDEO_THUMBNAIL_HEIGHT,
    VIDEO_THUMBNAIL_WIDTH
} from '../../../../catalog/src/components/MediathekLectureItem/VideoThumbnail';

const Thumbnail = (props: ThumbnailProps) => {
    const {
        brand,
        contentId,
        duration: durationProp,
        fileName,
        thumbnailUrl,
        contentType,
        specialityIconUrl,
        title,
        headerUrl,
        size = 'normal',
        freeBadgeProps,
        showDuration = true,
        contentIsFree = false,
        parentContentIsFree = false,
        onVideoView,
        onCourseView
    } = props;
    const { isMember } = useMembershipContext();

    const videoContainerRef = useRef<HTMLDivElement | null>(null);
    useIntersectionObserver(videoContainerRef, {}, () => onVideoView && onVideoView(contentId));

    const courseContainerRef = useRef<HTMLDivElement | null>(null);
    useIntersectionObserver(courseContainerRef, {}, () => onCourseView && onCourseView(contentId));

    const classes = useStyles(props);
    const duration =
        brand !== Types.ProductBrand.Guideline && durationProp
            ? formatters.formatContentDuration(durationProp)
            : null;

    const thumbnailUrlCompressed =
        thumbnailUrl &&
        buildImageURL(thumbnailUrl, {
            width: VIDEO_THUMBNAIL_WIDTH,
            height: VIDEO_THUMBNAIL_HEIGHT
        });

    if (contentType === Types.ContentType.Course) {
        return (
            <Box
                className={classNames(classes.imageContainer, {
                    [classes.smallImageContainer]: size === 'small'
                })}
                ref={courseContainerRef}
            >
                <Box
                    className={classNames({
                        [classes.smallCourseContainerImage]: size === 'small'
                    })}
                >
                    {specialityIconUrl ? (
                        <ColoredIcon
                            width={96}
                            height={96}
                            className={classes.topic}
                            url={specialityIconUrl}
                        />
                    ) : (
                        <Icon
                            className={classNames(classes.topic, {
                                [classes.smallCourseTopic]: size === 'small'
                            })}
                            icon="topicGeneric"
                        />
                    )}
                </Box>
                {size === 'normal' && (
                    <>
                        <Box className={classes.titleContainer}>
                            <Typography
                                title={title}
                                variant="displayXSmallBold"
                                className={classes.title}
                            />
                            {brand && (
                                <BrandIcon
                                    classes={{
                                        image: classes.brandImage,
                                        brandName: classes.brandName
                                    }}
                                    brand={brand}
                                />
                            )}
                        </Box>
                    </>
                )}
                {showFreeBadge({ contentIsFree, parentContentIsFree, isMember }) && (
                    <FreeBadge classes={{ freeBadge: classes.freeBadge }} {...freeBadgeProps} />
                )}
            </Box>
        );
    } else if (contentType === Types.ContentType.Webinar) {
        return (
            <Box
                className={classNames(classes.webupContainer, {
                    [classes.smallImageContainer]: size === 'small'
                })}
                ref={courseContainerRef}
            >
                <Box>
                    {headerUrl ? (
                        <img
                            height="100%"
                            width="100%"
                            alt=""
                            className={classNames(classes.webupImage, {
                                [classes.webupContainerSmall]: size === 'small'
                            })}
                            src={headerUrl}
                        />
                    ) : (
                        brand && (
                            <BrandIcon
                                brand={brand}
                                classes={{
                                    container: classNames(classes.webupImage, {
                                        [classes.webupContainerSmall]: size === 'small'
                                    }),
                                    brandName: classes.brandNameWebinar,
                                    image: classes.brandImageWebinar
                                }}
                            />
                        )
                    )}
                </Box>
                {showFreeBadge({ contentIsFree, parentContentIsFree, isMember }) && (
                    <FreeBadge classes={{ freeBadge: classes.freeBadge }} {...freeBadgeProps} />
                )}
            </Box>
        );
    }
    return (
        <div className={classes.playerContainer} ref={videoContainerRef}>
            <AspectRatio>
                {brand === Types.ProductBrand.Guideline ? (
                    <div className={classes.fallbackContainer}>
                        <Icon className={classes.guidelineIcon} icon="guidelines" />
                    </div>
                ) : thumbnailUrlCompressed ? (
                    <img
                        alt={fileName || ''}
                        src={thumbnailUrlCompressed}
                        width="100%"
                        height="100%"
                        style={{ borderRadius: 3 }}
                    />
                ) : (
                    <div className={classes.fallbackContainer} />
                )}
            </AspectRatio>
            {duration && showDuration && (
                <Typography className={classes.duration} title={duration} />
            )}
            {showFreeBadge({ contentIsFree, parentContentIsFree, isMember }) && (
                <FreeBadge classes={{ freeBadge: classes.freeBadge }} {...freeBadgeProps} />
            )}
        </div>
    );
};

export default Thumbnail;
