import { PageLocalesPaths } from './contexts';

// use env variable for easier local testing of different domains
export const DEFAULT_DOMAIN = (process.env.NEXT_PUBLIC_LOCAL_ENV_DOMAIN || 'de') as Domain;

export const LOCALE_TO_COUNTRY_CODE_3: Record<Locale, CountryCode3> = {
    'de-DE': 'DEU',
    'de-CH': 'CHE',
    'de-AT': 'AUT',
    'fr-CH': 'CHE',
    'en-GB': 'GBR'
};

export const DOMAIN_TO_LOCALE: Record<Domain, Locale> = {
    de: 'de-DE',
    at: 'de-AT',
    ch: 'de-CH',
    uk: 'en-GB'
};

export const COUNTRY_CODE_TO_LOCALE: Record<CountryCode, Locale> = {
    de: 'de-DE',
    at: 'de-AT',
    ch: 'de-CH',
    gb: 'en-GB'
};

export const LANGUAGE_TO_LOCALE: Record<Language, Array<Locale>> = {
    de: ['de-DE', 'de-AT', 'de-CH'],
    fr: ['fr-CH'],
    en: ['en-GB']
};

export const LOCALE_TO_CALENDAR_LANG: Record<Locale, Language> = {
    'de-AT': 'de',
    'de-CH': 'de',
    'de-DE': 'de',
    'en-GB': 'en',
    'fr-CH': 'fr'
};

export const LOCALE_TO_DOMAIN: Record<Locale, Domain> = {
    'de-DE': 'de',
    'de-AT': 'at',
    'de-CH': 'ch',
    'en-GB': 'uk',
    'fr-CH': 'ch'
};

export const LOCALE_TO_DOMAIN_FOR_LINKS: Record<Locale, DomainForLinks> = {
    'de-DE': 'de',
    'de-AT': 'at',
    'de-CH': 'de-CH',
    'en-GB': 'uk',
    'fr-CH': 'fr-CH'
};

export const COUNTRY_CODE_3_TO_LOCALE: Record<CountryCode3, Locale> = {
    DEU: 'de-DE',
    AUT: 'de-AT',
    CHE: 'de-CH',
    GBR: 'en-GB'
};

export const LOCALE_TO_COUNTRY_CODE: Record<Locale, CountryCode> = {
    'de-DE': 'de',
    'de-CH': 'ch',
    'de-AT': 'at',
    'fr-CH': 'ch',
    'en-GB': 'gb'
};

export const DOMAIN_TO_COUNTRY_CODE: Record<Domain, CountryCode> = {
    de: 'de',
    at: 'at',
    ch: 'ch',
    uk: 'gb'
};

export const ALLOWED_LOCALES_BY_COUNTRY_CODE: Record<CountryCode, Array<Locale>> = {
    de: ['de-DE'],
    at: ['de-AT'],
    ch: ['de-CH', 'fr-CH'],
    gb: ['en-GB']
};

export const ALLOWED_LOCALES_BY_COUNTRY_CODE_3: Record<CountryCode3, Array<Locale>> = {
    DEU: ['de-DE'],
    AUT: ['de-AT'],
    CHE: ['de-CH', 'fr-CH'],
    GBR: ['en-GB']
};

export const COUNTRY_CODE_3_TO_DOMAIN: Record<CountryCode3, Domain> = {
    DEU: 'de',
    AUT: 'at',
    CHE: 'ch',
    GBR: 'uk'
};

export const COUNTRY_CODE_3_TO_COUNTRY_CODE: Record<CountryCode3, CountryCode> = {
    DEU: 'de',
    AUT: 'at',
    CHE: 'ch',
    GBR: 'gb'
};

export const LOCALE_TO_FULL_DOMAIN: Record<Locale, string> = {
    'de-DE': 'fomf.de',
    'de-AT': 'fomf.at',
    'de-CH': 'fomf.ch',
    'fr-CH': 'fomf.ch',
    'en-GB': 'cmed.org.uk'
};

export const BASEPATH_TO_DOMAIN: Record<Locale, string> = {
    'de-DE': '',
    'de-AT': '',
    'de-CH': '',
    'fr-CH': '/fr-CH',
    'en-GB': ''
};

export const LOCALES: Array<Locale> = ['de-DE', 'de-AT', 'de-CH', 'fr-CH', 'en-GB'];
export const COUNTRY_CODES: Array<CountryCode> = ['de', 'at', 'ch', 'gb'];
export const COUNTRY_CODES_3: Array<CountryCode3> = ['DEU', 'AUT', 'CHE', 'GBR'];
export const DOMAINS: Array<Domain> = ['de', 'at', 'ch', 'uk'];

export const DEFAULT_COUNTRY_CODE = DOMAIN_TO_COUNTRY_CODE[DEFAULT_DOMAIN];

export const DEFAULT_LOCALE = DOMAIN_TO_LOCALE[DEFAULT_DOMAIN];

export const TERMS_AND_CONDITIONS_LINKS: Record<DomainForLinks, string> = {
    de: '/agb',
    at: '/agb',
    'fr-CH': '/conditions',
    'de-CH': '/agb',
    uk: '/terms-and-conditions'
};

export const PRIVACY_LINKS: Record<DomainForLinks, string> = {
    de: '/datenschutz',
    'de-CH': '/datenschutz',
    'fr-CH': '/protections-des-donnees',
    at: '/datenschutz',
    uk: '/privacy'
};

export const SUPPORT_EMAIL_ADDRESS: Record<Domain, string> = {
    de: 'info@fomf.de',
    at: 'info@fomf.at',
    ch: 'info@fomf.ch',
    uk: 'contact@cmed.org.uk'
};

export const MEMBERSHIP_COUNTRY_TO_ALLOWED_DOMAINS: Record<CountryCode3, Array<Domain>> = {
    DEU: ['de'],
    AUT: ['at'],
    CHE: ['ch'],
    GBR: []
};

export const SITE_DOMAIN_TO_ALLOWED_PUBLISHED_DOMAINS: Record<Domain, Array<string>> = {
    de: ['DE'],
    at: ['AT'],
    ch: ['CH'],
    uk: ['UK']
};

export const HIDE_TIMEOUT = 10000;

export const WITHOUT_MEMBERSHIP_ID = 'WITHOUT-MEMBERSHIP-ID-UUID-';

export const LOCALE_TO_TIMEZONE: Record<Locale, string> = {
    'de-AT': 'Europe/Vienna',
    'de-CH': 'Europe/Zurich',
    'de-DE': 'Europe/Berlin',
    'fr-CH': 'Europe/Zurich',
    'en-GB': 'Europe/London'
};

export const CONDITIONS_PAGE_LOCALES: PageLocalesPaths = {
    'de-DE': '/agb',
    'en-GB': '/terms-and-conditions',
    'fr-CH': '/conditions',
    'de-AT': '/agb',
    'de-CH': '/agb'
};

export const PRIVACY_PAGE_LOCALES: PageLocalesPaths = {
    'de-DE': '/datenschutz',
    'en-GB': '/privacy',
    'fr-CH': '/protections-des-donnees',
    'de-AT': '/datenschutz',
    'de-CH': '/datenschutz'
};
