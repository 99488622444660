import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
    (theme) => ({
        title: {
            fontWeight: 700,
            fontSize: 36,
            lineHeight: 54 / 36,
            color: theme.palette.primary.main,
            marginBottom: theme.spacing(3)
        },
        list: {
            display: 'flex',
            flexWrap: 'wrap',
            margin: theme.spacing(-2),
            padding: theme.spacing(1),

            [theme.breakpoints.up('md')]: {
                flexWrap: 'nowrap',
                justifyContent: 'center'
            }
        },
        listItem: {
            margin: theme.spacing(1),
            padding: theme.spacing(1),
            flexBasis: '100%',

            [theme.breakpoints.up('md')]: {
                flexBasis: '33.333333%'
            }
        }
    }),
    { name: 'TopUpsList' }
);

export default useStyles;
