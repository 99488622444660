const formatEntityWithDescription = (
    entity:
        | {
              description: Array<{
                  locale: string;
                  value: string;
                  isSuffix?: boolean;
              }>;
          }
        | null
        | undefined,
    props: {
        locale: string;
    }
): string => {
    if (!entity) {
        return '';
    }

    const translations = entity.description;
    const translation = translations.find((item) => item.locale.includes(props.locale));
    if (translation && 'isSuffix' in translation && translation.isSuffix === true) {
        return `, ${translation.value}`;
    }
    return translation?.value || '';
};

export default formatEntityWithDescription;
