import { useCallback, useState } from 'react';

const useSimpleForm = <Values extends Record<string, unknown>>(
    initialValues: Values
): [Values, (state: Partial<Values>) => void] => {
    const [formState, setFormState] = useState(initialValues);
    const updateFormState = useCallback(
        (state: Partial<Values>) => {
            setFormState({
                ...formState,
                ...state
            });
        },
        [formState]
    );

    return [formState, updateFormState];
};

export default useSimpleForm;
