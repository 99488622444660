import React, { MouseEventHandler, useEffect, useRef, useState } from 'react';
import { Stepper, Step, StepLabel, StepConnector, Hidden, Container, Box } from '@mui/material';
import classNames from 'classnames';

import Button from '../Button/Button';
import { useStepperConnectorClasses, useStyles } from './Stepper.styles';
import { Typography } from '..';
import StepIcon from './StepIcon';
import { StepperProps } from './StepIcon.types';

const CustomStepper = (props: StepperProps) => {
    const {
        stepIds,
        stepsContent,
        backLocaleId,
        nextLocaleId,
        submitLocaleId,
        step,
        onStepChange,
        stepIcons,
        isMultipleStepsForm = false,
        loading,
        className,
        classes: classesProp = {},
        contentHeader,
        preStepsElement = null,
        preStepsMobileElement = null,
        ...others
    } = props;
    const isNonControlledMode = typeof step === 'undefined';
    const classes = useStyles();
    const stepperConnectorClasses = useStepperConnectorClasses();
    const [currentStep, setCurrentStep] = useState(step || 0);
    const isLastStep = currentStep === stepIds.length - 1;
    const buttonsAvailable = backLocaleId || nextLocaleId || submitLocaleId;

    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (typeof step === 'number' && step !== currentStep) {
            setCurrentStep(step);
            if (typeof window !== 'undefined' && containerRef.current) {
                const scrollPosition = containerRef.current.scrollTop;
                window.scrollTo(0, scrollPosition);
            }
        }
    }, [step]);

    const changeStep = (nextStep: number) => {
        isNonControlledMode && setCurrentStep(nextStep);
        onStepChange && onStepChange(nextStep);
    };

    const handleNext: MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault();
        if (!isLastStep) {
            const nextStep = currentStep + 1;
            changeStep(nextStep);
        }
    };

    const handleBack: MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault();
        if (currentStep > 0) {
            const previousStep = currentStep - 1;
            changeStep(previousStep);
        }
    };

    return (
        <div className={classNames(classes.container, className)} ref={containerRef}>
            <Box className={classesProp.stepperContainer}>
                <Container>
                    <Hidden mdDown implementation="css">
                        <Box className={classes.wrappedDesktopContainer}>
                            <Stepper
                                nonLinear
                                activeStep={currentStep}
                                classes={{ root: classes.root }}
                                className={classesProp.stepper}
                                {...others}
                            >
                                {preStepsElement}
                                {stepIds.map((stepId, index) => (
                                    <Step
                                        className={classNames(classes.step, {
                                            [classes.firstStep]: index === 0,
                                            [classes.firstStepExtraSpace]:
                                                index === 0 && preStepsElement != null
                                        })}
                                        key={stepId}
                                    >
                                        {index > 0 ? (
                                            <StepConnector classes={stepperConnectorClasses} />
                                        ) : null}
                                        <StepLabel
                                            onClick={() => changeStep(index)}
                                            className={classes.stepLabelContainer}
                                            classes={{
                                                labelContainer: classes.stepLabelLabelContainer
                                            }}
                                            StepIconComponent={
                                                stepIcons
                                                    ? () => {
                                                          const icon = stepIcons[index];
                                                          return icon ? (
                                                              <StepIcon
                                                                  icon={icon}
                                                                  data-testid={`step-icon-${index}`}
                                                              />
                                                          ) : (
                                                              <>{index}</>
                                                          );
                                                      }
                                                    : () => (
                                                          <div
                                                              data-testid={`step-icon-${index}`}
                                                              className={classNames(classes.ball, {
                                                                  [classNames(
                                                                      classes.currentBall,
                                                                      classesProp.currentBall
                                                                  )]: currentStep === index
                                                              })}
                                                          >
                                                              {index + 1}
                                                          </div>
                                                      )
                                            }
                                        >
                                            <Typography
                                                className={classNames(classes.stepLabel, {
                                                    [classNames(
                                                        classes.currentStepLabel,
                                                        classesProp.currentStepLabel
                                                    )]: currentStep === index
                                                })}
                                                localeId={stepId}
                                            />
                                        </StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Box>
                    </Hidden>
                    <Hidden mdUp implementation="css">
                        <Box display="flex" alignItems="center">
                            {preStepsMobileElement}
                            <Typography
                                className={classNames(classes.stepLabel, classesProp.stepper)}
                                localeId="common.stepper.mobile-title"
                                localeValues={{
                                    step: (step || 0) + 1,
                                    numberOfSteps: stepIds.length
                                }}
                            />
                        </Box>
                    </Hidden>
                </Container>
            </Box>
            {stepsContent && stepsContent.length > 0 && (
                <div className={classesProp.content} data-testid="step-content-container">
                    <div>
                        {contentHeader}
                        {isMultipleStepsForm
                            ? stepsContent.map((stepContent, index) => {
                                  return (
                                      <div
                                          data-testid={`step-content-${index}`}
                                          key={index}
                                          className={`${
                                              currentStep !== index ? classes.hidden : ''
                                          }`}
                                      >
                                          {stepContent}
                                      </div>
                                  );
                              })
                            : stepsContent[currentStep]}
                        {buttonsAvailable && (
                            <div className={classes.buttonContainer}>
                                {backLocaleId && (
                                    <Button
                                        disabled={currentStep === 0}
                                        onClick={handleBack}
                                        className={classes.button}
                                        color="secondary"
                                        localeId={backLocaleId}
                                        data-testid="back-button"
                                    />
                                )}
                                {isLastStep && submitLocaleId && (
                                    <Button
                                        type="submit"
                                        disabled={loading}
                                        color="secondary"
                                        localeId={submitLocaleId}
                                        data-testid="submit-button"
                                    />
                                )}
                                {!isLastStep && nextLocaleId && (
                                    <Button
                                        disabled={isLastStep}
                                        onClick={handleNext}
                                        className={classes.button}
                                        localeId={nextLocaleId}
                                        data-testid="next-button"
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CustomStepper;
