const setCookie = (name: string, value: string, sessionCookie = false) => {
    let expires = '';
    if (!sessionCookie) {
        const date = new Date();
        date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
        expires = 'expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + value + ';' + expires + ';path=/';
};

export default setCookie;
