import React, { useEffect, useState } from 'react';
import { CalendarProps } from './Calendar.types';
import useStyles from './Calendar.styles';
import dayjs, { Dayjs } from 'dayjs';
import { Box } from '@mui/material';
import Month from '../Month/Month';
import { PickedDateType, PickerVariant } from '../DateRangePicker.types';
import { isSinglePicked } from '../DateRangePicker.utils';
import { DateFormat } from '../../utils/dates';

export const createHandleMonthChange =
    (
        year: number,
        month: number,
        setYear: (year: number) => void,
        setMonth: (month: number) => void
    ) =>
    (forward: boolean) => {
        const newMonth = month + (forward ? 1 : -1);
        if (newMonth < 0) {
            setMonth(newMonth + 12);
            setYear(year - 1);
        } else if (newMonth > 11) {
            setMonth(newMonth - 12);
            setYear(year + 1);
        } else {
            setMonth(newMonth);
        }
    };

// 1 for Monday in 0-6 system where 0 is Sunday
// In future it could be set according to locale if required
const weekStartDay = 1;

const Calendar = (props: CalendarProps) => {
    const { minDate, maxDate, startDate, variant, value, onChange } = props;
    const currentDate = (startDate || dayjs()).startOf('day');
    const [year, setYear] = useState(currentDate.year());
    const [month, setMonth] = useState(currentDate.month());

    const nextMonth = month === 11 ? 0 : month + 1;
    const nextYear = month === 11 ? year + 1 : year;

    const handlePickedDateChange = (date: { [key in PickedDateType]?: Dayjs | null }) => {
        const newValue = {
            ...value,
            ...date
        };

        onChange(newValue);
    };

    const handleMonthChange = createHandleMonthChange(year, month, setYear, setMonth);

    useEffect(() => {
        const valueDate = isSinglePicked(value) ? value.single : value.start;
        if (!valueDate) {
            return;
        }

        const valueMonth = valueDate.month();
        const valueYear = valueDate.year();

        const currentMonthString = month >= 9 ? `${month + 1}` : `0${month + 1}`;
        const currentMonthDate = dayjs(`01/${currentMonthString}/${year}`, DateFormat.date);
        const nextMonthDate = variant === PickerVariant.RANGE && currentMonthDate.add(1, 'month');

        const isSameMonthAsCurrent = valueDate.isSame(currentMonthDate, 'month');
        const isSameMonthAsNext = nextMonthDate && valueDate.isSame(nextMonthDate, 'month');

        if (isSameMonthAsCurrent || isSameMonthAsNext) {
            return;
        }

        setMonth(valueMonth);
        setYear(valueYear);
    }, [value]);

    const classes = useStyles(props);

    return (
        <Box className={classes.root}>
            {variant === PickerVariant.SINGLE ? (
                <Month
                    currentDate={currentDate}
                    year={year}
                    month={month}
                    onMonthChange={handleMonthChange}
                    minDate={minDate}
                    maxDate={maxDate}
                    pickedDate={value}
                    monthPosition="single"
                    onPickedDateChange={handlePickedDateChange}
                    weekStartDay={weekStartDay}
                />
            ) : (
                <>
                    <Month
                        currentDate={currentDate}
                        year={year}
                        month={month}
                        onMonthChange={handleMonthChange}
                        minDate={minDate}
                        maxDate={maxDate}
                        pickedDate={value}
                        monthPosition="start"
                        onPickedDateChange={handlePickedDateChange}
                        className={classes.monthStart}
                        weekStartDay={weekStartDay}
                    />
                    <Month
                        currentDate={currentDate}
                        year={nextYear}
                        month={nextMonth}
                        onMonthChange={handleMonthChange}
                        minDate={minDate}
                        maxDate={maxDate}
                        pickedDate={value}
                        monthPosition="end"
                        onPickedDateChange={handlePickedDateChange}
                        weekStartDay={weekStartDay}
                        isNextMonth
                    />
                </>
            )}
        </Box>
    );
};

export default Calendar;
