/**
 * Splits an array into chunks of the specified size.
 */
const chunkArray = <T>(array: Array<T>, chunkSize: number) => {
    const chunks = [];
    if (chunkSize < 0) {
        throw new Error('Chunk size cannot be negative');
    }
    for (let i = 0; i < array.length; i += chunkSize) {
        chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
};

export default chunkArray;
