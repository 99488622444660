import isDomainChFr from './isDomainChFr';

const removeDoubleSlashes = (url: string): string => {
    return url.replace(/([^:/]|^)\/\/+/g, '$1/');
};

/**
 * TODO: arsinclair: remove this function when packages are merged.
 * @deprecated
 */
const createURL = (
    url: string,
    destinationPackage?: unknown,
    createAbsolute?: boolean
): { url: string; type: 'absolute' } => {
    if (url.startsWith('http://') || url.startsWith('https://')) {
        return { url, type: 'absolute' };
    }

    const language = isDomainChFr() ? (url.startsWith('/') ? '/fr-CH' : '/fr-CH/') : '';

    const isWindow = typeof window !== 'undefined';

    const origin = isWindow ? window.location.origin : '';

    const localPort = isWindow && window.location.port;
    const localOrigin = isWindow && window.location.origin;
    const localURL = !!localPort && `${localOrigin}/${language}${url}`;

    const absoluteURL = localURL || `${origin}/${language}${url}`;

    if (createAbsolute) {
        return { url: removeDoubleSlashes(absoluteURL), type: 'absolute' };
    }

    if (localURL) {
        return { url: removeDoubleSlashes(localURL), type: 'absolute' };
    }

    return { url: removeDoubleSlashes(absoluteURL), type: 'absolute' };
};

export default createURL;
