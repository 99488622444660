import { COUNTRY_CODES, COUNTRY_CODES_3, DOMAINS, LOCALES } from '../constants';

export const isDomain = (value: string): value is Domain => DOMAINS.includes(value as Domain);

export const isLocale = (value: string): value is Locale => LOCALES.includes(value as Locale);

export const isCountryCode3 = (value: string): value is CountryCode3 =>
    COUNTRY_CODES_3.includes(value as CountryCode3);

export const isCountryCode = (value: string): value is CountryCode =>
    COUNTRY_CODES.includes(value as CountryCode);
