import React from 'react';
import {
    Addon,
    AddonLatestVideo,
    AddonCardContent,
    buildURLWithParams,
    CATALOG_URLS,
    getAddonCatalogLink
} from '../../../';
import { Box, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Button } from 'components';

const useStyles = makeStyles(
    (theme) => ({
        container: {
            marginBottom: theme.spacing(2),

            '&:last-child': {
                marginBottom: 0
            }
        },
        divider: {
            marginBottom: theme.spacing(1.5)
        },
        contentTitle: {
            marginLeft: theme.spacing(-1),
            marginBottom: theme.spacing(0.5),
            padding: theme.spacing(0.5, 1),
            transition: theme.transitions.create('background-color'),

            '&:hover': {
                background: theme.palette.body[200],
                boxShadow: 'none',

                '& $titleIcon': {
                    opacity: 1
                }
            }
        },

        titleIcon: {
            opacity: 0,
            transition: theme.transitions.create('opacity'),
            color: theme.palette.primary.main,
            width: 17,
            height: 17
        },
        titleEndIcon: {
            marginLeft: theme.spacing(1)
        },
        startButtonContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: theme.spacing(1)
        },
        startButton: {
            color: theme.palette.common.white,
            backgroundColor: '#689F38'
        }
    }),
    // index to override Button styles
    { name: 'AddonsPopoverItem', index: 2 }
);

type Props = {
    content: Addon;
    latestVideo: AddonLatestVideo;
};

const AddonsPopoverItem = (props: Props) => {
    const { latestVideo, content } = props;
    const classes = useStyles(props);
    const currentSchedule = latestVideo;
    const addonCatalogLink = getAddonCatalogLink(content.contentId, currentSchedule?.contentId);
    const addonDashboardLink = buildURLWithParams(CATALOG_URLS.syrot, [content.contentId]);

    return (
        <Box className={classes.container}>
            <Divider className={classes.divider} />
            <Button
                href={addonDashboardLink}
                variant="text"
                typographyProps={{ color: 'primary', variant: 'h6' }}
                classes={{
                    button: classes.contentTitle,
                    icon: classes.titleIcon,
                    endIcon: classes.titleEndIcon
                }}
                endIcon="caretRight"
                iconStrokeWidth={3}
            >
                {content.title}
            </Button>
            {currentSchedule ? (
                <AddonCardContent
                    contentId={content.contentId}
                    duration={currentSchedule.source?.duration}
                    time={currentSchedule.contentProgress?.time}
                    videoId={currentSchedule.contentId}
                    brand={content.brand}
                    contentType={currentSchedule.contentType}
                    specialityIconUrl={currentSchedule.product?.specialityIconUrl}
                    thumbnailUrl={currentSchedule.source?.thumbnail}
                    title={content.title}
                    subtitle={content.subtitle}
                    completed={currentSchedule.contentProgress?.completed}
                    endDateTime={currentSchedule.endDateTime}
                    replacementDate={currentSchedule.replacementDate}
                />
            ) : (
                <Box className={classes.startButtonContainer}>
                    <Button
                        href={addonCatalogLink}
                        variant="text"
                        localeId="common.addon-card.start"
                        className={classes.startButton}
                        endIcon="arrowRight"
                    />
                </Box>
            )}
        </Box>
    );
};

export default AddonsPopoverItem;
