import { parseDBDate } from 'components';
import { Dayjs } from 'dayjs';

/**
 * Useful to format numbers with 2 digits adding a 0 at the beginning when the number is smaller
 * than 10.
 * Examples:
 * 1 -> "01"
 * 9 -> "09"
 * 12 -> "12"
 */
const format2Precision = (digit: number): string => {
    if (!digit) {
        return '00';
    } else if (digit < 10) {
        return `0${digit}`;
    }
    return `${digit}`;
};

const MINUTES_IN_1_HOUR = 60;
const SECONDS_IN_1_MINUTE = 60;
const HOURS_IN_DAY = 24;

/**
 * Receives start and end dates and return an user friendly formatted string.
 * @example formatDuration('2022-02-10T10:00:00Z', '2022-02-10T10:15:00Z');
 * // returns "15:00"
 */
const formatDuration = (
    startDate: Dayjs | string | null | undefined,
    endDate: Dayjs | string | null | undefined
): string | null => {
    const startDateDayjs = parseDBDate(startDate);
    const endDateDayjs = parseDBDate(endDate);

    if (endDateDayjs.diff(startDateDayjs, 'minutes') < MINUTES_IN_1_HOUR * HOURS_IN_DAY) {
        const minutes = Number(endDateDayjs.diff(startDateDayjs, 'minutes')) % MINUTES_IN_1_HOUR;
        const seconds = Number(endDateDayjs.diff(startDateDayjs, 'seconds') % SECONDS_IN_1_MINUTE);
        const hoursDiff = endDateDayjs.diff(startDateDayjs, 'hours');
        const hours = hoursDiff < HOURS_IN_DAY ? hoursDiff : '';
        return `${hours ? `${format2Precision(hours)}:` : ''}${format2Precision(
            minutes
        )}:${format2Precision(seconds)}`;
    }
    return null;
};

export default formatDuration;
