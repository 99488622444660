import { useEffect } from 'react';
import { useRouter } from 'next/router';
import trackPageView from './trackPageView';
import getCategory from './getCategory';
import { useCommonTrackedProperties } from './common-tracked-properties/useCommonTrackedProperties';

export default function usePageTracking() {
    const router = useRouter();
    const commonProperties = useCommonTrackedProperties();

    const trackPage = async (url: string) => {
        const categoryResult = getCategory(url);
        trackPageView(categoryResult, commonProperties);
    };

    useEffect(() => {
        trackPage(router.basePath + router.pathname);

        const handleRouteChange = (url: string) => {
            trackPage(url);
        };

        router.events.on('routeChangeComplete', handleRouteChange);

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, []);
}
