import { getLanguageFromLocale } from '.';
import { Types } from '..';

const getSpecialitiesByLocale = (specialities: Array<Types.SpecialityR>, shortLocale: string) => {
    return specialities
        .filter((speciality) => {
            const { translations } = speciality.description;
            if (translations !== undefined && translations !== null && translations.length > 0) {
                const emptyTranslations = translations.filter(
                    (translation: { value: string }) => translation.value === ''
                );
                if (emptyTranslations.length === 0) {
                    return speciality;
                }
            }
        })
        .map((speciality) => {
            const { translations } = speciality.description;
            return {
                id: speciality.code,
                label: translations?.find(
                    (translation) => getLanguageFromLocale(translation.locale) === shortLocale
                )?.value
            };
        });
};

export default getSpecialitiesByLocale;
