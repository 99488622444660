import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
    (theme) => ({
        button: {
            boxShadow: 'none',
            borderRadius: 6,
            height: 42,
            padding: theme.spacing(2, 3),

            '&:disabled': {
                pointerEvents: 'all !important',
                cursor: 'not-allowed'
            }
        },
        uppercase: {
            textTransform: 'uppercase'
        },
        primary: {
            color: theme.palette.offWhite.main,

            backgroundColor: theme.palette.primary.main,
            '&:hover&:not($disabled)&:not($minimal)': {
                backgroundColor: theme.palette.primary.dark,
                boxShadow: 'none'
            },

            '&:focus': {
                boxShadow: `0px 0px 0px 8px ${theme.palette.line.main}`
            }
        },
        secondary: {
            color: theme.palette.common.white,

            backgroundColor: theme.palette.secondary.main,
            '&:hover': {
                backgroundColor: `${theme.palette.primary['600']}`,
                boxShadow: 'none'
            },

            '&:focus': {
                boxShadow: `0px 0px 0px 8px ${theme.palette.secondary.light}`
            },

            '& $onlyIcon': {
                color: theme.palette.common.white
            }
        },
        disabled: {
            backgroundColor: theme.palette.common.white,

            '&$primary, &$secondary': {
                '& $icon, &$icon$onlyIcon': {
                    color: theme.palette.common.black
                }
            },
            '&$outlined&:hover': {
                color: 'rgba(0, 0, 0, 0.26)',
                backgroundColor: theme.palette.common.white
            },
            '&$text&:hover': {
                color: 'rgba(0, 0, 0, 0.26)',
                backgroundColor: 'transparent'
            }
        },
        icon: {
            width: 24,
            height: 24
        },
        buttonLabel: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        contained: {},
        text: {},
        root: {},
        error: {},
        info: {},
        success: {},
        warning: {},
        inherit: {},

        startIcon: {
            margin: `0 12px 0 0 `
        },

        endIcon: {
            margin: `0 0 0 12px`
        },

        onlyIcon: {
            margin: 0
        },
        custom: {
            background: 'unset'
        },
        small: {
            height: 32,
            '&> *': {
                fontSize: 14
            }
        },
        medium: {
            height: 36,
            '&> *': {
                fontSize: 15
            }
        },
        large: {
            height: 42,
            '&> *': {
                fontSize: 16
            }
        },
        xLarge: {
            height: 48,
            '&> *': {
                fontSize: 17
            }
        },
        outlined: {},
        ghost: {
            background: 'transparent',
            border: 'none',
            '&:hover': {
                background: 'rgba(0, 0, 0, 0.04)'
            }
        },
        gray: {
            color: '#080808',
            backgroundColor: '#F4F7F9',
            '&:hover&:not($disabled)&:not($minimal)': {
                backgroundColor: '#E8EDF2',
                boxShadow: 'none'
            },
            '&:active&:not($disabled)&:not($minimal)': {
                backgroundColor: '#DCE3EA'
            },
            '&:focus': {
                boxShadow: '0px 0px 0px 8px rgba(244, 247, 249, 0.5)'
            }
        },
        minimal: {
            background: 'unset',
            '&:hover': {
                backgroundColor: 'transparent'
            }
        }
    }),
    { name: 'Button', index: 1 }
);

export default useStyles;
