import { MenuItem, SelectProps as MUISelectProps } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { TextField } from '../TextField';
import { TextFieldProps } from '../TextField/TextField.types';
import type { SelectableOption } from '../utils/componentsTypes';
import { getOptionsWithTranslationsOrLabel } from '../utils/translationOptionsVisibility';

type Props<ID extends string | null = string> = Omit<
    MUISelectProps,
    'onChange' | 'label' | 'multiple' | 'value'
> &
    Pick<
        TextFieldProps,
        | 'error'
        | 'helperText'
        | 'required'
        | 'icon'
        | 'hideClearIcon'
        | 'size'
        | 'label'
        | 'onClear'
    > & {
        value?: ID | undefined;
        onChange?: (value: ID) => void;
        /**
         * Array of options that can be selected when the Select is opened.
         * @default []
         */
        options?: Array<SelectableOption>;
    };

const renderItem = (option: SelectableOption) => (
    <MenuItem value={option.id} key={option.id}>
        {option.label ? option.label : <FormattedMessage id={option.localeId} />}
    </MenuItem>
);

const Select = <ID extends string | null = string>(props: Props<ID>) => {
    const {
        value,
        options = [],
        onChange,
        error,
        helperText,
        label,
        name,
        icon,
        required,
        hideClearIcon,
        readOnly,
        disabled,
        renderValue,
        onClear,
        className,
        ...others
    } = props;

    return (
        <TextField
            select
            name={name}
            value={value || ('' as ID)}
            onChange={onChange}
            onClear={onClear}
            label={label}
            required={required}
            helperText={helperText}
            error={error}
            fullWidth
            icon={icon}
            hideClearIcon={hideClearIcon}
            readOnly={readOnly}
            disabled={disabled}
            className={className}
            SelectProps={{
                name,
                renderValue: (value) => {
                    const option = options.find((o) => o.id === value);
                    if (renderValue) {
                        return renderValue(option);
                    }
                    return option ? renderItem(option) : null;
                },
                children: [
                    ...(hideClearIcon
                        ? []
                        : [
                              <MenuItem value="" key="no-selection">
                                  <em>
                                      <FormattedMessage id="common.select-input.no-selection" />
                                  </em>
                              </MenuItem>
                          ]),
                    ...getOptionsWithTranslationsOrLabel(options).map(renderItem)
                ].filter(Boolean),
                ...others
            }}
        />
    );
};

export type SelectProps = Props;
export default Select;
