import { Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';

import { Button, Typography } from '../';
import type { UncontrolledMenuProps } from './UncontrolledMenu.types';
import type { SelectableOption } from '../utils/componentsTypes';

const UncontrolledMenu = (props: UncontrolledMenuProps) => {
    const { options, onOptionClick, ...others } = props;
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);

    const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleOptionClick = (option: SelectableOption) => {
        onOptionClick(option);
        handleClose();
    };

    return (
        <>
            <Button
                aria-controls="demo-positioned-menu"
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                onlyIcon
                startIcon="dots"
                minimal
                variant="text"
            />
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                {...others}
            >
                {options.map((option) => (
                    <MenuItem key={option.id} onClick={() => handleOptionClick(option)}>
                        <Typography localeId={option.localeId} title={option.label} />
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default UncontrolledMenu;
