import { CSSProperties } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';

import { isTypographyVariant } from './utils';
import type { ThemeTypes } from '../';
import { objectKeys } from '../../../common';

type VariantStyleRules = Record<ThemeTypes.AllTypography, CSSProperties>;

const useStyles = makeStyles(
    (theme) => {
        // automatically generate classes for all custom typography variants
        const variantClasses = objectKeys(theme.typography)
            .filter(isTypographyVariant)
            .reduce<VariantStyleRules>((acc, item) => {
                acc[item] = theme.typography[item];
                return acc;
            }, {} as VariantStyleRules);

        const fontFamilyClasses = {
            Roboto: {
                fontFamily: '"Roboto", sans-serif'
            },
            Poppins: {
                fontFamily: '"Poppins", sans-serif'
            }
        };

        return {
            ...variantClasses,
            ...fontFamilyClasses,
            root: {
                display: 'block'
            }
        };
    },
    { name: 'Typography' }
);

export default useStyles;
