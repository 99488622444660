import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
    (theme) => ({
        paper: {
            maxWidth: 800,
            margin: theme.spacing('auto', 0, 0),

            [theme.breakpoints.up('sm')]: {
                margin: theme.spacing('auto', 0, 'auto')
            }
        },
        content: {
            padding: theme.spacing(2, 2, 3),

            [theme.breakpoints.up('sm')]: {
                padding: theme.spacing(5)
            }
        },
        logo: {
            width: 40,
            height: 40,

            margin: theme.spacing(0, 'auto', 1),

            fill: theme.palette.secondary.main,

            [theme.breakpoints.up('sm')]: {
                width: 72,
                height: 72,

                margin: theme.spacing(0, 'auto', 5)
            }
        },

        title: {
            textAlign: 'center',
            fontWeight: 600,
            lineHeight: 1.5,
            letterSpacing: '0.25px',
            color: theme.palette.primary.main,
            fontFamily: 'Poppins'
        },

        subtitle: {
            ...theme.typography.textMedium,

            textAlign: 'center',
            fontWeight: 600,
            lineHeight: 1.5,
            letterSpacing: '0.25px',

            color: theme.palette.primary.main,
            fontFamily: 'Poppins',

            marginBottom: theme.spacing(3),

            [theme.breakpoints.up('sm')]: {
                ...theme.typography.textLarge,
                fontWeight: 600,
                lineHeight: 1.5,
                letterSpacing: '0.25px',

                marginBottom: theme.spacing(6)
            }
        },

        checkbox: {
            color: theme.palette.primary[500],
            marginBottom: theme.spacing(3),
            '& a': {
                color: 'inherit',
                fontWeight: 'inherit'
            }
        },
        checkboxCheckmarkContainer: {
            ...theme.border({
                width: 2,
                color: theme.palette.primary.main
            })
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'center',
            margin: theme.spacing(5, 0, 7),

            [theme.breakpoints.up('sm')]: {
                margin: theme.spacing(6, 0, 8)
            }
        },
        button: {
            width: 320
        },
        description: {
            color: '#59748D',
            lineHeight: 20 / 14
        },
        footnote: {
            color: '#59748D'
        }
    }),
    {
        name: 'PrivacyPolicyModal',
        // index to override Checkbox classes
        index: 1
    }
);
