import { gql } from '@apollo/client';
import { Fragments, env } from '../../';
import { AcademicDegreeFields, CreditPoint } from '../fragments';

export const GetAuthenticatedUser = gql`
    ${AcademicDegreeFields}
    ${CreditPoint}
    query GetAuthenticatedUser($tenantId: ID = "${env.TENANT_ID}") {
        authenticatedUser(tenantId: $tenantId) {
            id
            email
            firstName
            lastName
            isActivated
            hasValidActivationToken
            mobile
            occupationType
            roles
            accountType
            totalPointCertified {
                ...CreditPoint
            }
            company {
                logoUrl
                name
                id
                companyPage {
                    slug
                }
            }
            lastLogin
            gender
            academicDegree {
                ...AcademicDegreeFields
            }
            country
            dataSharingConsent
            medicalIdentificationNumber
            healthProfessionalConfirmation
            privacyPolicyConfirmation
            isCompleteOnboardingRequired
        }
    }
`;

export const GetSpecialities = gql`
    query GetSpecialities {
        specialities {
            id
            description {
                translations {
                    locale
                    value
                }
            }
            code
        }
    }
`;

export const GetUserForDashboard = gql`
    ${Fragments.UserFields}
    query GetUserForDashboard($userId: ID!, $tenantId: ID = "${env.TENANT_ID}") {
        user(userId: $userId, tenantId: $tenantId) {
            ...UserFields
        }
    }
`;

export const GetUserMembership = gql`
    ${Fragments.UserMembershipFields}
    query GetUserMembership($userId: ID!) {
        userMembership(userId: $userId) {
            ...UserMembershipFields
        }
    }
`;

export const BookingsCount = gql`
    query BookingsCount($searchQuery: BookingSearchQuery!, $tenantId: ID = "${env.TENANT_ID}") {
        bookings(searchQuery: $searchQuery, tenantId: $tenantId) {
            totalCount
        }
    }
`;

export const Bookings = gql`
    ${Fragments.BookingFields}
    query Bookings($searchQuery: BookingSearchQuery!, $tenantId: ID = "${env.TENANT_ID}") {
        bookings(searchQuery: $searchQuery, tenantId: $tenantId) {
            data {
                ...BookingFields
            }
            pageNumber
            pageSize
            totalCount
        }
    }
`;

export const GetUserAddresses = gql`
    ${Fragments.UserAddressFields}
    query GetUserAddresses($userId: ID!, $tenantId: ID = "${env.TENANT_ID}") {
        user(userId: $userId, tenantId: $tenantId) {
            __typename
            addresses {
                ...UserAddressFields
            }
            id
        }
    }
`;

export const ContentDocuments = gql`
    ${Fragments.ContentDocumentSearchFields}
    ${Fragments.AggregationFields}
    query ContentDocuments($searchQuery: ContentSearchInput!) {
        contentDocuments(searchQuery: $searchQuery) {
            data {
                ...ContentDocumentSearchFields
                sponsorships {
                    sponsorCompany {
                        name
                        slug
                        id
                        logoUrl
                        publishStatus
                    }
                }
            }
            totalCount
            pageNumber
            pageSize
            aggregations {
                ...AggregationFields
            }
        }
    }
`;

export const ContentDocumentsCount = gql`
    query ContentDocumentsCount($searchQuery: ContentSearchInput!) {
        contentDocuments(searchQuery: $searchQuery) {
            totalCount
        }
    }
`;

export const GetContentByContentId = gql`
    ${Fragments.ContentWithProgramSchedulesFields}
    query GetContentByContentId($contentId: String!) {
        content(contentId: $contentId) {
            __typename
            ...ContentWithProgramSchedulesFields
            questions {
                id
            }
        }
    }
`;

export const GetTenantContents = gql`
    query GetTenantContents($searchQuery: SearchQuery!) {
        contentsV2(searchQuery: $searchQuery) {
            data {
                id
                title
                brand
                provider {
                    id
                }
            }
        }
    }
`;

export const GetContentForAnalyticsById = gql`
    ${Fragments.ContentForAnalyticsFields}
    query GetContentForAnalyticsById($contentId: String!) {
        content(contentId: $contentId) {
            __typename
            ...ContentForAnalyticsFields
        }
    }
`;

export const GetCourseForAnalyticsById = gql`
    ${Fragments.CourseForAnalyticsFields}
    query GetCourseForAnalyticsById($courseId: String!) {
        course(identifier: $courseId) {
            __typename
            ...CourseForAnalyticsFields
        }
    }
`;

export const GetAcademicDegrees = gql`
    ${Fragments.AcademicDegreeFields}
    query GetAcademicDegrees($locale: String!) {
        userAcademicDegrees(locale: $locale) {
            ...AcademicDegreeFields
        }
    }
`;

export const GetMemberships = gql`
    ${Fragments.MembershipFields}
    query GetMemberships($country: String!, $tenantId: ID = "${env.TENANT_ID}") {
        memberships(country: $country, tenantId: $tenantId) {
            ...MembershipFields
        }
    }
`;

export const UserPaymentMethods = gql`
    ${Fragments.UserPaymentMethodFields}
    query UserPaymentMethods($userId: ID!, $tenantId: ID = "${env.TENANT_ID}", $requestFromCountry: String) {
        userPaymentMethods(userId: $userId, tenantId: $tenantId, requestFromCountry: $requestFromCountry) {
            ...UserPaymentMethodFields
        }
    }
`;

export const GetEventIdByContentId = gql`
    query GetEventIdByContentId($contentId: ID!, $tenantId: ID = "${env.TENANT_ID}") {
        eventForBooking(
            contentId: $contentId,
            tenantId: $tenantId
        ) {
            id
            content {
                externalId
            }
        }
    }
`;

export const GetEventForBooking = gql`
    ${Fragments.BookingEventFields}
    query GetEventForBooking(
        $eventId: ID
        $ubEventId: String
        $contentId: ID
        $tenantId: ID = "${env.TENANT_ID}"
        $userId: ID
        $bookingCountry: String
    ) {
        eventForBooking(
            eventId: $eventId
            ubEventId: $ubEventId
            contentId: $contentId
            tenantId: $tenantId
            userId: $userId
            bookingCountry: $bookingCountry
        ) {
            ...BookingEventFields
        }
    }
`;

export const GetPromoCodeForBooking = gql`
    ${Fragments.PromoCodeForOrderFields}
    query GetPromoCodeForBooking(
        $bookingRequest: BookingRequest!
        $tenantId: ID = "${env.TENANT_ID}"
        $userId: ID!
    ) {
        promoCodeForBooking(
            bookingRequest: $bookingRequest
            tenantId: $tenantId
            userId: $userId
        ) {
            ...PromoCodeForOrderFields
        }
    }
`;

export const GetPromoCodeForMembership = gql`
    ${Fragments.PromoCodeForMembershipOrder}
    query GetPromoCodeForMembership(
        $membershipId: ID!
        $tenantId: ID = "${env.TENANT_ID}"
        $promoCode: String!
    ) {
        promoCodeForMembership(
            membershipId: $membershipId
            tenantId: $tenantId
            promoCode: $promoCode
        ) {
            ...PromoCodeForMembershipOrder
        }
    }
`;

export const GetMembership = gql`
    ${Fragments.MembershipFields}
    query GetMembership($membershipId: ID!, $tenantId: ID! = "${env.TENANT_ID}") {
        membership(membershipId: $membershipId, tenantId: $tenantId) {
            ...MembershipFields
        }
    }
`;

export const GetAddOnCourses = gql`
    ${Fragments.AddonCourseFields}
    query GetAddOnCourses($searchQuery: SearchQuery!) {
        courses(searchQuery: $searchQuery) {
            data {
                ...AddonCourseFields
            }
            totalCount
            pageNumber
            pageSize
        }
    }
`;

export const SearchParticipantsSummaries = gql`
    ${Fragments.EventParticipantsSummaryFields}
    query SearchParticipantsSummaries($searchQuery: SearchQuery!,  $tenantId: ID = "${env.TENANT_ID}") {
        searchParticipantsSummaries(
            searchQuery: $searchQuery
            tenantId: $tenantId
        ) {
            pageNumber
            pageSize
            totalCount
            data {
                ...EventParticipantsSummaryFields
            }
        }
    }
`;

export const GetContentFile = gql`
    query GetContentFile($contentId: String!) {
        content(contentId: $contentId) {
            title
            source {
                file {
                    downloadUrl
                }
            }
        }
    }
`;
