import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',

        minHeight: `calc(100vh - ${theme.headerHeight.sm}px)`,

        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',

        zIndex: 10,

        [`${theme.breakpoints.up('lg')}`]: {
            minHeight: `calc(100vh - ${theme.headerHeight.lg}px)`
        }
    },
    cover: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        left: 0,
        top: 0,
        transform: 'none',
        width: '100%',
        height: '100vh',
        backgroundColor: 'rgba(255, 255, 255, 1)', // Semi-transparent white
        zIndex: 9999
    },

    inline: {
        margin: theme.spacing(4, 0)
    }
}));

export default useStyles;
