import makeStyles from '@mui/styles/makeStyles';
import type { ThemeTypes } from '../';

const useStyles = makeStyles<ThemeTypes.Theme>(
    (theme) => ({
        link: {
            color: theme.palette.primary.main,
            fontWeight: 500,
            textDecoration: 'none'
        }
    }),
    { name: 'Link' }
);

export default useStyles;
