import React, { useEffect, useState, Ref, MouseEventHandler } from 'react';
import { Link as MUILink, LinkProps as MUILinkProps } from '@mui/material';
import { getBasePath } from '../';

import useStyles from './Link.styles';
import classNames from 'classnames';

interface LinkProps extends Omit<MUILinkProps, 'color' | 'ref' | 'onClick'> {
    href?: string;
    component?: React.ElementType;
    onClick?: MouseEventHandler<HTMLElement>;
}

type CustomLinkType = React.ForwardRefExoticComponent<LinkProps> & {
    isNextApp?: boolean;
};

const Link: CustomLinkType = React.forwardRef((props: LinkProps, ref: Ref<HTMLElement>) => {
    const { href, component, className: propsClassName, children, ...others } = props;
    const [useNextLink, setUseNextLink] = useState(false);
    const { basePath } = getBasePath();
    const classes = useStyles();

    const isClientSide = typeof window !== 'undefined';

    /**
     * Next.JS seems to have troubles with the absolute links on language domains,
     * because when pass `https://api-staging.fomf.org/user-service/logout` to it,
     * it creates the following link `https://staging.fomf.de/userhttps://api-staging.fomf.org/user-service/logout`
     */
    const isAbsoluteLink = href?.startsWith('http://') || href?.startsWith('https://');

    useEffect(() => {
        // We must check if we are in next app in client side
        if (isClientSide && Link.isNextApp) {
            setUseNextLink(true);
        }
    }, [isClientSide]);

    const className = classNames(classes.link, propsClassName);

    const fullHref =
        typeof href === 'string' &&
        isClientSide &&
        useNextLink &&
        !isAbsoluteLink &&
        !href.startsWith('mailto:')
            ? basePath + href
            : href;

    return (
        <MUILink
            component={component ?? 'a'}
            className={className}
            to={fullHref}
            href={fullHref}
            ref={ref}
            {...others}
        >
            {children}
        </MUILink>
    );
});

Link.isNextApp = false;
Link.displayName = 'CustomLink';

export type { LinkProps };
export default Link;
