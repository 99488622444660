import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { Queries, getCountryCode3, Types } from '../..';

export const ssrFetchAuthenticatedUser = async (client: ApolloClient<NormalizedCacheObject>) => {
    const res = await client.query<
        Queries.GetAuthenticatedUserQuery | null,
        Queries.GetAuthenticatedUserQueryVariables
    >({
        query: Queries.GetAuthenticatedUser,
        errorPolicy: 'all'
    });
    return res.data?.authenticatedUser ?? undefined;
};

const ssrFetchUser = async (client: ApolloClient<NormalizedCacheObject>, userId: Types.UUID) => {
    const res = await client.query<
        Queries.GetUserForDashboardQuery | null,
        Queries.GetUserForDashboardQueryVariables
    >({
        query: Queries.GetUserForDashboard,
        variables: {
            userId
        },
        errorPolicy: 'all'
    });
    return res.data?.user ?? undefined;
};

const ssrFetchMembership = async (
    client: ApolloClient<NormalizedCacheObject>,
    userId: Types.UUID,
    locale: Locale
) => {
    const res = await client.query<
        Queries.GetUserMembershipQuery | null,
        Queries.GetUserMembershipQueryVariables
    >({
        query: Queries.GetUserMembership,
        variables: {
            userId
        }
    });
    const resMemberships = await client.query<
        Queries.GetMembershipsQuery | null,
        Queries.GetMembershipsQueryVariables
    >({
        query: Queries.GetMemberships,
        variables: {
            country: getCountryCode3(locale)
        }
    });

    return {
        userMembership: res.data?.userMembership ?? undefined,
        allMemberships: resMemberships.data?.memberships ?? undefined
    };
};

export const ssrFetchUserAndMembership = async (
    client: ApolloClient<NormalizedCacheObject>,
    locale: Locale
) => {
    const authenticatedUser = await ssrFetchAuthenticatedUser(client);
    if (authenticatedUser?.id) {
        const userId = authenticatedUser.id;
        const [user, membershipData] = await Promise.all([
            ssrFetchUser(client, userId),
            ssrFetchMembership(client, userId, locale)
        ]);
        return {
            authenticatedUser,
            user,
            ...membershipData
        };
    }
    return { authenticatedUser, user: null, userMembership: undefined, allMemberships: undefined };
};
