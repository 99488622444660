import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
    (theme) => ({
        list: {
            display: 'flex',
            flexDirection: 'column',
            overflowX: 'hidden',
            overflowY: 'auto',
            height: '100%'
        },
        item: {
            flex: 1,
            display: 'block',
            padding: 0
        },
        loader: {
            display: 'flex',
            alignSelf: 'center',
            margin: theme.spacing(3, 0)
        },
        loadMoreButton: {
            margin: theme.spacing(5, 'auto', 0, 'auto')
        }
    }),
    {
        name: 'VerticalList',
        index: 10 // To set a higher the CSS priority over the Icon width and height styles
    }
);

export default useStyles;
