const getCookie = (name: string, cookieHeader: string | null = null) => {
    if (typeof window !== 'undefined') {
        return extractCookieValue(document.cookie, name);
    }

    if (cookieHeader) {
        return extractCookieValue(cookieHeader, name);
    }

    return '';
};

const extractCookieValue = (cookie: string, name: string) => {
    const decodedCookie = decodeURIComponent(cookie).split(';');
    for (let i = 0; i < decodedCookie.length; i++) {
        while (decodedCookie[i]?.charAt(0) === ' ') {
            decodedCookie[i] = decodedCookie[i]?.substring(1) || '';
        }
        if (decodedCookie[i]?.indexOf(`${name}=`) == 0) {
            return decodedCookie[i]?.substring(`${name}=`.length, decodedCookie[i]?.length);
        }
    }
};

export default getCookie;
