import React from 'react';
import { Box } from '@mui/material';
import { useTenantContext, useUserContext } from '../../../contexts';
import { Button, Avatar, Icon, Typography } from 'components';
import { useToolbarHeaderStyles } from './Header.mobile.styles';
import { useIntl } from 'react-intl';
import { MEDIATHEK_URLS } from '../../../urls';
import NextLink from '../../NextLink';
import classnames from 'classnames';
import { getCountryCode, isFeatureEnabled } from '../../../utils';
import { USER_URLS, setStateInCookies } from '../../../..';
import { useRouter } from 'next/router';

const UserMenu = ({ onHeaderClick }: ToolbarHeaderProps) => {
    const userCtx = useUserContext();
    const { isLoggedIn, user } = userCtx;
    const { locale } = useIntl();
    const classes = useToolbarHeaderStyles();
    const { tenantConfig } = useTenantContext();
    const router = useRouter();
    const handleLoginClick = () => {
        const url = new URL(window.location.href);
        url.searchParams.delete('state');
        const nonce = setStateInCookies({});
        router.push(
            USER_URLS.loginWithQuery({
                nonce,
                locale,
                tenant: tenantConfig?.companyPage?.slug || ''
            })
        );
    };

    return isLoggedIn && (user?.firstName || user?.email) ? (
        <div className={classes.userMenuContainer} onClick={onHeaderClick}>
            <Avatar
                className={classes.avatar}
                email={user?.email}
                firstName={user?.firstName}
                lastName={user?.lastName}
            />
            <div className={classes.rightContainer}>
                <Typography variant="textXSmall" className={classes.subtitle}>
                    {user.firstName ?? ''} {user.lastName ?? ''}
                </Typography>
                <Icon
                    icon="caretRight"
                    className={classnames(classes.caretRight, classes.primary)}
                />
            </div>
        </div>
    ) : (
        <div className={classes.userMenuContainer} onClick={handleLoginClick}>
            <Box className={classes.avatar}>
                <Icon icon="login" className={classes.loginIcon} />
            </Box>
            <div className={classes.rightContainer}>
                <div className={classes.signIn}>
                    <Typography
                        variant="textSmall"
                        className={classes.title}
                        color={tenantConfig?.id != null ? 'primary' : 'secondary'}
                        localeId={
                            tenantConfig?.id != null
                                ? 'common.login.tenant-title'
                                : 'common.header.login-sign-up'
                        }
                    />
                    <Icon icon="arrowRightType2" className={classes.signInIcon} />
                </div>
            </div>
        </div>
    );
};

type ToolbarHeaderProps = {
    onHeaderClick: () => void;
};

export const ToolbarHeader = (props: ToolbarHeaderProps) => {
    const { locale } = useIntl();
    const router = useRouter();
    const isTopicsPageEnabled = isFeatureEnabled('topicSelector', getCountryCode(locale as Locale));
    const classes = useToolbarHeaderStyles();
    const { isLoggedIn } = useUserContext();
    const { formatMessage } = useIntl();
    const { tenantConfig } = useTenantContext();

    const handleRegisterClick = () => {
        const url = new URL(window.location.href);
        url.searchParams.delete('state');
        const nonce = setStateInCookies({});
        const toPush = USER_URLS.registration({ nonce, locale });
        router.push(toPush);
    };

    return (
        <Box className={classes.toolbarContainer}>
            <UserMenu {...props} />
            {tenantConfig?.id != null ? (
                !isLoggedIn && (
                    <Box mt={2}>
                        <Button
                            onClick={handleRegisterClick}
                            size="large"
                            variant="outlined"
                            localeId="common.header.mobile.tenant-registration-new"
                        />
                    </Box>
                )
            ) : isTopicsPageEnabled ? (
                <NextLink href={MEDIATHEK_URLS.topics} className={classes.topicsLink}>
                    <Icon icon="listBullets" className={classes.topicsIcon} />
                    {formatMessage({
                        id: 'common.header.topics'
                    })}
                </NextLink>
            ) : null}
        </Box>
    );
};
