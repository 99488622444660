import makeStyles from '@mui/styles/makeStyles';
import type { ThemeTypes } from '../';
import { MultiCheckboxProps, MultiCheckboxClasses } from './MultiCheckbox.types';

const useStyles = makeStyles<ThemeTypes.Theme, MultiCheckboxProps, MultiCheckboxClasses>(
    (theme) => ({
        label: {
            marginBottom: theme.spacing(3)
        },
        options: {
            display: 'flex',
            flexWrap: 'wrap',
            margin: theme.spacing(-1),
            [theme.breakpoints.down('sm')]: {
                display: 'block'
            }
        },
        option: {
            flexBasis: '50%',
            padding: theme.spacing(1)
        },
        [theme.breakpoints.up('md')]: {
            label: {
                marginBottom: theme.spacing(4)
            },
            options: {
                margin: theme.spacing(-2)
            },
            option: {
                padding: theme.spacing(2)
            }
        }
    })
);

export default useStyles;
