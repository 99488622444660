import { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import { NextRouter, useRouter as useNextRouter } from 'next/router';
import { InitialType, useUrlSearchParams } from 'use-url-search-params';
import { getBasePath } from 'components';

import { noop } from '../';
import { QueryParamsContext, QueryParamsType } from '../contexts';

export type Router<T> = Pick<
    NextRouter,
    'asPath' | 'pathname' | 'isReady' | 'basePath' | 'defaultLocale' | 'push'
> & {
    query: T;
    setQuery: (newQuery: T) => void;
    back: () => void;
    href: string;
    routeDynamicParams: Record<string, string | undefined>;
    locale: Locale;
};

/**
 * this function will return the index which will help us to split the url
 * `/user/login` to `user` and `login`
 * or `/user/fr-CH/login` to /user/fr-CH` and `login`
 * this will be used to extract the pathname without basepath
 *
 * @deprecated
 **/
const getPathnameSplitIndex = (basePath: string, localePath: string) => {
    let pathPartsToStrip: number;
    // admin, user
    if (basePath.length > 1) {
        pathPartsToStrip = localePath ? 3 : 2;
    } else {
        // catalog
        pathPartsToStrip = localePath ? 2 : 1;
    }
    return pathPartsToStrip;
};

const getRouteDynamicParams = (
    localePath: string,
    pathParts: Array<string>,
    template?: string
): Record<string, string | undefined> => {
    const templateParts = template?.split('/');
    const routeDynamicParams: Record<string, string | undefined> = {};
    if (templateParts) {
        templateParts.forEach((each, idx) => {
            if (each.startsWith('[')) {
                const key = each.slice(1, -1);
                routeDynamicParams[key] = pathParts[idx + (localePath.length > 0 ? 1 : 0)];
            }
        });
    }
    return routeDynamicParams;
};

const removeDynamicParamsFromQuery = <T extends QueryParamsType>(
    query: T,
    routeDynamicParams: Record<string, string | undefined>
): T => {
    const newQuery = {
        ...query
    };
    Object.keys(routeDynamicParams).forEach((key) => {
        if (key in newQuery) {
            delete newQuery[key];
        }
    });
    return newQuery;
};

/**
 * @deprecated
 */
const getRouter = <T extends QueryParamsType>(
    nextRouter: NextRouter,
    template?: string
): Router<T> => {
    const defaultLocale = nextRouter?.defaultLocale;
    const locale = (nextRouter?.locale || defaultLocale) as Locale;
    if (typeof window === 'undefined') {
        const router: Router<T> = {
            asPath: nextRouter?.asPath,
            locale,
            defaultLocale,
            pathname: nextRouter?.pathname,
            query: nextRouter?.query as T,
            isReady: nextRouter?.isReady,
            basePath: nextRouter?.basePath,
            push: nextRouter?.push,
            back: nextRouter?.back,
            href: '',
            setQuery: noop,
            routeDynamicParams: {}
        };

        return router;
    }

    const href = window.location.href;
    const pathParts = window.location.pathname.split('/');

    // TODO: this is a workaround to get the localePath and basePath
    // We should consider removing the `getBasePath` function and use the `nextRouter` object
    const { localePath, basePath: clientSideBasePath } = getBasePath();
    const basePath = nextRouter?.basePath ?? clientSideBasePath;
    const pathnameSplitIndex = getPathnameSplitIndex(basePath, localePath);

    const pathname = '/' + pathParts.slice(pathnameSplitIndex).join('/');
    const asPath = nextRouter?.asPath;
    const searchParams = new URLSearchParams(window.location.search.slice(1));
    const routeDynamicParams = getRouteDynamicParams(localePath, pathParts, template);

    const query = Array.from(searchParams.keys()).reduce<QueryParamsType>((acc, key) => {
        const one = searchParams.get(key);
        const all = searchParams.getAll(key);
        if (all.length > 1) {
            acc[key] = all;
        } else {
            acc[key] = one;
        }

        return acc;
        // TODO to be backward compatible, we can just remove this in the future and use
        // routeDynamicParams
    }, routeDynamicParams);
    const isReady = true;

    return {
        asPath,
        locale,
        pathname,
        routeDynamicParams,
        query: query as T,
        isReady,
        defaultLocale,
        basePath,
        push: nextRouter.push,
        back: nextRouter.back,
        href,
        setQuery: noop
    };
};

type UseRouterOptions = {
    template?: string;
    persistQueryInContext?: boolean;
};

/**
 * TODO: fix router with route dynamic params inside query
 * The default behavior for nextjs router is having path query params and route
 * dynamic params, both be presented inside the query object of the router
 * currently we are not supporting that and the query object of the router
 * will just include path query params and there is a separate object `routeDynamicParams`
 * for route dynamic params
 * TODO: this call needs to be \@deprecated in the future, but it should still be used in the meantime to preserve our routing customizations
 */
const useRouter = <T extends QueryParamsType>(routerOptions: UseRouterOptions = {}): Router<T> => {
    const nextRouter = useNextRouter();
    const template = routerOptions?.template;
    const { persistQueryInContext = true } = routerOptions;
    const { query, setQuery } = useContext(QueryParamsContext);

    const [router, setRouter] = useState<Router<T>>(getRouter<T>(nextRouter, template));

    // query includes route dynamic params, but we shouldn't include them into search params as it's effectively a duplicate
    const initialSearchParams = removeDynamicParamsFromQuery(
        router.query,
        router.routeDynamicParams
    );
    const [queryParams, setQueryParams] = useUrlSearchParams(initialSearchParams as InitialType);
    const [locationHref, setLocationHref] = useState<string>(
        typeof window !== 'undefined' ? window.location.pathname : ''
    );

    useEffect(() => {
        const interval = setInterval(() => {
            setLocationHref(window.location.pathname);
        }, 50);
        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        setRouter(getRouter(nextRouter, template));
    }, [locationHref, template]);

    router.query = useMemo(
        () => ({
            ...(persistQueryInContext ? query : {}),
            ...(queryParams as T)
        }),
        [queryParams]
    );

    const handleSetQuery = useCallback(
        (newQuery: QueryParamsType) => {
            // as the new query does not consist of the route dynamic params
            // we will lose them now if we set it up in the query previousely
            // we need to consider them as  well here
            // we had also this: setQueryParams({ ...router.routeDynamicParams, ...(newQuery as InitialType) });
            // the issue we faced was: /content/[id]/on-demand changed to /content/12345/on-demand?id=12345
            // Also the router query param is working if we set the persistQueryInContext to false, but we
            // need that in some cases
            setQueryParams(newQuery as InitialType);
            if (persistQueryInContext) {
                setQuery(newQuery);
            }
        },
        [persistQueryInContext, setQueryParams, setQuery]
    );

    router.setQuery = handleSetQuery;
    router.isReady = nextRouter.isReady;

    return router;
};
export default useRouter;
