import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Popover, Box } from '@mui/material';

import { DateMaskType, TextField } from '../';

import { DateRangePickerProps, RangePickerProps, SinglePickerProps } from './DateRangePicker.types';
import Calendar from './Calendar/Calendar';
import {
    isSingleVariantProps,
    displayFormat,
    rangeDisplayFormat,
    SingleDateUtils,
    RangeDateUtils
} from './DateRangePicker.utils';

const DateRangePicker = (props: DateRangePickerProps) => {
    const {
        minDate,
        maxDate,
        startDate,
        variant,
        value: valueProp,
        onChange: onChangeProp,
        onBlur,
        ...textFieldProps
    } = props;

    const inputRef = useRef<HTMLInputElement>(null);
    const [mask, setMask] = useState<DateMaskType | undefined>(undefined);

    const [isOpen, setIsOpen] = useState(false);

    const variantProps = {
        variant,
        value: valueProp,
        onChange: onChangeProp
    } as SinglePickerProps | RangePickerProps;

    const calendarValue = isSingleVariantProps(variantProps)
        ? SingleDateUtils.transformValueToDate(variantProps)
        : RangeDateUtils.transformValueToDate(variantProps);

    const calendarOnChange = isSingleVariantProps(variantProps)
        ? SingleDateUtils.createOnChange(variantProps.onChange)
        : RangeDateUtils.createOnChange(variantProps.onChange);

    const onClear = isSingleVariantProps(variantProps)
        ? SingleDateUtils.createOnClear(variantProps.onChange)
        : RangeDateUtils.createOnClear(variantProps.onChange);

    const textFieldValue = isSingleVariantProps(variantProps)
        ? SingleDateUtils.transformValueToDisplayString(variantProps)
        : RangeDateUtils.transformValueToDisplayString(variantProps);

    const onMaskComplete = isSingleVariantProps(variantProps)
        ? SingleDateUtils.createOnMaskComplete(variantProps)
        : RangeDateUtils.createOnMaskComplete(variantProps);

    useEffect(() => {
        if (!inputRef.current) {
            return;
        }

        const createMaskProps = { input: inputRef.current, minDate, maxDate };

        const dateMask = isSingleVariantProps(variantProps)
            ? SingleDateUtils.createDateMask(createMaskProps)
            : RangeDateUtils.createDateMask(createMaskProps);

        setMask(dateMask);

        dateMask.on(
            'complete',
            (event: ChangeEvent<HTMLInputElement> | undefined) =>
                event && onMaskComplete(event.target.value)
        );

        return () => {
            setMask(undefined);
            dateMask.destroy();
        };
    }, [inputRef]);

    useEffect(() => {
        if (mask) {
            mask.value = textFieldValue;
            mask.updateValue();
        }
    }, [textFieldValue]);

    return (
        <Box>
            <TextField
                {...textFieldProps}
                value={textFieldValue}
                inputRef={inputRef}
                // TODO create close button on calendar modal
                onClick={() => setIsOpen(true)}
                onClear={onClear}
                onBlur={onBlur}
                placeholder={
                    isSingleVariantProps(variantProps) ? displayFormat : rangeDisplayFormat
                }
                nativeAutocomplete={false}
            />
            <Popover
                open={isOpen}
                anchorEl={inputRef.current}
                disablePortal={false}
                onClose={() => setIsOpen(false)}
            >
                <Calendar
                    variant={variantProps.variant}
                    value={calendarValue}
                    onChange={calendarOnChange}
                    minDate={minDate}
                    maxDate={maxDate}
                    startDate={startDate}
                />
            </Popover>
        </Box>
    );
};

export default DateRangePicker;
