import React from 'react';
import { Typography } from '../';
import { Tab, Tabs } from '@mui/material';
import classNames from 'classnames';
import useStyles from './SectionsMenu.styles';
import type { SectionsMenuProps } from './SectionsMenu.types';

const SectionsMenu = (props: SectionsMenuProps<string>): React.ReactElement => {
    const {
        menus,
        selection,
        onChange,
        loading,
        className,
        mobileFullWidth,
        'data-testid': dataTestId
    } = props;
    const classes = useStyles(props);

    return (
        <div className={classNames(classes.container, className)} data-testid={dataTestId}>
            <Tabs
                value={selection}
                classes={{
                    root: classNames(classes.menus, {
                        [classes.mobileFullWidthMenus]: mobileFullWidth
                    }),
                    indicator: classes.indicator
                }}
                onChange={(_, newTab) => onChange(newTab)}
            >
                {menus.map((menu) => (
                    <Tab
                        key={menu.id}
                        title={menu.title}
                        value={menu.id}
                        className={classNames(classes.menu, {
                            [classes.mobileFullWidthTab]: mobileFullWidth
                        })}
                        disabled={loading}
                        label={
                            <>
                                <Typography
                                    title={menu.title}
                                    localeId={menu.localeId}
                                    fontFamily="Poppins"
                                />
                            </>
                        }
                    />
                ))}
            </Tabs>
        </div>
    );
};

export default SectionsMenu;
