import React from 'react';
import Head from 'next/head';
import { SeoIndexSetting } from '../graphql/generated';
import { Types } from '..';

export type SEODict = {
    title: string;
    description?: string;
};

type SEOByPathname = Record<string, SEODict>;

type Props = {
    pathname: string;
    seoDictionary: SEOByPathname;

    /**
     * @default Indexed follow
     */
    indexSetting: SeoIndexSetting | null | undefined;
};

/**
 * This component is useful to render static title, description and image SEO tags.
 */
export const SEO = (props: Props) => {
    const { pathname, seoDictionary, indexSetting = Types.SeoIndexSetting.IndexedFollow } = props;
    if (!seoDictionary[pathname]) {
        return null;
    }

    const noFollow =
        indexSetting === Types.SeoIndexSetting.NotIndexed ||
        indexSetting === Types.SeoIndexSetting.IndexedNoFollow;

    const title = seoDictionary[pathname]?.title;
    const description = seoDictionary[pathname]?.description;
    return (
        <Head>
            {title && <title>{title}</title>}
            {description && <meta name="description" content={description} />}
            {noFollow && <meta name="robots" content="noindex, nofollow" />}
        </Head>
    );
};
