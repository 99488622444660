import { formatDateForDB, parseDBDate } from 'components';
import dayjs from 'dayjs';
import {
    booleanFilter,
    Fragments,
    getDomainFromLocation,
    getMembershipDateRange,
    LOCALE_TO_DOMAIN,
    Membership,
    MEMBERSHIP_COUNTRY_TO_ALLOWED_DOMAINS,
    Queries,
    Types,
    UserMembership,
    UserMembershipItem
} from '..';

export type MembershipItem = {
    amountUnit: string;
    country: string;
    amount: number;
};

export const validateMembershipCreditItem = (
    membershipCreditItem: Types.MembershipItem | Types.UserMembershipItem
): Types.MembershipItem | undefined => {
    const membershipItem = isUserMembershipItem(membershipCreditItem)
        ? membershipCreditItem.membershipItem
        : membershipCreditItem;

    if (
        membershipItem.country === 'DEU' &&
        membershipItem.featureType === Types.MembershipItemFeatureType.Cme
    ) {
        return membershipItem;
    }

    if (
        membershipItem.country === 'CHE' &&
        membershipItem.featureType === Types.MembershipItemFeatureType.Accreditation
    ) {
        return membershipItem;
    }

    if (
        membershipItem.country === 'AUT' &&
        membershipItem.featureType === Types.MembershipItemFeatureType.Accreditation
    ) {
        return membershipItem;
    }
};

export const getMembershipCreditNameForItem = (
    membershipCreditItem: Types.MembershipItem | Types.UserMembershipItem,
    locale: Locale
): string | undefined => {
    const validatedMembershipItem = validateMembershipCreditItem(membershipCreditItem);

    if (!validatedMembershipItem) {
        return;
    }

    return getMembershipCreditName(validatedMembershipItem.country, locale);
};

export const getMembershipCreditName = (country: string, locale: Locale): string | undefined => {
    if (country === 'DEU') {
        return 'CME';
    }

    if (country === 'CHE') {
        if (locale === 'fr-CH') {
            return 'Crédits';
        }

        return 'Credits';
    }

    if (country === 'AUT') {
        return 'DFP';
    }
};

const transformMembershipItem = ({
    remainingAmount,
    usedAmount,
    ...rest
}: Fragments.UserMembershipItemFieldsFragment): UserMembershipItem &
    Fragments.UserMembershipItemFieldsFragment => ({
    ...rest,
    amount: {
        total: rest.membershipItem.amount || 0,
        used: usedAmount || 0,
        remaining: remainingAmount || 0
    },
    remainingAmount,
    usedAmount
});

/**
 * Finds government accreditation item
 * Return object with `single` and `all` fields.
 * `single` is used when we need to know credit name
 * `all` used when we need to calculate amount of points for multipoint accreditation like in CHE
 */
export const getAccreditationItem = (
    creditPoints: Array<Types.CreditPointR>,
    creditCountry?: string
): {
    single: Types.CreditPointR | undefined;
    all: Array<Types.CreditPointR>;
} => {
    // Germany
    if (creditCountry === 'DEU') {
        const item = creditPoints.find(({ unit }) => unit === 'CME');
        return {
            single: item,
            all: [item].filter(booleanFilter)
        };
    }

    // Switzerland
    if (creditCountry === 'CHE') {
        const iterator = (item: Types.CreditPointR) =>
            item.mainAccreditation && item.unit !== 'CME' && item.unit !== 'DFP';
        return {
            single: creditPoints.find(iterator),
            all: creditPoints.filter(iterator)
        };
    }

    // Austria
    if (creditCountry === 'AUT') {
        const item = creditPoints.find(({ unit }) => unit === 'DFP');

        return {
            single: item,
            all: [item].filter(booleanFilter)
        };
    }

    return {
        single: undefined,
        all: []
    };
};

export const getUserAccreditationCertifiedPoints = (
    user: Fragments.UserFieldsFragment,
    userMembership: Fragments.UserMembershipFieldsFragment
) => {
    const totalPointsCertifiedItem = getAccreditationItem(
        user.totalPointCertified,
        userMembership.membership.country
    );
    return totalPointsCertifiedItem.all.reduce((acc, item) => acc + (item.amount || 0), 0);
};

export const getUserAccreditationMembershipEffectedCertifiedPoints = (
    user: Fragments.UserFieldsFragment,
    userMembership: Fragments.UserMembershipFieldsFragment
) => {
    const totalPointsCertifiedItem = getAccreditationItem(
        user.totalMembershipPointsEffectedCertified,
        userMembership.membership?.country
    );
    return totalPointsCertifiedItem.all.reduce((acc, item) => acc + (item.amount || 0), 0);
};

/**
 * Find fomf membership credit item
 * @param items
 */
export const getMembershipCreditItem = <
    Item extends Types.MembershipItem | Types.UserMembershipItem
>(
    items: Array<Item>
): Item | undefined => {
    const membershipCreditItem = items.find((item) => {
        const membershipItem = isUserMembershipItem(item)
            ? item.membershipItem
            : isMembershipItem(item)
              ? item
              : undefined;

        if (!membershipItem) {
            return;
        }

        if (membershipItem.membershipItemType !== Types.MembershipItemType.Main) {
            return;
        }

        const validatedMembershipCreditItem = validateMembershipCreditItem(membershipItem);

        return !!validatedMembershipCreditItem;
    });

    return membershipCreditItem;
};

export const getMembershipCreditInfo = (
    userMembership: Types.Maybe<Fragments.UserMembershipFieldsFragment>
): { usedAmount: number; remainingAmount: number; totalAmount: number } => {
    const membershipCreditItem = getMembershipCreditItem(userMembership?.items || []);
    return {
        usedAmount: membershipCreditItem?.usedAmount || 0,
        remainingAmount: membershipCreditItem?.remainingAmount || 0,
        totalAmount: membershipCreditItem?.membershipItem.amount || 0
    };
};

const isUserMembershipItem = (
    membershipItem: Types.MembershipItem | Types.UserMembershipItem
): membershipItem is Types.UserMembershipItem =>
    !!(membershipItem as Types.UserMembershipItem).membershipItem;

const isMembershipItem = (
    membershipItem: Types.MembershipItem | Types.UserMembershipItem
): membershipItem is Types.MembershipItem =>
    (membershipItem as Types.MembershipItem).__typename === 'MembershipItem';

const isUserMembershipValidForDomain = (
    userMembership: Fragments.UserMembershipFieldsFragment | null | undefined,
    currentDomain?: Domain
): boolean => {
    const membershipCountry = userMembership?.membership?.country;

    if (!membershipCountry) {
        return false;
    }

    const allowedDomains = MEMBERSHIP_COUNTRY_TO_ALLOWED_DOMAINS[membershipCountry as CountryCode3];
    const isMembershipCountryValid = allowedDomains
        ? allowedDomains.includes(currentDomain || getDomainFromLocation())
        : false;
    return isMembershipCountryValid;
};

/**
 * Takes response from `GetUserMembershipQuery` and returns `userMembership` if it is paid or trial, and not expired.
 * Also checks membership country against current domain
 * @param userMembershipData response of `GetUserMembershipQuery`
 * @param expiryThreshold option param to pass event date to check that membership will last until this event starts
 */
export const getUserPaidNotExpiredMembership = (
    userMembershipData: Queries.GetUserMembershipQuery | null | undefined,
    expiryThreshold?: string | null | undefined,
    locale?: Locale
): UserMembership | null => {
    const userMembership = userMembershipData?.userMembership;
    const isMembershipExpired =
        userMembership && parseDBDate(userMembership.expiresAt).isBefore(expiryThreshold);
    const isMembershipPaid = (userMembership?.membership?.price || 0) > 0;
    const isTrialMembership = userMembership?.membership
        ? getIsMembershipForTrial(userMembership?.membership)
        : false;

    const currentDomain = locale ? LOCALE_TO_DOMAIN[locale] : undefined;
    const isWrongDomain = !isUserMembershipValidForDomain(userMembership, currentDomain);

    if (
        !userMembership ||
        (!isMembershipPaid && !isTrialMembership) ||
        isMembershipExpired ||
        isWrongDomain
    ) {
        return null;
    }

    const membershipItems = userMembership.items.map(transformMembershipItem);

    const accreditationPointsItem = getMembershipCreditItem(membershipItems);
    const items = userMembership.items.map(transformMembershipItem);

    if (!accreditationPointsItem) {
        return null;
    }

    return {
        ...userMembership,
        membershipType: userMembership.membership?.membershipType,
        items,
        accreditationPoints: accreditationPointsItem.amount
    };
};

export const getMembershipDateRangeOnBooking = (cycle: Types.MembershipCycle): string => {
    const today = dayjs().utc().startOf('day');

    let expiresAtDate;
    switch (cycle) {
        case Types.MembershipCycle.OneMonth:
            expiresAtDate = today.add(1, 'month');
            break;
        case Types.MembershipCycle.TwoMonths:
            expiresAtDate = today.add(2, 'months');
            break;
        case Types.MembershipCycle.SixMonths:
            expiresAtDate = today.add(6, 'months');
            break;
        case Types.MembershipCycle.ThreeMonths:
            expiresAtDate = today.add(3, 'months');
            break;
        case Types.MembershipCycle.ThreeYears:
            expiresAtDate = today.add(3, 'years');
            break;
        case Types.MembershipCycle.OneYear:
        default:
            expiresAtDate = today.add(1, 'year');
            break;
    }

    expiresAtDate = expiresAtDate?.subtract(1, 'day');

    const expiresAt = formatDateForDB(expiresAtDate);

    const membershipDateRange = getMembershipDateRange(expiresAt, cycle).range;

    return membershipDateRange;
};

export const getIsMembershipForTrial = (
    membership: Pick<Membership, 'price' | 'cycle'>
): boolean => {
    return (
        membership.price === 0 &&
        (membership.cycle === Types.MembershipCycle.OneMonth ||
            membership.cycle === Types.MembershipCycle.TwoMonths ||
            membership.cycle === Types.MembershipCycle.ThreeMonths)
    );
};

const trialMembershipLinkParams = (locale: Locale) => {
    switch (locale) {
        case 'de-DE':
            return 'utm_source=web&utm_medium=kundenkontodt&utm_campaign=testmitgliedschaft';
        case 'de-CH':
            return 'utm_source=web&utm_medium=kundenkontochdt&utm_campaign=testmitgliedschaft';
        case 'fr-CH':
            return 'utm_source=web&utm_medium=kundenkontochfr&utm_campaign=testmitgliedschaft';
    }
};

export const getTrialMembershipRelativeUrl = (locale: Locale): string => {
    return `/booking/trialmembership?${trialMembershipLinkParams(locale)}`;
};

export const getTrialMembershipUrl = (locale: Locale): string => {
    return `/booking/trialmembership?${trialMembershipLinkParams(locale)}`;
};

export const hasGroupMembership = (userMembership: UserMembership | null | undefined) =>
    Boolean(userMembership?.addedToGroupMembershipDateTime);
