import React, { createContext, useContext, useEffect, useState } from 'react';
import { useIsDesktop } from '../hooks';
import { useUserContext } from './UserContext';

type ContextType = {
    isDisplayingMarketingSection: boolean;
    isMarketingHomeEnabled: boolean;
};

const MarketingHomeContext = createContext<ContextType | undefined>(undefined);

export const MarketingContextProvider: React.FC<{
    isEnabled: boolean;
    children: React.ReactNode;
}> = ({ isEnabled, children }) => {
    const { isLoggedIn } = useUserContext();
    const isEnabledAndGuest = isEnabled && !isLoggedIn;
    const isDesktop = useIsDesktop();
    const [isDisplayingMarketingSection, setIsDisplayingMarketingSection] =
        useState(isEnabledAndGuest);

    useEffect(() => {
        if (!isEnabledAndGuest) {
            return;
        }
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    setIsDisplayingMarketingSection(entry.isIntersecting);
                });
            },
            {
                threshold: 0.1,
                rootMargin: isDesktop ? '120px 0px 0px 0px' : '100px 0px 0px 0px'
            }
        );

        const banner = document.getElementById('guest-marketing-banner');
        if (banner) {
            observer.observe(banner);
        }

        return () => observer.disconnect();
    }, [isEnabledAndGuest, isDesktop]);

    return (
        <MarketingHomeContext.Provider
            value={{
                isDisplayingMarketingSection,
                isMarketingHomeEnabled: isEnabledAndGuest
            }}
        >
            {children}
        </MarketingHomeContext.Provider>
    );
};

export const useMarketingHomeContext = () => {
    const context = useContext(MarketingHomeContext);
    if (context === undefined) {
        return {
            isDisplayingMarketingSection: false,
            isMarketingHomeEnabled: false,
            setIsDisplayingMarketingSection: () => {
                return undefined;
            }
        };
    }

    return context;
};
