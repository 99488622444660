import {
    createYupPhoneValidationSchema,
    countries,
    StringSchema,
    ArraySchema,
    ComponentsTypes
} from 'components';
export * from './userHelpers';
import * as yup from 'yup';
import { bypassMedicalVerificationByEmail, Types, verifyEFN, verifyGLN, verifyOAK } from '../..';
import { BaseSchema } from 'yup';

const makeSchemaRequired = <IsOptional extends boolean, ItemSchema extends BaseSchema>(
    item: ItemSchema,
    isOptional?: IsOptional
): ItemSchema => (isOptional ? item : item.required());

export const email = () =>
    yup.string().email('common.valid-email').required('common.error.required-email');

export const ubAccountCode = () =>
    yup.string().matches(/^[0-9]{8}$/, 'common.valid-ub-account-code');

export const password = () =>
    yup
        .string()
        .required()
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, 'common.password-strength');

export const country = <IsOptional extends boolean>(isOptional?: IsOptional) => ({
    country: makeSchemaRequired(
        yup.string().oneOf(countries.map((country) => country.id)),
        isOptional
    )
});

export const medicalIdentificationNumber = (allowNoMedicalNumberForHealthProfessional = true) => ({
    ...country(),
    email: yup.string().required(),
    healthProfessionalConfirmation: yup.boolean().test((value, context) => {
        if (!context.parent.medicalIdentificationNumber && value !== true) {
            return context.createError({
                message: { localeId: 'common.health-professional-acceptance' }
            });
        }
        return true;
    }),
    medicalIdentificationNumber: yup
        .string()
        .when('healthProfessionalConfirmation', {
            is: true,
            then: allowNoMedicalNumberForHealthProfessional
                ? yup.string().notRequired()
                : yup.string().typeError('common.must-be-a-number').required(),
            otherwise: yup.string().typeError('common.must-be-a-number').required()
        })
        .when('country', {
            // Germany- EFN Number
            is: 'DEU',
            then: yup.string().test((value, context) => {
                const bypassByEmail = bypassMedicalVerificationByEmail(context.parent.email);
                if (bypassByEmail || (typeof value === 'string' && verifyEFN(value))) {
                    return true;
                }

                if (
                    !value &&
                    context.parent.healthProfessionalConfirmation &&
                    allowNoMedicalNumberForHealthProfessional
                ) {
                    return true;
                }

                if (typeof value === 'string' && value.length !== 15) {
                    return context.createError({
                        message: { localeId: 'common.medical-id.deu-required-length' }
                    });
                }

                return context.createError({
                    message: { localeId: 'common.medical-id.deu-required-format' }
                });
            })
        })
        .when('country', {
            // TODO: validate GBR medical id number?
            is: 'GBR',
            then: yup.string()
        })
        .when('country', {
            // Austria - ÖÄK Number
            is: 'AUT',
            then: yup.string().test((value, context) => {
                const bypassByEmail = bypassMedicalVerificationByEmail(context.parent.email);
                if (bypassByEmail || (typeof value === 'string' && verifyOAK(value))) {
                    return true;
                }

                if (
                    !value &&
                    context.parent.healthProfessionalConfirmation &&
                    allowNoMedicalNumberForHealthProfessional
                ) {
                    return true;
                }

                return context.createError({
                    message: { localeId: 'common.medical-id.aut-required-format' }
                });
            })
        })
        .when('country', {
            // Switzerland - GLN Number
            is: 'CHE',
            then: yup.string().test((value, context) => {
                const bypassByEmail = bypassMedicalVerificationByEmail(context.parent.email);
                if (bypassByEmail || (typeof value === 'string' && verifyGLN(value))) {
                    return true;
                }

                if (
                    !value &&
                    context.parent.healthProfessionalConfirmation &&
                    allowNoMedicalNumberForHealthProfessional
                ) {
                    return true;
                }

                if (typeof value === 'string' && value.length !== 13) {
                    return context.createError({
                        message: { localeId: 'common.medical-id.che-required-length' }
                    });
                }

                return context.createError({
                    message: { localeId: 'common.medical-id.che-required-format' }
                });
            })
        })
});

export const fieldOfActivity = () => ({
    fieldOfActivity: yup
        .string()
        .oneOf(Object.values(Types.FieldOfActivity))
        .required() as StringSchema<Types.FieldOfActivity>
});

export const specialities = () => ({
    specialities: yup
        .array(yup.object({ id: yup.string().required(), label: yup.string() }))
        .min(1)
        .required() as ArraySchema<ComponentsTypes.SelectableOption>
});

export const newPassword = () => ({
    newPassword: password(),
    newPasswordConfirmation: yup
        .string()
        .test(
            'isEqual',
            'common.passwords-do-not-match',
            (value, context) => value === context.parent.newPassword
        )
        .required()
});

export const occupationType = () => ({
    occupationType: yup
        .string()
        .oneOf(Object.values(Types.OccupationType))
        .required() as StringSchema<Types.OccupationType>
});

export const participantType = () =>
    yup.object({
        id: yup.string().required(),
        name: yup.string().required(),
        occupationType: yup
            .string()
            .oneOf(Object.values(Types.OccupationType))
            .required() as StringSchema<Types.OccupationType>
    });

export const academicDegree = () => ({
    academicDegreeCode: yup.string()
});

export const gender = () => ({
    gender: yup.string().oneOf(Object.values(Types.Gender)).required() as StringSchema<Types.Gender>
});

export const name = () => yup.string().min(1, 'common.min-length').required();

export const getCompleteProfileValidationSchema = () =>
    yup.object({
        // Step 1
        firstName: name(),
        lastName: name(),
        ...gender(),
        ...academicDegree(),
        mobile: createYupPhoneValidationSchema(),
        // Step 2
        ...occupationType(),
        ...fieldOfActivity(),
        ...specialities(),
        ...medicalIdentificationNumber()
    });

export const getGenericProfileValidationSchema = () =>
    yup.object({
        firstName: name(),
        lastName: name(),
        ...gender(),
        ...academicDegree(),
        mobile: createYupPhoneValidationSchema()
    });

export const address = <IsOptional extends boolean>(isOptional?: IsOptional) => ({
    street: makeSchemaRequired(yup.string().nullable(), isOptional),
    apartmentNumber: makeSchemaRequired(yup.string().nullable(), isOptional),
    postalCode: makeSchemaRequired(
        yup
            .string()
            .nullable()
            .when('country', {
                is: 'DEU',
                then: yup.string().matches(/(^\d{5})$/, 'common.zip-code.deu-required-format')
            })
            .when('country', {
                is: (value: string) => ['AUT', 'CHE'].includes(value),
                then: yup.string().matches(/(^\d{4})$/, 'common.zip-code.aut-che-required-format')
            }),
        isOptional
    ),
    city: makeSchemaRequired(yup.string().nullable(), isOptional),
    country: makeSchemaRequired(yup.string().nullable(), isOptional),
    isPersonal: yup.boolean().nullable().required(),
    id: yup.string() as StringSchema<Types.UUID>,
    fullName: yup.string().nullable().when('isPersonal', {
        is: false,
        then: yup.string().required().nullable()
    }),
    company: yup.string().nullable().when('isPersonal', {
        is: false,
        then: yup.string().optional().nullable()
    }),
    additionalInformation: yup.string().nullable().optional(),
    toDelete: yup.boolean().optional()
});
