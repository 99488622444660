import React from 'react';

import useStyles from './MembershipFeaturesBanner.styles';
import { Box } from '@mui/material';
import { Button, Typography } from 'components';
import BulletPoints from '../BulletPoints/BulletPoints';
import { BulletPointProps } from '../BulletPoints/BulletPoint.types';
import NextLink from '../NextLink';
import { BOOKING_URLS } from '../../urls';

type Props = {
    isOnDemandCourseBanner?: boolean;
    buttonHref?: string;
};

const MembershipFeaturesBanner = ({ isOnDemandCourseBanner, buttonHref }: Props) => {
    const classes = useStyles();
    const itemsOptions: Pick<
        BulletPointProps,
        'variant' | 'color' | 'iconColor' | 'icon' | 'classes'
    > = {
        classes: {
            text: classes.bulletPoint
        },
        variant: 'h5',
        color: 'offWhite',
        iconColor: 'secondary',
        icon: 'successSolid'
    };

    const items = isOnDemandCourseBanner
        ? [
              {
                  ...itemsOptions,
                  localeId: 'common.booking.event.membership-ondemand-features.feature-1'
              },
              {
                  ...itemsOptions,
                  localeId: 'common.booking.event.membership-ondemand-features.feature-2'
              },
              {
                  ...itemsOptions,
                  localeId: 'common.booking.event.membership-ondemand-features.feature-3'
              },
              {
                  ...itemsOptions,
                  localeId: 'common.booking.event.membership-ondemand-features.feature-4'
              }
          ]
        : [
              {
                  ...itemsOptions,
                  localeId: 'common.booking.event.membership-features.feature-1'
              },
              {
                  ...itemsOptions,
                  localeId: 'common.booking.event.membership-features.feature-2'
              },
              {
                  ...itemsOptions,
                  localeId: 'common.booking.event.membership-features.feature-3'
              }
          ];

    return (
        <Box className={classes.membershipsFeaturesContainer}>
            <Typography
                variant="h4"
                localeId="common.booking.event.membership-features.title"
                color="common"
                colorVariant="white"
            />
            <BulletPoints items={items} />
            <Box className={classes.membershipButtonContainer}>
                <Button variant="secondary" uppercase={false}>
                    <NextLink
                        href={buttonHref || BOOKING_URLS.memberships}
                        className={classes.membershipButtonLink}
                    >
                        <Typography
                            localeId="common.booking.event.membership-features.button"
                            color="offWhite"
                        />
                    </NextLink>
                </Button>
            </Box>
        </Box>
    );
};

export default MembershipFeaturesBanner;
