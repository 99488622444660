import React, { PropsWithChildren } from 'react';
import {
    Footer,
    useHeaderHeight,
    PayNotPaidMembershipHeaderBanner,
    useIsDesktop,
    HeaderDesktop,
    HeaderMobile,
    SimpleFooter,
    useMarketingHomeContext
} from 'common';
import { Box, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { ThemeTypes } from 'components';
import { TenantConfig } from '../utils/tenant';
import classNames from 'classnames';

const useStyles = makeStyles<ThemeTypes.Theme, { headerHeight: number }>((theme) => ({
    paper: {
        backgroundColor: theme.palette.common.white,
        margin: 0,
        position: 'sticky',
        top: 0,
        right: 0,
        left: 0,
        zIndex: 1000,
        borderRadius: 0
    },
    content: ({ headerHeight }) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: `calc(100vh - ${headerHeight}px)`
    }),
    withMargin: {
        marginTop: ({ headerHeight }) => headerHeight
    }
}));

type DefaultLayoutProps = PropsWithChildren & {
    isMobile?: boolean | undefined | null;
    tenantConfig?: TenantConfig | null;
};

export const DefaultLayout = ({ children, isMobile, tenantConfig }: DefaultLayoutProps) => {
    const headerHeight = useHeaderHeight({ defaultMatches: isMobile === false });
    const { isMarketingHomeEnabled } = useMarketingHomeContext();
    const classes = useStyles({
        headerHeight: tenantConfig?.id != null ? 120 : headerHeight
    });
    const isDesktop = useIsDesktop({ defaultMatches: isMobile !== true });

    return (
        <>
            <Paper className={classes.paper}>
                {isDesktop ? <HeaderDesktop /> : <HeaderMobile />}
            </Paper>
            <Box
                className={classNames(classes.content, {
                    [classes.withMargin as string]: !isMarketingHomeEnabled
                })}
            >
                <PayNotPaidMembershipHeaderBanner isMobile={isMobile ?? true} />
                {children}
                {tenantConfig?.id != null ? <SimpleFooter /> : <Footer />}
            </Box>
        </>
    );
};
