import {
    booleanFilter,
    getIsMembershipForTrial,
    Membership,
    MembershipCycleByCountry,
    Types,
    WITHOUT_MEMBERSHIP_ID
} from '..';

/**
 * Use this helper to get the showable memberships. As we are offering some free memberships as
 * trial membership, we don't want to show them while offering the available memberships to the customers.
 * Allows to filter by occupation type as well
 * @param memberships - memberships to filter
 * @param allowedOccupationTypes - list of allowed occupation types. No filter for occupation types if array is absent
 * @param showTrial - flag to include trial memberships in the list
 * @param membershipCycleByCountry - a map including the membership cycles by country
 */
export const getMembershipsToShow = (
    memberships: Array<Membership | null | undefined>,
    allowedOccupationTypes?: Array<Types.OccupationType> | null | undefined,
    showTrial?: boolean,
    membershipCycleByCountry?: MembershipCycleByCountry
): Array<Membership> => {
    return memberships
        .filter(booleanFilter)
        .filter((membership) =>
            showTrial && membershipCycleByCountry !== undefined
                ? membership.cycle == membershipCycleByCountry?.[membership.country]?.[0]
                : true
        )
        .filter((membership) =>
            allowedOccupationTypes && allowedOccupationTypes.length > 0
                ? membership.occupationType &&
                  allowedOccupationTypes.includes(membership.occupationType)
                : true
        )
        .filter(
            (membership) =>
                membership.price > 0 ||
                (membership.price === 0 && membership.id === WITHOUT_MEMBERSHIP_ID) ||
                (showTrial && getIsMembershipForTrial(membership))
        )
        .sort((a, b) => (a.id !== WITHOUT_MEMBERSHIP_ID ? a.orderPriority - b.orderPriority : 0));
};

export default getMembershipsToShow;
