import React from 'react';
import classnames from 'classnames';

import useStyles from './Alert.styles';
import { Backdrop, Box } from '@mui/material';
import { AlertProps } from './Alert.types';
import { Icon } from '../Icon';
import { Typography } from '../Typography';

const Alert = (props: AlertProps) => {
    const classes = useStyles(props);
    const {
        messageLocaleId,
        messageValues,
        message,
        titleLocaleId,
        titleValues,
        variant,
        actionButton,
        onClose
    } = props;

    return (
        <Backdrop
            open
            className={classes.backdrop}
            onClick={onClose}
            data-testid="components/alert"
        >
            <Box
                className={classnames(classes.container, `test_alert_${variant}`)}
                onClick={(event) => {
                    event.stopPropagation();
                }}
            >
                <Box className={classes.iconContainer}>
                    <Icon icon={variant} className={classnames(classes.icon, classes[variant])} />
                </Box>
                <Icon icon="close" className={classes.closeIcon} onClick={onClose} />
                <Box className={classes.contentContainer}>
                    {titleLocaleId != null && (
                        <Box className={classes.title}>
                            <Typography
                                localeId={titleLocaleId}
                                localeValues={titleValues}
                                variant="h3"
                            />
                        </Box>
                    )}
                    <Box>
                        <Typography
                            title={message}
                            localeId={messageLocaleId}
                            localeValues={messageValues}
                            variant="body1"
                        />
                    </Box>
                    {actionButton && (
                        <Box className={classes.actionButton} onClick={onClose}>
                            {actionButton}
                        </Box>
                    )}
                </Box>
            </Box>
        </Backdrop>
    );
};

export default Alert;
