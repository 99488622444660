import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
    (theme) => ({
        container: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'flex-end'
        },
        button: {
            height: 36,
            paddingInline: theme.spacing(2)
        },
        avatar: {
            width: 40,
            height: 40,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            fontWeight: 400,
            ...theme.typography.textXSmall,
            cursor: 'pointer',

            '&:hover': {
                outlineOffset: 1,
                outline: `1px solid ${theme.palette.label.main}`
            }
        },
        primaryButton: {
            marginLeft: theme.spacing(1),
            textTransform: 'none',
            [theme.breakpoints.only('md')]: {
                paddingLeft: theme.spacing(1.5),
                paddingRight: theme.spacing(1.5)
            }
        },
        buttonText: {
            letterSpacing: 0
        },
        link: {
            display: 'flex',
            width: '100%',
            height: '100%',
            alignItems: 'center'
        },
        accreditationPointsContainer: {
            display: 'none',
            height: 34,
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            alignItems: 'center',
            marginRight: theme.spacing(1),
            position: 'relative',
            ...theme.border({ width: 0.5, color: theme.palette.body.light, radius: 6 }),
            [theme.breakpoints.up('sm')]: {
                display: 'flex'
            },
            [theme.breakpoints.up('lg')]: {
                marginRight: theme.spacing(2)
            }
        },
        accreditationPoints: {
            fontWeight: 600,
            whiteSpace: 'pre',
            '& b': {
                color: theme.palette.secondary.main,
                margin: theme.spacing(0, 0.5)
            }
        },
        addonsContainer: {
            marginRight: theme.spacing(2.5)
        }
    }),
    // so that "primaryButton" has more priorty over Button
    { name: 'HeaderUserNavBar', index: 10 }
);

export default useStyles;
