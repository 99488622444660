import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 60,
        height: 60,
        background: theme.palette.background.default,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '50%',
        fontSize: 26,
        textTransform: 'uppercase'
    }
}));

export default useStyles;
