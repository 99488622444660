import { CATALOG_URLS } from '../urls';
import buildURLWithParams from './buildURLWithParams';

type Content = { contentId: string | null | undefined; slug?: string | null | undefined };

export const generateContentURL = (
    { slug, contentId }: Content,
    redirectToProgram = false
): string => {
    const newURL = `${buildURLWithParams(CATALOG_URLS.content, [
        slug ?? contentId ?? ''
    ])}${redirectToProgram ? '#program' : ''}`;
    return newURL;
};

export const generateOnDemandContentURL = ({ slug, contentId }: Content): string => {
    return buildURLWithParams(CATALOG_URLS.onDemandContent, [slug ?? contentId ?? '']);
};

export const generateOnDemandCourseURL = ({ slug, contentId }: Content): string => {
    return buildURLWithParams(CATALOG_URLS.onDemandAccreditationCourse, [slug ?? contentId ?? '']);
};
