import React from 'react';
import { Box } from '@mui/material';
import useStyles from './PayNotPaidMembershipHeaderBanner.styles';
import { Button, Icon, Typography } from 'components';
import { BOOKING_URLS, useRouter, useMembershipContext, Types } from '../../..';

export const PayNotPaidMembershipHeaderBanner = ({ isMobile }: { isMobile: boolean }) => {
    const classes = useStyles();
    const router = useRouter();
    const { userMembership } = useMembershipContext();

    const isMembershipRenewalAllowed =
        userMembership && userMembership.state === Types.UserMembershipState.NotPaid;

    if (router.pathname === '/dashboard/membership' || !isMembershipRenewalAllowed) {
        return null;
    }

    // On content page do not make it stick to prevent visual bugs.
    const position = router.pathname.startsWith('/content/') ? 'initial' : 'sticky';
    return (
        <Box className={classes.container} position={position}>
            <Box className={classes.contentContainer}>
                <Icon icon="info" className={classes.infoIcon} />
                {isMobile ? (
                    <Typography
                        className={classes.title}
                        variant="body2"
                        localeId="common.header.pay-not-paid.mobile"
                    />
                ) : (
                    <Typography
                        className={classes.title}
                        variant="subtitle2"
                        localeId="common.header.pay-not-paid.desktop"
                    />
                )}
                <Button
                    className={classes.button}
                    color="error"
                    variant="outlined"
                    size={isMobile ? 'small' : 'medium'}
                    href={`${BOOKING_URLS.memberships}/${userMembership?.membership.id}`}
                >
                    <Typography
                        className={classes.buttonText}
                        localeId="common.membership.pay-not-paid.button"
                    />
                </Button>
            </Box>
        </Box>
    );
};
