import React, { RefObject } from 'react';
import Link from 'next/link';
import { Link as MUILink, LinkProps as MUILinkProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { ThemeTypes } from 'components';

const useStyles = makeStyles<ThemeTypes.Theme>(
    (theme) => ({
        link: {
            color: theme.palette.primary.main,
            fontWeight: 500,
            textDecoration: 'none'
        }
    }),
    { name: 'Link' }
);

type Props = Pick<MUILinkProps, 'children' | 'className' | 'target'> & {
    href: string;
    onClick?: (
        e:
            | React.MouseEvent<HTMLAnchorElement, MouseEvent>
            | React.MouseEvent<HTMLSpanElement, MouseEvent>
    ) => void;
    ['data-testid']?: string;
};

const NextLink = React.forwardRef((props: Props, ref: React.Ref<HTMLElement>) => {
    const { children, href, className, onClick, ...otherProps } = props;
    const classes = useStyles();

    const linkClasses = classNames(classes.link, className);

    return (
        <MUILink
            className={linkClasses}
            ref={ref as RefObject<HTMLAnchorElement>}
            href={href}
            component={Link}
            onClick={onClick}
            {...otherProps}
        >
            {children}
        </MUILink>
    );
});

NextLink.displayName = 'NextLink';

export default NextLink;
