import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    spacing: {
        marginBottom: theme.spacing(2),
        '&:last-child': {
            marginBottom: 0
        }
    },
    addAddressButtonIcon: {
        width: 32,
        height: 32
    },
    addressItem: {
        padding: theme.spacing(2),
        ...theme.border(),

        transition: theme.transitions.create(['background-color', 'border-color']),

        '&$active': {
            backgroundColor: theme.palette.accent.main,
            borderColor: theme.palette.accent.main
        },

        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(4, 7)
        }
    },
    active: {}
}));

export default useStyles;
