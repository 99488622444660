import { Fragments, Types, booleanFilter } from 'common';
import uniq from 'lodash/uniq';

/**
 * This helper is useful to sort the attachments by file name
 *
 * @param attachments
 * @returns a list of Attachments sorted by file name
 */
const sortAttachments = (attachments: Array<Fragments.AttachmentFieldsFragment>) =>
    attachments.sort(
        (attachmentA, attachmentB) =>
            attachmentA?.file?.name?.localeCompare(attachmentB?.file?.name || '') ?? 0
    );

/**
 * This helper is useful to sort the attachments by the groupId
 *
 * @param attachments
 * @returns a list of Attachments sorted by groupId
 */
const sortAttachmentSections = (attachments: Array<Fragments.AttachmentFieldsFragment>) =>
    attachments.sort(
        (attachmentA, attachmentB) =>
            attachmentA?.groupId?.localeCompare(attachmentB?.groupId || '') ?? 0
    );

type ContentAttachments = Partial<
    Pick<Fragments.ContentWithProgramSchedulesFieldsFragment, 'attachments'>
>;

const getAttachments = ({
    content,
    isEventBooked
}: {
    content?: ContentAttachments;
    isEventBooked: boolean;
}) => {
    if (!content?.attachments) {
        return [];
    } else if (isEventBooked) {
        return content.attachments;
    }
    return sortAttachments(
        content.attachments.filter(
            (attachment) =>
                attachment.attachmentType === Types.AttachmentType.Public ||
                attachment.attachmentType === Types.AttachmentType.Authenticated
        )
    );
};

const categorizeAttachments = (props: {
    content?: Fragments.ContentWithProgramSchedulesFieldsFragment;
    isEventBooked: boolean;
    loading: boolean;
}) => {
    const attachments = getAttachments(props);
    const attachmentsWithGroup: Array<Fragments.AttachmentFieldsFragment> = [];
    const attachmentsWithNoGroup: Array<Fragments.AttachmentFieldsFragment> = [];
    attachments.forEach((attachment) => {
        if (attachment.groupId && attachment.groupId.length > 0) {
            attachmentsWithGroup.push(attachment);
        } else {
            attachmentsWithNoGroup.push(attachment);
        }
    });

    return {
        attachmentsWithGroup: sortAttachments(attachmentsWithGroup),
        attachmentsWithNoGroup: sortAttachments(attachmentsWithNoGroup)
    };
};

const getAttachmentGroupIds = (attachments: Array<Fragments.AttachmentFieldsFragment>) =>
    uniq(sortAttachmentSections(attachments).map((attachment) => attachment.groupId)).filter(
        booleanFilter
    );

const getAttachmentsByGroup = (
    groupId: string,
    attachments: Array<Fragments.AttachmentFieldsFragment>
) => {
    return sortAttachments(attachments.filter((attachment) => attachment.groupId === groupId));
};

const getIconName = (attachment: Fragments.AttachmentFieldsFragment) =>
    attachment.attachmentType === Types.AttachmentType.DigitalCoursebook ? 'book' : 'download';

export default {
    getAttachments,
    categorizeAttachments,
    getAttachmentGroupIds,
    getAttachmentsByGroup,
    getIconName
};
