import React from 'react';
import classnames from 'classnames';
import { Box, FormHelperText } from '@mui/material';

import { CheckboxProps } from './Checkbox.types';
import useStyles from './Checkbox.styles';
import { Icon, mergeClasses, Typography } from '../index';

const Checkbox = (props: CheckboxProps) => {
    const {
        checked,
        disabled,
        onChange,
        className,
        label,
        labelLocaleId,
        labelLocaleValues,
        threeWay,
        error,
        helperText,
        'data-test': dataTest,
        'data-testid': dataTestEntityId
    } = props;
    const classes = mergeClasses(useStyles(props), props.classes);
    const onClick = disabled ? () => undefined : onChange;

    const showLabel = !!(label || labelLocaleId);

    const checkedFalse = threeWay ? checked === false : null;
    const checkedTrue = checked === true;

    return (
        <>
            <Box
                className={classnames(
                    classes.container,
                    { [classes.disabled]: disabled, [classes.error]: error },
                    className
                )}
                onClick={onClick}
                data-test-checked={checked}
                data-test={dataTest}
                data-testid={dataTestEntityId}
            >
                <Box
                    className={classnames(classes.checkmarkContainer, {
                        [classes.checked]: checkedTrue,
                        [classes.checkedFalse]: checkedFalse,
                        [classes.inactive]: !onChange
                    })}
                >
                    <Icon
                        icon={checkedFalse ? 'cross' : 'checkmarkRound'}
                        className={classes.checkmark}
                    />
                </Box>
                {showLabel && (
                    <Box className={classes.label}>
                        {label
                            ? label
                            : labelLocaleId && (
                                  <Typography
                                      localeId={labelLocaleId}
                                      localeValues={labelLocaleValues}
                                  />
                              )}
                    </Box>
                )}
            </Box>

            {helperText && <FormHelperText error>{helperText}</FormHelperText>}
        </>
    );
};

export default Checkbox;
