/* eslint-disable no-duplicate-imports */
import { useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import type { CustomFormikProps, FormikFormType, SchemaValues } from '../';
import { scrollToErrorField } from '../';
import { AnyObjectSchema } from 'yup';

const useCustomFormik = <
    Schema extends AnyObjectSchema,
    Values extends SchemaValues<Schema> = SchemaValues<Schema>
>(
    props: CustomFormikProps<Schema>
): FormikFormType<Values> => {
    const {
        validationSchema,
        initialValues: initialValuesFromProps,
        keepId,
        keepTypename,
        ...rest
    } = props;

    const { id, __typename, ...restInitialValues } = initialValuesFromProps;
    const initialValues = useMemo(
        () => ({
            ...restInitialValues,
            ...(keepId ? { id } : undefined),
            ...(keepTypename ? { __typename } : undefined)
        }),
        [restInitialValues]
    );

    const formik = useFormik<Values>({
        ...rest,
        validationSchema,
        initialValues: initialValues as Values
    });

    const result = useMemo(
        () => ({
            ...formik,
            validationSchema
        }),
        [formik, validationSchema]
    );

    const { errors, isValid, submitCount } = formik;

    useEffect(() => {
        if (isValid) {
            return;
        }

        scrollToErrorField(errors);
    }, [submitCount]);

    return result;
};

export default useCustomFormik;
