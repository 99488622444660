import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

interface AspectRatioOptions {
    children: React.ReactChild | React.ReactChildren;
}

const useStyle = makeStyles({
    container: {
        position: 'relative',
        '&::before': {
            height: 0,
            content: `""`,
            display: 'block',
            // formula: (1/ratio) * 100% ex: 1/(16/9) * 100%
            paddingBottom: '56.25%'
        },
        '& > *:not(style)': {
            overflow: 'hidden',
            position: 'absolute',
            top: '0',
            right: '0',
            bottom: '0',
            left: '0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%'
        },
        '& > img, & > video': {
            objectFit: 'cover'
        }
    }
});

const AspectRatio = (props: AspectRatioOptions) => {
    const { children } = props;

    // enforce single child
    const child = React.Children.only(children) as React.ReactNode;
    const classes = useStyle();

    return <div className={classes.container}>{child}</div>;
};

export default AspectRatio;
