import { Types } from '..';
import formatDurationInSeconds from './formatDurationInSeconds';

type FormatContentDurationProps = NonNullable<Types.Content['source']>['duration'];

export const formatContentDuration = (duration: FormatContentDurationProps): string | null => {
    if (duration) {
        return formatDurationInSeconds(duration);
    }
    return null;
};
