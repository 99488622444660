import { gql } from '@apollo/client';
import { env } from 'common';

export const bookmarkContent = gql`
    mutation bookmarkContent($contentId: String!, $bookmarked: Boolean!) {
        bookmarkContent(contentId: $contentId, bookmarked: $bookmarked)
    }
`;

export const addToMailchimpAudience = gql`
    mutation addToMailchimpAudience(
        $email: String!
        $locale: Locale!
        $mobile: String
        $tags: [String!]!
    ) {
        addToMailchimpAudience(email: $email, locale: $locale, mobile: $mobile, tags: $tags) {
            added
        }
    }
`;

export const trackContentProgress = gql`
    mutation trackContentProgress($completed: Boolean, $contentId: String!, $time: Float) {
        trackContentProgress(completed: $completed, contentId: $contentId, time: $time)
    }
`;

export const answerQuestions = gql`
    mutation answerQuestions($userAnswers: [UserAnswer!]!) {
        answerQuestions(userAnswers: $userAnswers)
    }
`;

export const answerQuestion = gql`
    mutation answerQuestion($userAnswer: UserAnswer!) {
        answerQuestion(userAnswer: $userAnswer)
    }
`;

export const orderEvent = gql`
    mutation orderEvent(
        $bookingRequest: BookingRequest!
        $tenantId: ID = "${env.TENANT_ID}"
        $userId: ID!
    ) {
        orderEvent(
            bookingRequest: $bookingRequest
            tenantId: $tenantId
            userId: $userId
        ) {
            bookingId
            totalPrice
            clientReference
            automaticallyCharged
        }
    }
`;

export const updateBooking = gql`
    mutation updateBooking(
        $bookingId: ID!
        $bookingRequest: BookingRequest!
        $tenantId: ID = "${env.TENANT_ID}"
    ) {
        updateBooking(bookingId: $bookingId, bookingRequest: $bookingRequest, tenantId: $tenantId) {
            clientReference
        }
    }
`;

export const orderMembership = gql`
    mutation orderMembership(
        $tenantId: ID = "${env.TENANT_ID}"
        $checkoutSessionId: String
        $membershipOrderRequest: MembershipOrderRequest!
        $userId: ID!
    ) {
        orderMembership(
            tenantId: $tenantId
            checkoutSessionId: $checkoutSessionId
            membershipOrderRequest: $membershipOrderRequest
            userId: $userId
        ) {
            clientReference
            id
        }
    }
`;

export const payNotPaidMembershipOrder = gql`
    mutation payNotPaidMembershipOrder(
        $tenantId: ID = "${env.TENANT_ID}"
        $userMembershipId: ID!
        $paymentMethod: PaymentMethod!
    ) {
        payNotPaidMembershipOrder(
            tenantId: $tenantId,
            userMembershipId: $userMembershipId,
            paymentMethod: $paymentMethod
        ) {
            clientReference
            id
        }
    }
`;

export const EarlyRenewMembership = gql`
    mutation EarlyRenewMembership($userId: ID!) {
        earlyRenewMembership(userId: $userId) {
            earlyRenewed
        }
    }
`;

export const CreateReminderConfig = gql`
    mutation CreateReminderConfig(
        $userId: ID!
        $createEventReminderInput: CreateReminderConfigInput!
    ) {
        createReminderConfig(
            userId: $userId
            createReminderConfigInput: $createEventReminderInput
        ) {
            id
        }
    }
`;

export const DeleteReminderConfig = gql`
    mutation DeleteReminderConfig($userId: ID!, $reminderConfigId: ID!) {
        deleteReminderConfig(userId: $userId, reminderConfigId: $reminderConfigId) {
            deleted
        }
    }
`;

export const SetWatchedVideoStatus = gql`
    mutation SetWatchedVideoStatus($contentMediaId: String!, $isDeleted: Boolean!) {
        setWatchedVideoStatus(contentMediaId: $contentMediaId, deleted: $isDeleted)
    }
`;
