import React from 'react';
import { ThemeProvider as MUIThemeProvider } from '@mui/material';
import { ThemeProviderProps } from '@mui/styles';
import { ThemeTypes } from '..';

type Props = Omit<ThemeProviderProps, 'theme'> & {
    theme: ThemeTypes.Theme;
};

const ThemeProvider = (props: Props) => {
    const { children, theme, ...others } = props;
    return (
        <MUIThemeProvider theme={theme} {...others}>
            {children}
        </MUIThemeProvider>
    );
};

export default ThemeProvider;
