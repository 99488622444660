import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import {
    FormikInputProps,
    FormikInputWrapper,
    TextField,
    TextFieldProps,
    UseFormikProps
} from '../';

type Props = Omit<FormikInputProps<TextFieldProps>, 'intl' | 'formik'> & UseFormikProps;

/**
 * Use this component for TextFields under a formik form.
 */
const FormikTextField = ({
    error,
    hasError,
    value,
    gridProps,
    gridClassName,
    ...others
}: Props) => {
    return (
        <FormikInputWrapper gridProps={gridProps} className={gridClassName}>
            <TextField
                fullWidth
                value={value ?? ''}
                error={hasError}
                helperText={hasError ? <FormattedMessage {...error} /> : null}
                {...others}
            />
        </FormikInputWrapper>
    );
};

export default memo(FormikTextField);
