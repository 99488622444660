import { createApolloClient, Queries, Types } from 'common';

const getContentByExternalId = async (externalId: string, cookie?: string) => {
    const client = createApolloClient({ batchHttpLinkOptions: { headers: { cookie } } });
    const { data, error } = await client.query<Queries.GetTenantContentsQuery | null>({
        query: Queries.GetTenantContents,
        variables: {
            searchQuery: {
                filters: [
                    {
                        field: 'externalId',
                        value: externalId,
                        operation: Types.SearchFilterOperation.Equal
                    }
                ]
            }
        },
        errorPolicy: 'ignore'
    });
    if (data?.contentsV2.data.length === 1) {
        return {
            content: data.contentsV2.data[0],
            error
        };
    }
    return { content: null, error };
};

export default getContentByExternalId;
