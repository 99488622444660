import getDomainFromLocation from './utils/getDomainFromLocation';
import * as Types from './graphql/generated';
import { COUNTRY_CODE_3_TO_DOMAIN } from './constants';

type ENV = 'prod' | 'staging';

const FOMF_API_BASE_URL = process.env.NEXT_PUBLIC_FOMF_API_BASE_URL;
const FOMF_SERVICE_API_BASE_URL = process.env.NEXT_PUBLIC_FOMF_SERVICE_API_BASE_URL;
const CMED_API_BASE_URL = process.env.NEXT_PUBLIC_CMED_API_BASE_URL;

const getApiDomain = () => {
    const domain = getDomainFromLocation();
    const apiDomain =
        domain === 'uk'
            ? CMED_API_BASE_URL
            : (FOMF_API_BASE_URL || '').replace('.org', `.${domain}`);
    return apiDomain;
};

const getServiceApiDomain = () => {
    const domain = getDomainFromLocation();
    const apiDomain =
        domain === 'uk'
            ? CMED_API_BASE_URL
            : (FOMF_SERVICE_API_BASE_URL || '').replace('.org', `.${domain}`);
    return apiDomain;
};

// for user-service always stick to the main api
const USER_SERVICE_BASE_URL = () =>
    `${getServiceApiDomain()?.replace('api-vercel', 'api')}/user-service`;

const BOOKING_SERVICE_BASE_URL = () => `${getServiceApiDomain()}/booking-service`;

const APOLLO_CLIENT_URI = () => `${getApiDomain()}/graph`;

const STRIPE_PUBLISHABLE_KEY = (country?: CountryCode3) => {
    const domainToKey: Record<Domain, string | undefined> = {
        de: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY_DE,
        at: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY_AT,
        ch: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY_CH,
        uk: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY_UK
    };
    const domain = country ? COUNTRY_CODE_3_TO_DOMAIN[country] : getDomainFromLocation();
    return domainToKey[domain] || '';
};

const ENV = process.env.NEXT_PUBLIC_ENV as ENV | undefined;
const GOOGLE_MAP_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY;

const SEGMENT_WRITE_KEY_BY_LOCALE = (locale: Locale) => {
    switch (locale) {
        case 'de-DE':
            return process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY_DEU;
        case 'de-AT':
            return process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY_AUT;
        case 'de-CH':
        case 'fr-CH':
            return process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY_CHE;
        case 'en-GB':
            return process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY_GBR;
    }
};

const LAUNCHDARKLY_CLIENT_SIDE_ID = process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_SIDE_ID;

if (!LAUNCHDARKLY_CLIENT_SIDE_ID) {
    throw new Error('LAUNCHDARKLY_CLIENT_SIDE_ID is not defined');
}

const IS_PREVIEW_ENVIRONMENT =
    process.env.NEXT_PUBLIC_IS_PREVIEW_ENVIRONMENT || process.env.IS_PREVIEW_ENVIRONMENT;

const env = {
    SAAS_USER_BASE_URL: process.env.NEXT_PUBLIC_SAAS_USER_BASE_URL, // unused
    OAUTH_SERVER_BASE_URL: process.env.NEXT_PUBLIC_OAUTH_SERVER_BASE_URL,
    OAUTH_CLIENT_ID: process.env.NEXT_PUBLIC_OAUTH_CLIENT_ID,
    FOMF_API_BASE_URL,
    USER_SERVICE_BASE_URL,
    BOOKING_SERVICE_BASE_URL,
    TENANT_ID: process.env.NEXT_PUBLIC_TENANT_ID as Types.UUID,
    APOLLO_CLIENT_URI,
    STRIPE_PUBLISHABLE_KEY,
    NODE_ENV: process.env.NODE_ENV,
    PAYPAL_CLIENT_ID: process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID,
    ENV,
    GTM_ID: process.env.NEXT_PUBLIC_GTM_ID,
    GOOGLE_MAP_API_KEY,
    SEGMENT_WRITE_KEY_BY_LOCALE,
    LAUNCHDARKLY_SERVER_SIDE_SDK_KEY: process.env.NEXT_PUBLIC_LAUNCHDARKLY_SERVER_SIDE_SDK_KEY,
    LAUNCHDARKLY_CLIENT_SIDE_ID,
    IS_PREVIEW_ENVIRONMENT,
    DATOCMS_READONLY_API_TOKEN: process.env.DATOCMS_READONLY_API_TOKEN
};

export default env;
