import React from 'react';
import { Types } from '../../';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    ratingContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: theme.spacing(0.5),
        fontFamily: 'Roboto',
        lineHeight: '20px',
        fontSize: '14px',
        fontWeight: 600,
        userSelect: 'none',
        color: theme.palette.primary.main
    },
    starIcon: {
        width: 14,
        height: 14,
        marginBlockEnd: 2
    },
    ratingText: {
        fontWeight: 500
    }
}));

type VideoRatingProps = Pick<Types.ContentDocument, 'rating'>;

const VideoRating = ({ rating }: VideoRatingProps) => {
    const classes = useStyles();

    if (!rating) {
        return <div className={classes.ratingContainer}>&#8203;</div>;
    }

    return (
        <div className={classes.ratingContainer} data-testid="video-rating">
            <img
                className={classes.starIcon}
                src="https://bk-fms-eu.storage.googleapis.com/public/static/icon-star-rating.svg"
                alt="Star"
            />
            <span className={classes.ratingText}>{`${Math.round(rating * 10) / 10}`}</span>
            {` / 5`}
        </div>
    );
};

export default VideoRating;
