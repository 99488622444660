const showFreeBadge = ({
    contentIsFree,
    parentContentIsFree,
    isMember
}: {
    contentIsFree: boolean | null | undefined;
    parentContentIsFree?: boolean | null | undefined;
    isMember: boolean;
}): boolean => {
    if ((contentIsFree || parentContentIsFree) && !isMember) {
        return true;
    }
    return false;
};

export default showFreeBadge;
