import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    container: {
        '&:hover': {
            '& $iconContainer': {
                background: theme.palette.body[200]
            }
        }
    },
    iconContainer: {
        ...theme.border({
            width: 0.5,
            color: theme.palette.body.light,
            radius: 6
        }),
        padding: theme.spacing(0.5),
        transition: theme.transitions.create('background-color')
    },
    icon: {
        margin: 0
    },
    popoverRoot: {
        pointerEvents: 'none'
    },
    popoverPaper: {
        pointerEvents: 'auto',
        minWidth: 400,
        background: 'transparent',
        boxShadow: 'none',
        borderRadius: 0,
        overflow: 'visible'
    },
    innerPaper: {
        marginTop: theme.spacing(2),
        background: theme.palette.common.white,
        borderRadius: 4,
        boxShadow:
            '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
        overflow: 'hidden'
    },
    dialogTitle: {
        ...theme.border({
            width: 1,
            kind: 'bottom',
            color: theme.palette.body.light
        })
    },
    dialogContent: {
        padding: 0
    },
    dialogCloseButton: {
        top: theme.spacing(2.5),
        right: theme.spacing(2.5)
    },
    dialogPaper: {
        background: theme.palette.common.white
    }
}));

export default useStyles;
