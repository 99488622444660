import React from 'react';
import { Switch as MUISwitch } from '@mui/material';

import { SwitchProps } from './Switch.types';
import useStyles from './Switch.styles';

const Switch = (props: SwitchProps) => {
    const { classes: classesProps, disabled: disabledProp, readOnly, ...rest } = props;
    const classes = useStyles(props);
    const disabled = disabledProp || readOnly;
    return (
        <MUISwitch
            disabled={disabled}
            classes={{
                ...classes,
                ...classesProps
            }}
            {...rest}
        />
    );
};

export default Switch;
