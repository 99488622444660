import React, { useCallback } from 'react';
import { FormControl, RadioGroup as MUIRadioGroup, FormHelperText } from '@mui/material';
import classnames from 'classnames';

import useStyles from './RadioGroup.styles';
import { RadioGroupProps } from './RadioGroup.types';
import { noop, RadioGroupItem, Typography } from '../index';

const RadioGroup = (props: RadioGroupProps) => {
    const {
        value,
        onChange = noop,
        options,
        name,
        label,
        localeId,
        error,
        helperText,
        className: classNameProp,
        typographyProps,
        disabled,
        classes: classesProp,
        radioItemRenderer = (children) => children
    } = props;
    const classes = useStyles(props);
    const isBooleanValues = options
        .map((item) => item.id)
        .reduce((acc, item) => (typeof item === 'boolean' ? acc : false), true);
    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
            if (isBooleanValues) {
                onChange(event, value === 'true');
            } else {
                onChange(event, value);
            }
        },
        [isBooleanValues, onChange]
    );

    const formikValue = typeof value === 'undefined' ? '' : value;

    return (
        <FormControl
            component="fieldset"
            className={classnames({ [classes.disabled]: disabled }, classNameProp)}
        >
            {(localeId || label) && (
                <Typography
                    variant="displayXSmall"
                    localeId={localeId}
                    title={label}
                    color="primary"
                    className={classnames(classes.label, { [classes.error]: error })}
                    {...typographyProps}
                />
            )}
            <MUIRadioGroup name={name} value={formikValue} onChange={handleChange}>
                {options.map((option, index) =>
                    radioItemRenderer(
                        <RadioGroupItem
                            {...option}
                            disabled={disabled}
                            key={option.id.toString() || index.toString()}
                            className={classesProp?.item}
                        />,
                        index
                    )
                )}
            </MUIRadioGroup>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
};

export default RadioGroup;
