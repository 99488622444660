import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            position: 'relative'
        },
        edit: {
            position: 'absolute',
            top: 0,
            right: 0
        },
        delete: {
            position: 'absolute',
            top: theme.spacing(4),
            right: 0
        }
    }),
    { name: 'UserAddress' }
);

export default useStyles;
