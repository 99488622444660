import classnames from 'classnames';
import React from 'react';

import { CircularProgress, CircularProgressProps, Box } from '@mui/material';
import useStyles from './Loader.styles';

interface Props extends CircularProgressProps {
    /**
     * If true, the loader is placed under a container and centered both vertical and horizontally.
     * @default false
     */
    fullScreen?: boolean;
    /**
     * If true the loader will cover all page
     */
    cover?: boolean;
    className?: string;
}

const CustomLoader = (props: Props) => {
    const { fullScreen = false, className, cover, ...others } = props;
    const classes = useStyles();
    if (cover) {
        return (
            <Box className={classes.cover}>
                <CircularProgress {...others} />
            </Box>
        );
    }
    if (fullScreen) {
        return (
            <Box className={classes.container}>
                <CircularProgress {...others} />
            </Box>
        );
    }
    return <CircularProgress {...others} className={classnames(className, classes.inline)} />;
};

export type { Props };
export default CustomLoader;
