import makeStyles from '@mui/styles/makeStyles';
import type { ThemeTypes } from '../';
import { RadioGroupProps, RadioGroupClasses } from './RadioGroup.types';

const useStyles = makeStyles<ThemeTypes.Theme, RadioGroupProps, RadioGroupClasses>((theme) => ({
    label: {
        marginBottom: theme.spacing(2)
    },
    error: {
        color: theme.palette.error.main
    },
    disabled: {
        cursor: 'not-allowed',
        pointerEvents: 'all',

        '& .MuiFormControlLabel-root.Mui-disabled': {
            pointerEvents: 'none'
        }
    }
}));

export default useStyles;
