import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { useIntl } from 'react-intl';
import {
    Button,
    FormikTextField,
    FormikSelect,
    FormikPhoneInput,
    Typography,
    FormikCountriesSelect
} from 'components';
import {
    useUpdateUserDetails,
    useAcademicDegreesOptions,
    Types,
    UserOptions,
    UserTransformers,
    useUserFullName
} from '../../';
import AddressForm from './AddressForm';
import { EditPersonalInfoProps } from './EditPersonalInfo.types';
import useStyles from './EditPersonalInfo.styles';
import {
    useAddressFormik,
    usePersonalInfoFormik,
    useUpdateUserAddress
} from './EditPersonalInfo.utils';
import UserAddressItem from './UserAddressItem';

const NEW_ADDRESS_ID = 'NEW_ADDRESS_ID';

const EditPersonalInfo = (props: EditPersonalInfoProps) => {
    const { user, 'data-test': dataTest, isAdmin = false } = props;
    const { locale } = useIntl();
    const classes = useStyles();
    const [updateUser, { loading }] = useUpdateUserDetails();
    const updateUserAddress = useUpdateUserAddress(user, updateUser);
    const userFullName = useUserFullName(user);

    const [editAddressId, setEditAddressId] = useState<string | null>(null);
    const showAddAddressForm = () => setEditAddressId(NEW_ADDRESS_ID);
    const closeAddressForm = () => {
        addressFormik.resetForm();
        setEditAddressId(null);
    };
    const otherAddresses = (user?.addresses || [])
        .filter((item) => item && !item?.isPersonal)
        .map((item) => UserTransformers.address(item, userFullName));

    const personalInfoFormik = usePersonalInfoFormik({ user, isAdmin, updateUser });
    const addressFormik = useAddressFormik({ user, updateUser, closeAddressForm });

    const showEditAddressForm = (address: Types.AddressFormik) => {
        if (address.id) {
            addressFormik.setValues(address);
            setEditAddressId(address.id);
        }
    };

    const handleAddressDelete = (address: Types.AddressFormik) => {
        if (address.id) {
            closeAddressForm();

            updateUserAddress({
                ...address,
                toDelete: true
            });
        }
    };

    const showOtherAddress = !!user.addresses?.find((item) => item?.isPersonal)?.id;

    const academicDegreesOptions = useAcademicDegreesOptions({
        locale,
        gender: personalInfoFormik.values.gender
    });

    return (
        <>
            <form noValidate onSubmit={personalInfoFormik.handleSubmit}>
                <Typography
                    localeId="common.profile.personal-info.title"
                    variant="displayXSmallBold"
                    color="primary"
                    className={classes.spacing}
                />
                <Typography
                    localeId="common.profile.personal-info.description"
                    variant="textMedium"
                    color="body"
                    className={classes.spacing}
                />
                <Grid container spacing={9}>
                    <Grid item xs={12} lg={6}>
                        <Grid container spacing={2}>
                            <FormikSelect
                                name="gender"
                                formik={personalInfoFormik}
                                options={UserOptions.gender}
                            />
                            <FormikSelect
                                name="academicDegreeCode"
                                formik={personalInfoFormik}
                                options={academicDegreesOptions}
                            />
                            <FormikTextField name="firstName" formik={personalInfoFormik} />
                            <FormikTextField name="lastName" formik={personalInfoFormik} />
                            {isAdmin && (
                                <FormikCountriesSelect name="country" formik={personalInfoFormik} />
                            )}
                            <FormikPhoneInput name="mobile" formik={personalInfoFormik} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Grid container spacing={2}>
                            {isAdmin && (
                                <FormikTextField
                                    name="ubAccountCode"
                                    localeId="admin.member.ub-id"
                                    formik={personalInfoFormik}
                                    data-test={`${dataTest}_ub-id`}
                                />
                            )}
                            <FormikTextField
                                name="personalAddress.additionalInformation"
                                localeId="common.additional-information"
                                formik={personalInfoFormik}
                                data-test={`${dataTest}_additional-information`}
                            />
                            <FormikTextField
                                name="personalAddress.street"
                                localeId="common.street"
                                formik={personalInfoFormik}
                                data-test={`${dataTest}_street`}
                            />
                            <FormikTextField
                                name="personalAddress.apartmentNumber"
                                localeId="common.apartment-number"
                                formik={personalInfoFormik}
                                data-test={`${dataTest}_apartment-number`}
                            />
                            <FormikTextField
                                name="personalAddress.city"
                                localeId="common.city"
                                formik={personalInfoFormik}
                                gridProps={{ xs: 6 }}
                                data-test={`${dataTest}_city`}
                            />
                            <FormikTextField
                                name="personalAddress.postalCode"
                                localeId="common.postal-code"
                                formik={personalInfoFormik}
                                gridProps={{ xs: 6 }}
                                data-test={`${dataTest}_postal-code`}
                            />
                            <FormikCountriesSelect
                                name="personalAddress.country"
                                localeId="common.country"
                                formik={personalInfoFormik}
                                data-test={`${dataTest}_country`}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} lg={4} />
                    <Grid item xs={12} lg={4}>
                        <Button
                            type="submit"
                            disabled={loading}
                            localeId="common.save-changes"
                            fullWidth
                            data-test={`${dataTest}_save-changes`}
                        />
                    </Grid>
                    <Grid item xs={12} lg={4} />
                    <Grid item xs={12} />
                </Grid>
            </form>

            {showOtherAddress && (
                <form noValidate onSubmit={addressFormik.handleSubmit}>
                    <Typography
                        localeId="common.profile.address-info.title"
                        variant="displayXSmallBold"
                        color="primary"
                        className={classes.spacing}
                    />
                    <Typography
                        localeId="common.profile.address-info.description"
                        variant="textMedium"
                        color="body"
                        className={classes.spacing}
                    />
                    <Grid container spacing={9}>
                        <Grid item xs={12} lg={6}>
                            {otherAddresses.map((address) => (
                                <UserAddressItem
                                    isActive={editAddressId === address.id}
                                    onEdit={() => showEditAddressForm(address)}
                                    onDelete={() => handleAddressDelete(address)}
                                    user={user}
                                    address={address}
                                    addressFormik={addressFormik}
                                    closeAddressForm={closeAddressForm}
                                    key={address.id || ''}
                                />
                            ))}
                            {!editAddressId && (
                                <Button
                                    startIcon="plusCircle"
                                    localeId="common.add-new-address"
                                    classes={{ icon: classes.addAddressButtonIcon }}
                                    iconStrokeWidth={2}
                                    variant="contained"
                                    onClick={showAddAddressForm}
                                    type="button"
                                    fullWidth
                                    size="large"
                                />
                            )}
                            {editAddressId === NEW_ADDRESS_ID && (
                                <>
                                    <Typography
                                        localeId="common.add-new-address"
                                        className={classes.spacing}
                                    />
                                    <AddressForm
                                        addressFormik={addressFormik}
                                        onClose={closeAddressForm}
                                    />
                                </>
                            )}
                        </Grid>
                    </Grid>
                </form>
            )}
        </>
    );
};

export default EditPersonalInfo;
