import {
    Fragments,
    Membership,
    transformTopUpToProduct,
    transformUserForIdentifying,
    Types
} from '../index';
import { getAnalytics } from './loadAnalyticsWithConsent';
import { transformMembershipToProduct } from './segmentTransformers';
import { IntlShape } from 'react-intl';
import dayjs from 'dayjs';

export const membershipProductAddedToCart = async (membership: Membership) => {
    const analytics = await getAnalytics();
    analytics?.web.productAdded(transformMembershipToProduct(membership));
};

export const topUpProductAddedToCart = async (
    topUp: Types.MembershipItem,
    locale: Locale,
    buyLink: string,
    index: number | undefined,
    membership: Membership
) => {
    const analytics = await getAnalytics();
    analytics?.web.productAdded(
        transformTopUpToProduct({ topUp, locale, buyLink, index, membership })
    );
};

export const identifyUser = async (
    userId: Types.UUID,
    userProps: {
        user: Fragments.UserFieldsFragment;
        userMembership: Fragments.UserMembershipFieldsFragment | null;
        intl: IntlShape;
    } | null
) => {
    const analytics = await getAnalytics();
    if (!analytics) {
        return;
    }
    const userForIdentifying = userProps ? transformUserForIdentifying(userProps) : undefined;
    analytics.segment.identify(userId, userForIdentifying);
};

export const resetUser = async () => {
    const analytics = await getAnalytics();
    return analytics?.segment.reset();
};

export const trackLogin = async () => {
    const analytics = await getAnalytics();
    analytics?.web.login({
        date_time: dayjs().toDate()
    });
};

export const trackNewsletterStart = async () => {
    const analytics = await getAnalytics();
    analytics?.web.newsletterRegistrationStarted({});
};

export const trackNewsletterCompleted = async () => {
    const analytics = await getAnalytics();
    analytics?.web.newsletterRegistrationCompleted({});
};
