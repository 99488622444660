import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        backgroundColor: '#FFF7DD',
        top: 137,
        height: '50px',
        zIndex: 110,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            height: 40,
            top: 80
        }
    },
    contentContainer: {
        width: '100%',
        maxWidth: '1440px',
        display: 'flex',
        alignItems: 'center',
        margin: theme.spacing(0.5, 0),
        paddingLeft: theme.spacing(0.5),
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            paddingLeft: 0
        }
    },
    title: {
        marginRight: theme.spacing(2),
        color: '#59748D',
        [theme.breakpoints.down('sm')]: {
            marginRight: theme.spacing(4),
            fontSize: '14px',
            lineHeight: '20px'
        }
    },
    infoIcon: {
        marginRight: theme.spacing(2),
        width: '24px',
        height: '24px',
        color: '#59748D',
        [theme.breakpoints.down('sm')]: {
            width: '16px',
            height: '16px'
        }
    },
    button: {
        borderColor: theme.palette.error.dark,
        color: theme.palette.error.dark,
        '&:hover': {
            backgroundColor: theme.palette.error.dark,
            color: theme.palette.common.white
        },
        [theme.breakpoints.down('sm')]: {
            width: '110px'
        }
    },
    buttonText: {
        textTransform: 'none',
        textWrap: 'nowrap'
    }
}));

export default useStyles;
