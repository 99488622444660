import { FormikErrors, getIn } from 'formik';
import { AnyObjectSchema } from 'yup';
import { SchemaFieldDescription, SchemaDescription } from 'yup/lib/schema';
import { SchemaValues } from './formik.types';

const getPathForSchema = (name: string) => {
    const paths = name.split('.');
    const pathForSchema = paths.join('.fields.');
    return pathForSchema;
};

const isSchemaDescription = (field: SchemaFieldDescription): field is SchemaDescription =>
    field ? !!(field as SchemaDescription).tests : false;

export const isRequiredField = (validationSchema: AnyObjectSchema, name: string): boolean => {
    return Boolean(
        getIn(validationSchema.describe().fields, getPathForSchema(name))?.tests.find(
            ({ name: testName }: { name: string }) => testName === 'required'
        )
    );
};

export const getErrorsParams = (
    validationSchema: AnyObjectSchema,
    name: string
): Record<string, string> => {
    const field = getIn(validationSchema.describe().fields, getPathForSchema(name));
    if (isSchemaDescription(field)) {
        const fieldTestsParams = field.tests.reduce((acc, item) => {
            return {
                ...acc,
                ...item.params
            };
        }, {});

        return fieldTestsParams;
    }
    return {};
};

/**
 * Takes errors from Formik and returns all possible inputs names
 * @param formikErrors result of `await formik.validateForm()` or `formik.errors`. (first is preferred)
 * @returns array of possible inputs names
 */
export const getFieldErrorNames = <
    Schema extends AnyObjectSchema,
    Values extends SchemaValues<Schema>,
    Errors extends FormikErrors<Values> = FormikErrors<Values>
>(
    formikErrors: Errors
): Array<string> => {
    const transformObjectToDotNotation = (
        obj: Errors,
        prefix = '',
        result: Array<string> = []
    ): Array<string> => {
        Object.keys(obj).forEach((key) => {
            const value = obj[key];
            if (!value) {
                return;
            }

            const nextKey = prefix ? `${prefix}.${key}` : key;
            result.push(nextKey);

            if (typeof value === 'object') {
                transformObjectToDotNotation(value as unknown as Errors, nextKey, result);
            }
        });

        return result;
    };

    return transformObjectToDotNotation(formikErrors);
};

/**
 * Takes errors from Formik and scrolls to the first input with an error
 * @param formikErrors result of `await formik.validateForm()` or `formik.errors`. (first is preferred)
 */
export const scrollToErrorField = <
    Schema extends AnyObjectSchema,
    Values extends SchemaValues<Schema>
>(
    formikErrors: FormikErrors<Values>
) => {
    const fieldErrorNames = getFieldErrorNames(formikErrors);

    const element = fieldErrorNames
        .map((name) => document.querySelector(`[name="${name}"]`))
        .filter(Boolean)[0];

    if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
};
