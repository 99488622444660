import React from 'react';
import { DayProps } from './Day.types';
import useStyles from './Day.styles';
import { Typography } from '../..';
import classnames from 'classnames';

const Day = (props: DayProps) => {
    const {
        day,
        isDisabled,
        isInsideRange,
        isInsideRangeRoundedLeft,
        isInsideRangeRoundedRight,
        isPicked,
        isCurrent,
        onClick
    } = props;
    const classes = useStyles(props);
    const className = classnames(classes.root, {
        [classes.current]: isCurrent,
        [classes.disabled]: isDisabled,
        [classes.picked]: isPicked,
        [classes.pickedStart]: isPicked === 'start',
        [classes.pickedEnd]: isPicked === 'end',
        [classes.insideRange]: isInsideRange,
        [classes.insideRangeRoundedLeft]: isInsideRangeRoundedLeft && !isInsideRangeRoundedRight,
        [classes.insideRangeRoundedRight]: isInsideRangeRoundedRight && !isInsideRangeRoundedLeft,
        [classes.insideRangeRounded]: isInsideRangeRoundedLeft && isInsideRangeRoundedRight
    });
    return <Typography variant="textXSmall" className={className} title={day} onClick={onClick} />;
};

export default Day;
