import { DrawerContentProps } from './DrawerMenu.types';
import { Box, IconButton, List, ListItem, ListItemIcon } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { isMenuLinkItem } from './utils';
import { NextLink } from '../../../common/src';
import { Icon } from '../Icon';
import { Typography } from '../Typography';

const DrawerContent = (props: DrawerContentProps) => {
    const {
        handleDrawerToggle,
        items,
        currentPath,
        classes,
        toolbarContent = null,
        menuItemsHeader = null,
        menuItemsFooter = null,
        hideDrawerIconOnOpen = false
    } = props;

    return (
        <>
            <div className={classes.toolbar}>
                {!hideDrawerIconOnOpen && (
                    <IconButton
                        color="inherit"
                        edge="start"
                        onClick={handleDrawerToggle}
                        size="large"
                    >
                        <Icon icon="menu" className={classes.menuIcon} />
                    </IconButton>
                )}
                {toolbarContent}
            </div>
            {menuItemsHeader}
            <Box className={classes.itemsContainer}>
                {items.map((sectionItems, index) => (
                    <List className={classes.list} key={index}>
                        {sectionItems.map((item) => {
                            if (!isMenuLinkItem(item)) {
                                return item;
                            }

                            const { href, iconName, iconColor, iconColorVariant } = item;
                            const LinkComponent = !href ? 'span' : NextLink;

                            const isActive =
                                item.isActive || item.exact
                                    ? currentPath === item.href
                                    : item.href && currentPath
                                      ? currentPath.includes(item.href)
                                      : false;

                            const sharedProps = {
                                className: classes.link
                            };

                            const contents = (
                                <>
                                    {iconName && (
                                        <ListItemIcon className={classes.listItemIcon}>
                                            <Icon
                                                icon={iconName}
                                                className={classes.linkIcon}
                                                color={iconColor}
                                                colorVariant={iconColorVariant}
                                            />
                                        </ListItemIcon>
                                    )}
                                    <Typography
                                        localeId={item.localeId}
                                        title={item.text}
                                        variant="linkSmall"
                                    />
                                </>
                            );

                            return (
                                <ListItem
                                    className={classNames(classes.listItem, {
                                        [classes.activeItem]: isActive
                                    })}
                                    key={item.localeId || item.text}
                                >
                                    {href ? (
                                        <LinkComponent {...sharedProps} href={href}>
                                            {contents}
                                        </LinkComponent>
                                    ) : (
                                        <span {...sharedProps}>{contents}</span>
                                    )}
                                </ListItem>
                            );
                        })}
                    </List>
                ))}
            </Box>
            {menuItemsFooter}
        </>
    );
};
export default DrawerContent;
