import React from 'react';
import { useIntl } from 'react-intl';
import {
    withUser,
    Types,
    useSnackbar,
    redirectAnonymous,
    useChangeURL,
    USER_URLS,
    useRouter,
    Fragments
} from '../../';
import { Loader } from 'components';
import { useGetUserForDashboardQuery } from '../../graphql/queries';
import { isProfileComplete } from '../..';

// TODO: Find a way to handle `any`
interface Props {
    Component: React.ComponentType<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
    componentProps?: Record<string, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
    userId: Types.UUID;
    redirectAnonymousToSignUp?: boolean;
}

const AuthWrapper = (props: Props) => {
    const { userId, componentProps, redirectAnonymousToSignUp } = props;
    const Component = props.Component as React.ComponentType<{
        user: Fragments.UserFieldsFragment;
    }>;

    const { enqueueSnackbar } = useSnackbar();
    const { loading, data, error } = useGetUserForDashboardQuery({
        variables: {
            userId: userId
        }
    });
    const changeUrl = useChangeURL();

    const router = useRouter();
    const { locale } = useIntl();
    const queryTenantId = router.query.tenantId;
    const tenantId = Array.isArray(queryTenantId) ? queryTenantId[0] : queryTenantId;

    if (loading) {
        return <Loader fullScreen />;
    }

    if (error) {
        enqueueSnackbar({ variant: 'error', messageLocaleId: 'common.user-loading-error' });
        redirectAnonymous({ tenantId, changeURL: changeUrl, redirectAnonymousToSignUp, locale });
        return <Loader fullScreen />;
    }

    const user = data?.user;

    if (!user) {
        redirectAnonymous({ locale, tenantId, changeURL: changeUrl, redirectAnonymousToSignUp });
        return <Loader fullScreen />;
    }

    if (
        user &&
        !user.roles?.includes('ADMIN') &&
        router.pathname !== '/user/complete-profile' &&
        !isProfileComplete(user, locale)
    ) {
        changeUrl(USER_URLS.completeProfile({}), 'catalog');
        return <Loader fullScreen />;
    } else if (user.roles?.length === 1 && user.roles[0] === 'ADMIN') {
        const url = tenantId ? `/admin/members?tenantId=${tenantId}` : `/admin/members`;
        router.push(url);
        return <Loader fullScreen />;
    }

    return <Component {...componentProps} user={user} />;
};

const EnhancedAuthWrapper: React.ComponentType<Omit<Props, 'userId'>> = withUser<Props>(
    AuthWrapper,
    undefined
);

export default EnhancedAuthWrapper;
