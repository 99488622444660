import { Types } from '../';

const { TicketType, ParticipationType, Currency } = Types;

export const participationType = [
    {
        id: ParticipationType.Livestream,
        localeId: 'common.livestream'
    },
    {
        id: ParticipationType.OnSite,
        localeId: 'common.onsite'
    },
    {
        id: ParticipationType.Hybrid,
        localeId: 'common.hybrid'
    },
    {
        id: ParticipationType.OnDemand,
        localeId: 'common.ondemand'
    }
];

export const ticketType = [
    {
        id: TicketType.HalfDayTicket,
        localeId: 'common.ticket-type.half-day-ticket'
    },
    {
        id: TicketType.DayTicket,
        localeId: 'common.ticket-type.day-ticket'
    },
    {
        id: TicketType.EntireEvent,
        localeId: 'common.ticket-type.entire-event'
    },
    {
        id: TicketType.Workshop,
        localeId: 'common.ticket-type.workshop'
    },
    {
        id: TicketType.KombiTicket,
        localeId: 'common.ticket-type.kombi-ticket'
    },
    {
        id: TicketType.OnDemand,
        localeId: 'common.ticket-type.on-demand'
    },
    {
        id: TicketType.PrintedCourseBook,
        localeId: 'common.printed-coursebook'
    }
];

export const ticketTypeTitle = [
    {
        id: TicketType.HalfDayTicket,
        localeId: 'common.ticket-type.half-day-ticket-title'
    },
    {
        id: TicketType.DayTicket,
        localeId: 'common.ticket-type.day-ticket-title'
    },
    {
        id: TicketType.EntireEvent,
        localeId: 'common.ticket-type.entire-event'
    },
    {
        id: TicketType.Workshop,
        localeId: 'common.ticket-type.workshop-title'
    },
    {
        id: TicketType.KombiTicket,
        localeId: 'common.ticket-type.kombi-ticket'
    },
    {
        id: TicketType.OnDemand,
        localeId: 'common.ticket-type.on-demand'
    }
];

export const currency = [
    {
        id: Currency.Chf,
        label: 'CHF'
    },
    {
        id: Currency.Eur,
        label: '€'
    },
    {
        id: Currency.Gbp,
        label: '£'
    }
];
