/**
 * This type is a copy of the `SpacingArgument` type from `@mui/system` package (`@mui/system/createTheme/createSpacing`).
 * We need to copy it here because @mui/system is not compatible with @mui/material at the moment.
 *
 * Once we upgrade both packages to the latest version, we can remove this type and use the one from `@mui/system`.
 */
type SpacingArgument = number | string;

/**
 * This type is a copy of the `Spacing` type from `@mui/system` package.
 * We need to copy it here because @mui/system is not compatible with @mui/material at the moment.
 *
 * Once we upgrade both packages to the latest version, we can remove this type and use the one from `@mui/system`.
 */
interface Spacing {
    (): string;
    (value: number): string;
    (topBottom: SpacingArgument, rightLeft: SpacingArgument): string;
    (top: SpacingArgument, rightLeft: SpacingArgument, bottom: SpacingArgument): string;
    (
        top: SpacingArgument,
        right: SpacingArgument,
        bottom: SpacingArgument,
        left: SpacingArgument
    ): string;
}

const spacingSteps: Record<string, number> = {
    0: 0,
    0.5: 4,
    1: 8,
    1.5: 12,
    2: 16,
    2.5: 20,
    3: 24,
    4: 32,
    5: 40,
    6: 48,
    7: 56,
    8: 64,
    9: 72, // default MaterialUI key
    10: 80, // default MaterialUI key
    12: 96,
    14: 112
};

const spacingFunction = (originalKey: number) => {
    const sign = Math.sign(originalKey);
    const key = Math.abs(originalKey);

    const spacingValue = spacingSteps[key];
    if (typeof spacingValue !== 'undefined') {
        return sign * spacingValue;
    } else {
        // eslint-disable-next-line no-console
        console.trace(`Warning! Unsupported spacing "${originalKey}" was used`);

        return 0;
    }
};

const spacing = ((
    arg1: SpacingArgument,
    arg2?: SpacingArgument,
    arg3?: SpacingArgument,
    arg4?: SpacingArgument
) => {
    if (
        typeof arg1 === 'number' &&
        typeof arg2 === 'undefined' &&
        typeof arg3 === 'undefined' &&
        typeof arg4 === 'undefined'
    ) {
        return spacingFunction(arg1);
    }

    return [arg1, arg2, arg3, arg4]
        .filter((arg) => ['number', 'string'].includes(typeof arg))
        .map((item) => (typeof item === 'number' ? spacingFunction(item) + 'px' : item))
        .join(' ');
}) as Spacing;

// we set `mui` to true so material-ui doesn't do any additional transformations on spacing function
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
spacing.mui = true;

export default spacing;
