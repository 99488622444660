import React from 'react';
import { CountdownProps } from './Countdown.types';
import useStyles from './Countdown.styles';
import { Typography } from 'components';
import { Box } from '@mui/material';
import classnames from 'classnames';

const Countdown = (props: CountdownProps) => {
    const { value, className } = props;
    const classes = useStyles();
    return (
        <Box className={classnames(classes.container, className)}>
            <Typography localeId="common.countdown.offer-yet-title" variant="textSmall" />
            <Typography title={value} variant="displaySmallBold" />
            <Typography
                localeId={value === 1 ? 'common.countdown.day' : 'common.countdown.days'}
                variant="textSmall"
            />
        </Box>
    );
};

export default Countdown;
