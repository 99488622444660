import { Autocomplete as MUIAutocomplete } from '@mui/material';
import React from 'react';
import { Icon } from '../Icon';
import { SelectableOption } from '../utils/componentsTypes';

import { useStyles } from './Autocomplete.styles';
import { AutocompleteProps, ValueType } from './Autocomplete.types';
import {
    createRenderTextField,
    getOptionLabelDefault,
    getOptionSelectedDefault
} from './Autocomplete.utils';

const Autocomplete = <Value extends ValueType = ValueType>(props: AutocompleteProps<Value>) => {
    const multiple = true;
    const classes = useStyles();

    const {
        options,
        value: valueProp,
        onChange,
        disabled,
        ['data-testid']: dataTestId,
        ...others
    } = props;

    const renderInput = createRenderTextField(others, classes);
    const value = props.value as AutocompleteProps<Value>['value'];

    return (
        <MUIAutocomplete
            options={options}
            getOptionLabel={getOptionLabelDefault as (option: SelectableOption) => string}
            isOptionEqualToValue={getOptionSelectedDefault}
            value={value}
            onChange={onChange}
            multiple={multiple}
            renderInput={renderInput}
            ChipProps={{
                deleteIcon: <Icon icon="close" strokeWidth={3} />,
                classes: {
                    label: classes.chipLabel,
                    deleteIcon: classes.chipDeleteIcon,
                    root: classes.chipRoot
                }
            }}
            classes={{
                inputRoot: classes.inputRoot
            }}
            disableClearable={props.hideClearIcon}
            disabled={disabled}
            data-testid={dataTestId}
            {...others}
        />
    );
};

export default Autocomplete;
