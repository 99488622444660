import { Types } from '..';

export const participantOption = [
    {
        id: Types.ContentParticipationOption.Hybrid,
        localeId: 'common.product-participant-option.hybrid'
    },
    {
        id: Types.ContentParticipationOption.LiveStream,
        localeId: 'common.product-participant-option.livestream'
    },
    {
        id: Types.ContentParticipationOption.OnSite,
        localeId: 'common.product-participant-option.onsite'
    },
    {
        id: Types.ContentParticipationOption.OnDemand,
        localeId: 'common.ondemand'
    }
];

export const participantOptionLong = [
    {
        id: Types.ContentParticipationOption.Hybrid,
        localeId: 'common.product-participant-option.hybrid.long'
    },
    {
        id: Types.ContentParticipationOption.LiveStream,
        localeId: 'common.product-participant-option.livestream'
    },
    {
        id: Types.ContentParticipationOption.OnSite,
        localeId: 'common.product-participant-option.onsite'
    },
    {
        id: Types.ContentParticipationOption.OnDemand,
        localeId: 'common.ondemand'
    }
];

export const brand = [
    {
        id: Types.ProductBrand.Fortbildungstage,
        localeId: 'common.product-brand.fortbildungstage'
    },
    {
        id: Types.ProductBrand.Refresher,
        localeId: 'common.product-brand.refresher'
    },
    {
        id: Types.ProductBrand.RefresherActuell,
        localeId: 'common.product-brand.refresher-actuell'
    },
    {
        id: Types.ProductBrand.Summedup,
        localeId: 'common.product-brand.summed-up'
    },
    {
        id: Types.ProductBrand.UpdateRefresher,
        localeId: 'common.product-brand.update-refresher'
    },
    {
        id: Types.ProductBrand.Wu660,
        localeId: 'common.product-brand.wu660'
    },
    {
        id: Types.ProductBrand.Wuex,
        localeId: 'common.product-brand.wuex'
    },
    {
        id: Types.ProductBrand.Wuif,
        localeId: 'common.product-brand.wuif'
    },
    {
        id: Types.ProductBrand.Skill,
        localeId: 'common.product-brand.skill'
    },
    {
        id: Types.ProductBrand.Guideline,
        localeId: 'common.product-brand.guideline'
    },
    {
        id: Types.ProductBrand.Syrot,
        localeId: 'common.product-brand.syrot'
    },
    {
        id: Types.ProductBrand.SpecialistCertificate,
        localeId: 'common.product-brand.specialist-certificate'
    },
    {
        id: Types.ProductBrand.StudyConferenceUpdate,
        localeId: 'common.product-brand.study-conference-update'
    },
    {
        id: Types.ProductBrand.Ondemand,
        localeId: 'common.product-type.on-demand'
    },
    {
        id: Types.ProductBrand.Syfat,
        localeId: 'common.product-type.syfat'
    },
    {
        id: Types.ProductBrand.SgmoCurriculum,
        localeId: 'common.product-type.sgmo-curriculum'
    },
    {
        id: Types.ProductBrand.KfsSymposion,
        localeId: 'common.product-type.kfs-symposion'
    },
    {
        id: Types.ProductBrand.OndemandAccredited,
        localeId: 'common.product-type.on-demand-accredited'
    }
];

export const contentType = [
    {
        id: Types.ContentType.Article,
        localeId: 'common.product-type.article'
    },
    {
        id: Types.ContentType.LiveCourse,
        locale: 'common.product-type.live-course'
    },
    {
        id: Types.ContentType.Course,
        localeId: 'common.product-type.course'
    },
    {
        id: Types.ContentType.Lecture,
        localeId: 'common.product-type.lecture'
    },
    {
        id: Types.ContentType.Webinar,
        localeId: 'common.product-type.webinar'
    },
    {
        id: Types.ContentType.AddonCourse,
        localeId: 'common.product-type.addon-course'
    },
    {
        id: Types.ContentType.Pdf,
        localeId: 'common.product-type.pdf'
    },
    {
        id: Types.ContentType.OndemandCourse,
        localeId: 'common.product-type.on-demand-course'
    },
    {
        id: Types.ContentType.OnDemandAccredited,
        localeId: 'common.product-type.on-demand-accredited'
    },
    {
        id: Types.ContentType.PartnerEvent,
        localeId: 'common.product-type.partner-event'
    }
];

export const contentLocale = [
    {
        id: 'de-CH',
        localeId: 'catalog.content.details.content-language.german'
    },
    {
        id: 'fr-CH',
        localeId: 'catalog.content.details.content-language.french'
    },
    {
        id: 'de-DE',
        localeId: 'catalog.content.details.content-language.german'
    },
    {
        id: 'de-AT',
        localeId: 'catalog.content.details.content-language.german'
    },
    {
        id: 'en-GB',
        localeId: 'catalog.content.details.content-language.english'
    }
];
