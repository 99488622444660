const formatObject = <T extends Record<string, unknown>>(
    object: T | undefined,
    key: keyof T
): string | undefined => {
    const message = object?.[key];
    if (typeof message !== 'string' && typeof message !== 'undefined') {
        throw new Error(
            `Field "${String(key)}" of object "${JSON.stringify(object)}" is not a string`
        );
    }
    return message;
};

export default formatObject;
