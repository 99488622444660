import React, { ReactNode } from 'react';
import { useSnackbar as useDefaultSnackbar, SnackbarKey } from 'notistack';
import { Snackbar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    snackbar: {
        '& .MuiSnackbarContent-message': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%'
        },

        [theme.breakpoints.up('md')]: {
            minWidth: '344px'
        }
    }
}));

type Props = {
    text: ReactNode;
    action?: ReactNode;
};

export type SnackbarProviderContext = {
    enqueueSnackbar: (options: Props) => SnackbarKey;
    closeSnackbar: (key?: SnackbarKey) => void;
};

export const useMUISnackbar = (): SnackbarProviderContext => {
    const classes = useStyles();
    const { enqueueSnackbar, closeSnackbar } = useDefaultSnackbar();

    const pushSnackbar = (options: Props) =>
        enqueueSnackbar(undefined, {
            content: (key) => (
                <Snackbar
                    id={key ? key.toString() : ''}
                    open
                    className={classes.snackbar}
                    autoHideDuration={6000}
                    onClose={() => closeSnackbar(key)}
                    message={options.text}
                    action={options.action}
                />
            )
        });

    return { enqueueSnackbar: pushSnackbar, closeSnackbar };
};
