import React, { useContext, useEffect, useState } from 'react';
import {
    getTenantConfigFromPathname,
    getTenantsConfiguration,
    TenantConfig
} from '../../../catalog/src/utils/tenant';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';

export type TenantContextType = {
    tenantConfig: TenantConfig | null;
    tenantsConfigs: Array<TenantConfig> | null;
};

const defaultTenantContext = { tenantConfig: null, tenantsConfigs: null };

const TenantContext = React.createContext<TenantContextType>(defaultTenantContext);
const useTenantContext = () => useContext(TenantContext);

type TenantContextProviderProps = {
    children: React.ReactNode;
    tenantConfig: TenantConfig | null;
    tenantsConfigs: Array<TenantConfig>;
    messages: Record<string, string>;
};

const TenantContextProvider = (props: TenantContextProviderProps) => {
    const [tenantConfig, setTenantConfig] = useState(props.tenantConfig);
    const router = useRouter();
    const { formatMessage } = useIntl();
    const {
        pathname,
        query: { tenant, id }
    } = router;

    useEffect(() => {
        const fetchTenantConfig = async () => {
            const tenantsConfig = await getTenantsConfiguration();
            const newTenantConfig = await getTenantConfigFromPathname(
                tenantsConfig,
                pathname,
                { tenant, id },
                formatMessage
            );
            if (newTenantConfig) {
                setTenantConfig(newTenantConfig);
            }
        };
        if (!tenantConfig) {
            fetchTenantConfig();
        }
    }, [pathname, tenant, id]);
    return (
        <TenantContext.Provider value={{ tenantConfig, tenantsConfigs: props.tenantsConfigs }}>
            {props.children}
        </TenantContext.Provider>
    );
};
export { TenantContextProvider, useTenantContext };
