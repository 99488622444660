import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
    (theme) => ({
        container: {
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            userSelect: 'none',

            '&$error $checkmarkContainer': {
                borderColor: theme.palette.error.main
            }
        },
        checkmarkContainer: {
            position: 'relative',
            flexShrink: 0,
            width: 24,
            height: 24,
            border: `1px solid ${theme.palette.label.main}`,
            borderRadius: 3,
            background: theme.palette.offWhite.main,
            cursor: 'pointer',
            transition: theme.transitions.create(['background-color', 'border-color']),

            '&$checked': {
                backgroundColor: theme.palette.secondary.dark,
                borderColor: theme.palette.secondary.dark,
                boxShadow: 'inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)',
                '& $checkmark': {
                    opacity: 1
                }
            },

            '&$checkedFalse': {
                backgroundColor: theme.palette.error.main,
                borderColor: theme.palette.error.main,
                boxShadow: 'inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)',
                '& $checkmark': {
                    opacity: 1
                }
            },

            '&$checked, &$checkedFalse': {
                '$disabled &': {
                    background: '#d1dbe0',
                    borderColor: '#d1dbe0',
                    boxShadow: 'none'
                }
            }
        },
        checkmark: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            width: 13,
            height: 13,
            filter: 'drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.15))',
            opacity: 0,
            transition: theme.transitions.create('opacity'),
            color: theme.palette.common.white
        },
        checked: {},
        checkedFalse: {},
        disabled: {
            pointerEvents: 'none'
        },
        inactive: {
            cursor: 'default'
        },
        label: {
            marginLeft: theme.spacing(1.5)
        },
        error: {}
    }),
    { name: 'Checkbox', index: 1 }
);

export default useStyles;
