import {
    Fragments,
    getAnalytics,
    useRouter,
    initializeApollo,
    Queries,
    SegmentAnalytics,
    booleanFilter,
    WebTracking,
    Types
} from 'common';
import {
    PlaybackTrackingParams,
    transformContentDocumentsToProductList,
    transformContentDocumentToProduct,
    transformCourseDocumentToProduct,
    transformPartnerCompany,
    transformToPlayback
} from './segmentTransformers';
import { ContentDocumentFields } from '../content';
import type { CourseTableItem } from '../../containers/MediathekHome/CoursesSection/components/CoursesTable';

type ContentListDocument = {
    contentId: string;
    parent?: {
        contentId: string;
        contentType: Types.ContentType;
        isFree?: boolean | null;
    } | null;
};

// View Item List
// https://segment.com/docs/connections/destinations/catalog/actions-google-analytics-4/#view-item-list
export const contentListViewed = async (
    contentDocuments: Array<ContentListDocument>,
    listId: string | undefined,
    locale: Locale
) => {
    if (contentDocuments.length > 0) {
        const analytics = await getAnalytics();
        if (!analytics) {
            return;
        }

        const contentsForAnalytics = (
            await Promise.all(
                contentDocuments.map((contentDocument) =>
                    getContentForAnalyticsById(contentDocument.contentId)
                )
            )
        ).filter(booleanFilter);

        analytics.web.productListViewed(
            transformContentDocumentsToProductList(contentsForAnalytics, listId, locale)
        );
    }
};

// Product Clicked
export const contentClicked = async (contentId: string, params: GenericParams) => {
    const response = await getContentAndAnalytics({ ...params, contentId });
    if (!response) {
        return;
    }
    const { content, analytics } = response;
    analytics.web.productClicked(content);
};

// Product Clicked
export const courseClicked = async (course: CourseTableItem, params: GenericParams) => {
    const response = await getContentAndAnalytics({
        ...params,
        contentId: course.segmentFields.contentId
    });
    if (!response) {
        return;
    }
    const { content, analytics } = response;
    analytics.web.productClicked(content);
};

// Product Viewed
// https://segment.com/docs/connections/destinations/catalog/actions-google-analytics-4/#select-item
export const contentViewed = async (
    contentDocument:
        | ContentDocumentFields
        | Fragments.ContentDocumentSearchFieldsFragment
        | Fragments.ContentWithProgramSchedulesFieldsFragment,
    params: GenericParams
) => {
    const response = await getContentAndAnalytics({
        ...params,
        contentId: contentDocument.contentId
    });
    if (!response) {
        return;
    }
    const { content, analytics } = response;
    analytics.web.productViewed(content);
};

export const videoThumbnailViewed = async (contentId: string, params: GenericParams) => {
    const response = await getContentAndAnalytics({ ...params, contentId });
    if (!response) {
        return;
    }
    const { content, analytics } = response;
    analytics.web.videoThumbnailViewed(content);
};

export const documentThumbnailViewed = async (contentId: string, params: GenericParams) => {
    const response = await getContentAndAnalytics({ ...params, contentId });
    if (!response) {
        return;
    }
    const { content, analytics } = response;
    analytics.web.documentThumbnailViewed(content);
};

export const videoStarted = async (
    videoParams: PlaybackTrackingParams,
    contentParams: CourseParams | ContentParams
) => {
    const response = await getContentOrCourseAndAnalytics(contentParams);
    if (!response) {
        return;
    }
    const { content, analytics } = response;

    const video = transformToPlayback(videoParams, true);
    analytics.web.videoPlaybackStarted({
        ...content,
        ...video
    });
};

export const videoProgress = async (
    videoParams: PlaybackTrackingParams,
    contentParams: CourseParams | ContentParams
) => {
    const response = await getContentOrCourseAndAnalytics(contentParams);
    if (!response) {
        return;
    }
    const { content, analytics } = response;

    const video = transformToPlayback(videoParams, false);
    analytics.web.videoPlaybackProgress({
        ...content,
        ...video
    });
};

export const videoPaused = async (
    videoParams: PlaybackTrackingParams,
    contentParams: CourseParams | ContentParams
) => {
    const response = await getContentOrCourseAndAnalytics(contentParams);
    if (!response) {
        return;
    }
    const { content, analytics } = response;

    const video = transformToPlayback(videoParams, false);
    analytics.web.videoPlaybackPaused({
        ...content,
        ...video
    });
};

export const videoResumed = async (
    videoParams: PlaybackTrackingParams,
    contentParams: CourseParams | ContentParams
) => {
    const response = await getContentOrCourseAndAnalytics(contentParams);
    if (!response) {
        return;
    }
    const { content, analytics } = response;

    const video = transformToPlayback(videoParams, false);
    analytics.web.videoPlaybackResumed({
        ...content,
        ...video
    });
};

export const videoCompleted = async (
    videoParams: PlaybackTrackingParams,
    contentParams: CourseParams | ContentParams
) => {
    const response = await getContentOrCourseAndAnalytics(contentParams);
    if (!response) {
        return;
    }
    const { content, analytics } = response;

    const video = transformToPlayback(videoParams, false);
    analytics.web.videoPlaybackCompleted({
        ...content,
        ...video
    });
};

export const videoStoppedNoMembership = async (
    videoParams: PlaybackTrackingParams,
    contentParams: CourseParams | ContentParams
) => {
    const response = await getContentOrCourseAndAnalytics(contentParams);
    if (!response) {
        return;
    }
    const { content, analytics } = response;

    const video = transformToPlayback(videoParams, false);
    analytics.web.videoPlaybackInterrupted({
        ...content,
        video_playback: {
            ...video.video_playback,
            method: 'NO_MEMBERSHIP'
        }
    });
};

const productPathRegex = /^\/product\/(\w+)$/;
const mediathekPathRegex = /^\/mediathek(?:\/(\w+))?(?:\/(\w+))?(?:\?(\w+=\w+))?$/;

export const useGenerateListIdFromRoute = (): string | undefined => {
    const router = useRouter();
    const currentPath = router.pathname;

    switch (true) {
        case currentPath === '/':
            return 'homepage';
        case productPathRegex.test(currentPath):
            return getProductListId(currentPath);
        case mediathekPathRegex.test(currentPath):
            return getMediathekListId(currentPath);
        default:
            return undefined;
    }
};

const getProductListId = (currentPath: string): string | undefined => {
    const match = currentPath.match(productPathRegex);
    return match ? ['product', match[1]].filter(Boolean).join('-') : undefined;
};

const getMediathekListId = (currentPath: string): string | undefined => {
    const match = currentPath.match(mediathekPathRegex);
    return match
        ? ['mediathek', match[1], match[2]].filter(Boolean).join('-').replace('topics', 'specialty')
        : undefined;
};

const calendarEventTransformer = (
    content: Queries.GetContentForAnalyticsByIdQuery['content'],
    transformedContent: WebTracking.ProductAdded
) => {
    return {
        ...transformedContent,
        event_ub_id: content.externalId || content.parent?.externalId || null
    };
};

export const trackAddToCalendar = async (contentId: string, params: GenericParams) => {
    const response = await getContentAndAnalytics(
        { ...params, contentId },
        calendarEventTransformer
    );
    if (!response) {
        return;
    }
    const { content, analytics } = response;

    analytics.web.addedToCalendar({
        event_ub_id: null, // will be overwritten by transformer
        ...content
    });
};

export const trackCreateEventReminder = async (
    contentId: string,
    reminderType: WebTracking.EventReminderCreated['reminderType'],
    params: GenericParams
) => {
    const response = await getContentAndAnalytics({ ...params, contentId });
    if (!response) {
        return;
    }
    const { content, analytics } = response;
    analytics.web.eventReminderCreated({
        ...content,
        reminderType
    });
};

export const courseThumbnailViewed = async (contentId: string, params: GenericParams) => {
    const response = await getContentAndAnalytics({ ...params, contentId });
    if (!response) {
        return;
    }
    const { content, analytics } = response;
    analytics.web.courseThumbnailViewed(content);
};

export const partnerLogoViewed = async (
    company: Fragments.PartnerCompanySimplifiedFragment,
    params: { locale: Locale; index: number }
) => {
    const analytics = await getAnalytics();
    analytics?.web.partnerLogoViewed(transformPartnerCompany(company, params));
};

const getContentForAnalyticsById = async (
    contentId: string
): Promise<Queries.GetContentForAnalyticsByIdQuery['content'] | undefined> => {
    if (contentId.length === 0) {
        return;
    }

    const graphqlClient = initializeApollo();
    const { data } = await graphqlClient.query<
        Queries.GetContentForAnalyticsByIdQuery | null,
        Queries.GetContentForAnalyticsByIdQueryVariables
    >({
        query: Queries.GetContentForAnalyticsById,
        variables: {
            contentId
        }
    });
    const content = data?.content;
    return content;
};

const getCourseForAnalyticsById = async (
    courseId: string
): Promise<Queries.GetCourseForAnalyticsByIdQuery['course'] | undefined> => {
    if (courseId.length === 0) {
        return;
    }

    const graphqlClient = initializeApollo();
    const { data } = await graphqlClient.query<
        Queries.GetCourseForAnalyticsByIdQuery | null,
        Queries.GetCourseForAnalyticsByIdQueryVariables
    >({
        query: Queries.GetCourseForAnalyticsById,
        variables: {
            courseId
        }
    });
    const content = data?.course;
    return content;
};

type CustomTransformer = (
    content: Queries.GetContentForAnalyticsByIdQuery['content'],
    transformedContent: WebTracking.ProductAdded
) => WebTracking.ProductAdded;

const getContentAndAnalytics = async (
    params: ContentParams,
    customTransformer?: CustomTransformer
): Promise<{
    content: WebTracking.ProductAdded;
    analytics: SegmentAnalytics;
} | null> => {
    const analytics = await getAnalytics();
    if (!analytics || !params.contentId) {
        return null;
    }

    const contentResponse = await getContentForAnalyticsById(params.contentId);
    if (!contentResponse) {
        return null;
    }
    const content = transformContentDocumentToProduct(contentResponse, params);
    const transformedContent = customTransformer
        ? customTransformer(contentResponse, content)
        : content;

    return { content: transformedContent, analytics };
};

const getCourseAndAnalytics = async (
    params: CourseParams
): Promise<{
    content: WebTracking.ProductAdded;
    analytics: SegmentAnalytics;
} | null> => {
    const analytics = await getAnalytics();
    if (!analytics || !params.courseId) {
        return null;
    }

    const courseResponse = await getCourseForAnalyticsById(params.courseId);
    if (!courseResponse) {
        return null;
    }
    const content = transformCourseDocumentToProduct(courseResponse, params);

    return { content, analytics };
};

type GenericParams = {
    locale: Locale;
    index: number | undefined;
};

type ContentParams = GenericParams & {
    contentId: string | undefined;
};

type CourseParams = GenericParams & {
    courseId: string | undefined;
};

const getContentOrCourseAndAnalytics = async (
    contentParams: CourseParams | ContentParams
): Promise<{
    content: WebTracking.ProductAdded;
    analytics: SegmentAnalytics;
} | null> => {
    const contentId = (contentParams as ContentParams).contentId;
    const courseId = (contentParams as CourseParams).courseId;

    if (contentId && courseId) {
        return Promise.reject('Only one of `contentId` and `courseId` can be passed');
    }

    if (contentId) {
        const response = await getContentAndAnalytics(contentParams as ContentParams);
        return response;
    } else if (courseId) {
        const response = await getCourseAndAnalytics(contentParams as CourseParams);
        return response;
    } else {
        return Promise.reject('One and only one of `contentId` and `courseId` can be passed');
    }
};
