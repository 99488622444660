export const booleanFilter = <T>(item: T | undefined | null | false | '' | 0): item is T =>
    Boolean(item);

type ObjectKeys = {
    <T extends Record<string, unknown>, K extends Array<keyof T>>(object: T): K;
    <T, K extends Array<keyof T> = Array<keyof T>>(object: T): K;
};

export const objectKeys: ObjectKeys = <T, K extends Array<keyof T>>(object: T): K =>
    Object.keys(object) as K;

export const isObject = (value: unknown): value is object => typeof value === 'object';
