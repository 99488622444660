import React, { useEffect, useState } from 'react';
import { Box, Accordion as AccordionMUI, AccordionSummary, AccordionDetails } from '@mui/material';

import useStyles from './Accordion.styles';
import { AccordionProps } from './Accordion.types';
import { Icon } from '../Icon';

const Accordion = (props: AccordionProps) => {
    const {
        title,
        content,
        expanded: expandedProp,
        onExpandedChange,
        defaultExpanded = false,
        toggleMode = 'container'
    } = props;
    const [expanded, setExpanded] = useState<boolean>(expandedProp || defaultExpanded);
    const classes = useStyles(props);

    const toggleExpanded = () => {
        if (onExpandedChange) {
            onExpandedChange(!expanded);
        } else {
            setExpanded(!expanded);
        }
    };

    const handleContainerClick = () => {
        if (toggleMode === 'container') {
            toggleExpanded();
        }
    };

    const handleTitleClick = () => {
        if (toggleMode === 'title') {
            toggleExpanded();
        }
    };

    useEffect(() => {
        if (expandedProp != null) {
            setExpanded(expandedProp);
        }
    }, [expandedProp]);

    return (
        <Box className={classes.container} mt={1} mb={1} onClick={handleContainerClick}>
            <AccordionMUI expanded={expanded} classes={{ root: classes.accordionItem }}>
                <AccordionSummary
                    onClick={handleTitleClick}
                    classes={{
                        root: classes.accordionSummaryContainer,
                        content: classes.accordionSummaryContent,
                        expanded: classes.accordionSummaryExpanded
                    }}
                    expandIcon={<Icon icon="caretDown" />}
                >
                    {title}
                </AccordionSummary>
                <AccordionDetails classes={{ root: classes.accordionDetailsContainer }}>
                    {content}
                </AccordionDetails>
            </AccordionMUI>
        </Box>
    );
};

export default Accordion;
