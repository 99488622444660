import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';

import IMask from 'imask';

const loadUtils = async () => {
    if (!window.intlTelInputUtils) {
        return window.intlTelInputGlobals.loadUtils(
            'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.13/js/utils.min.js'
        );
    }
};

const errorMessages = [
    'common.phone-input-error.invalid-number',
    'common.phone-input-error.invalid-country-code',
    'common.phone-input-error.too-short',
    'common.phone-input-error.too-long',
    'common.phone-input-error.invalid-number'
];
// from https://github.com/jackocnr/intl-tel-input/blob/master/src/js/utils.js#L113
// const numberTypes = {
//   'FIXED_LINE': 0,
//   'MOBILE': 1,
//   'FIXED_LINE_OR_MOBILE': 2,
//   'TOLL_FREE': 3,
//   'PREMIUM_RATE': 4,
//   'SHARED_COST': 5,
//   'VOIP': 6,
//   'PERSONAL_NUMBER': 7,
//   'PAGER': 8,
//   'UAN': 9,
//   'VOICEMAIL': 10,
//   'UNKNOWN': -1,
// };

class IntlTel {
    iti: intlTelInput.Plugin | undefined;
    mask: IMask.InputMask<{ mask: string }> | undefined;
    isValid: boolean | undefined;
    private readonly input: HTMLInputElement;
    private readonly onTelephoneChange: (props: {
        value?: string;
        validate: () => string;
        setCountry: (country: string) => void;
    }) => void;

    constructor(
        input: HTMLInputElement,
        onTelephoneChange: (props: {
            value?: string;
            validate: () => string;
            setCountry: (country: string) => void;
        }) => void
    ) {
        this.input = input;
        this.onTelephoneChange = onTelephoneChange;

        this.onChange = this.onChange.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onCountryChange = this.onCountryChange.bind(this);
        this.validate = this.validate.bind(this);
    }

    async init(): Promise<void> {
        await loadUtils();
        const input = this.input;
        const itiOptions = {
            preferredCountries: ['at', 'de', 'ch', 'gb'],
            autoPlaceholder: 'aggressive' as const,
            nationalMode: false,
            separateDialCode: true
        };

        this.iti = intlTelInput(input, itiOptions);

        this.onTelephoneChange({
            validate: this.validate,
            setCountry: this.iti.setCountry.bind(this.iti)
        });

        this.initEvents();
    }

    initEvents() {
        const input = this.input;
        input.addEventListener('focus', this.onFocus);
        input.addEventListener('countrychange', this.onCountryChange);
        ['change', 'keyup'].forEach((e) => input.addEventListener(e, this.onChange));

        input.addEventListener('paste', (event) => {
            event.preventDefault();
            const pasted = event.clipboardData?.getData('Text');
            if (pasted) {
                this.input.value = pasted;
                this.input.dispatchEvent(new Event('change'));
            }
        });
    }

    setMask() {
        if (this.mask) {
            this.mask.destroy();
        }
        const placeholder = this.input.getAttribute('placeholder') || '';
        const options = {
            mask: placeholder.replace(/[0-9]/g, '0') + '[00000]'
        };
        this.mask = IMask(this.input, options);
    }

    validate = (): string => {
        if (!this.iti) {
            return '';
        }

        this.isValid = this.iti.isValidNumber();

        const errorMessage = !this.isValid && errorMessages[this.iti.getValidationError()];

        return errorMessage || '';
    };

    onChange = () => {
        if (!this.iti) {
            return;
        }

        const number = this.iti.getNumber();
        this.onTelephoneChange({
            value: number,
            validate: this.validate,
            setCountry: this.iti.setCountry.bind(this.iti)
        });
    };

    onFocus = () => {
        this.setMask();
    };

    onCountryChange = () => {
        this.setMask();
        this.onChange();
    };

    destroy = () => {
        this.iti?.destroy();
    };
}

export default IntlTel;
