import dayjs from 'dayjs';

import { createURL, generateRandomString, getCookie, setCookie } from '../';
import { CookiesState, COOKIES_STATE_KEY } from '.';

const setStateInCookies = (
    redirectTo: { url?: string; destinationPackage?: unknown } | null,
    nonce?: string
) => {
    if (typeof window === 'undefined') {
        return '';
    }

    const url =
        redirectTo &&
        createURL(redirectTo.url || window.location.href, redirectTo.destinationPackage, true).url;
    const searchParams = new URLSearchParams(window.location.search.slice(1));
    const nonceInUrl = searchParams.get(COOKIES_STATE_KEY);

    const nonceToUse = nonce ? nonce : nonceInUrl ? nonceInUrl : generateRandomString();
    const stateCookie = getCookie(COOKIES_STATE_KEY) || '{}';
    const parsedState: CookiesState = JSON.parse(stateCookie);

    if (!url) {
        if (parsedState[nonceToUse]) {
            delete parsedState[nonceToUse];
            setCookie(COOKIES_STATE_KEY, JSON.stringify(parsedState));
        }
    } else {
        const newState: CookiesState = {
            ...parsedState,
            [nonceToUse]: {
                redirectTo: url,
                timestamp: dayjs().unix()
            }
        };
        setCookie(COOKIES_STATE_KEY, JSON.stringify(newState));
    }

    return nonceToUse;
};

export default setStateInCookies;
