import getDomainFromLocation from './getDomainFromLocation';
const isDomainChFr = () => {
    if (typeof window === 'undefined') {
        return false;
    }

    const domain = getDomainFromLocation();

    if (domain === 'ch') {
        const pathname = window.location.pathname;
        const pathParts = pathname.split('/');
        if (pathParts[1] === 'fr-CH') {
            return true;
        }
    }

    return false;
};

export default isDomainChFr;
