import { gql } from '@apollo/client';

export const GetCompanyConfiguration = gql`
    query GetCompanyConfiguration($companyId: ID!) {
        company(id: $companyId) {
            id
            colorSettings {
                primaryDark
                primaryLight
                primaryTextDark
                primaryTextLight
            }
            companyPage {
                slug
            }
        }
    }
`;
