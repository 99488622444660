import React from 'react';
import { Box } from '@mui/material';
import { HeaderLinksProps } from './HeaderLinks.types';
import { HeaderLink } from './HeaderLink';
import makeStyles from '@mui/styles/makeStyles';
import { HEADER_LINKS_SEPARATOR } from './Header.utils';

const useStyles = makeStyles(
    (theme) => ({
        linksContainer: {
            display: 'flex',
            gap: theme.spacing(1),
            height: theme.spacing(5),
            alignItems: 'center',
            zIndex: 1
        },
        separator: {
            width: 1,
            height: theme.spacing(2.5),
            backgroundColor: theme.palette.body.light
        }
    }),
    { name: 'HeaderLinks' }
);

const flattenWithSeparator = (array: HeaderLinksProps['menuItems']) => {
    return ([] as HeaderLinksProps['menuItems'][number]).concat(
        ...array.map((innerArray, index) => {
            if (index > 0) {
                return [{ localeId: HEADER_LINKS_SEPARATOR, href: '' }, ...innerArray];
            } else {
                return innerArray;
            }
        })
    );
};

const HeaderLinks = (props: HeaderLinksProps) => {
    const classes = useStyles();
    const { menuItems } = props;
    const flatMenuItems = flattenWithSeparator(menuItems);

    return (
        <Box className={classes.linksContainer}>
            {flatMenuItems.map((menuItem, index) => {
                if (menuItem.localeId === HEADER_LINKS_SEPARATOR) {
                    return <div key={index} className={classes.separator} />;
                }

                return (
                    <HeaderLink
                        {...menuItem}
                        key={menuItem.localeId}
                        href={'href' in menuItem ? menuItem.href : null}
                        childLinks={
                            'secondLevelLinks' in menuItem ? menuItem.secondLevelLinks : undefined
                        }
                    />
                );
            })}
        </Box>
    );
};

export default HeaderLinks;
