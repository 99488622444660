import React, { ReactNode } from 'react';
import { Button as MUIButton, ButtonProps as MUIButtonProps } from '@mui/material';
import classnames from 'classnames';

import useStyles from './Button.styles';
import { ButtonProps, ButtonIcon } from './Button.types';
import { Icon, isIconName, Typography } from '../';
import { NextLink } from '../../../common/src';

const renderIcon = (
    icon: ButtonIcon,
    iconStrokeWidth: number | undefined,
    className: string
): ReactNode => {
    if (!icon) {
        return null;
    }

    return typeof icon === 'string' && isIconName(icon) ? (
        <Icon className={className} icon={icon} strokeWidth={iconStrokeWidth} />
    ) : (
        icon
    );
};

const getVariant = (variant: ButtonProps['variant']): MUIButtonProps['variant'] => {
    return variant === 'ghost' || variant === 'gray' ? 'text' : variant;
};

const Button = (props: ButtonProps) => {
    const {
        children,
        className = '',
        disabled = false,
        endIcon,
        color = 'primary',
        localeId,
        localeValues,
        startIcon,
        onClick,
        variant = 'contained',
        iconStrokeWidth,
        onlyIcon: onlyIconProp,
        classes: propsClasses,
        disableRipple = true,
        typographyProps,
        size = 'medium',
        uppercase = true,
        minimal = false,
        href,
        unstyled,
        ...others
    } = props;

    const classes = useStyles(props);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        !disabled && onClick && onClick(event);
    };

    const onlyIcon = onlyIconProp || (!children && !localeId);

    if (unstyled) {
        return (
            <MUIButton
                className={className}
                disabled={disabled}
                onClick={handleClick}
                startIcon={renderIcon(startIcon, iconStrokeWidth, classes.icon)}
                endIcon={renderIcon(endIcon, iconStrokeWidth, classes.icon)}
                variant={getVariant(variant)}
                disableRipple={disableRipple}
                LinkComponent={NextLink}
                color={variant === 'gray' ? 'inherit' : color}
                href={href}
                {...others}
            >
                {children}
            </MUIButton>
        );
    }

    return (
        <MUIButton
            className={classnames(
                classes.button,
                classes[variant],
                variant === 'contained' ? classes[color] : undefined,
                {
                    [classes.disabled]: disabled,
                    [classes.uppercase]: uppercase,
                    [classes.minimal]: minimal
                },
                classes[size],
                className
            )}
            classes={{
                startIcon: classnames(classes.startIcon, { [classes.onlyIcon]: onlyIcon }),
                endIcon: classnames(classes.endIcon, { [classes.onlyIcon]: onlyIcon }),
                root: classes.root
            }}
            disabled={disabled}
            onClick={handleClick}
            LinkComponent={NextLink}
            startIcon={renderIcon(startIcon, iconStrokeWidth, classes.icon)}
            endIcon={renderIcon(endIcon, iconStrokeWidth, classes.icon)}
            variant={getVariant(variant)}
            disableRipple={disableRipple}
            color={variant === 'contained' ? color : undefined}
            href={href}
            {...others}
        >
            {!onlyIcon && (
                <Typography
                    localeId={localeId}
                    title={children}
                    localeValues={localeValues}
                    variant="linkSmall"
                    {...typographyProps}
                />
            )}
        </MUIButton>
    );
};

export type { ButtonProps };
export default Button;
