import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import {
    FormikInputProps,
    FormikInputWrapper,
    isRequiredField,
    TextField,
    TextFieldProps,
    useFormikLabels
} from '../';

type Props = FormikInputProps<TextFieldProps> & {
    required?: boolean;
};

/**
 * Use this component for TextFields under a formik form.
 * @deprecated use NewFormikTextField
 */
// TODO remove this file and rename NewFormikTextField to FormikTextField
const FormikTextField = (props: Props) => {
    const { formik, gridProps, gridClassName, name, intl, type, localeId, ...others } = props;
    const { label, error, hasError, formikValue } = useFormikLabels(props);

    const value = typeof formikValue === 'undefined' || formikValue === null ? '' : formikValue;

    return (
        <FormikInputWrapper gridProps={gridProps} className={gridClassName}>
            <TextField
                name={name}
                label={label}
                fullWidth
                required={isRequiredField(formik.validationSchema, name)}
                value={value}
                onChange={(value) => {
                    formik.setFieldTouched(name, true);
                    formik.setFieldValue(name, value);
                }}
                error={hasError}
                helperText={hasError && <FormattedMessage {...error} />}
                onBlur={formik.handleBlur}
                type={type}
                {...others}
            />
        </FormikInputWrapper>
    );
};

const EnhancedFormikTextField = injectIntl(FormikTextField);

export { FormikTextField };

export default EnhancedFormikTextField;
