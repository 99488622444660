import React, { useState, useEffect } from 'react';
import {
    AppBar,
    Toolbar,
    IconButton,
    Hidden,
    Drawer,
    useTheme,
    MenuItem as MenuItemMUI
} from '@mui/material';
import classNames from 'classnames';

import DrawerContent from './DrawerContent';
import useStyles from './DrawerMenu.styles';
import { DrawerMenuProps } from './DrawerMenu.types';
import { getDefaultMenuState, isDesktop, isMenuLinkItem } from './utils';
import { Typography } from '../Typography';
import { Icon } from '../Icon';
import { NextLink } from '../../../common/src';

const DrawerMenu = (props: DrawerMenuProps) => {
    const {
        children = null,
        header = null,
        disabledMenu,
        items: itemsProp,
        currentPath,
        closeOnClick = false,
        onOpenSecondLevelMenu,
        appBarClass,
        onCloseSecondLevelMenu,
        forceShowItems = false
    } = props;
    const classes = useStyles(props);
    const [open, setOpen] = useState(false);
    const theme = useTheme();

    useEffect(() => {
        setOpen(getDefaultMenuState(theme));
    }, []);

    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    if (disabledMenu) {
        return (
            <>
                {header}
                {children}
            </>
        );
    }

    const container = typeof window !== 'undefined' ? () => window.document.body : undefined;
    const items = itemsProp.map((section) =>
        section.map((item, index) => {
            if (!isMenuLinkItem(item)) {
                return item;
            }

            const { href, color, colorVariant, iconName, iconColor, iconColorVariant } = item;
            const CustomLink = !href ? 'span' : NextLink;

            const isActive =
                item.isActive ||
                (currentPath && href && currentPath.includes(href) && href !== '/');

            const sharedProps = {
                className: classes.link,
                ['data-testid']: item['data-testid']
            };

            const contents = (
                <>
                    {iconName && (
                        <Icon
                            className={classNames(classes.listItemIcon)}
                            icon={iconName}
                            color={iconColor || color}
                            colorVariant={(iconColor && iconColorVariant) || colorVariant}
                        />
                    )}
                    {item.icon ? item.icon : null}
                    {item.iconUrl && (
                        <img
                            width={24}
                            height={24}
                            alt={item.text || ''}
                            src={item.iconUrl}
                            className={classes.listItemIcon}
                        />
                    )}
                    {open && (
                        <Typography
                            localeId={item.localeId}
                            title={item.text}
                            variant="body1"
                            className={classes.menuItemLinkTitle}
                            color={color}
                            colorVariant={colorVariant}
                        />
                    )}
                    {item.secondLevelLinks ? (
                        <Icon icon="arrowRightType2" className={classes.subMenuIcon} />
                    ) : null}
                    {item.cta && <Typography title={item.cta} className={classes.badge} />}
                </>
            );

            return (
                <MenuItemMUI
                    key={index}
                    className={classNames(item.className, classes.menuItem, {
                        [classes.activeItem]: isActive
                    })}
                    onClick={() => {
                        if (item.onClick) {
                            item.onClick();
                        } else if (href && closeOnClick) {
                            setOpen(false);
                        } else if (item.secondLevelLinks && onOpenSecondLevelMenu) {
                            onOpenSecondLevelMenu([item.secondLevelLinks]);
                        }

                        // close second level menu on navigation by link
                        if (!item.onClick && href && onCloseSecondLevelMenu) {
                            onCloseSecondLevelMenu();
                        }
                    }}
                >
                    {href ? (
                        <CustomLink {...sharedProps} href={href}>
                            {contents}
                        </CustomLink>
                    ) : (
                        <span {...sharedProps}>{contents}</span>
                    )}
                </MenuItemMUI>
            );
        })
    );

    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classNames(classes.appBar, appBarClass)}>
                <Toolbar classes={{ gutters: classes.toolbarGutters }}>
                    {((items.length > 0 && !isDesktop(theme)) || forceShowItems) && (
                        <IconButton
                            color="inherit"
                            edge="start"
                            onClick={handleDrawerToggle}
                            size="large"
                        >
                            <Icon icon="menu" className={classes.menuIcon} />
                        </IconButton>
                    )}
                    {header}
                </Toolbar>
            </AppBar>
            {(items.length > 0 || forceShowItems) && (
                <>
                    <Hidden mdUp implementation="css">
                        <Drawer
                            container={container}
                            variant="temporary"
                            open={open && !isDesktop(theme)}
                            onClose={handleDrawerToggle}
                            className={classes.mobileDrawer}
                            classes={{ paper: classes.drawerPaper }}
                            ModalProps={{ keepMounted: true }}
                        >
                            <DrawerContent
                                {...props}
                                classes={classes}
                                handleDrawerToggle={handleDrawerToggle}
                                items={items}
                            />
                        </Drawer>
                    </Hidden>
                    <Hidden mdDown implementation="css">
                        <Drawer
                            variant="permanent"
                            className={classNames(classes.drawer, {
                                [classes.drawerOpen]: open,
                                [classes.drawerClose]: !open
                            })}
                            classes={{
                                paper: classNames(classes.drawerPaper, {
                                    [classes.drawerOpen]: open,
                                    [classes.drawerClose]: !open
                                })
                            }}
                        >
                            <DrawerContent
                                {...props}
                                classes={classes}
                                handleDrawerToggle={handleDrawerToggle}
                                items={items}
                            />
                        </Drawer>
                    </Hidden>
                    <main className={classes.content}>{children}</main>
                </>
            )}
        </div>
    );
};

export default DrawerMenu;
