import { Types } from '..';

export const getStreamRedirectUrl = (
    event: Pick<Types.EventR, 'ubEventId' | 'locale'> & {
        sponsorCompanyId?: string | null;
    },
    page: 'livestream' | 'on-demand' | 'exhibition'
) => {
    const { ubEventId, locale: eventLocale, sponsorCompanyId } = event;

    const locale = eventLocale ? eventLocale : 'de-DE';
    const localeParam = locale === 'de-DE' ? 'de' : locale;

    const streamPath = event.ubEventId === '5901' ? 'stream-webups' : 'stream';

    const path = `/${streamPath}/api/event-link/redirect-ub-event-id`;
    const queryParams = new URLSearchParams({
        locale: localeParam,
        page
    });
    if (ubEventId) {
        queryParams.set('event_ub_id', ubEventId);
    }
    if (sponsorCompanyId) {
        queryParams.set('sponsor_company_id', sponsorCompanyId);
    }

    const result = `${path}?${queryParams}`;

    return result.toString();
};
