import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Addon, BrandIcon, Types } from '../../../';
import { Loader, Typography } from 'components';
import AddonsPopoverItem from './AddonsPopoverItem';
import { Box, Divider } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.common.white,
        padding: theme.spacing(2, 2, 1),

        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(2.5, 2, 1)
        }
    },
    brandTitle: {
        marginBottom: theme.spacing(2),
        color: '#59748D'
    }
}));

type Props = {
    brand: Types.ProductBrand;
    loading: boolean;
    addons: Array<Addon>;
    isDesktop: boolean;
};

const AddonsPopoverContent = (props: Props) => {
    const { brand, loading, addons, isDesktop } = props;
    const classes = useStyles(props);
    return (
        <Box className={classes.container}>
            {isDesktop && (
                <BrandIcon
                    brand={brand}
                    classes={{ container: classes.brandTitle }}
                    color="noColor"
                />
            )}
            {loading && <Loader />}
            {!loading && addons.length && (
                <Typography
                    localeId="common.addons.current-content"
                    variant="overline"
                    color="primary"
                    colorVariant="500"
                    mb={2}
                />
            )}
            {addons.map((item) => (
                <AddonsPopoverItem
                    latestVideo={item.courseProgress?.lastWatchedVideo}
                    content={item}
                    key={item.id}
                />
            ))}
            {!isDesktop && <Divider />}
        </Box>
    );
};

export default AddonsPopoverContent;
