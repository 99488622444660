import * as yup from 'yup';
import {
    useCreateAddressesRequest,
    noop,
    useExecMutation,
    UserTransformers,
    UserValidation,
    useUserFullName,
    useUpdateUserDetails,
    Fragments
} from '../../';
import { createYupPhoneValidationSchema, SchemaValues, useCustomFormik } from 'components';
import { useCallback } from 'react';

const getPersonalInfoValidationSchema = <IsAdmin extends boolean>(isAdmin: IsAdmin) => {
    const basePersonalAddressSchema = yup.object({
        ...UserValidation.address(isAdmin)
    });
    return yup.object({
        ...UserValidation.gender(),
        ...UserValidation.academicDegree(),
        ...UserValidation.country(isAdmin),
        firstName: UserValidation.name(),
        lastName: UserValidation.name(),
        mobile: createYupPhoneValidationSchema(),
        personalAddress: isAdmin
            ? basePersonalAddressSchema.nullable()
            : basePersonalAddressSchema.required(),
        ubAccountCode: UserValidation.ubAccountCode().required()
    });
};

type PersonalInfoFormValues = SchemaValues<typeof getPersonalInfoValidationSchema>;

const fillUserForFormik = (
    user: Fragments.UserFieldsFragment,
    userFullName: string
): PersonalInfoFormValues => {
    const personalAddress = UserTransformers.address(
        user?.addresses?.find((item) => item?.isPersonal === true),
        userFullName,
        true
    );
    personalAddress.country = personalAddress.country || user.country || null;

    return {
        academicDegreeCode: user.academicDegree?.code,
        mobile: {
            value: user.mobile,
            validate: noop,
            setCountry: undefined
        },
        country: user.country || undefined,
        firstName: user.firstName || undefined,
        gender: user.gender || undefined,
        lastName: user.lastName || undefined,
        personalAddress: personalAddress,
        ubAccountCode: user.ubAccountCode || undefined
    };
};

export const usePersonalInfoFormik = ({
    user,
    isAdmin,
    updateUser
}: {
    user: Fragments.UserFieldsFragment;
    isAdmin: boolean;
    updateUser: ReturnType<typeof useUpdateUserDetails>[0];
}) => {
    const execMutation = useExecMutation();
    const userFullName = useUserFullName(user);
    const createAddressesRequest = useCreateAddressesRequest(user);
    return useCustomFormik({
        initialValues: fillUserForFormik(user, userFullName),
        validationSchema: getPersonalInfoValidationSchema(isAdmin),
        onSubmit: async (values) => {
            const { mobile, personalAddress, ...rest } = values;
            const updateUserRequest = {
                ...rest,
                mobile: mobile.value,
                addresses: personalAddress ? createAddressesRequest([personalAddress]) : null
            };

            await execMutation(
                updateUser,
                {
                    variables: {
                        userId: user.id,
                        updateUserRequest
                    }
                },
                { messageLocaleId: 'common.complete-profile.success-message' }
            );
        }
    });
};

const getAddressValidationSchema = () =>
    yup.object({
        ...UserValidation.address()
    });

export type AddressFormik = ReturnType<typeof useAddressFormik>;
export const useAddressFormik = ({
    user,
    updateUser,
    closeAddressForm
}: {
    user: Fragments.UserFieldsFragment;
    updateUser: ReturnType<typeof useUpdateUserDetails>[0];
    closeAddressForm: () => void;
}) => {
    const userFullName = useUserFullName(user);
    const updateUserAddress = useUpdateUserAddress(user, updateUser);

    return useCustomFormik({
        initialValues: UserTransformers.address(undefined, userFullName),
        validationSchema: getAddressValidationSchema(),
        onSubmit: async (values, formikHelpers) => {
            await updateUserAddress(values);

            closeAddressForm();
            formikHelpers.resetForm();
        }
    });
};

export const useUpdateUserAddress = (
    user: Fragments.UserFieldsFragment,
    updateUserMutation: ReturnType<typeof useUpdateUserDetails>[0]
) => {
    const execMutation = useExecMutation();
    const createAddressesRequest = useCreateAddressesRequest(user);
    const updateUser = useCallback(
        (address: SchemaValues<typeof getAddressValidationSchema>) => {
            const updateUserRequest = {
                addresses: createAddressesRequest([address])
            };

            return execMutation(
                updateUserMutation,
                {
                    variables: {
                        userId: user.id,
                        updateUserRequest
                    }
                },
                { messageLocaleId: 'common.complete-profile.success-message' }
            );
        },
        [user.id]
    );

    return updateUser;
};
