import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';

import { ThemeTypes } from 'components';
import breakpoints from './breakpoints';

const displayLarge = {
    fontFamily: 'Poppins',
    fontSize: 46,
    lineHeight: '69px',
    fontWeight: 400,
    [`@media (min-width: ${breakpoints.values.sm}px)`]: {
        fontSize: 64,
        lineHeight: '96px'
    }
};

const displayMedium = {
    fontFamily: 'Poppins',
    fontSize: 30,
    lineHeight: '60px',
    fontWeight: 400,
    [`@media (min-width: ${breakpoints.values.sm}px)`]: {
        fontSize: 56,
        lineHeight: '84px'
    }
};

const displaySmall = {
    fontFamily: 'Poppins',
    fontSize: 36,
    lineHeight: '54px',
    fontWeight: 400,
    [`@media (min-width: ${breakpoints.values.sm}px)`]: {
        fontSize: 48,
        lineHeight: '72px'
    }
};

const displayXSmall = {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: 24,
    lineHeight: 32 / 24,
    letterSpacing: 1
};

const typography: ThemeTypes.Typography = {
    h1: {
        fontFamily: 'Poppins',
        fontWeight: 700,
        fontSize: 34,
        lineHeight: '51px',
        [`@media (min-width: ${breakpoints.values.sm}px)`]: {
            fontSize: 44,
            lineHeight: '66px'
        }
    },
    h2: {
        fontFamily: 'Poppins',
        fontWeight: 700,
        fontSize: 28,
        lineHeight: '42px',
        [`@media (min-width: ${breakpoints.values.sm}px)`]: {
            fontSize: 36,
            lineHeight: '54px'
        }
    },
    h3: {
        fontFamily: 'Poppins',
        fontWeight: 700,
        fontSize: 24,
        lineHeight: '36px',
        [`@media (min-width: ${breakpoints.values.sm}px)`]: {
            fontSize: 29,
            lineHeight: '44px'
        }
    },
    h4: {
        fontFamily: 'Poppins',
        fontWeight: 700,
        fontSize: 21,
        lineHeight: '32px',
        [`@media (min-width: ${breakpoints.values.sm}px)`]: {
            fontSize: 24,
            lineHeight: '36px'
        }
    },
    h5: {
        fontFamily: 'Poppins',
        fontWeight: 700,
        fontSize: 18,
        lineHeight: '27px',
        [`@media (min-width: ${breakpoints.values.sm}px)`]: {
            fontSize: 20,
            lineHeight: '30px'
        }
    },
    h6: {
        fontFamily: 'Poppins',
        fontWeight: 700,
        fontSize: 16,
        lineHeight: '24px'
    },
    subtitleLarge: {
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: 24,
        lineHeight: '36px'
    },
    subtitle1: {
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: 20,
        lineHeight: '30px'
    },
    subtitle2: {
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '24px'
    },
    body1: {
        fontFamily: 'Roboto',
        fontWeight: 500,
        fontSize: 16
    },
    bodyLarge: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: 18,
        lineHeight: '21.09px'
    },
    body2: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '24px'
    },
    caption: {
        fontFamily: 'Roboto',
        fontWeight: 700,
        fontSize: 12,
        lineHeight: '14.06px'
    },
    overline: {
        fontFamily: 'Roboto',
        fontWeight: 700,
        fontSize: 13,
        lineHeight: '19.5px',
        textTransform: 'uppercase'
    },
    displayLarge,
    displayLargeBold: {
        ...displayLarge,
        fontWeight: 700
    },
    displayMedium: {
        ...displayMedium
    },
    displayMediumBold: {
        ...displayMedium,
        fontWeight: 700
    },
    displaySmall: {
        ...displaySmall
    },
    displaySmallBold: {
        ...displaySmall,
        fontWeight: 700
    },
    // The following styles are here to assure backward compatibility. We will remove them
    // https://linear.app/fomf/issue/ECOM-17/get-rid-of-old-styles-for-text-and-link
    displayXSmall,
    displayXSmallBold: {
        ...displayXSmall,
        fontWeight: 700
    },
    textLarge: {
        fontWeight: 400,
        fontSize: 20,
        lineHeight: 38 / 20,
        letterSpacing: 0.75
    },
    textMedium: {
        fontWeight: 400,
        fontSize: 18,
        lineHeight: 34 / 18,
        letterSpacing: 0.75
    },
    textSmall: {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: 24 / 16,
        letterSpacing: 0.25
    },
    textXSmall: {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: 22 / 14,
        letterSpacing: 0.25
    },
    textXXSmall: {
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '14px',
        letterSpacing: 0.25
    },
    linkLarge: {
        fontWeight: 500,
        fontSize: 20,
        lineHeight: 38 / 20,
        letterSpacing: 0.75
    },
    linkMedium: {
        fontWeight: 500,
        fontSize: 18,
        lineHeight: 34 / 18,
        letterSpacing: 1
    },
    linkSmall: {
        fontWeight: 500,
        fontSize: 16,
        lineHeight: 24 / 16,
        letterSpacing: 0.75
    },
    linkXSmall: {
        fontWeight: 500,
        fontSize: 14,
        lineHeight: 22 / 14,
        letterSpacing: 0.25
    },
    buttonLarge: {
        fontWeight: 500,
        fontSize: 16,
        lineHeight: 1,
        letterSpacing: 0
    }
};

export default typography;
