import makeStyles from '@mui/styles/makeStyles';
import { ThemeTypes } from 'components';

import { FreeBadgeClasses, FreeBadgeProps } from './FreeBadge.types';

const useStyles = makeStyles<ThemeTypes.Theme, FreeBadgeProps, FreeBadgeClasses>((theme) => ({
    freeBadge: {
        width: '28px',
        top: theme.spacing(1),
        right: theme.spacing(1)
    },
    primary: {
        '& > svg': {
            fill: theme.palette.primary.main
        }
    },
    white: {
        '& > svg': {
            fill: theme.palette.common.white
        }
    },
    gray: {
        '& > svg': {
            fill: theme.palette.primary['300']
        }
    }
}));

export default useStyles;
