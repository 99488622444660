import { parseDBDate } from 'components';
import { formatDateRange, Types } from '../';
import dayjs, { Dayjs } from 'dayjs';

const getMembershipDateRange = (
    expiresAt: string,
    cycle: Types.MembershipCycle,
    initialStartedAt?: string | null | undefined,
    startedAt?: string | null | undefined
): {
    startDate: Dayjs;
    range: string;
} => {
    // In we have started calculating membership start and end dates on the backend in some places.
    // For now only in the user dahsboard membership page.
    if (startedAt) {
        const expiresAtDay = parseDBDate(expiresAt, true);
        const startedAtDay = parseDBDate(startedAt, true);
        const range = formatDateRange(startedAtDay, expiresAtDay);
        const result = {
            startDate: startedAtDay,
            range
        };
        return result;
    }

    const expiresAtDay = parseDBDate(expiresAt, true);
    let startsAtDay;
    switch (cycle) {
        case Types.MembershipCycle.OneMonth:
            startsAtDay = expiresAtDay.subtract(1, 'month');
            break;
        case Types.MembershipCycle.TwoMonths:
            startsAtDay = expiresAtDay.subtract(2, 'month');
            break;
        case Types.MembershipCycle.ThreeMonths:
            startsAtDay = expiresAtDay.subtract(3, 'month');
            break;
        case Types.MembershipCycle.SixMonths:
            startsAtDay = expiresAtDay.subtract(6, 'month');
            break;
        case Types.MembershipCycle.ThreeYears:
            startsAtDay = expiresAtDay.subtract(3, 'year');
            break;
        case Types.MembershipCycle.OneYear:
        default:
            startsAtDay = expiresAtDay.subtract(1, 'year');
            break;
    }

    const parsedInitialStartedAt = initialStartedAt ? parseDBDate(initialStartedAt, true) : null;
    const currentDatetime = dayjs();
    startsAtDay =
        parsedInitialStartedAt &&
        expiresAtDay.isAfter(currentDatetime) &&
        expiresAtDay.diff(currentDatetime, 'month') > 12
            ? parsedInitialStartedAt
            : startsAtDay.add(1, 'day');

    const range = formatDateRange(startsAtDay, expiresAtDay);
    const result = {
        startDate: startsAtDay,
        range
    };
    return result;
};

export default getMembershipDateRange;
