import { DialogProps } from '@mui/material';
import type { ClassNameMap } from '@mui/styles';
import { IconName } from '../../Icon';

enum DialogHeightEnum {
    Normal,
    FullHeight,
    AutoHeight
}

type FullScreenDialogProps = Partial<Omit<DialogProps, 'classes'>> & {
    open: boolean;
    onClose: () => void;
    actionButton?: {
        title?: string;
        localeId?: string;
        onClick: () => void;
        icon?: IconName;
        iconStrokeWidth?: number;
    };
    children: React.ReactNode;
    actions?: React.ReactNode;
    title?: string;
    titleLocaleId?: string;
    size?: DialogHeightEnum;
    classes?: Partial<
        ClassNameMap<'dialogContent' | 'dialogPaper' | 'titleContainer' | 'closeIcon'>
    >;
};

export type { FullScreenDialogProps };
export { DialogHeightEnum };
