import React, { useState } from 'react';
import classnames from 'classnames';
import { Avatar, Button, Link, Typography } from 'components';
import {
    CATALOG_URLS,
    USER_URLS,
    useHydrationContext,
    getAccreditationItem,
    hasGroupMembership
} from '../..';
import { HeaderUserNavBarProps } from './HeaderUserNavBar.types';
import useStyles from './HeaderUserNavBar.styles';
import UserPopover from './UserPopover/UserPopover';
import { useIntl } from 'react-intl';
import { AddonsPopover } from './AddonsPopover';

type CreditsBadgeProps = {
    total: number;
};
const CreditsBadge = ({ total }: CreditsBadgeProps) => {
    const classes = useStyles();

    return (
        <div className={classes.accreditationPointsContainer}>
            <Typography
                localeId="common.header.credits"
                localeValues={{
                    amount: total
                }}
                color="primary"
                fontFamily="Poppins"
                variant="body1"
                className={classes.accreditationPoints}
            />
        </div>
    );
};

const HeaderUserNavBar = (props: HeaderUserNavBarProps) => {
    const {
        isLoading,
        isLoggedIn,
        user,
        userMembership,
        loginActionLocaleId,
        loginHrefOrHandler = USER_URLS.login,
        registrationHrefOrHandler = USER_URLS.registration({})
    } = props;
    const { isHydrated } = useHydrationContext();
    const { locale } = useIntl();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const totalPointsCertifiedItem =
        user && userMembership
            ? getAccreditationItem(user.totalPointCertified, userMembership.membership.country)
            : undefined;
    const total =
        totalPointsCertifiedItem?.all.reduce((acc, item) => acc + (item.amount || 0), 0) || 0;

    const userHasGroupMembership = hasGroupMembership(userMembership);

    const links = {
        dashboard: USER_URLS.dashboard,
        homePage: CATALOG_URLS.base,
        registration: registrationHrefOrHandler,
        logout: USER_URLS.logout({ locale }),
        profile: USER_URLS.profile,
        dashboardMembership: USER_URLS.dashboardMembership
    };

    // Open the UserPopover menu
    const handleAvatarClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
        setAnchorEl(e.currentTarget);
    };

    return (
        <div className={classes.container}>
            {userMembership && <CreditsBadge total={total} />}
            {!isLoading && !isLoggedIn && isHydrated && (
                <Button
                    href={typeof loginHrefOrHandler === 'string' ? loginHrefOrHandler : undefined}
                    onClick={
                        typeof loginHrefOrHandler === 'function' ? loginHrefOrHandler : undefined
                    }
                    localeId={loginActionLocaleId}
                    variant="text"
                    typographyProps={{ className: classes.buttonText }}
                    uppercase={false}
                    className={classes.button}
                />
            )}
            {userHasGroupMembership && (
                <AddonsPopover isDesktop className={classes.addonsContainer} />
            )}
            {isLoggedIn && (user?.firstName || user?.email) ? (
                <div onClick={handleAvatarClick} data-test="common/header-avatar">
                    <Avatar
                        className={classes.avatar}
                        email={user.email}
                        firstName={user.firstName}
                        lastName={user?.lastName}
                    />
                </div>
            ) : (
                !isLoading &&
                isHydrated && (
                    <Button
                        component={Link}
                        href={
                            isLoggedIn
                                ? links.dashboard
                                : typeof links.registration === 'string'
                                  ? links.registration
                                  : undefined
                        }
                        onClick={
                            !isLoading && typeof links.registration === 'function'
                                ? links.registration
                                : undefined
                        }
                        className={classnames(classes.button, classes.primaryButton)}
                        typographyProps={{ className: classes.buttonText }}
                        size="large"
                        variant="outlined"
                        localeId="common.registration.primary-action"
                    />
                )
            )}
            {user && (
                <UserPopover
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    links={links}
                    user={user}
                    onClose={() => setAnchorEl(null)}
                    hasGroupMembership={userHasGroupMembership}
                />
            )}
        </div>
    );
};

export default HeaderUserNavBar;
