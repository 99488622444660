import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import Select, { SelectProps } from '../Select/Select';
import { FormikInputProps, FormikInputWrapper, isRequiredField, useFormikLabels } from '../';
import getCommonLocaleId from '../utils/getCommonLocaleId';

type Props = FormikInputProps<SelectProps>;

/**
 * Use this component for Selects under a formik form.
 */
const FormikSelect = (props: Props) => {
    const { formik, gridProps, gridClassName, name, options, intl, ...others } = props;
    const { messages } = intl;
    const { label, error, hasError, formikValue } = useFormikLabels(props);

    if (
        typeof formikValue !== 'undefined' &&
        typeof formikValue !== 'string' &&
        formikValue !== null
    ) {
        console.error(`Field ${name} is not a string`);
    }

    const optionsWithTranslations = options?.map((option) => {
        const optionLocaleId = option.localeId
            ? option.localeId
            : getCommonLocaleId(option.id || '', name);
        if (!messages[optionLocaleId] && !option.label) {
            throw new Error(`Missing translation for option "${optionLocaleId}"`);
        }
        return {
            id: option.id,
            localeId: optionLocaleId,
            label: option.label
        };
    });

    const isRequired = isRequiredField(formik.validationSchema, name);

    return (
        <FormikInputWrapper gridProps={gridProps} className={gridClassName}>
            <Select
                name={name}
                label={label}
                fullWidth
                defaultValue=""
                value={typeof formikValue === 'string' ? formikValue : ''}
                onChange={(value) => {
                    formik.setFieldTouched(name, true);
                    formik.setFieldValue(name, value);
                }}
                error={hasError}
                helperText={hasError && <FormattedMessage {...error} />}
                options={optionsWithTranslations}
                onBlur={formik.handleBlur}
                required={isRequired}
                {...others}
            />
        </FormikInputWrapper>
    );
};

const EnhancedFormikSelect = injectIntl(FormikSelect);

export { FormikSelect };
export default EnhancedFormikSelect;
