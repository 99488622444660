const categoryMap: Record<string, string> = {
    '/livestream': 'Livestream',
    '/partner': 'Partner',
    '/product': 'Product',
    '/content': 'Content',
    '/mediathek': 'Mediathek',
    '/search': 'Search',
    '/checkout': 'Checkout',
    '/user': 'User Dashboard',
    '/': 'Home'
};

const checkoutSubCategories: Array<string> = ['booking', 'memberships', 'top-up'];

export interface CategoryResult {
    page_category: string;
    sub_category?: string;
}
export default function getCategory(path: string): CategoryResult {
    const urlPath = path.split('/').filter((p) => p.length > 0);

    if (urlPath.length >= 2 && urlPath[0] && checkoutSubCategories.includes(urlPath[0])) {
        return {
            page_category: 'Checkout',
            sub_category: urlPath[1]
        };
    }

    const entry = Object.entries(categoryMap).find(
        ([key]) => (path === '/' && key === '/') || (key !== '/' && path.startsWith(key))
    );

    if (entry) {
        return { page_category: entry[1] };
    }

    // If none of the categories match, default to the first part of the path
    if (urlPath.length > 0 && categoryMap['/' + urlPath[0]]) {
        return { page_category: categoryMap['/' + urlPath[0]] || 'Other' };
    }

    // Default category
    return { page_category: 'Other' };
}
