import { Types } from '..';

/**
 * Represents the features available for each country.
 */
export type FeaturesByCountry = {
    topicSelector: Array<CountryCode>;
    memberships: Array<CountryCode>;
    mediathek: Array<CountryCode>;
    paypal: Array<CountryCode>;
    sepa: Array<CountryCode>;
    twint: Array<CountryCode>;
    partnerLink: Array<CountryCode>;
    accreditationHint: Array<CountryCode>;
    earlyPrices: Array<CountryCode>;
    contentLanguage: Array<CountryCode>;
    contentLanguageSwitch: Array<CountryCode>;
    partnerLandingPage: Array<CountryCode>;
    partnerForum: Array<CountryCode>;
    segmentConsentManager: Array<CountryCode>;
    membershipAccreditedCoursesFootnote: Array<CountryCode>;
    dataSharingConsent: Array<CountryCode>;
    bookingParticipationTypesOrder: Array<CountryCode>;
    medicalNumberCheck: Array<CountryCode>;
    livestreamPage: Array<CountryCode>;
    summedUpPage: Array<CountryCode>;
    oneStepBooking: Array<CountryCode>;
    bookingWarningEFN: Array<CountryCode>;
    newsletterPage: Array<CountryCode>;
    aboutUsHeaderMenu: Array<CountryCode>;
};

type FeatureByCountryAndOccupationTypeHandler = Record<
    CountryCode,
    (occupationType?: Types.OccupationType | null) => boolean
>;

/**
 * Object containing feature functions based on country and occupation type.
 */
export type FeaturesByCountryAndOccupationType = {
    watchWuifContent: FeatureByCountryAndOccupationTypeHandler;
};

const featuresByCountry: FeaturesByCountry = {
    topicSelector: ['de', 'at', 'ch'],
    memberships: ['de', 'ch', 'at'],
    mediathek: ['de', 'ch', 'at'],
    paypal: ['de'],
    sepa: ['de', 'at'],
    twint: ['ch'],
    partnerLink: ['ch', 'at', 'de'],
    accreditationHint: ['de', 'at'],
    earlyPrices: ['de', 'at'],
    contentLanguage: ['ch'],
    contentLanguageSwitch: ['de', 'at', 'ch'],
    partnerLandingPage: ['ch', 'at', 'de'],
    partnerForum: ['ch', 'at', 'de'],
    segmentConsentManager: ['ch', 'at', 'de'],
    membershipAccreditedCoursesFootnote: ['de'],
    dataSharingConsent: ['de', 'at', 'gb'],
    bookingParticipationTypesOrder: ['de', 'at', 'ch'],
    medicalNumberCheck: ['de', 'at', 'ch'],
    livestreamPage: ['de', 'at', 'ch'],
    summedUpPage: ['de', 'at', 'ch'],
    oneStepBooking: ['de', 'at', 'ch'],
    bookingWarningEFN: ['de', 'at', 'ch'],
    newsletterPage: ['de', 'at', 'ch'],
    aboutUsHeaderMenu: ['de', 'at', 'ch']
};

const featuresByCountryAndOccupationType: FeaturesByCountryAndOccupationType = {
    watchWuifContent: {
        ch: (occupationType) => occupationType != null,
        at: (occupationType) => occupationType != null,
        gb: (occupationType) => occupationType != null,
        de: (occupationType) =>
            occupationType != null && occupationType !== 'HEALTHCARE_PROFESSIONAL'
    }
};

/**
 * Represents the combined features available for each country and occupation type.
 */
export type Features = FeaturesByCountry & FeaturesByCountryAndOccupationType;

/**
 * Checks if a feature is enabled for a specific country and/or occupation type.
 * @param feature - The feature key name to check.
 * @param value - The country code to check against the feature.
 * @param occupationType - The occupation type to check against the feature (required for features that depend on occupation type).
 * @returns true if the feature is enabled, false otherwise.
 * @throws {Error} If occupation type is missing for features that require it.
 */
const isFeatureEnabled = <FeatureKey extends keyof Features>(
    feature: FeatureKey,
    value: CountryCode,
    occupationType?: Types.OccupationType | null
): boolean => {
    const featureByCountryAndOccupationType = feature as keyof FeaturesByCountryAndOccupationType;
    if (featuresByCountryAndOccupationType[featureByCountryAndOccupationType]) {
        return featuresByCountryAndOccupationType[featureByCountryAndOccupationType][value](
            occupationType
        );
    }

    const featureByCountry = feature as keyof FeaturesByCountry;
    const allowedValues = featuresByCountry[featureByCountry];
    const searchedValues = Array.isArray(value) ? value : [value];

    const result = allowedValues.reduce<boolean>(
        (acc, allowedValue) => searchedValues.includes(allowedValue) || acc,
        false
    );
    return result;
};

export default isFeatureEnabled;
