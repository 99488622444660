import { useState } from 'react';
import { LDFlagSet } from 'launchdarkly-react-client-sdk';
import { useRouter } from '../../hooks';
import { USER_URLS, MEDIATHEK_URLS, CATALOG_URLS, BOOKING_URLS } from '../../urls';
import {
    HeaderLinksProps,
    HeaderLinkChildItem,
    HeaderLinkItemBase,
    HeaderLinkParentItem,
    HeaderLinkItem
} from './HeaderLinks.types';
import isFeatureEnabled from '../../utils/isFeatureEnabled';
import { HeaderQueryParams } from '.';
import { IconName, MenuItem } from 'components';
import { ParsedUrlQuery } from 'node:querystring';
import { booleanFilter } from '../../utils';

const LOCALE_TO_CREDIT_ICON: Record<Locale, IconName> = {
    'de-DE': 'cme',
    'de-AT': 'dfp',
    'de-CH': 'creditsDeCh',
    'fr-CH': 'creditsFrCh',
    'en-GB': 'cme'
};

export const HEADER_LINKS_SEPARATOR = 'separator';

const getDefaultLinks = (
    locale: Locale,
    aboutUsLinks?: HeaderLinksProps['menuItems']
): HeaderLinksProps['menuItems'] => [
    [
        {
            localeId: 'common.header.home-site',
            href: CATALOG_URLS.base,
            iconName: 'homeFill',
            iconColor: 'secondary'
        },
        {
            localeId: 'common.header.my-fomf',
            href: USER_URLS.dashboard,
            showWhenFeatureFlagAvailable: ({ myFomfPages }) => !myFomfPages,
            showWhenLoggedIn: true
        },
        {
            localeId: 'common.header.my-fomf',
            href: USER_URLS.myFOMFDashboard,
            showWhenLoggedIn: true,
            showWhenFeatureFlagAvailable: ({ myFomfPages }) => myFomfPages
        }
    ],
    [
        {
            href: locale === 'en-GB' ? '/product/GP' : CATALOG_URLS.allCourses,
            localeId: 'common.header.link-cme-course'
        },
        // if UK, then insert a webup link at the top level, instead of the submenu
        ...(locale === 'en-GB'
            ? [
                  {
                      localeId: 'common.header.link-webup',
                      href: MEDIATHEK_URLS.webup
                  }
              ]
            : []),
        {
            localeId: 'common.header.link.all-courses',
            showWhenFeatureAvailable: 'mediathek',
            secondLevelLinks: [
                {
                    href: CATALOG_URLS.livestream,
                    localeId: 'common.header.link-livestream',
                    showWhenFeatureAvailable: 'livestreamPage',
                    iconName: 'live'
                },
                {
                    href: CATALOG_URLS.allCourses,
                    localeId: 'common.header.link-cme-course',
                    iconName: LOCALE_TO_CREDIT_ICON[locale]
                },
                {
                    href: CATALOG_URLS.onDemandLanding,
                    localeId: 'common.header.link-cme-on-demand-course',
                    showWhenFeatureFlagAvailable: (flags) => {
                        switch (locale) {
                            case 'de-DE':
                            case 'de-CH':
                            case 'fr-CH':
                                return true;
                            case 'de-AT':
                                return flags.learningOnDemandAccreditationAtTemp14102024;
                            default:
                                return false;
                        }
                    },
                    iconName: 'progressPlay'
                },
                {
                    localeId: 'common.header.link-webup',
                    href: MEDIATHEK_URLS.webup,
                    iconName: 'webUp'
                },
                {
                    localeId: 'common.header.link.syfat',
                    href: CATALOG_URLS.syfatLanding,
                    showWhenFeatureFlagAvailable: (flags) => {
                        switch (locale) {
                            case 'de-DE':
                            case 'de-AT':
                                return true;
                            case 'de-CH':
                            case 'fr-CH':
                                return flags.learningSyfatForChMembersTemp01102024;
                            default:
                                return false;
                        }
                    },
                    iconName: 'syfat'
                },
                {
                    localeId: 'common.header.link-handouts',
                    href: CATALOG_URLS.handoutsLanding,
                    showWhenFeatureFlagAvailable: (flags) => {
                        return flags.learningKursbucherTemp25032025;
                    },
                    iconName: 'handouts'
                },
                {
                    localeId: 'common.header.link-summed-up',
                    href: MEDIATHEK_URLS.summedup,
                    showWhenFeatureAvailable: 'summedUpPage',
                    iconName: 'summedUp'
                }
            ]
        },
        {
            localeId: 'common.header.link.medkit',
            showWhenFeatureAvailable: 'mediathek',
            secondLevelLinks: [
                {
                    localeId: 'common.header.link-skills',
                    href: MEDIATHEK_URLS.skills,
                    showWhenFeatureAvailable: 'mediathek',
                    iconName: 'skills'
                },
                {
                    localeId: 'common.header.link-guidelines',
                    href: MEDIATHEK_URLS.guidelines,
                    showWhenFeatureAvailable: 'mediathek',
                    iconName: 'guidelines'
                }
            ]
        },
        // if UK, then insert a Guidelines page link at the top level, instead of the submenu
        ...(locale === 'en-GB'
            ? [
                  {
                      localeId: 'common.header.link-guidelines',
                      href: MEDIATHEK_URLS.guidelines
                  }
              ]
            : []),
        {
            localeId: 'common.header.link-partnerforum',
            showWhenFeatureAvailable: 'partnerForum',
            secondLevelLinks: [
                {
                    localeId: 'catalog.partner.tabs.videos',
                    href: CATALOG_URLS.partners + '/videos',
                    iconName: 'play2'
                },
                {
                    localeId: 'catalog.partner.tabs.partners',
                    href: CATALOG_URLS.partners + '/partners',
                    iconName: 'brands2'
                }
            ]
        }
    ],
    ...(aboutUsLinks || []),
    [
        {
            localeId: 'common.header.link-membership',
            showWhenFeatureAvailable: 'memberships',
            iconName: 'membership',
            color: 'primary',
            colorVariant: '600',
            secondLevelLinks: [
                {
                    localeId: 'common.header.link.user-membership',
                    href: BOOKING_URLS.memberships,
                    iconName: 'userFill'
                },
                {
                    localeId: 'common.header.link.group-membership',
                    href: '/gruppenmitgliedschaft',
                    iconName: 'userGroupFill'
                }
            ]
        }
    ]
];

const filterHeaderLinks = (args: {
    headerLinks: HeaderLinksProps['menuItems'];
    countryCode: CountryCode;
    locale: Locale;
    flags: LDFlagSet;
    pathname: string;
    query: ParsedUrlQuery;
    isLoggedIn: boolean;
}) => {
    const { headerLinks, countryCode, flags, pathname, query, isLoggedIn } = args;
    const menuItemFilter = (menu: HeaderLinkItemBase) => {
        const isFrontendFeatureEnabled =
            menu.showWhenFeatureAvailable != null &&
            isFeatureEnabled(menu.showWhenFeatureAvailable, countryCode);
        const isLDFlagEnabled =
            menu.showWhenFeatureFlagAvailable != null && menu.showWhenFeatureFlagAvailable(flags);

        let shouldShow = true;

        if (shouldShow && menu.showWhenFeatureAvailable) {
            shouldShow = isFrontendFeatureEnabled;
        }
        if (shouldShow && menu.showWhenFeatureFlagAvailable) {
            shouldShow = isLDFlagEnabled;
        }
        if (shouldShow && menu.showWhenLoggedIn && !isLoggedIn) {
            shouldShow = false;
        }

        return shouldShow;
    };

    const addIsLinkActiveMap = <T extends HeaderLinkChildItem | HeaderLinkParentItem>(
        headerLinkItem: T,
        isSecondLevel?: boolean
    ): T => {
        const href = 'href' in headerLinkItem ? headerLinkItem.href : '';
        const secondLevelLinks =
            'secondLevelLinks' in headerLinkItem ? headerLinkItem.secondLevelLinks : [];

        const allLinks = [href, ...secondLevelLinks.map((link) => link.href)];
        const allLinksFiltered = allLinks
            .filter(booleanFilter)
            // Special handling of cme course link shown twice. We don't want to mark both menu items as active
            .filter((href, index) => !(href === cmeCourseLinkUrl && (index > 0 || isSecondLevel)));

        const isActive = allLinksFiltered.some((href) =>
            getIsLinkActive({ href, pathname, query })
        );

        return {
            ...headerLinkItem,
            isActive
        };
    };

    const cmeCourseLinkUrl = headerLinks.reduce<string | undefined>((acc, items) => {
        if (acc) {
            return acc;
        }

        let cmeCourseLinkItem: HeaderLinkChildItem | undefined;
        items.forEach(
            (item) =>
                'secondLevelLinks' in item &&
                item.secondLevelLinks.forEach((link) => {
                    if (link.localeId === 'common.header.link-cme-course') {
                        cmeCourseLinkItem = link;
                    }
                })
        );
        return (
            (cmeCourseLinkItem && 'href' in cmeCourseLinkItem && cmeCourseLinkItem.href) ||
            undefined
        );
    }, undefined);

    const result = headerLinks
        .map((each) =>
            each
                .filter(menuItemFilter)
                .map((each) => ({
                    ...each,
                    ...('secondLevelLinks' in each
                        ? {
                              secondLevelLinks: each.secondLevelLinks
                                  .filter(menuItemFilter)
                                  .map((link) => addIsLinkActiveMap(link, true))
                          }
                        : undefined)
                }))
                .map((link) => addIsLinkActiveMap(link))
        )
        .filter((each) => each.length > 0);

    return result;
};

export const getHeaderLinks = (args: {
    countryCode: CountryCode;
    locale: Locale;
    flags: LDFlagSet;
    pathname: string;
    query: ParsedUrlQuery;
    isLoggedIn: boolean;
    showAboutUsLinks?: boolean;
}): HeaderLinksProps['menuItems'] => {
    const { locale, showAboutUsLinks } = args;

    const aboutUsLinks = showAboutUsLinks ? getAboutUsLinks(args) : undefined;
    const defaultLinks = getDefaultLinks(locale, aboutUsLinks);

    const result = filterHeaderLinks({
        ...args,
        headerLinks: defaultLinks
    });

    return result;
};

export const userLogoutSubMenuLinks = (locale: Locale): Array<MenuItem> => [
    {
        localeId: 'common.header.user-navbar.popover.logout',
        onClick: USER_URLS.logout({ locale }),
        color: 'error',
        colorVariant: 'dark',
        iconName: 'logout',
        iconColor: 'primary',
        iconColorVariant: 'main'
    }
];

export const userSubMenuLinks = (locale: Locale): Array<Array<MenuItem>> => [
    [
        {
            localeId: 'common.header.my-cme',
            href: USER_URLS.dashboard,
            color: 'secondary',
            colorVariant: '800',
            iconName: 'event',
            iconColor: 'primary',
            iconColorVariant: 'main'
        },
        {
            localeId: 'common.header.user-navbar.popover.user-management',
            href: USER_URLS.profile,
            color: 'secondary',
            colorVariant: '800',
            iconName: 'accountCircle',
            iconColor: 'primary',
            iconColorVariant: 'main'
        },
        {
            localeId: 'common.header.user-navbar.popover.membership',
            href: USER_URLS.dashboardMembership,
            color: 'secondary',
            colorVariant: '800',
            iconName: 'fomfShape',
            iconColor: 'primary',
            iconColorVariant: 'main'
        }
    ],
    userLogoutSubMenuLinks(locale)
];

export const useHeaderSearch = () => {
    const router = useRouter<HeaderQueryParams>();
    const {
        query: { search: searchQueryParam = null },
        setQuery,
        pathname
    } = router;
    const [search, setSearch] = useState<string | null>(searchQueryParam);

    const onSearch = () => {
        // Don't redirect to search page if search input is empty
        if (!search) {
            return;
        }
        router.push(`${MEDIATHEK_URLS.search}?search=${search}&sortBy=RELEVANCE`);
    };

    const onSearchChange = (text: string) => {
        setSearch(text || null);
    };

    const onClear = () => {
        // only open search page on clearing when already on search page
        if (searchQueryParam === search && pathname === MEDIATHEK_URLS.search) {
            setQuery({ search: null });
            router.push(MEDIATHEK_URLS.search);
        } else {
            setSearch(null);
        }
    };

    return {
        search,
        onSearch,
        onSearchChange,
        onClear
    };
};

/**
 * This function is used to replace the query params in the url with the actual values. Example: `/product/[code]` -> `/product/AM`.
 */
export const replaceNextJSPageRoutingQueryParamsWithActualValues = (
    url: string,
    query: NodeJS.Dict<string | Array<string>>
) => {
    let newUrl = url;
    if (Object.keys(query).length > 0) {
        for (const pair of Object.entries(query)) {
            let value = pair[1];
            const key = pair[0];
            if (value) {
                if (Array.isArray(value)) {
                    value = value.join('/');
                }

                newUrl = newUrl.replace(`[${key}]`, value);
                newUrl = newUrl.replace(`[...${key}]`, value);
            }
        }
    }
    return newUrl;
};

/**
 * This function is used to normalise the url to be relative to the current domain. Additionally, it removes the query string, hash, coalesces double slashes into a single one and removes a trailing slash.
 */
export const normaliseUrl = (url: string) => {
    let newUrl = url;

    if (typeof window !== 'undefined' && window.location.origin) {
        newUrl = url.replace(window.location.origin, '');
    }

    newUrl = newUrl.replace(/\/$/, '');
    newUrl = newUrl.replace(/\?.*$/, '');
    newUrl = newUrl.replace(/#.*$/, '');
    newUrl = newUrl.replace(/(?:\/)?fr-CH/, '');
    if (newUrl === '') {
        newUrl = '/';
    }
    if (newUrl[0] !== '/') {
        newUrl = `/${newUrl}`;
    }
    newUrl = newUrl.replace(/([^:/]|^)\/\/+/g, '$1/');
    return newUrl;
};

export const getIsLinkActive = (args: {
    href: string | null | undefined;
    pathname: string;
    query: ParsedUrlQuery;
}) => {
    const { href, pathname, query } = args;
    const relativeNormalisedUrl = href && normaliseUrl(href);
    const normalisedNextJSPageUrl = replaceNextJSPageRoutingQueryParamsWithActualValues(
        pathname,
        query
    );

    return relativeNormalisedUrl === normalisedNextJSPageUrl;
};

const scientificBoardUrlByDomain: Record<Locale, string | null> = {
    'de-DE': '/wissenschaftliches-kollegium',
    'de-CH': '/wissenschaftliches-kollegium',
    'fr-CH': `/commission-scientifique`,
    'de-AT': '/wissenschaftliches-kollegium',
    'en-GB': null
};

const companyAndMissionUrlByDomain: Record<Locale, string | null> = {
    'de-DE': '/ueber-uns/unternehmen',
    'de-CH': '/ueber-uns/unternehmen',
    'fr-CH': '/a-propos-de-fomf/entreprise',
    'de-AT': '/ueber-uns/unternehmen',
    'en-GB': '/about-us/mission'
};

export const getAboutUsLinks = (args: {
    countryCode: CountryCode;
    locale: Locale;
    flags: LDFlagSet;
    pathname: string;
    query: ParsedUrlQuery;
    isLoggedIn: boolean;
    addSeparator?: boolean;
}): HeaderLinksProps['menuItems'] => {
    const { locale, addSeparator = false } = args;

    const defaultLinks: HeaderLinksProps['menuItems'] = [
        [
            {
                color: 'primary',
                colorVariant: '600',
                localeId: 'common.header.link-about-us',
                showWhenFeatureAvailable: 'aboutUsHeaderMenu',
                showWhenFeatureFlagAvailable: (flags) => flags.aboutUsHeaderMenuItemTemp03022025,
                secondLevelLinks: [
                    companyAndMissionUrlByDomain[locale] && {
                        localeId: 'common.header.link-about-us.mission',
                        href: companyAndMissionUrlByDomain[locale] as string,
                        iconName: 'arrowMark' as const
                    },
                    scientificBoardUrlByDomain[locale] && {
                        href: scientificBoardUrlByDomain[locale] as string,
                        localeId: 'common.header.scientific-board',
                        iconName: 'stethoscope' as const
                    },
                    {
                        href: '/partner-fortbildungsforum/partners',
                        localeId: 'common.header.link-about-us.partners',
                        iconName: 'handshake' as const,
                        showWhenFeatureAvailable: 'partnerForum' as const
                    },
                    {
                        href: '/newsletter',
                        localeId: 'common.header.newsletter',
                        iconName: 'envelope' as const,
                        showWhenFeatureAvailable: 'newsletterPage' as const
                    },
                    {
                        href: '/contact',
                        localeId: 'common.header.contact',
                        iconName: 'phone' as const
                    },
                    {
                        href: '/faq',
                        localeId: 'common.header.faq',
                        iconName: 'help' as const
                    }
                ].filter(booleanFilter)
            },
            ...(addSeparator
                ? [
                      {
                          localeId: HEADER_LINKS_SEPARATOR,
                          href: '',
                          showWhenFeatureAvailable: 'aboutUsHeaderMenu',
                          showWhenFeatureFlagAvailable: (flags) =>
                              flags.aboutUsHeaderMenuItemTemp03022025
                      } as HeaderLinkItem
                  ]
                : [])
        ]
    ];

    const result = filterHeaderLinks({
        ...args,
        headerLinks: defaultLinks
    });

    return result;
};
