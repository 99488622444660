import { MutableRefObject, useEffect, useState } from 'react';

export type ObserverSettings = {
    // default `0` which stands for triggering visible state as soon a first pixel is visible
    viewThreshold?: number;
    // default `false`
    isOneTimeView?: boolean;
};

const useIntersectionObserver = (
    elementRef: MutableRefObject<HTMLElement | null>,
    settings?: ObserverSettings,
    callback?: () => void
) => {
    const { viewThreshold = 0, isOneTimeView = false } = settings || {};
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const element = elementRef.current;
        if (!element) {
            return;
        }

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (!entry?.isIntersecting) {
                    setIsVisible(false);
                    return;
                }
                setIsVisible(true);
                isOneTimeView && observer.disconnect();
            },
            { threshold: viewThreshold }
        );
        observer.observe(element);
        return () => {
            observer.disconnect();
        };
    }, [elementRef.current]);

    useEffect(() => {
        if (isVisible) {
            callback && callback();
        }
    }, [isVisible]);

    return isVisible;
};

export default useIntersectionObserver;
