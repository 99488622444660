import React from 'react';
import { Icon, isIconName } from 'components';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';

type Props = {
    companyLogo: string | null | undefined;
    className?: string;
};

const useStyles = makeStyles(() => ({
    image: {
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center'
    }
}));

const UserCompanyLogo = (props: Props) => {
    const { companyLogo, className } = props;
    const classes = useStyles();

    const logo = companyLogo || 'domain';

    return isIconName(logo) ? (
        <Icon icon={logo} className={className} />
    ) : (
        <Box
            style={{ backgroundImage: `url(${logo})` }}
            className={classnames(classes.image, className)}
        />
    );
};

export default UserCompanyLogo;
