import React from 'react';

import { Box, Button } from '@mui/material';

import { Badge, Icon, Typography } from 'components';

import useStyles from './ContentPlaylistSyrotItem.styles';
import { Types, Speakers, SpeakerVariant, buildURLWithParams, MEDIATHEK_URLS } from '../../';
import { ContentPlaylistSyrotItemProps } from './ContentPlaylistSyrotItem.types';
import classnames from 'classnames';
import { ContentDuration } from '../ContentDuration';
import Link from 'next/link';

const ContentPlaylistSyrotItem = (props: ContentPlaylistSyrotItemProps) => {
    const {
        id,
        speakers,
        completed,
        title,
        contentId,
        contentType,
        time,
        duration,
        subContentId,
        disabled,
        selectedLecture,
        onSelect,
        schedule
    } = props;
    const classes = useStyles();

    const isPdf = contentType === Types.ContentType.Pdf;

    const contentLink = buildURLWithParams(MEDIATHEK_URLS.content, [contentId || '']);
    const contentLinkWithQuery = subContentId ? `${contentLink}/${subContentId}` : contentLink;
    const clickProps = onSelect
        ? {
              onClick: () => {
                  if (!disabled && selectedLecture?.id !== id) {
                      onSelect(schedule);
                  }
              }
          }
        : {
              href: contentLinkWithQuery
          };

    return (
        <Button
            {...clickProps}
            LinkComponent={Link}
            className={classnames(classes.container, {
                [classes.selectedItem]: id === selectedLecture?.id,
                [classes.disabledItem]: disabled
            })}
        >
            <Box className={classes.checkboxContainer}>
                {completed ? (
                    <Icon className={classes.checkedIcon} icon="success" />
                ) : (
                    <Icon className={classes.icon} icon="circle" />
                )}
            </Box>
            <Box className={classes.contentContainer}>
                <Typography title={title} className={classes.title} />
                {!isPdf && <ContentDuration time={time} duration={duration} />}
                {isPdf && <Badge kind="info" title="PDF" />}
                <Speakers
                    speakers={speakers}
                    variant={SpeakerVariant.BRIEF}
                    classes={{
                        speakerText: classes.speakerName
                    }}
                />
            </Box>
        </Button>
    );
};

export default ContentPlaylistSyrotItem;
