import { Types, buildURLWithParams, MEDIATHEK_URLS } from '../';
import { parseDBDate } from 'components';
import { ContentUnitItem } from '../../../catalog/src/components/ContentUnit/ContentUnit.types';

export const getProgressPercentage = (
    time: number | null | undefined,
    duration: number | null | undefined
): number => {
    time = time || 0;
    duration = duration || 0;
    const progressPercentage = duration && (time / duration) * 100;
    return Math.trunc(progressPercentage);
};

export const getSchedulesForContentUnit = (schedule: Array<ContentUnitItem>, id: Types.UUID) => {
    const contentSchedules = schedule
        .filter((item) => item?.id === id)
        .sort((scheduleA, scheduleB) => (scheduleA.order || 0) - (scheduleB.order || 0));
    return contentSchedules;
};

export const getScheduleIsStarted = (time: number | null | undefined) => (time ? time > 0 : false);

export const getCurrentWatchingContentId = (
    videos: Array<{
        contentProgress?:
            | { trackedDateTime?: Types.Scalars['DateTime'] | null | undefined; contentId: string }
            | null
            | undefined;
        id?: string | undefined;
        contentId: string | null | undefined;
    }>
): string | null => {
    const allNotWatched = videos.every((item) => !item.contentProgress?.trackedDateTime);
    if (allNotWatched) {
        return null;
    }
    const getLastWatchTime = (trackedDateTime: Types.Scalars['DateTime'] | null | undefined) => {
        const lastWatchedTime = trackedDateTime ? parseDBDate(trackedDateTime).unix() : 0;
        return lastWatchedTime;
    };

    const sortedVideos = [...videos]
        // sort in DESC order
        .sort(
            (a, b) =>
                getLastWatchTime(b.contentProgress?.trackedDateTime) -
                getLastWatchTime(a.contentProgress?.trackedDateTime)
        );

    const lastWatchedVideo = sortedVideos[0];
    return lastWatchedVideo?.contentId || null;
};

export const getAddonCatalogLink = (
    contentId: string,
    lectureContentId: string | null | undefined
) => {
    const catalogAddonLinkQuery = lectureContentId ? `/${lectureContentId}` : '';
    const catalogAddonLink =
        buildURLWithParams(MEDIATHEK_URLS.content, [contentId]) + catalogAddonLinkQuery;
    return catalogAddonLink;
};
