import { DEFAULT_COUNTRY_CODE, DOMAIN_TO_COUNTRY_CODE } from '../constants';
import getDomainFromLocation from './getDomainFromLocation';

const getCountryCodeFromLocation = (): CountryCode => {
    if (typeof window === 'undefined') {
        return DEFAULT_COUNTRY_CODE;
    }

    const domain = getDomainFromLocation();
    const countryCode = DOMAIN_TO_COUNTRY_CODE[domain] || DEFAULT_COUNTRY_CODE;
    return countryCode;
};

export default getCountryCodeFromLocation;
