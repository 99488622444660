import React from 'react';
import { DayItemProps } from './Month.types';
import { handleOnDayClick, isDateInRange, isDatePicked } from './Month.utils';
import Day from '../Day/Day';
import dayjs from 'dayjs';
import { isSinglePicked } from '../DateRangePicker.utils';

const DayItem = (props: DayItemProps) => {
    const {
        day,
        currentDate,
        minDate,
        maxDate,
        rowIndex,
        dayIndex,
        allDays,
        pickedDate,
        onPickedDateChange,
        monthStartDay,
        dayMonth,
        isNextMonth
    } = props;

    const pickedSingleDate =
        isSinglePicked(pickedDate) && dayjs.isDayjs(pickedDate.single)
            ? pickedDate.single
            : undefined;
    const pickedRangeStartDate =
        !isSinglePicked(pickedDate) && dayjs.isDayjs(pickedDate.start)
            ? pickedDate.start
            : undefined;
    const pickedRangeEndDate =
        !isSinglePicked(pickedDate) && dayjs.isDayjs(pickedDate.end) ? pickedDate.end : undefined;

    const isDayForPreviousMonth = day.month() !== dayMonth;

    const isDayPicked = isDatePicked({
        day,
        pickedRangeEndDate,
        pickedSingleDate,
        pickedRangeStartDate,
        isDayForPreviousMonth,
        isNextMonth
    });

    return (
        <Day
            day={day.date()}
            isCurrent={day.isSame(currentDate)}
            isDisabled={
                (!!minDate && day.isBefore(minDate)) ||
                (!!maxDate && day.isAfter(maxDate)) ||
                isDayForPreviousMonth
            }
            isPicked={isDayPicked}
            isInsideRange={isDateInRange({
                day,
                pickedRangeStartDate,
                pickedRangeEndDate,
                isDayForPreviousMonth,
                isNextMonth
            })}
            isInsideRangeRoundedLeft={
                (rowIndex === 0 && dayIndex === monthStartDay) || dayIndex === 0
            }
            isInsideRangeRoundedRight={
                rowIndex * 7 + dayIndex + 1 === allDays.length || dayIndex === 6
            }
            onClick={() =>
                handleOnDayClick({
                    day,
                    pickedDate,
                    onPickedDateChange
                })
            }
        />
    );
};

export default DayItem;
