import React from 'react';
import { Avatar } from '@mui/material';
import { Typography } from 'components';
import classNames from 'classnames';
import { SpeakerProps, SpeakerVariant, SpeakerAvatarSize } from './Speaker.types';
import useStyles from './Speaker.styles';

// TODO this is a more general component than SpeakerAvatar, and we should remove it in the future
const Speaker = (props: SpeakerProps) => {
    const { size, speaker, showAvatar, variant, renderText, hideDescription } = props;
    const classes = useStyles(props);

    if (!speaker) {
        return null;
    }
    const text = renderText ? renderText(speaker) : `${speaker.title} ${speaker.name}`;
    if (variant === SpeakerVariant.BRIEF) {
        return <Typography title={text} variant="caption" className={classes.speakerText} />;
    }

    return (
        <div className={classes.container} data-testid="speaker/full">
            {showAvatar && (
                <Avatar
                    src={speaker.avatarSrcUrl ?? ''}
                    className={classNames({
                        [classes.largeAvatar]: size === SpeakerAvatarSize.LARGE,
                        [classes.smallAvatar]: size === SpeakerAvatarSize.SMALL
                    })}
                />
            )}
            <div
                className={classNames(classes.details, {
                    [classes.detailsFull]: variant === SpeakerVariant.FULL,
                    [classes.detailsSmall]: size === SpeakerAvatarSize.SMALL
                })}
            >
                <Typography
                    variant={size === SpeakerAvatarSize.LARGE ? 'h3' : 'body2'}
                    title={text}
                    color="primary"
                    fontFamily="Poppins"
                    className={classes.title}
                />
                {speaker.description && variant === SpeakerVariant.FULL && !hideDescription && (
                    <Typography
                        variant={size === SpeakerAvatarSize.LARGE ? 'h3' : 'caption'}
                        title={speaker.description}
                        className={classes.description}
                    />
                )}
            </div>
        </div>
    );
};

export default Speaker;
