import React from 'react';
import { ContentDurationProps } from './ContentDuration.types';
import useStyles from './ContentDuration.styles';
import { Typography } from 'components';
import { Box, LinearProgress } from '@mui/material';
import { getProgressPercentage, getScheduleIsStarted } from '../utils';
import { formatters } from '../..';

const ContentDuration = (props: ContentDurationProps) => {
    const { duration, hideOnUnstarted, time } = props;
    const classes = useStyles(props);

    const watchStarted = getScheduleIsStarted(time);
    const progressPercentage = getProgressPercentage(time, duration);

    const durationTitle = formatters.formatContentDuration(duration);

    if (!watchStarted && hideOnUnstarted) {
        return null;
    }

    return (
        <Box className={classes.durationContainer}>
            <Typography variant="textXSmall" color="primary" title={durationTitle} />
            {watchStarted && (
                <LinearProgress
                    variant="determinate"
                    color="secondary"
                    className={classes.progress}
                    value={progressPercentage}
                />
            )}
        </Box>
    );
};

export default ContentDuration;
