import { useCallback } from 'react';
import { createURL, useRouter, Router, QueryParamsType } from '../';

export interface ChangeURL {
    (url: string, destinationPackage?: unknown, createAbsolute?: boolean): void;
}

/**
 * TODO: arsinclair: This should be deleted after package are merged.
 *
 * @deprecated Use `useRouter.push()` from `next/router` instead.
 */
const createChangeURL =
    (router: Router<QueryParamsType>): ChangeURL =>
    (url, destinationPackage, createAbsolute) => {
        const finalURL = createURL(url, destinationPackage, createAbsolute);
        router.push(finalURL.url);
    };

/**
 * TODO: arsinclair: This hook should be deleted after package are merged.
 *
 * @deprecated Use `useRouter.push()` from `next/router` instead.
 */
const useChangeURL = (): ChangeURL => {
    const router = useRouter();

    const changeURL: ChangeURL = useCallback(createChangeURL(router), [router]);

    return changeURL;
};

export default useChangeURL;
