import React, { useRef } from 'react';
import { Tabs, Tab } from '@mui/material';
import { Link } from 'react-scroll';
import Sticky from 'react-stickynode';

import useStyles from './AnchorHeader.styles';
import { Typography } from '../Typography';
import { AnchorHeaderTab, AnchorHeaderProps } from './AnchorHeader.types';
import classNames from 'classnames';

const defaultHeight = 56;

const AnchorHeader = (props: AnchorHeaderProps) => {
    const {
        top = 0,
        height = defaultHeight,
        hashSpy = false,
        tabs,
        innerZ = 1000,
        selectedTab,
        onSelectedTab,
        indicatorColor = null,
        tabClassName,
        ...others
    } = props;

    const classes = useStyles({ height, indicatorColor });
    const clickTimeoutRef = useRef<number | null>(null);

    const getLinkOffset = () => {
        // react-scroll doumentation: Scroll additional px ( like padding ) by clicking on each link,
        // it will scroll down to that item. But in this page there are two fixed elements on top of
        // the page, so the item will be positioned under them first is the main header of the application,
        // second is the header of this page which is also fixed so we need to set an offset equals to
        // the sum of this two heights
        return -1 * top - 1 * height;
    };

    const handleSetActive = (to: string) => {
        if (!clickTimeoutRef.current) {
            onSelectedTab?.(to);
        }
    };

    // We need to prevent the onSetActive from triggering too quickly in case of short sections
    const handleClick = (tabName: string) => {
        onSelectedTab?.(tabName);
        if (clickTimeoutRef.current) {
            window.clearTimeout(clickTimeoutRef.current);
        }
        clickTimeoutRef.current = window.setTimeout(() => {
            clickTimeoutRef.current = null;
        }, 1000);
    };

    const renderTab = (tab: AnchorHeaderTab) => {
        const { tabName, localeId, disabled } = tab;
        return (
            <Tab
                key={localeId}
                className={classNames(classes.tab, tabClassName)}
                disabled={disabled}
                label={
                    <Link
                        className={classes.link}
                        to={tabName}
                        hashSpy={hashSpy}
                        spy
                        smooth
                        duration={500}
                        onClick={() => handleClick(tabName)}
                        offset={getLinkOffset()}
                        onSetActive={handleSetActive}
                    >
                        <Typography variant="textXSmall" localeId={localeId} />
                    </Link>
                }
                value={tabName}
            />
        );
    };

    return (
        <Sticky top={top} innerZ={innerZ} {...others}>
            <Tabs
                value={selectedTab}
                className={classes.links}
                classes={{ indicator: classes.tabsIndicator }}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
            >
                {tabs.map(renderTab)}
            </Tabs>
        </Sticky>
    );
};

export default AnchorHeader;
