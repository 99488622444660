import React, { useEffect, useRef, useState } from 'react';
import { Wrapper as GoogleMapWrapper } from '@googlemaps/react-wrapper';
import env from '../../env';
import { MapProps } from './Map.types';

const Map = (props: MapProps) => {
    const { address, lat, lng, className } = props;
    const ref = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<google.maps.Map>();
    const [marker, setMarker] = useState<google.maps.Marker>();

    useEffect(() => {
        if (!map || marker) {
            return;
        }
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address }).then((data) => {
            const { results } = data;
            if (results[0]) {
                const location = {
                    lat: results[0].geometry.location.lat(),
                    lng: results[0].geometry.location.lng()
                };
                map.setCenter(location);
                map.setZoom(14);
                const mapMarker = new window.google.maps.Marker({ position: location });
                mapMarker.setMap(map);
                setMarker(mapMarker);
            }
        });
    }, [lat, lng, address, map]);

    useEffect(() => {
        if (ref.current && !map) {
            setMap(
                new window.google.maps.Map(ref.current, { center: { lat: 0, lng: 0 }, zoom: 14 })
            );
        }
    }, [map, ref]);

    return <div ref={ref} id="map" className={className} />;
};

const MapWrapper = (props: MapProps) => {
    const { address, lat, lng } = props;
    if (!env.GOOGLE_MAP_API_KEY) {
        throw new Error(
            'Google Map API key not detected, to be able to show the map properly, please provide a google map API key'
        );
    }

    if (!address && !(lat && lng)) {
        throw new Error('Failed to show the Map, either have an address or { lat, lng }');
    }
    return (
        <GoogleMapWrapper apiKey={env.GOOGLE_MAP_API_KEY}>
            <Map {...props} />
        </GoogleMapWrapper>
    );
};

export default MapWrapper;
