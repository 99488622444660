import makeStyles from '@mui/styles/makeStyles';

const iconSizeXs = 50;
const iconSizeLg = 65;

const useStyles = makeStyles(
    (theme) => ({
        contentContainer: {
            position: 'relative',
            width: 300,
            overflowY: 'auto',
            padding: theme.spacing(5, 2, 2),

            borderRadius: theme.border().borderRadius,

            background: theme.palette.background.default,
            boxShadow: '0px 5px 15px 4px rgba(0, 0, 0, 0.05)',

            textAlign: 'center',

            [theme.breakpoints.up('lg')]: {
                width: 570,
                padding: theme.spacing(7, 5, 5)
            }
        },
        container: {
            position: 'relative',
            display: 'flex',
            maxHeight: '80vh',
            overflowY: 'hidden',
            paddingTop: iconSizeXs / 2,
            zIndex: 1,

            [theme.breakpoints.up('lg')]: {
                paddingTop: iconSizeLg / 2
            }
        },
        iconContainer: {
            position: 'absolute',
            left: '50%',
            top: (-1 * iconSizeXs) / 2,
            transform: 'translate(-50%, 50%)',

            width: iconSizeXs,
            height: iconSizeXs,
            padding: 6,
            borderRadius: '50%',

            overflow: 'hidden',
            background: theme.palette.background.default,
            boxShadow: '0px 2px 17px 7px rgba(0, 0, 0, 0.05)',
            zIndex: 1,

            [theme.breakpoints.up('lg')]: {
                top: (-1 * iconSizeLg) / 2,
                width: iconSizeLg,
                height: iconSizeLg
            }
        },
        icon: {
            width: '100%',
            height: '100%'
        },
        info: {
            color: theme.palette.primary.main
        },
        error: {
            color: theme.palette.error.main
        },
        success: {
            color: theme.palette.success.main
        },
        title: {
            marginBottom: theme.spacing(3),
            [theme.breakpoints.up('lg')]: {
                marginBottom: theme.spacing(4)
            }
        },
        actionButton: {
            marginTop: theme.spacing(3)
        },
        backdrop: {
            zIndex: theme.zIndex.modal
        },
        closeIcon: {
            position: 'absolute',

            cursor: 'pointer',
            opacity: 0.5,
            transition: '.3s opacity',

            width: 24,
            height: 24,

            top: `calc(${theme.spacing(2)}px + ${iconSizeXs / 2}px)`,
            right: theme.spacing(2),

            '&:hover': {
                opacity: 1
            },

            zIndex: 1,

            [theme.breakpoints.up('lg')]: {
                top: `calc(${theme.spacing(2)}px + ${iconSizeLg / 2}px)`
            }
        }
    }),
    { name: 'Alert', index: 1 }
);

export default useStyles;
