import classNames from 'classnames';
import React from 'react';
import { Icon } from '..';
import useStyles from './StepIcon.styles';
import { StepIconProps } from './StepIcon.types';

const StepIcon = (props: StepIconProps) => {
    const { icon, 'data-testid': dataTestId } = props;
    const classes = useStyles();
    return (
        <div className={classNames(classes.root)} data-testid={dataTestId}>
            <Icon icon={icon} className={classes.icon} />
        </div>
    );
};

export default StepIcon;
