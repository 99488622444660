import React, { useCallback } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Image from 'next/image';
import { useIntl } from 'react-intl';
import { useExecMutation, useMUISnackbar, type Types } from 'common';
import { useBookmarkContentMutation } from '../../../../../graphql/catalog/mutations';
import { Alert, Button } from '@mui/material';
import type { SnackbarKey } from 'notistack';
import type { ThemeTypes } from 'components';

const useStyles = makeStyles<ThemeTypes.Theme, Pick<BookmarkButtonProps, 'position'>>((theme) => ({
    bookmarkButton: ({ position }) => ({
        boxSizing: 'border-box',
        padding: 4,

        ...(position === 'absolute'
            ? {
                  position: 'absolute',
                  top: 8,
                  left: 16
              }
            : {}),

        borderRadius: 6,
        backgroundColor: `${theme.palette.common.white}CC`,
        cursor: 'pointer',
        fontSize: 0,
        zIndex: 100,
        border: 'none',

        '&:hover': {
            backgroundColor: `${theme.palette.common.white}FF`,
            filter: 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25))'
        }
    }),
    snackbarActionButton: {
        color: '#88E3EF',
        border: '1px solid transparent',

        '&:hover': {
            backgroundColor: 'transparent',
            color: '#88E3EF',
            borderColor: '#88E3EF'
        }
    }
}));

type HandleButtonClickProps = {
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>;
    isBookmarked: boolean;
    contentId: Types.UUID;
};

export type BookmarkButtonProps = {
    id: Types.UUID;
    isBookmarked: boolean;
    /**
     * Position of the button. If `absolute`, the button will be positioned in the top left corner of the container.
     */
    position: 'absolute' | 'relative';
    removeContent?: (contentId: Types.UUID) => void;
    restoreContent?: (contentId: Types.UUID) => void;
    showUndoNotification?: boolean;
};

/* TODO(k.elchuzade): move this component to more common place, since it will be used later in all videos and documents */
export const BookmarkButton = ({
    id,
    isBookmarked,
    removeContent,
    restoreContent,
    position,
    showUndoNotification = false
}: BookmarkButtonProps) => {
    const classes = useStyles({ position });
    const { formatMessage } = useIntl();
    const { enqueueSnackbar, closeSnackbar } = useMUISnackbar();
    const execMutation = useExecMutation();

    const [bookmarkContent] = useBookmarkContentMutation();

    const bookmarkIcon = isBookmarked
        ? 'https://bk-public-prod.storage.googleapis.com/public/static/icon-bookmark-filled.svg'
        : 'https://bk-public-prod.storage.googleapis.com/public/static/icon-bookmark.svg';

    const bookmarkAriaLabel = isBookmarked
        ? formatMessage({ id: 'catalog.remove-from-bookmarks' })
        : formatMessage({ id: 'catalog.add-to-bookmarks' });

    const showSnackbar = (undoCallback: () => void) => {
        if (!showUndoNotification) {
            return;
        }

        return enqueueSnackbar({
            text: formatMessage({ id: 'my-fomf.item-unbookmarked' }),
            action: (
                <Button
                    className={classes.snackbarActionButton}
                    variant="text"
                    size="small"
                    onClick={undoCallback}
                >
                    {formatMessage({ id: 'catalog.undo-action' })}
                </Button>
            )
        });
    };

    const handleUndo = async (snackbarKey: SnackbarKey | undefined, videoId: Types.UUID) => {
        try {
            const response = await execMutation(bookmarkContent, {
                variables: {
                    bookmarked: true,
                    contentId: videoId
                }
            });
            if (response.data?.bookmarkContent === true) {
                restoreContent?.(videoId);
                closeSnackbar(snackbarKey);
            }
        } catch (error) {
            console.error('[Undo Failed]', error);
        }
    };

    const handleButtonClick = useCallback(
        async ({ event, isBookmarked, contentId }: HandleButtonClickProps) => {
            event.preventDefault();
            event.stopPropagation();

            try {
                const response = await execMutation(bookmarkContent, {
                    variables: {
                        bookmarked: !isBookmarked,
                        contentId: contentId
                    }
                });

                if (response.data?.bookmarkContent === true) {
                    if (isBookmarked) {
                        // Content was unbookmarked
                        removeContent?.(contentId);
                        const snackbarKey = showSnackbar(() => handleUndo(snackbarKey, contentId));
                    } else {
                        restoreContent?.(contentId);
                    }
                } else {
                    console.error('[Unbookmarking Item Failed]: graphql error');
                    enqueueSnackbar({
                        text: (
                            <Alert severity="warning">
                                {formatMessage({ id: 'my-fomf.failed-to-unbookmark-item' })}
                            </Alert>
                        )
                    });
                }
            } catch (error) {
                console.error('[Unbookmarking Item Failed]: unknown error: ', error);
                enqueueSnackbar({
                    text: (
                        <Alert severity="warning">
                            {formatMessage({ id: 'my-fomf.failed-to-unbookmark-video' })}
                        </Alert>
                    )
                });
            }
        },
        [enqueueSnackbar, execMutation, bookmarkContent, removeContent, handleUndo, showSnackbar]
    );

    return (
        <button
            className={classes.bookmarkButton}
            role="button"
            aria-label={bookmarkAriaLabel}
            tabIndex={0}
            onClick={(event) => handleButtonClick({ event, isBookmarked, contentId: id })}
        >
            <Image src={bookmarkIcon} alt="" unoptimized width={24} height={24} />
        </button>
    );
};
