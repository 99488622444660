import React from 'react';
import { motion } from 'motion/react';

import { Box, Container, IconButton } from '@mui/material';
import { useIntl } from 'react-intl';
import HeaderUserNavBar from '../../HeaderUserNavBar/HeaderUserNavBar';
import { useUserContext, useMembershipContext, useMarketingHomeContext } from '../../../contexts';
import HeaderLinks from '../HeaderLinks';
import { Logo, SearchBox, Icon, DrawerMenu } from 'components';
import type { HeaderProps } from '..';
import useStyles from './Header.desktop.styles';
import { getAboutUsLinks, getHeaderLinks, useHeaderSearch } from '../Header.utils';
import { booleanFilter, getCountryCode, isFeatureEnabled } from '../../../utils';
import LocaleSwitch from '../LocaleSwitch';
import useHeaderHeight from '../../../hooks/useHeaderHeight';
import { CATALOG_URLS, MEDIATHEK_URLS } from '../../../urls';
import NextLink from '../../NextLink';
import { theme, useFlags } from '../../..';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import { useTenantContext } from '../../../contexts/TenantContext';
import { TenantHeaderDesktop } from './TenantHeader.desktop';

const AnimatedHeaderContent = ({
    headerHeight,
    isDisplayingMarketingSection,
    children
}: {
    headerHeight: number;
    isDisplayingMarketingSection: boolean;
    children: React.ReactNode;
}) => (
    <Container maxWidth="xl" disableGutters>
        <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{
                opacity: isDisplayingMarketingSection ? 0 : 1,
                height: isDisplayingMarketingSection ? 0 : headerHeight,
                overflow: isDisplayingMarketingSection ? 'hidden' : 'visible',
                visibility: isDisplayingMarketingSection ? 'hidden' : 'visible'
            }}
            transition={{
                type: 'tween',
                duration: 0.15
            }}
        >
            {children}
        </motion.div>
    </Container>
);

export const ContactIcons = (props: { className?: string }) => {
    const classes = useStyles({});
    return (
        <Box className={classnames(props.className, classes.iconsContainer)}>
            <IconButton
                component={NextLink}
                className={classes.iconButton}
                href={CATALOG_URLS.contact}
                size="large"
            >
                <Icon icon="phone" className={classes.icon} />
            </IconButton>
            <IconButton
                component={NextLink}
                className={classes.iconButton}
                href={CATALOG_URLS.faq}
                size="large"
            >
                <Icon icon="help" className={classes.icon} />
            </IconButton>
        </Box>
    );
};

export const HeaderDesktop = (props: HeaderProps) => {
    const { drawerItems, drawerProps } = props;
    const { query, pathname } = useRouter();
    const { isLoading, isLoggedIn, user } = useUserContext();
    const { userMembership } = useMembershipContext();
    const headerHeight = useHeaderHeight();
    const { isDisplayingMarketingSection, isMarketingHomeEnabled } = useMarketingHomeContext();
    const flags = useFlags();
    const { formatMessage, locale } = useIntl();
    const countryCode = getCountryCode(locale);
    const { tenantConfig } = useTenantContext();

    // We need to keep 1440px as default value for maxWidth, because it's the default value in the theme,
    // and home pages. The partner forum pages are an exception for now.
    const classes = useStyles({
        maxWidth: tenantConfig?.id != null ? theme.breakpoints.values.xl : 1440
    });
    const { onClear, onSearch, onSearchChange, search } = useHeaderSearch();

    const isMediathekEnabled = isFeatureEnabled('mediathek', getCountryCode(locale));

    const getLinksArgs = {
        countryCode,
        locale,
        flags,
        query,
        pathname,
        isLoggedIn
    };

    const headerLinks = getHeaderLinks(getLinksArgs);

    const userNavBarProps = {
        isLoggedIn,
        isLoading,
        user,
        userMembership,
        classes: {
            primaryButton: classes.primaryButton,
            link: classes.link,
            avatar: classes.avatar
        }
    };

    const headerContent = (
        <>
            {tenantConfig?.id != null ? (
                <TenantHeaderDesktop
                    tenantConfig={tenantConfig}
                    userNavBarProps={userNavBarProps}
                />
            ) : (
                <>
                    <Box className={classes.topContainer}>
                        <Box className={classes.logoWrapperContainer}>
                            <Box className={classes.logo}>
                                <NextLink className={classes.link} href="/">
                                    <Logo locale={locale} />
                                </NextLink>
                            </Box>
                            {isMediathekEnabled ? (
                                <SearchBox
                                    placeholder={formatMessage({
                                        id: 'common.header.search-placeholder'
                                    })}
                                    onChange={onSearchChange}
                                    value={search || ''}
                                    onEnter={onSearch}
                                    onClear={onClear}
                                    classes={{
                                        root: classes.searchBoxRoot,
                                        inputBaseRoot: classes.searchBoxInputBaseRoot
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <NextLink
                                                href={MEDIATHEK_URLS.topics}
                                                className={classes.topicsLink}
                                            >
                                                <Icon
                                                    icon="listBullets"
                                                    className={classes.topicsIcon}
                                                />
                                                {formatMessage({
                                                    id: 'common.header.topics'
                                                })}
                                            </NextLink>
                                        )
                                    }}
                                />
                            ) : (
                                <Box className={classes.navbarContainer}>
                                    <HeaderLinks menuItems={headerLinks} />
                                    <LocaleSwitch />
                                </Box>
                            )}
                        </Box>
                        {isMediathekEnabled ? (
                            <Box
                                className={classnames(
                                    classes.rightLinksContainer,
                                    classes.rightLinksHiddenLgUp
                                )}
                            >
                                <HeaderLinks
                                    menuItems={getAboutUsLinks({
                                        ...getLinksArgs,
                                        addSeparator: true
                                    })}
                                />
                                <ContactIcons />
                            </Box>
                        ) : (
                            <Box className={classes.rightLinksContainer}>
                                <HeaderLinks
                                    menuItems={getAboutUsLinks({
                                        ...getLinksArgs,
                                        addSeparator: true
                                    })}
                                />
                                <Box className={classes.rightLinksHiddenLgDown}>
                                    <ContactIcons />
                                </Box>
                            </Box>
                        )}
                        <Box className={classes.userInfoContainer}>
                            <HeaderUserNavBar
                                {...userNavBarProps}
                                loginActionLocaleId="common.login.title"
                            />
                        </Box>
                    </Box>
                    {isMediathekEnabled && (
                        <Box className={classes.navbarContainer}>
                            <HeaderLinks menuItems={headerLinks} />
                            <Box display="flex" alignItems="center">
                                <LocaleSwitch className={classes.localeSwitch} />
                                <Box
                                    className={classnames(
                                        classes.rightLinksContainer,
                                        classes.rightLinksHiddenLgDown
                                    )}
                                >
                                    <HeaderLinks
                                        menuItems={getAboutUsLinks({
                                            ...getLinksArgs,
                                            addSeparator: true
                                        })}
                                    />
                                    <ContactIcons />
                                </Box>
                            </Box>
                        </Box>
                    )}
                </>
            )}
        </>
    );

    return (
        <DrawerMenu
            items={drawerItems ?? []}
            classes={{
                ...drawerProps?.classes,
                root: [classes.root, drawerProps?.classes?.root].filter(booleanFilter).join(' ')
            }}
            appBarClass={classes.heightAppBar}
            header={
                isMarketingHomeEnabled ? (
                    <AnimatedHeaderContent
                        headerHeight={headerHeight}
                        isDisplayingMarketingSection={isDisplayingMarketingSection}
                    >
                        {headerContent}
                    </AnimatedHeaderContent>
                ) : (
                    <Container maxWidth="xl" className={classes.container}>
                        {headerContent}
                    </Container>
                )
            }
            {...drawerProps}
        />
    );
};
