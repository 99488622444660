import { Types } from '..';

const getAddonsQueryVariables = () => {
    return {
        searchQuery: {
            filters: [
                {
                    field: 'contentType',
                    operation: Types.SearchFilterOperation.Equal,
                    value: [Types.ContentType.AddonCourse],
                    dataType: Types.SearchFilterDataType.Enum
                },
                {
                    field: 'brandType',
                    operation: Types.SearchFilterOperation.Equal,
                    value: [Types.ProductBrand.Syrot],
                    dataType: Types.SearchFilterDataType.Enum
                }
            ]
        }
    };
};

export default getAddonsQueryVariables;
