import * as LaunchDarkly from '@launchdarkly/node-server-sdk';
import { LDContext } from '@launchdarkly/node-server-sdk';

import env from '../env';

let launchDarklyClient: LaunchDarkly.LDClient | undefined;

async function initialize() {
    if (env.LAUNCHDARKLY_SERVER_SIDE_SDK_KEY) {
        const client = LaunchDarkly.init(env.LAUNCHDARKLY_SERVER_SIDE_SDK_KEY);
        await client.waitForInitialization();
        return client;
    }
    console.warn('LAUNCHDARKLY_SERVER_SIDE_SDK_KEY not set, skipping LaunchDarkly initialization');
    return;
}

/**
 * Returns the LaunchDarkly server-side client singleton.
 */
export async function getLaunchDarklyServerClient() {
    if (launchDarklyClient) {
        return launchDarklyClient;
    }
    return (launchDarklyClient = await initialize());
}

export type { LDContext };
