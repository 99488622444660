import React from 'react';
import Head from 'next/head';

export type OGDict = {
    title?: string;
    description?: string;
    image?: string;
    url?: string;
};

type OGByPathname = Record<string, OGDict>;

type Props = {
    pathname: string;
    ogDictionary: OGByPathname;
};

/**
 * This component is useful to place the open graph tags for these pages that are static.
 * "The Open Graph protocol" docs: https://ogp.me/
 */
export const OG = (props: Props) => {
    const { pathname, ogDictionary } = props;
    if (!ogDictionary[pathname]) {
        return null;
    }

    const title = ogDictionary[pathname]?.title;
    const description = ogDictionary[pathname]?.description;
    const image = ogDictionary[pathname]?.image;
    const url = ogDictionary[pathname]?.url;

    return (
        <Head>
            {title && <meta name="og:title" content={title} />}
            {description && <meta name="og:description" content={description} />}
            {image && <meta name="og:image" content={image} />}
            {url && <meta name="og:url" content={url} />}
            <meta property="og:type" content="website" />
        </Head>
    );
};
