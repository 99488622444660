import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import {
    Fragments,
    getDomainFromLocation,
    MembershipOfferByCountry,
    Types,
    useMembershipContext
} from '..';

export type TrialAllowedResult = {
    isMembershipTrialAllowed: boolean;
    membershipTrialCountdown: number;
    // Indicates whether we should promote this trial in user dashboard
    showInDashboard: boolean;
};

const membershipOfferByCountry: MembershipOfferByCountry = {
    AUT: [new Date('2023-06-30T00:00:00.000Z'), new Date('2023-07-31T23:59:59.999Z')],
    CHE: [new Date('2023-06-24T22:00:00.000Z'), new Date('2023-07-03T23:59:59.999Z')],
    DEU: undefined,
    GBR: undefined
};
const ALLOWED_DOMAINS: Array<Domain> = ['de', 'at', 'ch'];

const useMembershipTrialAllowed = (
    user: Fragments.UserFieldsFragment | null | undefined
): TrialAllowedResult => {
    const { isMember, loading } = useMembershipContext();
    const currentDomain = getDomainFromLocation();

    const showInDashboard = false;

    const [result, setResult] = useState<TrialAllowedResult>({
        // allowing trial for anonymous so they can see the offer
        isMembershipTrialAllowed: !user,
        // -1 by default because 0 means it's the last day of the offer
        membershipTrialCountdown: -1,
        showInDashboard
    });

    useEffect(() => {
        const now = dayjs();

        if (loading) {
            return;
        }

        const FREE_MEMBERSHIP_OFFER_MINIMUM_DATE: Date | undefined = (() => {
            switch (currentDomain) {
                case 'ch':
                    return membershipOfferByCountry['CHE']?.[0];
                case 'at':
                    return membershipOfferByCountry['AUT']?.[0];
                default:
                    return undefined;
            }
        })();

        const FREE_MEMBERSHIP_OFFER_MAXIMUM_DATE: Date | undefined = (() => {
            switch (currentDomain) {
                case 'ch':
                    return membershipOfferByCountry['CHE']?.[1];
                case 'at':
                    return membershipOfferByCountry['AUT']?.[1];
                default:
                    return undefined;
            }
        })();

        // conditions which prevents trial from showing
        if (
            isMember ||
            (user &&
                user.occupationType !== Types.OccupationType.AssistantDoctor &&
                user.occupationType !== Types.OccupationType.Doctor) ||
            FREE_MEMBERSHIP_OFFER_MINIMUM_DATE === undefined ||
            FREE_MEMBERSHIP_OFFER_MAXIMUM_DATE === undefined ||
            now.isBefore(FREE_MEMBERSHIP_OFFER_MINIMUM_DATE) ||
            now.isAfter(FREE_MEMBERSHIP_OFFER_MAXIMUM_DATE) ||
            (ALLOWED_DOMAINS.length > 0 && !ALLOWED_DOMAINS.includes(currentDomain))
        ) {
            setResult({
                isMembershipTrialAllowed: false,
                membershipTrialCountdown: -1,
                showInDashboard
            });
            return;
        }

        const maxDate = dayjs(FREE_MEMBERSHIP_OFFER_MAXIMUM_DATE);
        const diff = maxDate.diff(now.startOf('day'), 'days');

        setResult({
            isMembershipTrialAllowed: true,
            membershipTrialCountdown: diff,
            showInDashboard
        });
    }, [user, isMember, loading]);

    return result;
};

export default useMembershipTrialAllowed;
