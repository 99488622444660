import { Types } from '../';
import isUUID from './isUUID';

const ensureUUID = (input: string | null | undefined): Types.UUID => {
    if (!isUUID(input)) {
        throw new Error(`String "${input}" is not an UUID`);
    }
    return input;
};

export default ensureUUID;
