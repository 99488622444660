import { useEffect } from 'react';

/**
 * Use this function just on the root "_app" of a NextJS application to remove the server-side CSS
 * in the client side.
 */
const useRemoveServerStyles = () => {
    // Once JS is loaded on the client, components initialized and the JSS styles are regenerated,
    // we have to remove server-side injected styles to avoid side-effects.
    // https://www.figma.com/file/1p1liCmJjJJv0dTc55eHvl/T%C3%BCrkis-Design-System?node-id=2199%3A7868
    return useEffect(() => {
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles?.parentElement) {
            jssStyles.parentElement.removeChild(jssStyles);
        }
    }, []);
};

export default useRemoveServerStyles;
