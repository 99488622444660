import getCountryCodeFromLocation from './getCountryCodeFromLocation';
import { Types } from '../';
import alpha3CountryCodeToAlpha2CountryCode from 'components/src/utils/alpha3CountryCodeToAlpha2CountryCode';

const getCurrencyBasedOnCountryCode = (countryCode?: CountryCode | CountryCode3) => {
    const countryCode2 = countryCode
        ? countryCode.length === 2
            ? countryCode
            : alpha3CountryCodeToAlpha2CountryCode(countryCode)
        : getCountryCodeFromLocation();
    switch (countryCode2) {
        case 'ch':
            return Types.Currency.Chf;
        case 'gb':
            return Types.Currency.Gbp;
        case 'de':
        case 'at':
        default:
            return Types.Currency.Eur;
    }
};

export default getCurrencyBasedOnCountryCode;
