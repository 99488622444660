import React from 'react';
import * as yup from 'yup';
import { FunctionSchema } from '../formik';
import { TextField } from '../TextField';

import { IntlTelInput } from './IntlTelInput';
import useStyles from './PhoneInput.styles';
import { PhoneInputProps } from './PhoneInput.types';

const createYupPhoneValidationSchema = (isRequired?: boolean) =>
    yup
        .object({
            value: isRequired ? yup.string().nullable().required() : yup.string().nullable(),
            validate: yup.mixed(),
            setCountry: yup.mixed() as FunctionSchema<(country: string) => void>
        })
        .test({
            name: 'isPhoneValid',
            test: (value, context) => {
                const error = value.validate && value.validate();
                if (error) {
                    return context.createError({
                        message: error
                    });
                }
                return true;
            }
        });

const PhoneInput = (props: PhoneInputProps) => {
    const { onChange, value, error, name, helperText, onClear, ...rest } = props;
    const classes = useStyles(props);

    return (
        <TextField
            {...rest}
            InputProps={{
                // Types are impossible to map correctly because we pass `customOnChange` which will be used instead of default HTML `onChange` event
                // eslint-disable-next-line  @typescript-eslint/ban-ts-comment
                // @ts-ignore
                inputComponent: IntlTelInput,
                inputProps: {
                    name
                }
            }}
            InputLabelProps={{
                shrink: true
            }}
            customOnChange={onChange}
            value={value}
            error={error}
            onClear={onClear}
            helperText={helperText}
            classes={{
                root: classes.root
            }}
        />
    );
};

export { createYupPhoneValidationSchema };
export default PhoneInput;
