import React, { useState } from 'react';
import classNames from 'classnames';
import { Box, Container, Divider, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import { useUserContext } from '../../../contexts';
import { DrawerMenu, Avatar, Icon, Logo, SearchBox, Typography, MenuItem } from 'components';
import type { HeaderProps } from '..';
import useStyles from './Header.mobile.styles';
import {
    getHeaderLinks,
    useHeaderSearch,
    userLogoutSubMenuLinks,
    userSubMenuLinks
} from '../Header.utils';
import { ToolbarHeader } from './ToolbarHeader';
import { getCountryCode } from '../../../utils';
import LocaleSwitch from '../LocaleSwitch';
import { UserCompanyLogo } from '../../UserCompanyLogo';
import { AddonsPopover } from '../../HeaderUserNavBar/AddonsPopover';
import NextLink from '../../NextLink';
import { CATALOG_URLS, useFlags } from '../../..';
import { useRouter } from 'next/router';
import { useTenantContext } from '../../../contexts/TenantContext';
import { TenantHeaderMobile } from './TenantHeader.mobile';

const HeaderMobileInner = () => {
    const classes = useStyles();
    const { formatMessage, locale } = useIntl();
    const { onClear, onSearch, onSearchChange, search } = useHeaderSearch();
    const [isSearching, setIsSearching] = useState(!!search);
    const theme = useTheme();
    const isMediaXs = useMediaQuery(theme.breakpoints.down('sm'));
    const { tenantConfig } = useTenantContext();
    const handleOpenSearch = () => {
        setIsSearching(true);
    };
    const handleCloseSearch = () => setIsSearching(false);
    const handleBlurSearch = () => {
        if (!search?.length) {
            handleCloseSearch();
        }
    };
    const handleSearchClick = () => {
        if (!isSearching) {
            handleOpenSearch();
        }
    };

    return (
        <Container
            classes={{
                root: tenantConfig?.id != null ? classes.withTenantContainer : classes.container
            }}
            maxWidth="xl"
        >
            {tenantConfig?.id != null ? (
                <TenantHeaderMobile />
            ) : (
                <>
                    <Box
                        className={classNames(classes.logo, {
                            [classes.hidden]: isMediaXs && isSearching
                        })}
                    >
                        <NextLink href="/">
                            <Logo locale={locale} />
                        </NextLink>
                    </Box>
                    <Box
                        className={classNames(classes.iconsContainer, {
                            [classes.hidden]: isSearching
                        })}
                    >
                        <AddonsPopover
                            classes={{ iconContainer: classes.addonIcon }}
                            isDesktop={false}
                        />
                    </Box>

                    <SearchBox
                        placeholder={formatMessage({
                            id: 'common.header.search-placeholder'
                        })}
                        onChange={onSearchChange}
                        value={search || ''}
                        onBlur={handleBlurSearch}
                        onEnter={onSearch}
                        onClear={onClear}
                        onClick={handleSearchClick}
                        classes={{
                            root: classNames(classes.searchBox, {
                                [classes.isActive]: isSearching
                            }),
                            input: classes.searchBoxInnerElement,
                            notchedOutline: classes.searchBoxInnerElement
                        }}
                    />
                </>
            )}
        </Container>
    );
};

const UserMenuToolbarContent = ({ onClick }: { onClick: () => void }) => {
    const classes = useStyles();
    return (
        <div onClick={onClick} className={classes.subMenuHeaderContainer}>
            <Icon icon="caretLeft" className={classes.backIcon} />
            <Typography color="secondary" localeId="common.header.main-menu" />
        </div>
    );
};

const UserMenuItemsHeader = () => {
    const classes = useStyles();
    const { user } = useUserContext();

    if (!user) {
        return null;
    }

    const { company } = user || {};
    const { name: companyName, logoUrl: companyLogo } = company || {};

    return (
        <div className={classes.userDetails}>
            <Divider />
            <Avatar
                className={classes.avatar}
                email={user.email}
                firstName={user.firstName}
                lastName={user.lastName}
            />
            <Typography className={classes.userFullName}>
                {user.firstName} {user.lastName}
            </Typography>
            <Divider className={classes.userDivider} />
            {company && (
                <>
                    <NextLink className={classes.companyLogoContainer} href="/">
                        <UserCompanyLogo
                            companyLogo={companyLogo}
                            className={classes.companyLogo}
                        />
                        <Typography title={companyName} />
                    </NextLink>
                    <Divider className={classes.userDivider} />
                </>
            )}
        </div>
    );
};

const FooterSection = () => {
    const classes = useStyles();

    return (
        <Box className={classes.footerSection}>
            <Box className={classes.footerIconsContainer}>
                <IconButton
                    component={NextLink}
                    className={classes.footerIconButton}
                    href={CATALOG_URLS.contact}
                    size="large"
                >
                    <Icon icon="phone" className={classes.footerIcon} />
                </IconButton>
                <IconButton
                    component={NextLink}
                    className={classes.footerIconButton}
                    href={CATALOG_URLS.faq}
                    size="large"
                >
                    <Icon icon="help" className={classes.footerIcon} />
                </IconButton>
            </Box>
            <LocaleSwitch />
        </Box>
    );
};

export const HeaderMobile = (props: HeaderProps) => {
    const { drawerItems, drawerProps } = props;
    const { query, pathname } = useRouter();
    const { locale } = useIntl();
    const flags = useFlags();
    const countryCode = getCountryCode(locale);
    const classes = useStyles();
    const { isLoggedIn } = useUserContext();
    const { tenantConfig } = useTenantContext();
    const [isUserChildMenuVisible, setIsUserChildMenuVisible] = useState(false);

    const [secondLevelMenuItems, setSecondLevelMenuItems] = useState<Array<Array<MenuItem>> | null>(
        null
    );

    const handleOpenUserMenu = () => {
        if (tenantConfig?.id != null) {
            setSecondLevelMenuItems([userLogoutSubMenuLinks(locale)]);
        } else {
            setSecondLevelMenuItems(userSubMenuLinks(locale));
        }
        setIsUserChildMenuVisible(true);
    };

    const handleCloseUserMenu = () => {
        setSecondLevelMenuItems(null);
        setIsUserChildMenuVisible(false);
    };

    const handleOpenSecondLevelMenu = (items: Array<Array<MenuItem>>) => {
        const backArrowMenuItem: MenuItem = {
            onClick: handleCloseSecondLevelMenu,
            iconName: 'arrowLeftType2',
            iconColor: 'primary',
            className: classes.secondLevelMenuBackIcon
        };

        setSecondLevelMenuItems([[backArrowMenuItem, ...(items[0] || [])], ...items.slice(1)]);
    };

    const handleCloseSecondLevelMenu = () => {
        setSecondLevelMenuItems(null);
    };

    const menuItems = secondLevelMenuItems || [
        ...getHeaderLinks({
            countryCode,
            locale,
            flags,
            query,
            pathname,
            isLoggedIn,
            showAboutUsLinks: true
        }),
        ...(drawerItems ?? [])
    ];

    return (
        <DrawerMenu
            classes={classes}
            closeOnClick
            items={
                tenantConfig?.id != null
                    ? isUserChildMenuVisible
                        ? secondLevelMenuItems || []
                        : []
                    : menuItems
            }
            forceShowItems={tenantConfig?.id != null}
            hideDrawerIconOnOpen
            header={<HeaderMobileInner />}
            toolbarContent={
                isUserChildMenuVisible ? (
                    <UserMenuToolbarContent onClick={handleCloseUserMenu} />
                ) : (
                    <ToolbarHeader onHeaderClick={handleOpenUserMenu} />
                )
            }
            menuItemsHeader={isUserChildMenuVisible ? <UserMenuItemsHeader /> : null}
            menuItemsFooter={
                isUserChildMenuVisible || secondLevelMenuItems ? null : <FooterSection />
            }
            onOpenSecondLevelMenu={handleOpenSecondLevelMenu}
            onCloseSecondLevelMenu={handleCloseSecondLevelMenu}
            {...drawerProps}
        />
    );
};
