import palette from './palette';
import typography from './typography';
import spacing from './spacing';

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        secondary: true;
    }
}

const overrides = {
    MuiDialogContent: {
        styleOverrides: {
            root: {
                padding: spacing(2, 3)
            }
        }
    },
    MuiCssBaseline: {
        styleOverrides: {
            body: {
                backgroundColor: palette.common?.white,
                fontFamily: 'Roboto',
                '*::selection': {
                    backgroundColor: palette.secondary?.main,
                    color: palette.common?.white
                }
            },
            svg: {
                display: 'block',
                width: '100%'
            }
        }
    },
    MuiPickersToolbarText: {
        styleOverrides: {
            toolbarTxt: {
                color: palette.primary?.light,
                ...typography.subtitle1,
                'h3&': {
                    fontSize: '3rem'
                },
                'h6&': {
                    fontSize: '1rem'
                }
            },
            toolbarBtnSelected: {
                color: palette.offWhite.main
            }
        }
    },
    MuiPickersDay: {
        styleOverrides: {
            daySelected: {
                color: palette.offWhite.main
            }
        }
    },
    MuiPickersYearSelection: {
        styleOverrides: {
            container: {
                color: palette.primary?.light
            }
        }
    },
    MuiPickersClockNumber: {
        styleOverrides: {
            clockNumberSelected: {
                color: palette.offWhite.main
            }
        }
    },
    MuiPickersClockPointer: {
        styleOverrides: {
            thumb: {
                backgroundColor: palette.offWhite.main
            }
        }
    },
    MuiTab: {
        styleOverrides: {
            root: {
                minHeight: 56,
                marginRight: 16, // TODO: try to use theme.spacing
                textTransform: 'none' as const,
                '&:last-child': {
                    marginRight: 0
                }
            },
            textColorInherit: {
                color: palette.label.main,
                opacity: 1,

                '&.Mui-selected': {
                    color: palette.titleActive.main
                }
            }
        }
    },
    MuiTabPanel: {
        styleOverrides: {
            root: {
                paddingVertical: 64 // TODO: try to use theme.spacing
            }
        }
    },
    MuiTabs: {
        styleOverrides: {
            indicator: {
                backgroundColor: palette.primary?.light
            },
            root: {
                height: 56
            }
        }
    },
    MuiChip: {
        styleOverrides: {
            root: {
                backgroundColor: palette.line.main
            }
        }
    },
    MuiMenuItem: {
        styleOverrides: {
            root: {
                minHeight: 'auto',
                lineHeight: 'initial',
                paddingTop: 2,
                paddingBottom: 3,
                paddingLeft: 0
            }
        }
    },
    MuiMenu: {
        styleOverrides: {
            list: {
                '& .MuiMenuItem-root': {
                    padding: '6px 16px 6px'
                }
            }
        }
    },
    MuiSelect: {
        styleOverrides: {
            select: {
                '&:focus': {
                    backgroundColor: 'none'
                }
            },
            iconOutlined: {
                right: 42
            }
        }
    },
    MuiFormHelperText: {
        styleOverrides: {
            root: {
                fontWeight: 500,
                fontSize: 14,
                lineHeight: 22 / 14,
                letterSpacing: 0.25,
                color: palette.label.main
            },

            contained: {
                // TODO: try to use theme.spacing
                marginTop: 8,
                marginLeft: 0,
                marginRight: 0
            }
        }
    },
    MuiButton: {
        variants: [
            {
                props: { variant: 'secondary' as const },
                style: {
                    color: 'white',
                    backgroundColor: palette.secondary?.main,
                    '&:hover': {
                        backgroundColor: palette.secondary?.dark
                    }
                }
            }
        ],
        styleOverrides: {
            root: {
                textTransform: 'none' as const
            },
            outlined: {
                border: `1px solid #E7EBEF`,
                '&:hover': {
                    border: `1px solid #E7EBEF`,
                    backgroundColor: '#E7EBEF'
                }
            },
            text: {
                '&:hover': {
                    backgroundColor: '#E7EBEF'
                }
            }
        }
    }
};

export default overrides;
