import { Box } from '@mui/material';
import React from 'react';
import classNames from 'classnames';
import { AvatarProps } from './Avatar.types';
import useStyles from './Avatar.styles';

const buildAvatarName = (email: string, firstName?: string | null, lastName?: string | null) => {
    if (firstName && lastName) {
        return `${firstName[0]}${lastName[0]}`;
    } else if (firstName) {
        return `${firstName[0]}${firstName[1]}`;
    }
    return `${email[0]}${email[1]}`;
};

const Avatar = (props: AvatarProps) => {
    const { className, email, firstName, lastName } = props;
    const classes = useStyles();
    return (
        <Box className={classNames(classes.container, className)}>
            {buildAvatarName(email, firstName, lastName)}
        </Box>
    );
};

export default Avatar;
