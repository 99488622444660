import { DEFAULT_DOMAIN, LOCALE_TO_DOMAIN } from '../constants';

/**
 * This function uses window location to access the domain. Ensure you are calling
 * it from the client and that it will never run on the server.
 */
const getDomainFromLocation = (): Domain => {
    if (typeof window === 'undefined') {
        return DEFAULT_DOMAIN;
    }

    if (window.location.port && process.env.NODE_ENV === 'development') {
        return LOCALE_TO_DOMAIN[window.location.pathname.split('/')[1] as Locale] ?? DEFAULT_DOMAIN;
    } else {
        const origin = window.location.hostname;
        const hostParts = origin.split('.');
        const domain = (
            hostParts.length > 1 ? hostParts[hostParts.length - 1] : DEFAULT_DOMAIN
        ) as Domain;
        return domain;
    }
};

export default getDomainFromLocation;
