const getMedicalIdLink = (countryCode: string | undefined) => {
    switch (countryCode) {
        case 'AUT':
            return 'https://www.aerztekammer.at/abfrage-arztnummer';
        case 'CHE':
            return 'https://www.medregom.admin.ch/';
        default:
            return undefined;
    }
};

export default getMedicalIdLink;
