import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
    (theme) => ({
        container: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: theme.spacing(2),
            borderWidth: 2,
            borderRadius: 2,
            borderColor: theme.palette.label.main,
            borderStyle: 'dashed',
            outline: 'none',
            transition: 'border .24s ease-in-out',
            cursor: 'pointer',

            '&:hover, &$active': {
                borderColor: theme.palette.primary.main
            },

            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(7, 4, 6)
            }
        },
        icon: {
            width: 40,
            height: 40,
            marginBottom: theme.spacing(2),
            color: theme.palette.primary.main
        },
        button: {
            marginBottom: theme.spacing(2)
        },
        active: {},
        accept: {
            borderColor: theme.palette.success.main
        },
        reject: {
            borderColor: theme.palette.error.main
        },
        fileName: {
            textAlign: 'center',
            marginTop: theme.spacing(4)
        },
        disabled: {
            opacity: 0.5,
            pointerEvents: 'none'
        }
    }),
    { name: 'UploadButton', index: 1 }
);

export default useStyles;
