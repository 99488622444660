import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
    (theme) => ({
        container: {
            margin: theme.spacing(1, 0),
            cursor: 'pointer'
        },
        accordionDetailsContainer: {
            padding: theme.spacing(2, 0.5, 0)
        },
        accordionSummaryContainer: {
            ...theme.border({ width: 0, radius: 6 }),
            fontWeight: 400,
            backgroundColor: 'transparent',
            margin: theme.spacing(0, -1),
            minHeight: 0,
            '&$expanded': {
                backgroundColor: theme.palette.secondary[200],
                minHeight: 0
            }
        },
        accordionSummaryContent: {
            margin: 0,
            '&$expanded': {
                margin: 0
            }
        },
        accordionSummaryExpanded: {},
        accordionItem: {
            ...theme.border({ radius: 6, color: theme.palette.body.light }),
            backgroundColor: theme.palette.secondary[200],
            padding: theme.spacing(1),
            boxShadow: 'none',
            '&$expanded': {
                margin: 0
            }
        }
    }),
    { name: 'Accordion' }
);

export default useStyles;
