import { CalendarOrClockPickerView } from '@mui/x-date-pickers/internals/models';
import React from 'react';
import {
    DateTimePicker as MUIDateTimePicker,
    DateTimePickerProps as MUIDateTimePickerProps,
    TimePicker as MUITimePicker,
    TimePickerProps as MUITimePickerProps,
    DatePicker as MUIDatePicker,
    DatePickerProps as MUIDatePickerProps,
    ClockPickerView,
    CalendarPickerView
} from '@mui/x-date-pickers';

import { DateTimePickerProps, InputVariant } from './DateTimePicker.types';
import { DateFormat } from '../utils/dates';
import { Dayjs } from 'dayjs';

export const getInput = (
    InputVariant: InputVariant,
    format: DateFormat | undefined,
    views: DateTimePickerProps['views'],
    openTo: DateTimePickerProps['openTo']
) => {
    switch (InputVariant) {
        case 'Time': {
            const localProps = {
                views: getViews<ClockPickerView>(views, ['hours', 'minutes', 'seconds']),
                openTo: getOpenTo<ClockPickerView>(openTo, ['hours', 'minutes', 'seconds']),
                format: format || DateFormat.time
            };
            return (props: Omit<MUITimePickerProps<Dayjs, Dayjs>, 'views' | 'openTo'>) => (
                <MUITimePicker {...props} {...localProps} />
            );
        }
        case 'Date': {
            const localProps = {
                views: getViews<CalendarPickerView>(views, ['year', 'day', 'month']),
                openTo: getOpenTo<CalendarPickerView>(openTo, ['year', 'day', 'month']),
                format: format || DateFormat.date
            };
            return (props: MUIDatePickerProps<Dayjs, Dayjs>) => (
                <MUIDatePicker {...props} {...localProps} />
            );
        }
        case 'DateTime': {
            const localProps = {
                views: getViews(views, ['year', 'day', 'month', 'hours', 'minutes']),
                openTo: getOpenTo(openTo, ['year', 'day', 'month', 'hours', 'minutes']),
                format: format || DateFormat.dateTime
            };
            return (props: MUIDateTimePickerProps<Dayjs, Dayjs>) => (
                <MUIDateTimePicker {...props} {...localProps} />
            );
        }
        default:
            throw new Error(`Unknown InputVariant ${InputVariant} was passed`);
    }
};

const getViews = <View extends string = CalendarOrClockPickerView>(
    views: ReadonlyArray<CalendarOrClockPickerView> | undefined,
    options: Array<View>
): Array<View> => {
    const array = (views || []) as Array<string>;
    const result = array.filter((item): item is View => options.includes(item as View));
    return result;
};

const getOpenTo = <View extends string = CalendarOrClockPickerView>(
    openTo: CalendarOrClockPickerView | undefined,
    options: Array<View>
): View | undefined => (openTo && options.includes(openTo as View) ? (openTo as View) : undefined);
