import React from 'react';
import classnames from 'classnames';
import { Popover } from '@mui/material';
import { Avatar, Typography, Link, Icon } from 'components';
import { useIntl } from 'react-intl';
import { UserPopoverProps } from './UserPopover.types';
import useStyles from './UserPopover.styles';
import { getCountryCode, isFeatureEnabled } from '../../../utils';
import { UserCompanyLink } from './UserCompanyLink';
import NextLink from '../../NextLink';

const UserPopover = (props: UserPopoverProps) => {
    const { user, links, open, anchorEl, onClose, hasGroupMembership } = props;
    const classes = useStyles();
    const { locale, formatMessage } = useIntl();

    return (
        <Popover
            anchorEl={anchorEl}
            open={open}
            PaperProps={{ className: classes.popoverContainer }}
            onClose={onClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <div className={classes.container}>
                <NextLink
                    href={links.dashboard}
                    className={classnames(classes.centered, classes.avatarContainer)}
                >
                    <Avatar
                        className={classes.avatar}
                        email={user.email}
                        firstName={user.firstName}
                        lastName={user.lastName}
                    />
                    <Typography
                        title={`${user.firstName} ${user.lastName}`}
                        fontFamily="Poppins"
                        color="primary"
                        className={classes.userNameTitle}
                    />
                </NextLink>
                <div className={classes.divider} />
                <UserCompanyLink
                    user={user}
                    hasGroupMembership={hasGroupMembership}
                    links={links}
                />
                <NextLink href={links.dashboard} className={classes.regularLink}>
                    <Icon icon="event" color="primary" className={classes.linkIcon} />
                    {formatMessage({ id: 'common.header.user-navbar.popover.my-cme' })}
                </NextLink>
                <NextLink href={links.profile} className={classes.regularLink}>
                    <Icon icon="accountCircle" color="primary" className={classes.linkIcon} />
                    {formatMessage({ id: 'common.header.user-navbar.popover.user-management' })}
                </NextLink>
                {isFeatureEnabled('memberships', getCountryCode(locale)) && (
                    <NextLink href={links.dashboardMembership} className={classes.regularLink}>
                        <Icon icon="fomfShape" color="primary" className={classes.linkIcon} />
                        {formatMessage({ id: 'common.header.user-navbar.popover.membership' })}
                    </NextLink>
                )}
                <div className={classes.divider} />
                <Link
                    onClick={links.logout}
                    className={classnames(classes.regularLink, classes.logOutLink)}
                >
                    <Icon icon="logout" color="primary" className={classes.linkIcon} />
                    {formatMessage({ id: 'common.header.user-navbar.popover.logout' })}
                </Link>
            </div>
        </Popover>
    );
};

export default UserPopover;
