import React from 'react';

import noop from '../utils/noop';
import { useTextFieldStyles } from './SearchBox.styles';
import { TextField } from '../TextField';
import type { SearchBoxProps } from './SearchBox.types';

const SearchBox = (props: SearchBoxProps) => {
    const {
        placeholder,
        value = '',
        onClear = noop,
        onEnter,
        onBlur,
        classes: classesProp,
        InputProps: InputPropsProp,
        ...others
    } = props;
    const inputRef = React.useRef<HTMLInputElement>(null);

    const classes = useTextFieldStyles(props);

    const handleClear = () => {
        onClear();
        inputRef.current?.focus();
    };

    const onKeyPress: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
        if (onEnter && event.key === 'Enter') {
            if (value) {
                onEnter();
            } else {
                // remove focus if press Enter without search input
                // especially useful on mobile
                inputRef.current?.blur();
            }
        }
    };

    return (
        <TextField
            classes={classes}
            icon="search"
            hideClearIcon={value.length === 0}
            value={value}
            onClear={handleClear}
            onKeyPress={onKeyPress}
            onBlur={onBlur}
            InputProps={{
                ...InputPropsProp,
                inputRef,
                classes: {
                    input: classes.input
                },
                placeholder
            }}
            {...others}
        />
    );
};

export default SearchBox;
