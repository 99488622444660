import { Box, DialogContent } from '@mui/material';
import { Button, Checkbox, Dialog, Logo, Typography } from 'components';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
    Mutations,
    getLocalizedLink,
    PRIVACY_LINKS,
    isFeatureEnabled,
    getCountryCode,
    Types,
    useUserContext
} from '../../';
import useExecMutation from '../../hooks/useExecMutation';
import { useStyles } from './PrivacyPolicyModal.styles';

export const PrivacyPolicyModal = () => {
    const { locale } = useIntl();
    const classes = useStyles();

    const isDataSharingVisible = isFeatureEnabled('dataSharingConsent', getCountryCode(locale));

    const { user } = useUserContext();
    const [isPrivacyPolicyModalOpened, setIsPrivacyPolicyModalOpened] = useState(false);

    const [isPrivacyPolicyAccepted, setIsPrivacyPolicyAccepted] = useState(false);
    const [dataSharingConsent, setDataSharingConsent] = useState<boolean | null>(
        isDataSharingVisible ? user?.dataSharingConsent || false : null
    );

    const [updateUser, { loading }] = Mutations.useUpdateUserDetailsForUserMutation();
    const execMutation = useExecMutation();

    useEffect(() => {
        if (user && !user.privacyPolicyConfirmation) {
            // Sometimes there are redirects happening because of sign up flow.
            // Wait for a second to show the modal to prevent this
            setTimeout(() => {
                setIsPrivacyPolicyModalOpened(true);
            }, 1000);
        }
    }, [user?.privacyPolicyConfirmation]);

    useEffect(() => {
        isDataSharingVisible && user && setDataSharingConsent(user.dataSharingConsent);
    }, [user?.dataSharingConsent]);

    const isSubmitButtonDisabled = !isPrivacyPolicyAccepted || loading || !user;

    const handleSubmit = async () => {
        if (isSubmitButtonDisabled) {
            return;
        }

        const updateUserRequest: Types.UpdateUserRequest = {
            privacyPolicyConfirmation: isPrivacyPolicyAccepted
        };

        if (typeof dataSharingConsent === 'boolean') {
            updateUserRequest.dataSharingConsent = dataSharingConsent;
        }

        await execMutation(updateUser, {
            variables: {
                userId: user.id,
                updateUserRequest
            }
        });

        setIsPrivacyPolicyModalOpened(false);
    };

    return (
        <Dialog open={isPrivacyPolicyModalOpened} maxWidth="md" classes={{ paper: classes.paper }}>
            <DialogContent className={classes.content}>
                <Logo locale={locale} type="compact" className={classes.logo} />
                <Typography
                    localeId="common.privacy-policy-modal.title"
                    variant="textLarge"
                    className={classes.title}
                />
                <Typography
                    localeId="common.privacy-policy-modal.subtitle"
                    variant="textMedium"
                    className={classes.subtitle}
                />
                <Checkbox
                    checked={isPrivacyPolicyAccepted}
                    onChange={() => setIsPrivacyPolicyAccepted(!isPrivacyPolicyAccepted)}
                    labelLocaleId="common.privacy-policy.accept-label"
                    labelLocaleValues={{
                        linkStart: `<a href="${getLocalizedLink(
                            PRIVACY_LINKS,
                            locale
                        )}" target="_blank">`,
                        linkEnd: '</a>'
                    }}
                    className={classes.checkbox}
                    classes={{
                        checkmarkContainer: classes.checkboxCheckmarkContainer
                    }}
                />
                {isDataSharingVisible && (
                    <>
                        <Checkbox
                            checked={dataSharingConsent}
                            onChange={() => setDataSharingConsent(!dataSharingConsent)}
                            labelLocaleId="common.data-sharing-consent.label"
                            className={classes.checkbox}
                            classes={{
                                checkmarkContainer: classes.checkboxCheckmarkContainer
                            }}
                        />
                        <Typography
                            localeId="common.data-sharing-consent.description"
                            variant="textXSmall"
                            className={classes.description}
                        />
                    </>
                )}
                <Box className={classes.buttonContainer}>
                    <Button
                        localeId="common.continue-label"
                        disabled={isSubmitButtonDisabled}
                        onClick={handleSubmit}
                        size="large"
                        uppercase={false}
                        className={classes.button}
                    />
                </Box>
                {isDataSharingVisible && (
                    <Typography
                        localeId="common.data-sharing-consent.footnote"
                        variant="textXXSmall"
                        className={classes.footnote}
                    />
                )}
            </DialogContent>
        </Dialog>
    );
};
