import makeStyles from '@mui/styles/makeStyles';
import type { ThemeTypes } from '../';

const useStyles = makeStyles<ThemeTypes.Theme>((theme) => ({
    divider: {
        marginBottom: theme.spacing(2)
    },
    country: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    flag: {
        margin: theme.spacing(0, 1)
    }
}));

export default useStyles;
