import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import type { ThemeTypes } from '../';

const useStyles = makeStyles(
    (theme: ThemeTypes.Theme) =>
        createStyles({
            root: {
                zIndex: 1,
                width: 48,
                height: 48,
                display: 'flex',
                borderRadius: '50%',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: theme.palette.secondary.light
            },
            icon: {
                color: theme.palette.primary.main,
                width: 28,
                height: 28
            }
        }),
    { name: 'StepIcon', index: 1 }
);

export default useStyles;
