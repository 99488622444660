import { createApolloClient, Queries } from 'common';

const getContentByContentId = async (contentId: string, cookie?: string) => {
    const client = createApolloClient({ batchHttpLinkOptions: { headers: { cookie } } });
    const { data, error = null } = await client.query<Queries.GetContentByContentIdQuery | null>({
        query: Queries.GetContentByContentId,
        variables: { contentId },
        errorPolicy: 'ignore'
    });
    const content = data?.content;
    return { content, error };
};

export default getContentByContentId;
