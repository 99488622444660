import React from 'react';

import useStyles from './BulletPoints.styles';

import BulletPoint from './BulletPoint';
import { BulletPointsProps } from './BulletPoints.types';

const BulletPoints = (props: BulletPointsProps) => {
    const { items, 'data-testid': dataTestId } = props;
    const classes = useStyles({});

    return (
        <div data-testid={dataTestId}>
            {items.map((item) => (
                <div className={classes.itemContainer} key={item.localeId || item.text}>
                    <BulletPoint {...item} />
                </div>
            ))}
        </div>
    );
};

export default BulletPoints;
