import makeStyles from '@mui/styles/makeStyles';
import { ThemeTypes } from '../../';
import { CalendarProps, CalendarClasses } from './Calendar.types';

const useStyles = makeStyles<ThemeTypes.Theme, CalendarProps, CalendarClasses>((theme) => ({
    root: {
        display: 'flex',
        padding: theme.spacing(2),
        background: theme.palette.common.white,
        borderRadius: 8,
        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1)'
    },
    monthStart: {
        marginRight: theme.spacing(3)
    }
}));

export default useStyles;
